import React, { useState } from "react";
import { Link } from "react-router-dom";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import LoadMore from "@components/LoadMore/LoadMore";
import MetaTags from "@components/MetaTags/MetaTags";
import { ImageModal } from "@events-awards/components/EventsAwardsGalleryImageModal";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import EventsAwardsQuickLinks from "@events-awards/components/EventsAwardsQuickLinks";
import { AWARDS_EVENTS_PAGE_ROW_DATA } from "@events-awards/utils/constants";
import { getAllImages } from "@events-awards/utils/helpers";
import {
  AwardDataRow,
  EventsAwardsGalleryContextData,
} from "@events-awards/utils/types";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsGalleryYearPageContent({
  title,
  articles,
  awardType,
}: EventsAwardsGalleryContextData): React.ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [visibleImages, setVisibleImages] = useState(30);

  const awardData: AwardDataRow | undefined = AWARDS_EVENTS_PAGE_ROW_DATA.find(
    (data) => data.awardType === awardType
  );

  const awardPath = awardData?.blockTitleProps?.link || "";

  const handleImageClick = (imageUrl: string): void => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const adBlockDetected = useDetectAdBlock();

  // Get all images from all articles
  const allImages = getAllImages(articles);

  const handleLoadMore = async () => {
    setVisibleImages((prev) => prev + 30);
  };

  const hasMoreImages = allImages?.length > visibleImages;

  return (
    <div
      className="min-h-screen w-full bg-gray-50"
      data-testid="events-awards-page"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={`${awardPath}/gallery`}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title={`${awardData?.blockTitleProps?.text}_Gallery`}
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`The Business Times ${awardData?.blockTitleProps?.text} - ${awardData?.description}`}
        ogType="website"
        slug={awardPath}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 mb-8">
            <EventsAwardsLogoMenu awardType={awardType} />
            <div className="mx-auto max-w-4xl py-8">
              <div className="mb-6 flex items-center gap-3 border-b border-gray-200">
                <Link
                  to={`${awardData?.blockTitleProps?.link}/gallery`}
                  className="inline-flex items-center text-2xl text-gray-900 no-underline hover:text-gray-700"
                >
                  <div className="flex items-center px-4 py-3">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="mr-3"
                      size="sm"
                    />
                    <span className="font-medium">{articles?.[0].title}</span>
                  </div>
                </Link>
              </div>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                {allImages?.slice(0, visibleImages).map((image) => (
                  <div
                    key={image.id}
                    className="aspect-video cursor-pointer overflow-hidden bg-white shadow-sm"
                    onClick={() => {
                      handleImageClick(
                        image.relation.fields["landscape-caas"].url
                      );
                    }}
                  >
                    <img
                      src={image.relation.fields["landscape-caas"].url}
                      alt={
                        image.fields?.find((f) => f.name === "caption")
                          ?.value || ""
                      }
                    />
                  </div>
                ))}
              </div>

              {hasMoreImages && (
                <div className="mt-8">
                  <LoadMore
                    onLoadMore={handleLoadMore}
                    hasMore={hasMoreImages}
                  />
                </div>
              )}
            </div>
            <EventsAwardsQuickLinks awardType={awardType} />
          </Column>
        </Row>
      </Container>

      <ImageModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        image={selectedImage}
      />
      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
