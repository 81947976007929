import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { useWindowSize } from "@hooks/useWindowSize";
import SingaporeGeComponentFactory from "@pages/SingaporeGe/2025/components/SingaporeGeComponentFactory";
import { SingaporeGEBasicProps } from "@pages/SingaporeGe/2025/utils/types";
import { cn } from "@util/helpers";

export default function SingaporeGeRelatedStories({
  relatedStories,
  comingUp,
  configData: parentConfig,
  highlights,
  index,
}: SingaporeGEBasicProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  const displayChildren =
    parentConfig?.children && parentConfig?.children?.length > 0;

  const isVisible =
    (parentConfig?.onlyMobile && !isScreenLG) ||
    (parentConfig?.onlyDesktop && isScreenLG) ||
    (!parentConfig?.onlyDesktop && !parentConfig?.onlyMobile);

  return (
    <Row rootClassName="mb-0" data-testid="ge-2025-bottom-section-component">
      <>
        {isVisible ? (
          <Column
            rootClassName={cn("w-full lg:w-8/12 mb-8", {
              "lg:w-full": !displayChildren,
            })}
          >
            <SingaporeGeComponentFactory
              configData={parentConfig}
              highlights={highlights}
              relatedStories={relatedStories}
              comingUp={comingUp}
              index={index}
              parentIndex={index}
            />
          </Column>
        ) : null}
      </>

      <>
        {displayChildren ? (
          <Column rootClassName="mb-8 w-full lg:w-4/12">
            {parentConfig?.children?.map((childConfig, childIndex) => {
              const isVisible =
                (childConfig?.onlyMobile && !isScreenLG) ||
                (childConfig?.onlyDesktop && isScreenLG) ||
                (!childConfig?.onlyDesktop && !childConfig?.onlyMobile);

              if (!isVisible) return <></>;

              return (
                <SingaporeGeComponentFactory
                  key={`${childConfig.type}-child-${childIndex}`}
                  configData={childConfig}
                  highlights={relatedStories}
                  relatedStories={highlights}
                  comingUp={comingUp}
                  index={childIndex}
                  parentIndex={index}
                />
              );
            })}
          </Column>
        ) : null}
      </>
    </Row>
  );
}
