import { Link } from "react-router-dom";
import logoE50 from "@assets/events-awards/logo-e50.png";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import { e50NavigationMenu } from "@events-awards/E50/utils/constants";
import { EVENTS_AWARDS_E50_PATH } from "@events-awards/utils/constants";

export default function EventsAwardsE50LogoMenu(): React.ReactElement {
  return (
    <>
      <Link to={EVENTS_AWARDS_E50_PATH}>
        <img
          src={logoE50}
          alt="E50 logo"
          width={120}
          height={162}
          className="mx-auto"
        />
      </Link>

      <EventsAwardsNavigationMenu
        menuData={e50NavigationMenu}
        linkClassName="after:bg-eventAwards-sca-accent"
      />
    </>
  );
}
