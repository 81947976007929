import { Link } from "react-router-dom";
import { TestIdClassNameProps } from "@app/types/Common";
import sgbudget2024Logo from "@assets/logo-budget2024-v3.svg";
import cx from "classnames";

type SGBudget2024BannerProps = TestIdClassNameProps & {
  copyText: string;
  link: string;
  buttonText: string;
  buttonClass?: string;
  imgClass?: string;
  headerClass?: string;
};

export default function SGBudget2024Banner({
  rootClassName,
  copyText,
  link,
  buttonText,
  buttonClass = "bg-gray-850 border-gray-850",
  imgClass,
  headerClass = "ml-2 border-l border-gray-850 border-opacity-20 pl-2",
}: SGBudget2024BannerProps): React.ReactElement {
  return (
    <div
      className={cx(
        "flex items-center justify-between gap-4 bg-gray-250 px-2 py-4",
        rootClassName
      )}
    >
      <div className="flex items-center">
        <img
          src={sgbudget2024Logo}
          width={40}
          height={56}
          alt="Singapore Budget 2024"
          className={imgClass}
        />
        <h3
          className={cx(
            "py-2 font-poppins text-xs font-normal lg:text-xl",
            headerClass
          )}
        >
          {copyText}
        </h3>
      </div>

      <Link
        to={link}
        target="_blank"
        className={cx(
          "whitespace-nowrap rounded-full border px-5 py-2 font-poppins text-4xs font-semibold text-white",
          "lg:px-12 lg:text-base",
          buttonClass
        )}
      >
        {buttonText}
      </Link>
    </div>
  );
}
