import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { useWindowSize } from "@hooks/useWindowSize";
import MyAccountLoginProfile from "@pages/MyAccount/components/MyAccountLoginProfile";
import MyAccountLogin from "@pages/MyAccount/components/MyAccountLoginTrigger";
import MyAccountPopover from "@pages/MyAccount/components/MyAccountPopover/MyAccountPopover";
import useOKTAUserStore from "@store/useOKTAUserStore";

export default function MyAccountUserHeader(): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userLoginId = OKTAUserInfo?.loginid;

  if (isScreenLG) return <MyAccountPopover />;

  return (
    <div
      className="relative flex-none"
      data-testid="my-account-header-mobile-component"
    >
      {userLoginId ? (
        <Link
          to={RouteFactory.myAccount}
          reloadDocument
          className="relative mr-2 flex h-8 w-8 items-center justify-center rounded-full border border-gray-175 p-3 font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850 duration-300 lg:hover:bg-gray-250"
        >
          <MyAccountLoginProfile
            firstCharOfLogin={userLoginId.charAt(0).toUpperCase()}
          />
        </Link>
      ) : (
        <MyAccountLogin />
      )}
    </div>
  );
}
