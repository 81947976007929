import { Helmet } from "react-helmet-async";

import noAdsCssString from "./NoAdSettings.head.css?raw";

export default function NoAdSettings(): React.ReactElement {
  return (
    <Helmet>
      <style type="text/css">{noAdsCssString}</style>
    </Helmet>
  );
}
