import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import { VideosDetailsPageContext } from "@pages/Videos/utils/types";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import VideosDetailsPageContent from "./VideosDetailsPageContent";

export default function VideosDetailsPage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<VideosDetailsPageContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: VideosDetailsPageContext) => {
        if (kind !== "static") {
          return <CustomError statusCode={context.statusCode} />;
        }

        return <VideosDetailsPageContent data={data} />;
      }}
    </Await>
  );
}
