import Ad from "@components/Advertisement/Ad";
import { useWindowSize } from "@hooks/useWindowSize";

type BreakingNewsDynamicAdsProps = Pick<
  React.HTMLAttributes<HTMLDivElement>,
  "className"
> & {
  articleCounter: number;
};

export default function BreakingNewsDynamicAds({
  className,
  articleCounter,
}: BreakingNewsDynamicAdsProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const iterator = isScreenLG ? 10 : 5;
  const adType = isScreenLG
    ? DesktopAdsFactory(articleCounter, iterator)
    : MobileAdsFactory(articleCounter, iterator);

  return (
    <>
      {articleCounter % iterator === 0 && adType ? (
        <Ad adType={adType} className={className} />
      ) : null}
    </>
  );
}

const DesktopAdsFactory = (index: number, iterator: number) => {
  if (index === iterator * 1) return "bn1";
  if (index === iterator * 2) return "bn2";
  if (index === iterator * 3) return "bn3";
  if (index === iterator * 4) return "bn4";
};

const MobileAdsFactory = (index: number, iterator: number) => {
  if (index === iterator * 1) return "bn1";
  if (index === iterator * 2) return "imu1";
  if (index === iterator * 3) return "bn2";
  if (index === iterator * 4) return "imu2";
  if (index === iterator * 5) return "bn3";
  if (index === iterator * 6) return "imu3";
  if (index === iterator * 7) return "bn4";
};
