import type { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { cn } from "@util/helpers";

export default function HubBrandedListingStories({
  article,
  index,
  articles,
}: {
  articles: SanitizedSectionArticle[];
  article: SanitizedSectionArticle;
  index: number;
}): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });

  const { title, id, kicker, media, blurb, updated, sections, urlPath } =
    article;

  return (
    <div ref={ref} data-testid="branded-content-stories">
      <BasicCard
        rootClassName={cn("story pt-6 pb-6 items-stretch", {
          "pt-0": index === 0,
          "pb-0": index === articles.length - 1,
        })}
        key={id}
        id={id}
        title={title}
        kicker={kicker?.fields?.[0].value}
        media={media?.[0]}
        blurb={blurb}
        updated={updated}
        slug={urlPath}
        defaultImage={{
          directoryName: sections?.[0]?.uniqueName || "",
        }}
        variations={{
          image: {
            position: "right",
            width: "w-120px md:w-1/4",
            extraClass: "order-1 md:order-2",
          },
          content: {
            width: "w-full-120px md:w-3/4",
            extraClass: "order-2 pl-4 md:order-1 md:pr-4 md:pl-0",
          },
          title: {
            size: "text-base",
            color: "text-gray-850",
          },
        }}
      />
    </div>
  );
}
