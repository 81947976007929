import myBTTabletImage from "@assets/img-mybt-1.svg";

import GetStartedButton from "./GetStartedButton";

export default function Section2(): React.ReactElement {
  return (
    <div className="w-full bg-gray-850 px-3 pb-36 pt-8">
      <div className="text-center">
        <img
          src={myBTTabletImage}
          alt="MyBT Tablet"
          width={350}
          height={480}
          className="mx-auto -mt-36 mb-32 block"
        />

        <h3 className="mb-14 font-lct text-10xl font-bold">
          <span className="text-gray-225">Personalise your </span>
          <span className="text-gray-510">
            news <br /> experience.
          </span>
        </h3>

        <h3 className="mb-14 font-lct text-10xl font-bold">
          <span className="text-gray-225">Choose keywords that</span>
          <br />
          <span className="text-gray-510">matter to you most</span>
        </h3>

        <h3 className="mb-14 font-lct text-10xl font-bold">
          <span className="text-gray-225">
            Read the latest on myBT <br /> and
          </span>
          <span className="text-gray-510"> stay in the know.</span>
        </h3>

        <GetStartedButton rootClassName="inline-block border-white bg-white text-gray-850 hover:bg-gray-850 hover:text-white" />
      </div>
    </div>
  );
}
