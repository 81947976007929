import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import leftArrow from "@assets/icon-chevron-right-dark.svg";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import MyAccountLoginProfile from "@pages/MyAccount/components/MyAccountLoginProfile";
import MyAccountPopoverActiveTabs from "@pages/MyAccount/components/MyAccountPopover/MyAccountPopoverActiveTabs";
import MyAccountTabs from "@pages/MyAccount/components/MyAccountTabs";
import { MyAccountTabsEnum } from "@pages/MyAccount/utils/types";
import useOKTAUserStore from "@store/useOKTAUserStore";
import { cn } from "@util/helpers";

export default function MyAccountPopover(): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userLoginId = OKTAUserInfo?.loginid;

  const sendDataToGTM = useGTMDispatch();
  const firstCharOfLogin = userLoginId?.charAt(0).toUpperCase();
  const [activeTab, setActiveTab] = useState<MyAccountTabsEnum>(
    MyAccountTabsEnum.myBT
  );

  const handlePopoverGA = () => {
    if (typeof window === "undefined") return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "profile",
      eventAction: "click",
      eventLabel: window.location.href,
    });
  };

  const handleMyAccountGA = () => {
    if (typeof window === "undefined") return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "my account",
      eventAction: "click",
      eventLabel: window.location.href,
    });
  };

  return (
    <Popover
      className="relative block flex-none"
      data-testid="my-account-popover-component"
    >
      <PopoverButton
        data-testid="my-account-popover-button"
        className={cn(
          "rounded-[25px] p-3 font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850 duration-300 lg:hover:bg-gray-250",
          {
            "flex h-8 w-8 items-center justify-center rounded-full border border-gray-175 ":
              userLoginId,
          },
          REMOVE_FOCUS_CSS
        )}
        onClick={handlePopoverGA}
      >
        {userLoginId ? (
          <MyAccountLoginProfile firstCharOfLogin={firstCharOfLogin} />
        ) : (
          <p className="flex-none">Sign in / Sign up for free</p>
        )}
      </PopoverButton>

      <Transition>
        <TransitionChild
          as={Fragment}
          enter="transition ease duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease duration-150 transform"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0"
        >
          <PopoverPanel
            data-testid="my-account-popover-panel"
            anchor={{ to: "bottom end" }}
            className="relative z-[100000] overflow-hidden rounded-md bg-white shadow-myAccountPopOver"
          >
            {userLoginId ? (
              <Link
                reloadDocument
                to={RouteFactory.myAccount}
                className="group mx-auto flex items-center justify-center border-b border-gray-175 py-6 font-poppins font-semibold text-gray-850"
                onClick={handleMyAccountGA}
              >
                <div className="flex items-center justify-center border-b border-transparent transition-all duration-300 group-hover:border-gray-850">
                  <span>My Account</span>
                  <img src={leftArrow} alt="arrow" width={24} height={24} />
                </div>
              </Link>
            ) : null}

            <MyAccountTabs
              handleTabChange={setActiveTab}
              activeTab={activeTab}
            />

            <MyAccountPopoverActiveTabs activeTab={activeTab} />
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  );
}
