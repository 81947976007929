import { Link } from "react-router-dom";
import logoSIA from "@assets/events-awards/logo-sia.png";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import {
  EVENTS_AWARDS_SIA_PATH,
  siaNavigationMenu,
} from "@events-awards/utils/constants";

export default function EventsAwardsSIALogoMenu(): React.ReactElement {
  return (
    <>
      <Link to={EVENTS_AWARDS_SIA_PATH}>
        <img
          src={logoSIA}
          alt="Sustainability Impact Awards logo"
          width={180}
          height={74}
          className="mx-auto"
        />
      </Link>

      <EventsAwardsNavigationMenu
        menuData={siaNavigationMenu}
        linkClassName="after:bg-eventAwards-sia-accent"
      />
    </>
  );
}
