import { filteredRecentSearchQuery } from "@pages/Search/utils/helpers";
import type { RecentSearchObject } from "@pages/Search/utils/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface recentSearchStore {
  searchQuery: RecentSearchObject[];
  setSearchQuery: (article: RecentSearchObject) => void;
  deleteAllSearchQuery: () => void;
}

const useRecentSearchStore = create(
  persist<recentSearchStore>(
    (set) => ({
      searchQuery: [],
      setSearchQuery: (searchQuery: RecentSearchObject) => {
        set((store) => {
          const updatedSearchQuery = [searchQuery, ...store.searchQuery];

          const filteredSearch = filteredRecentSearchQuery(
            searchQuery,
            updatedSearchQuery
          );

          localStorage.setItem(
            "bt-recent-search",
            JSON.stringify({ searchQuery: filteredSearch })
          );
          return { searchQuery: filteredSearch };
        });
      },
      deleteAllSearchQuery: () => {
        set(() => {
          localStorage.setItem(
            "bt-recent-search",
            JSON.stringify({ searchQuery: [] })
          );
          return { searchQuery: [] };
        });
      },
    }),
    {
      name: "bt-recent-search", // unique name
      storage: createJSONStorage(() => localStorage), // Specify localStorage as the storage option
    }
  )
);

export default useRecentSearchStore;
