import { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { getSeasonBasedOnDate } from "@pages/Section/layouts/Lifestyle/helpers";
import { cn } from "@util/helpers";
import dayjs from "dayjs";

import SeasonDivider from "./SeasonDivider";

type LifestyleLuxeArticlesProps = {
  initialArticles: SanitizedSectionArticle[];
  overviewArticles: SanitizedSectionArticle[];
  article: SanitizedSectionArticle;
  todaysSeason?: string;
  index: number;
};

export default function LifestyleLuxeArticles({
  article,
  index,
  todaysSeason,
  initialArticles,
  overviewArticles,
}: LifestyleLuxeArticlesProps): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });

  const {
    id,
    title: headline,
    media,
    kicker,
    sections,
    urlPath,
    blurb,
    updated: publishedDate,
  } = article;

  const previousIndex = index - 1 > 0 ? index - 1 : 0;
  const previousSeason =
    index <= initialArticles.length
      ? todaysSeason
      : getSeasonBasedOnDate(overviewArticles[previousIndex].updated);
  const year = dayjs(publishedDate).year();
  const currentSeason = getSeasonBasedOnDate(publishedDate);
  const isDisplayDivider =
    index > initialArticles.length - 1 && previousSeason !== currentSeason;

  return (
    <>
      {isDisplayDivider ? (
        <SeasonDivider
          season={currentSeason}
          year={year}
          className="col-span-1 md:col-span-6"
        />
      ) : null}

      <div
        className={cn(
          "col-span-1 h-full w-full pb-4 pt-5 first:border-t-0 md:col-span-2 md:border-0 md:py-0",
          {
            "md:col-span-3": [0, 1].includes(index),
            "border-t border-gray-175": !isDisplayDivider,
          }
        )}
        ref={ref}
      >
        <BasicCard
          key={id}
          id={id}
          title={headline}
          media={media?.[0]}
          kicker={kicker?.fields?.[0].value}
          defaultImage={{
            directoryName: sections?.[0].uniqueName || "",
          }}
          blurb={blurb}
          slug={urlPath}
          variations={{
            image: {
              position: "",
              width: "w-full",
              aspectRatio:
                "aspect-3x2 md:aspect-[348/385] lg:aspect-3x2 group-hover/card:lg:scale-[1.02] transition-all ease-in duration-[350ms] h-auto object-cover w-full",
              extraClass: "overflow-hidden",
              filters: [
                {
                  breakpoint: "(min-width: 992px)",
                  w: 574,
                  h: 382,
                  q: 100,
                  dpr: 1,
                  f: "webp",
                },
                {
                  breakpoint: "(min-width: 768px)",
                  w: 348,
                  h: 385,
                  q: 100,
                  dpr: 1,
                  f: "webp",
                },
                { w: 351, h: 234, q: 100, dpr: 1, f: "webp" },
              ],
            },
            content: {
              width: "w-full",
              extraClass: "pt-3",
              blurb: {
                extraClass:
                  "font-oceanSansStd mt-3 !block break-words text-lg font-light leading-[1.3] text-gray-750",
              },
            },
            title: {
              color: "!text-gray-850",
              size: "text-4xl md:text-8xl",
              extraClass:
                "font-ivyMode my-0 break-words font-semibold uppercase leading-tight transition-all duration-[350ms] hover:underline hover:underline-offset-4",
            },
            kicker: {
              color: "text-verticals-lifestyle-luxe",
              extraClass:
                "text-xs tracking-17% font-oceanSansStd font-extrabold mb-1",
            },
          }}
          rootClassName="group/card"
        />
      </div>
    </>
  );
}
