import { useState } from "react";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import LoadMore from "@components/LoadMore/LoadMore";
import MetaTags from "@components/MetaTags/MetaTags";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import EventsAwardsQuickLinks from "@events-awards/components/EventsAwardsQuickLinks";
import { AWARDS_EVENTS_PAGE_ROW_DATA } from "@events-awards/utils/constants";
import {
  AwardDataRow,
  EventsAwardsWinnersContextData,
} from "@events-awards/utils/types";
import { useLoadMoreEventsWinners } from "@hooks/useLoadMoreEventsWinners";
import { processArticleElements } from "@pages/Section/layouts/EventsAwardsPage/utils/helpers";

export default function EventsAwardsWinnerPage({
  title,
  articles,
  awardType,
}: EventsAwardsWinnersContextData): React.ReactElement {
  const [expandedYear, setExpandedYear] = useState(articles?.[0]?.title || "");

  const { articleList, handleLoadMore, hasMoreArticles } =
    useLoadMoreEventsWinners(articles, 10, awardType);

  const awardData: AwardDataRow | undefined = AWARDS_EVENTS_PAGE_ROW_DATA.find(
    (data) => data.awardType === awardType
  );

  const toggleYear = (year: string) => {
    setExpandedYear(expandedYear === year ? "" : year);
  };

  // Get the path and slot from award data
  const awardPath = awardData?.blockTitleProps?.link || "";

  return (
    <div
      className="min-h-screen w-full bg-gray-50"
      data-testid="events-awards-page"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={`${awardPath}/winners`}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title={`${awardData?.blockTitleProps?.text}_Winners`}
        level2="events awards"
      />

      <MetaTags
        title={title}
        description={`The Business Times ${awardData?.blockTitleProps?.text} - ${awardData?.description}`}
        ogType="website"
        slug={awardPath}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <EventsAwardsLogoMenu awardType={awardType} />

            <div className="mx-auto max-w-4xl py-8">
              {articleList?.map((article) => (
                <div key={article.title} className="mb-4 border-b">
                  <button
                    onClick={() => {
                      toggleYear(article.title);
                    }}
                    className="flex w-full items-center justify-between px-4 py-4 text-left transition-colors "
                  >
                    <span className="font-public-sans text-base font-semibold">
                      {article.title.toUpperCase()}
                    </span>
                    <svg
                      className="block w-[12px] transition-all duration-300 group-hover:fill-gray-850"
                      style={{
                        transform:
                          expandedYear === article.title
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 12 8"
                      fill="none"
                    >
                      <path
                        d="M1 1.33398C5 5.33398 6 6.33398 6 6.33398L11 1.33398"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>

                  {expandedYear === article.title && (
                    <div className="border-t border-gray-100 px-4 py-6">
                      <div className="space-y-8">
                        {processArticleElements(article.elements).map(
                          (award, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-1 gap-4 md:grid-cols-3"
                            >
                              <div>
                                <h2 className="tracking-wider text-left text-sm font-medium uppercase">
                                  {award.title}
                                </h2>
                              </div>

                              <div className="space-y-1 text-left">
                                {award.recipient &&
                                  (award.type === "paragraph" ? (
                                    <h3 className="text-sm font-medium">
                                      {award.recipient}
                                    </h3>
                                  ) : (
                                    <div data-testid="table-component">
                                      <table className="w-full table-auto">
                                        <tbody className="dark:bg-slate-800 bg-gray-50">
                                          {(typeof award.recipient === "string"
                                            ? JSON.parse(award.recipient)
                                            : award.recipient
                                          ).map(
                                            (row: string[], index: number) => (
                                              <tr key={index}>
                                                {row.map((cell, cellIndex) => (
                                                  <td
                                                    key={cellIndex}
                                                    className="border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400 border-b p-4"
                                                  >
                                                    {cell}
                                                  </td>
                                                ))}
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  ))}
                              </div>
                              <div className="space-y-1 text-left">
                                {award.position && (
                                  <p className="mb-0 text-sm text-gray-600">
                                    {award.position}
                                  </p>
                                )}
                                {award.organization && (
                                  <p className="text-sm text-gray-600">
                                    {award.organization}
                                  </p>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}

              {hasMoreArticles ? (
                <LoadMore
                  onLoadMore={handleLoadMore}
                  hasMore={hasMoreArticles}
                  loadText="MORE"
                />
              ) : null}
            </div>

            <EventsAwardsQuickLinks awardType={awardType} />
          </Column>
        </Row>
      </Container>
      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
