import { useEffect } from "react";
import { useFirstMountState } from "react-use";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetRecentArticleList } from "@pages/MyAccount/hooks/useGetRecentArticleList";
import { GaObject } from "@pages/MyAccount/utils/types";
import useOKTAUserStore from "@store/useOKTAUserStore";
import useRecentReadStore from "@store/useRecentReadStore";

import MyAccountRecentReadPopoverEmptyState from "./MyAccountRecentReadPopoverEmptyState";
import MyAccountRecentReadPopoverList from "./MyAccountRecentReadPopoverList";

export default function MyAccountRecentReadPopover(): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userLoginId = OKTAUserInfo?.loginid;

  const sendDataToGTM = useGTMDispatch();
  const isFirstMount = useFirstMountState();
  const articleIdList =
    useRecentReadStore((state) => state.articleIdList) || [];

  const { readArticleList, isLoading } = useGetRecentArticleList({
    articleIdList,
    size: 5,
  });

  const isEmptyState = readArticleList.length === 0;
  const displayViewMore = articleIdList.length > 5;

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!isFirstMount) return;

    const gaObject: GaObject = {
      event: "custom_event",
      eventCategory: "recent read",
      eventAction: "visible",
      eventLabel: window.location.href,
    };

    sendDataToGTM(gaObject);
  }, [sendDataToGTM, isFirstMount]);

  return (
    <div
      data-testid="my-account-recent-read-popover-component"
      className="w-[360px] p-6"
    >
      {!userLoginId || (isEmptyState && !isLoading) ? (
        <MyAccountRecentReadPopoverEmptyState />
      ) : null}

      {!isEmptyState && userLoginId && !isLoading ? (
        <MyAccountRecentReadPopoverList
          articleList={readArticleList}
          displayViewMore={displayViewMore}
        />
      ) : null}

      {isLoading && userLoginId ? (
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} size="2x" spin className="mb-6" />
        </div>
      ) : null}
    </div>
  );
}
