import { useEffect, useState } from "react";
import {
  BrightcovePlaylistResponse,
  BrightcoveVideo,
} from "@components/Brightcove/utils/types";
import axios from "axios";
import { first } from "lodash-es";

export const useBrightcoveFeaturedVideos = () => {
  const [videos, setVideos] = useState<BrightcovePlaylistResponse>([]);
  const [activeVideo, setActiveVideo] = useState<BrightcoveVideo>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideosData = async () => {
      const response = await axios.post<BrightcovePlaylistResponse>(
        "/_plat/api/v1/featured-videos"
      );

      setVideos(response.data || []);
      setIsLoading(false);
    };

    fetchVideosData();
  }, []);

  useEffect(() => {
    const firstVideo = first(videos);

    if (typeof firstVideo === "undefined") return;

    setActiveVideo(firstVideo);
  }, [videos]);

  return { activeVideo, videos, isLoading, setActiveVideo };
};
