import { HTMLAttributes } from "react";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { SanitizedSectionArticle } from "@typings/Cue";

export type BookletProps = {
  article: SanitizedSectionArticle;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function Booklet({
  className,
  article,
}: BookletProps): React.ReactElement {
  const attachmentLink =
    article.attachments && article.attachments.length > 0
      ? article.attachments?.[0].content["attachmentLink-caas"]
      : "";

  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-3/10",
      filters: [
        {
          breakpoint: "(min-width: 1200px)",
          w: 228,
          h: 152,
          dpr: 1,
          f: "webp",
        },
        {
          breakpoint: "(min-width: 992px)",
          w: 186,
          h: 124,
          dpr: 1,
          f: "webp",
        },
        { w: 420, h: 280, dpr: 1, f: "webp" },
      ],
      loading: "eager",
    },
    content: {
      width: "w-7/10",
      extraClass: "pl-4",
    },
    kicker: {
      color: "text-gray-515",
    },
    title: {
      size: "text-lg",
      color: "text-gray-850",
    },
  };

  if (!attachmentLink) return <></>;

  return (
    <div className={className} data-testid="booklet-component">
      <BlockTitle
        text="MTI BUSINESS BUDGET BOOK"
        rootClassName="mb-4 !text-lg lg:!text-3xl"
      />

      <div className="group relative flex gap-4">
        <BasicCard
          id={article.id}
          title={article.displaySetting?.displayHeadline || article.title}
          media={article.media}
          slug={attachmentLink}
          rootClassName="items-stretch"
          variations={variant}
        />
      </div>
    </div>
  );
}
