import type { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { useWindowSize } from "@hooks/useWindowSize";
import { isArticleUpdated } from "@util/helpers";

type MorePodcastsStoriesProps = {
  article: SanitizedSectionArticle;
  index: number;
};

export default function MorePodcastsStories({
  article: {
    id,
    title,
    displaySetting,
    sections,
    kicker,
    media,
    blurb,
    urlPath,
    paywall,
    edited,
    updated,
  },
  index,
}: MorePodcastsStoriesProps): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });
  const { isScreenLG } = useWindowSize();

  const variant: CardVariations = {
    kicker: {
      color: "text-gray-515",
      extraClass: "mb-3",
    },
    image: {
      width: "w-1/4",
      position: "left",
      extraClass: "order-1 lg:order-2",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 600, h: 400, dpr: 1, f: "webp" },
        { w: 300, h: 200, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: "w-3/4",
      extraClass: "order-2 pl-4 lg:pl-0 lg:pr-4 lg:order-1",
      blurb: {
        extraClass: "text-gray-850 !leading-normal mt-3",
      },
    },
    subscriberLabel: {
      extraClass: "mt-3",
    },
    title: {
      size: "text-base",
      color: "text-gray-850",
      extraClass: "text-base !leading-tight text-black !my-0",
    },
  };

  return (
    <div className="story py-4" ref={ref}>
      <BasicCard
        id={id}
        title={title}
        kicker={kicker?.fields?.[0]?.value}
        media={media}
        blurb={isScreenLG ? blurb : undefined}
        slug={`${urlPath}?card_name=more_podcasts&position=${index + 1}`}
        paywall={paywall?.contentAccess === "1"}
        variations={variant}
        edited={isArticleUpdated(updated, edited) ? edited : undefined}
        hasUpdatedTimestampDisplay={displaySetting?.hasUpdatedTimeDisplayed}
        defaultImage={{
          directoryName: sections?.[0]?.uniqueName || "",
        }}
      />
    </div>
  );
}
