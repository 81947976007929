import { useInView } from "react-intersection-observer";
import { useLocation, useSearchParams } from "react-router-dom";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export type useSetPageParamProps = {
  cardIndex: number;
  pageSize?: number;
};

export const useSetPageParam = ({
  cardIndex,
  pageSize = 10,
}: useSetPageParamProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const sendDataToGTM = useGTMDispatch();

  const setGaData = (page: string) => {
    const [parent] = pathname.replace(/^\/+/, "").split("/");
    const parentName = parent?.replaceAll("-", "_");

    const gaTitle = pathname.endsWith("/latest")
      ? `${parentName}_latest_index`
      : `${parentName}_index`;

    sendDataToGTM({
      event: "virtual_pv",
      level2: gaTitle,
      contentcat: 1,
      contenttype: "",
      pagination: parseInt(page),
      title: gaTitle,
      virtual_url: `${pathname.substring(1)}?page=${page}`,
    });
  };

  const setURLParams = (page: string) => {
    if (page === "1" && searchParams.has("page")) {
      searchParams.delete("page");
      setSearchParams(searchParams, { preventScrollReset: true });
      setGaData(page);
      return;
    }

    if (page !== "1") {
      searchParams.set("page", page);
      setSearchParams(searchParams, { preventScrollReset: true });
    }

    setGaData(page);
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (cardIndex % pageSize !== 0) return;
      if (!inView) return;

      const page = (cardIndex / pageSize + 1).toString();
      setURLParams(page);
    },
  });

  return {
    ref,
  };
};
