import { RouteFactory } from "@app/routePaths";
import logoDTA from "@assets/events-awards/logo-dta.png";
import logoE50 from "@assets/events-awards/logo-e50.png";
import logoEE from "@assets/events-awards/logo-ee.png";
import logoSBA from "@assets/events-awards/logo-sba.svg";
import logoSCA from "@assets/events-awards/logo-sca.png";
import logoSIA from "@assets/events-awards/logo-sia.png";
import { EventsAwardsPageRowProps } from "@events-awards/components/EventsAwardsPageRow";
import { AwardsMenu, AwardType } from "@events-awards/utils/types";
import { sectionNavigationItems } from "@util/constant";

export const EVENTS_AWARDS_PATH = RouteFactory.section("events-awards");
export const EVENTS_AWARDS_SLOT = EVENTS_AWARDS_PATH.replace("/", "");
export const EVENTS_AWARDS_LABEL =
  sectionNavigationItems[EVENTS_AWARDS_SLOT]?.label;

export const EVENTS_AWARDS_E50_PATH = RouteFactory.section(
  "events-awards/enterprise-50"
);
export const EVENTS_AWARDS_E50_SLOT = EVENTS_AWARDS_E50_PATH.replace("/", "");
export const EVENTS_AWARDS_E50_LABEL =
  sectionNavigationItems[EVENTS_AWARDS_E50_SLOT]?.label;

export const EVENTS_AWARDS_EE_PATH = RouteFactory.section(
  "events-awards/emerging-enterprise"
);
export const EVENTS_AWARDS_EE_SLOT = EVENTS_AWARDS_EE_PATH.replace("/", "");
export const EVENTS_AWARDS_EE_LABEL =
  sectionNavigationItems[EVENTS_AWARDS_EE_SLOT]?.label;

export const EVENTS_AWARDS_SBA_PATH = RouteFactory.section(
  "events-awards/singapore-business-awards"
);
export const SBA_URL_PATTERN = /\/events-awards\/singapore-business-awards\//;
export const EVENTS_AWARDS_SBA_SLOT = EVENTS_AWARDS_SBA_PATH.replace("/", "");
export const EVENTS_AWARDS_SBA_LABEL =
  sectionNavigationItems[EVENTS_AWARDS_SBA_SLOT]?.label;

export const EVENTS_AWARDS_SCA_PATH = RouteFactory.section(
  "events-awards/singapore-corporate-awards"
);
export const SCA_URL_PATTERN = /\/events-awards\/singapore-corporate-awards\//;
export const EVENTS_AWARDS_SCA_SLOT = EVENTS_AWARDS_SCA_PATH.replace("/", "");
export const EVENTS_AWARDS_SCA_LABEL =
  sectionNavigationItems[EVENTS_AWARDS_SCA_SLOT]?.label;

export const EVENTS_AWARDS_DTA_PATH = RouteFactory.section(
  "events-awards/design-ai-and-tech-awards"
);
export const DTA_URL_PATTERN = /\/events-awards\/design-ai-and-tech-awards\//;
export const EVENTS_AWARDS_DTA_SLOT = EVENTS_AWARDS_DTA_PATH.replace("/", "");
export const EVENTS_AWARDS_DTA_LABEL =
  sectionNavigationItems[EVENTS_AWARDS_DTA_SLOT]?.label;

export const SIA_URL_PATTERN =
  /\/events-awards\/sustainability-impact-awards\//;
export const EVENTS_AWARDS_SIA_PATH = RouteFactory.section(
  "events-awards/sustainability-impact-awards"
);
export const EVENTS_AWARDS_SIA_SLOT = EVENTS_AWARDS_SIA_PATH.replace("/", "");
export const EVENTS_AWARDS_SIA_LABEL =
  sectionNavigationItems[EVENTS_AWARDS_SIA_SLOT]?.label;

export const AWARDS_EVENTS_PAGE_ROW_DATA: ({
  awardType: AwardType;
} & Omit<EventsAwardsPageRowProps, "article">)[] = [
  {
    awardType: AwardType.DATA,
    blockTitleProps: {
      text: "Design AI and Tech AWARDS",
      link: RouteFactory.section("events-awards/design-ai-and-tech-awards"),
      rootClassName: "!text-lg mb-3",
    },
    description:
      "The Design AI and Tech Awards encourages innovators from all enterprises — startups, SMEs, and large corporations - to harness design and technology to develop groundbreaking solutions which tackle real business challenges.",
    logoAttributes: {
      src: logoDTA,
      alt: "Design AI and Tech AWARDS logo",
      width: 330,
      height: 44,
    },
  },
  {
    awardType: AwardType.SBA,
    blockTitleProps: {
      text: "SINGAPORE BUSINESS AWARDS",
      link: RouteFactory.section("events-awards/singapore-business-awards"),
      rootClassName: "!text-lg mb-3",
    },
    description:
      "The Singapore Business Awards was launched in 1985, starting with The Businessman of the Year Award. The following year, the Enterprise Award was introduced to recognise small- and medium-sized enterprises.",
    logoAttributes: {
      src: logoSBA,
      alt: "Singapore Business Awards logo",
      width: 330,
      height: 44,
    },
  },
  {
    awardType: AwardType.SCA,
    blockTitleProps: {
      text: "SINGAPORE CORPORATE AWARDS",
      link: RouteFactory.section("events-awards/singapore-corporate-awards"),
      rootClassName: "!text-lg mb-3",
    },
    description:
      "The Singapore Corporate Awards was launched on 19 September 2005 as the umbrella awards for exemplary corporate governance practices for listed companies in Singapore. It seeks to consolidate existing awards while introducing new awards in the area of excellent corporate governance.",
    logoAttributes: {
      src: logoSCA,
      alt: "Singapore Corporate Awards logo",
      width: 130,
      height: 169,
    },
  },
  {
    awardType: AwardType.E50,
    blockTitleProps: {
      text: "ENTERPRISE 50",
      link: RouteFactory.section("events-awards/enterprise-50"),
      rootClassName: "!text-lg mb-3",
    },
    description:
      "The E50 Awards, first established in 1995, seek to recognise the 50 most enterprising local, privately held companies who have contributed to the economic development of Singapore, both locally and abroad.",
    logoAttributes: {
      src: logoE50,
      alt: "Enterprise 50 logo",
      width: 115,
      height: 106,
    },
  },
  {
    awardType: AwardType.EE,
    blockTitleProps: {
      text: "EMERGING ENTERPRISE",
      link: RouteFactory.section("events-awards/emerging-enterprise"),
      rootClassName: "!text-lg mb-3",
    },
    description:
      "No annual award better honours the bold ambition of young businesses under ten years old than the Emerging Enterprise Award. Since 2008, the award has been empowering startups and young enterprises with both recognition and resources to take flight and achieve stellar growth.",
    logoAttributes: {
      src: logoEE,
      alt: "Emerging Enterprise logo",
      width: 175,
      height: 92,
    },
  },
  {
    awardType: AwardType.SIA,
    blockTitleProps: {
      text: "SUSTAINABILITY IMPACT AWARDS",
      link: RouteFactory.section("events-awards/sustainability-impact-awards"),
      rootClassName: "!text-lg mb-3",
    },
    description:
      "The Sustainability Impact Awards (SI Awards), launched on 1 February 2023, is jointly presented by The Business Times and UOB, with the Centre for Governance and Sustainability (CGS) at the National University of Singapore Business School as the knowledge partner.",
    logoAttributes: {
      src: logoSIA,
      alt: "Sustainability Impact Awards logo",
      width: 200,
      height: 73,
    },
  },
];

export const sbaNavigationMenu: AwardsMenu[] = [
  {
    text: "ABOUT",
    link: "/events-awards/singapore-business-awards/about",
    enabled: true,
  },
  {
    text: "AWARDS",
    link: "/events-awards/singapore-business-awards/awards",
    enabled: true,
  },
  {
    text: "WINNERS",
    link: "/events-awards/singapore-business-awards/winners",
    enabled: true,
  },
  {
    text: "NEWS",
    link: "/events-awards/singapore-business-awards/news",
    enabled: true,
  },
  {
    text: "GALLERY",
    link: "/events-awards/singapore-business-awards/gallery",
    enabled: true,
  },
  {
    text: "HOW TO NOMINATE",
    link: "/events-awards/singapore-business-awards/how-to-nominate",
    enabled: true,
  },
];

export const sbaQuickLinksMenu: AwardsMenu[] = [
  {
    text: "CONTACT US",
    link: "/events-awards/singapore-business-awards/contact-us",
    enabled: true,
  },
];

export const scaNavigationMenu: AwardsMenu[] = [
  {
    text: "ABOUT",
    link: "/events-awards/singapore-corporate-awards/about",
    enabled: true,
  },
  {
    text: "AWARDS",
    link: "/events-awards/singapore-corporate-awards/awards",
    enabled: true,
  },
  {
    text: "WINNERS",
    link: "/events-awards/singapore-corporate-awards/winners",
    enabled: true,
  },
  {
    text: "NEWS",
    link: "/events-awards/singapore-corporate-awards/news",
    enabled: true,
  },
  {
    text: "GALLERY",
    link: "/events-awards/singapore-corporate-awards/gallery",
    enabled: true,
  },
  {
    text: "HOW TO APPLY",
    link: "/events-awards/singapore-corporate-awards/how-to-apply",
    enabled: true,
  },
];

export const scaQuickLinksMenu: AwardsMenu[] = [
  {
    text: "CONTACT US",
    link: "/events-awards/singapore-corporate-awards/contact-us",
    enabled: true,
  },
  {
    text: "FAQ",
    link: "/events-awards/singapore-corporate-awards/faq",
    enabled: true,
  },
  {
    text: "PARTNERS",
    link: "/events-awards/singapore-corporate-awards/partners",
    enabled: true,
  },
];
export const siaNavigationMenu: AwardsMenu[] = [
  {
    text: "ABOUT",
    link: "/events-awards/sustainability-impact-awards/about",
    enabled: true,
  },
  {
    text: "AWARDS",
    link: "/events-awards/sustainability-impact-awards/awards",
    enabled: true,
  },
  {
    text: "WINNERS",
    link: "/events-awards/sustainability-impact-awards/winners",
    enabled: true,
  },
  {
    text: "NEWS",
    link: "/events-awards/sustainability-impact-awards/news",
    enabled: true,
  },
  {
    text: "GALLERY",
    link: "/events-awards/sustainability-impact-awards/gallery",
    enabled: true,
  },
  {
    text: "HOW TO NOMINATE",
    link: "/events-awards/sustainability-impact-awards/how-to-nominate",
    enabled: true,
  },
];

export const siaQuickLinksMenu: AwardsMenu[] = [
  {
    text: "CONTACT US",
    link: "/events-awards/sustainability-impact-awards/contact-us",
    enabled: true,
  },
  {
    text: "PARTNERS",
    link: "/events-awards/sustainability-impact-awards/partners",
    enabled: true,
  },
  {
    text: "JUDGES",
    link: "/events-awards/sustainability-impact-awards/judges",
    enabled: true,
  },
];

export const AwardTypeMap: Record<string, AwardType> = {
  "singapore-corporate-awards": AwardType.SCA,
  "enterprise-50": AwardType.E50,
  "emerging-enterprise": AwardType.EE,
  "singapore-business-awards": AwardType.SBA,
  "sustainability-impact-awards": AwardType.SIA,
  "design-ai-and-tech-awards": AwardType.DATA,
};

export const E50_URL_PATTERN = /\/events-awards\/enterprise-50\//;

export const EE_URL_PATTERN = /\/events-awards\/emerging-enterprise\//;
export const dtaQuickLinksMenu: AwardsMenu[] = [
  {
    text: "CONTACT US",
    link: "/events-awards/design-ai-and-tech-awards/contact-us",
    enabled: true,
  },
  {
    text: "PARTNERS",
    link: "/events-awards/design-ai-and-tech-awards/partners",
    enabled: true,
  },
  {
    text: "JUDGES",
    link: "/events-awards/design-ai-and-tech-awards/judges",
    enabled: true,
  },
];

export const dtaNavigationMenu: AwardsMenu[] = [
  {
    text: "ABOUT",
    link: "/events-awards/design-ai-and-tech-awards/about",
    enabled: true,
  },
  {
    text: "AWARDS",
    link: "/events-awards/design-ai-and-tech-awards/awards",
    enabled: true,
  },
  {
    text: "NEWS",
    link: "/events-awards/design-ai-and-tech-awards/news",
    enabled: true,
  },
  {
    text: "HOW TO NOMINATE",
    link: "/events-awards/design-ai-and-tech-awards/how-to-nominate",
    enabled: true,
  },
];
