import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";
import { getQuerylyParmsList } from "@pages/Search/utils/helpers";
import {
  FetchSearchDataParams,
  FetchSearchDataProps,
  QuerylySearchFacetedDataResponse,
  QuerylySearchResultsResponse,
  SearchResultsItems,
} from "@pages/Search/utils/types";
import useRecentSearchStore from "@store/useRecentSearchStore";
import axios from "axios";
import dayjs from "dayjs";

const fetchQuerylySearchData = async (paramList: FetchSearchDataParams) => {
  try {
    if (!paramList) return;

    const response = await axios.get<QuerylySearchResultsResponse>(
      `/_plat/api/v1/queryly-search`,
      {
        params: paramList,
        timeout: 10000,
      }
    );

    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching queryly list", error);
    return;
  }
};

export const useQuerylySearchFetchData = ({
  query,
  sortBy,
  selectedFacetedAllData,
}: FetchSearchDataProps) => {
  const [searchData, setSearchResults] = useState<SearchResultsItems[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [endindex, setEndIndex] = useState(0);
  const [facetedData, setFacetedData] =
    useState<QuerylySearchFacetedDataResponse>();
  const [displayLoadMore, setDisplayLoadMore] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryObject = useRecentSearchStore(
    (searchQueryStore) => searchQueryStore.setSearchQuery
  );

  const fetchSearchData = async ({
    query,
    sortBy,
    isHandleLoadMore,
    isDefaultEndIndex,
    selectedFacetedAllData,
  }: FetchSearchDataProps) => {
    setIsLoading(true);

    setQueryObject({
      query,
      dateSearch: dayjs().format("MM/DD/YYYY"),
    });

    const parameterList = getQuerylyParmsList({
      query,
      sortBy,
      endindex: isDefaultEndIndex ? 0 : endindex,
      selectedFacetedAllData,
    });

    const data = await fetchQuerylySearchData(parameterList);
    const searchResults = data?.items || [];

    const prevSearchData = searchData || [];
    const allSearchResults = isHandleLoadMore
      ? [...prevSearchData, ...searchResults]
      : searchResults;

    setSearchResults(allSearchResults);

    setEndIndex(data?.metadata?.endindex || 0);

    const displayLoadMore =
      (data?.metadata?.total || 0) <= allSearchResults.length;
    setDisplayLoadMore(!displayLoadMore);

    searchParams.set("query", query);
    setSearchParams(searchParams, { preventScrollReset: true });

    setFacetedData(data?.faceted);
    setIsLoading(false);
  };

  const handleClearSearchData = () => {
    setSearchResults(undefined);
    setEndIndex(0);
    setFacetedData(undefined);
    setDisplayLoadMore(false);
    setSearchParams();
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (!inView) return;
      if (!displayLoadMore) return;

      fetchSearchData({
        query,
        sortBy,
        isHandleLoadMore: true,
        isDefaultEndIndex: false,
        selectedFacetedAllData,
      });
    },
  });

  return {
    ref,
    searchData,
    isLoading,
    displayLoadMore,
    facetedData,
    handleClearSearchData,
    fetchSearchData,
  };
};
