import { articleTimestampFormat } from "@util/constant";
import { getFormattedTime } from "@util/helpers";
import dayjs from "dayjs";

export type ArticleTimeStampProps = {
  publishedDate: string;
  updatedDate: string;
  hasUpdatedTimeDisplayed?: boolean;
};

export default function ArticleTimeStamp({
  publishedDate,
  updatedDate,
  hasUpdatedTimeDisplayed = false,
}: ArticleTimeStampProps): React.ReactElement {
  const isEditedBeforePublished = dayjs(publishedDate) > dayjs(updatedDate);

  return (
    <div className="font-public-sans text-xs font-normal leading-6 text-gray-650">
      <span data-testid="article-published-time">
        Published {getFormattedTime(publishedDate, articleTimestampFormat)}
      </span>

      {hasUpdatedTimeDisplayed && !isEditedBeforePublished ? (
        <span data-testid="article-updated-time">
          {` — Updated ${getFormattedTime(updatedDate, articleTimestampFormat)}`}
        </span>
      ) : null}
    </div>
  );
}
