import { useEffect } from "react";
import { useFirstMountState } from "react-use";
import { Loading } from "@components/LoadMore/LoadMore";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useGetMyBTArticleList } from "@pages/MyAccount/hooks/useGetMyBTArticleList";
import { GaObject } from "@pages/MyAccount/utils/types";
import useMyBtStore from "@store/useMyBtStore";

import MyAccountMyBTPageEmptyState from "./MyAccountMyBTPageEmptyState";
import MyAccountMyBTPageList from "./MyAccountMyBTPageList";

export default function MyAccountMyBtPage(): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const isFirstMount = useFirstMountState();

  const userTags = useMyBtStore((store) => store.tags);
  const { myBTArticleList, isLoading, ref } = useGetMyBTArticleList({
    tags: userTags,
  });

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!isFirstMount) return;

    const userKeywords = userTags?.map(({ name }) => name).join("|");

    const gaObject: GaObject = {
      event: "custom_event",
      eventCategory: "mybt",
      eventAction: "visible",
      eventLabel: window.location.href,
    };

    if (userKeywords) {
      gaObject["user_keyword"] = userKeywords;
    }

    sendDataToGTM(gaObject);
  }, [userTags, sendDataToGTM, isFirstMount]);

  return (
    <div data-testid="my-account-my-bt-page-component" className="w-full">
      {myBTArticleList.length > 0 ? (
        <MyAccountMyBTPageList
          tagWithArticleList={myBTArticleList}
          loadMoreRef={ref}
        />
      ) : (
        <MyAccountMyBTPageEmptyState />
      )}

      <Loading isLoading={isLoading} />
    </div>
  );
}
