import { Link } from "react-router-dom";
import Container from "@components/Container/Container";
import {
  copyright,
  footerNavigationItems,
} from "@components/Footer/utils/constant";
import type { NavigationItem } from "@components/Footer/utils/types";
import cx from "classnames";

export default function FooterBottom(): React.ReactElement {
  return (
    <Container>
      <div className="w-full border-t py-4">
        <ul className="flex flex-wrap justify-center gap-3 font-public-sans text-4xs text-gray-650 md:gap-5">
          {footerNavigationItems.map(
            (navigationItem?: Pick<NavigationItem, "link" | "label">) => {
              return navigationItem ? (
                <li key={navigationItem.link}>
                  <Link
                    to={`${navigationItem.link}?ref=footer`}
                    className={cx(
                      "capitalize leading-[1.375rem] tracking-[0.00294rem] hover:underline"
                    )}
                    reloadDocument
                  >
                    {navigationItem.label}
                  </Link>
                </li>
              ) : null;
            }
          )}
        </ul>
        <div className="py-4 text-center font-public-sans text-4xs leading-[1.375rem] tracking-[0.00294rem] text-gray-650">
          <p className="mb-0">{copyright.content}</p>
        </div>
      </div>
    </Container>
  );
}
