import { Tag } from "@app/types/Cue";

import { EXPLORE_MORE } from "./constants";

export const getExploreMoreObjectByTags = (tags: Tag[]) => {
  const exploreMoreObj = EXPLORE_MORE.filter((data) =>
    data.tags?.some((objectTag) =>
      tags.some((tag) => tag.urlPath === objectTag.urlPath)
    )
  );

  return exploreMoreObj;
};
