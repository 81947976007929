import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { NavigationItem } from "@app/types/Navigation";
import logoBtBlack from "@assets/logo-bt-mobile-black.svg";
import logoThrive from "@assets/thrive-logo.svg";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

export type StickyBreadcrumbMainProps = {
  /** The parent category data. */
  parentCategoryData: NavigationItem;
};

/** The very irst breadcrumb in the sticky breadcrumb in the header. */
export default function StickyBreadcrumbMain({
  parentCategoryData: { link, label, stickyHeaderLabel },
}: StickyBreadcrumbMainProps): React.ReactElement {
  const { isScreenLG, isScreenXL } = useWindowSize();

  const testid = "sticky-breadcrumb-main-component";

  if (link.startsWith(RouteFactory.thrive)) {
    return (
      <>
        {isScreenXL ? (
          <div
            className="flex flex-row flex-nowrap items-center"
            data-testid={testid}
          >
            <Link
              to={`${RouteFactory.home}?ref=logo`}
              rel="noreferer"
              className={cn(
                "flex aspect-square h-9 w-auto cursor-pointer items-center justify-center rounded-full opacity-100 duration-300",
                "focus-within:outline-0 focus:outline-0",
                "lg:hover:rounded-full lg:hover:bg-gray-250"
              )}
            >
              <img
                src={logoBtBlack}
                width={24}
                height={20}
                className="w-6"
                alt="BT logo"
              />
            </Link>

            <div
              className="mx-3 hidden h-[24px] w-[1px] flex-none bg-gray-175 lg:block"
              role="separator"
            ></div>

            <Link
              to={`${RouteFactory.thrive}?ref=logo`}
              rel="noreferer"
              className={cn(
                "mr-4 flex h-9 w-auto cursor-pointer items-center justify-center rounded-full px-3 opacity-100 duration-300",
                "focus-within:outline-0 focus:outline-0",
                "lg:hover:rounded-full lg:hover:bg-gray-250"
              )}
            >
              <img src={logoThrive} width={75} height={18} alt="Thrive logo" />
            </Link>
          </div>
        ) : null}
      </>
    );
  }

  return (
    <>
      {isScreenLG ? (
        <div
          className="flex flex-row flex-nowrap items-center"
          data-testid={testid}
        >
          <Link
            className={cn(
              "m-0 p-3 font-poppins text-2xs font-bold leading-none tracking-tightest text-gray-850"
            )}
            to={`${link}?ref=listing-menubar`}
            reloadDocument
          >
            {stickyHeaderLabel || label}
          </Link>
        </div>
      ) : null}
    </>
  );
}
