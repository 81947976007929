import { RouteFactory } from "@app/routePaths";
import { Budget2025Data } from "@app/types/Page";
import budgetLogo from "@assets/budget/logo-2025-v1.png";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import KeywordFilter from "@components/KeywordFilter";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { TagType } from "@pages/Section/types";
import Highlights from "@pages/SingaporeBudget/2025/components/Highlights";
import LatestNews from "@pages/SingaporeBudget/2025/components/LatestNews";
import PodcastOverview from "@pages/SingaporeBudget/2025/components/PodcastOverview";
import RelatedStoriesOverview from "@pages/SingaporeBudget/2025/components/RelatedStoriesOverview";
import Videos from "@pages/SingaporeBudget/2025/components/Videos";
import Views from "@pages/SingaporeBudget/2025/components/Views";
import Whatsapp from "@pages/SingaporeBudget/2025/components/Whatsapp";
import { cn } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import Booklet from "./components/Booklet";
import Infographics from "./components/Infographics";

type SingaporeBudget2025Props = {
  title: string;
  data: Budget2025Data;
};

export default function SingaporeBudget2025({
  title,
  data: {
    highlights,
    latestNews,
    views,
    relatedStories,
    podcastsArticle,
    mtiArticle,
    infographicArticle,
    seoFooterData,
  },
}: SingaporeBudget2025Props): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  const infographicLink = infographicArticle?.displaySetting.externalURL || "";

  return (
    <div className="w-full" data-testid="singapore-budget-2025-component">
      <AdSettings
        adNames={["lb1", "imu2", "catfish", "abm"]}
        path={RouteFactory.keywordsBudget2025}
        adTargets={[
          { key: "page", value: "listing" },
          {
            key: "bttags",
            value: getFormattedTextForAds("Singapore Budget 2025"),
          },
        ]}
      />

      <MetaTags
        title={title}
        description={
          "Deputy Prime Minister Lawrence Wong will present Singapore's Budget 2025 in Parliament on Feb 18. Get the latest updates on BT's dedicated microsite."
        }
        ogType="website"
        slug="/singapore-budget-2025"
        keywords={[
          "singapore budget 2025 summary",
          "how much singapore reserves",
          "who approved singapore budget",
          "singapore budget news",
          "singapore budget quiz",
          "singapore budget 2025 date",
          "singapore budget 2025 payout",
          "singapore budget 2025 announcement",
          "budget 2025 payout date",
          "singapore budget on education",
          "how much singapore spent on covid",
          "singapore budget over the years",
        ]}
      />

      <GAData
        title="Singapore_Budget_2025"
        level2="miscellaneous"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header parentCategory={"keywords/singapore-budget-2025"} />

      <PulseEntry />

      <Container rootClassName="pt-6">
        <div className="mb-6 flex items-center justify-between border-b border-gray-850 pb-3">
          <img
            src={budgetLogo}
            width={150}
            height={60}
            alt="Singapore Budget 2025"
            className="w-[103px] lg:w-[150px]"
          />

          <KeywordFilter
            followtextButton
            tag={{
              name: "Singapore Budget 2025",
              uri: RouteFactory.keywordsBudget2025,
              aliases: [],
              fields: [],
              id: "singapore-budget-2025",
              parent: null,
              type: TagType.Keyword,
              urlPath: RouteFactory.keywordsBudget2025,
              urlPathHistory: [],
              sections: [],
            }}
            rootClassName="inline-block"
            tagClassName="hidden"
          />
        </div>

        <Row rootClassName="mb-12">
          <Column rootClassName="w-full lg:w-8/12">
            <Highlights articles={highlights} className="mb-6 lg:mb-0" />
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            {infographicArticle ? (
              <Infographics
                className={
                  "mb-10 border-t border-gray-850 pt-2 lg:mb-8 lg:border-none"
                }
                article={infographicArticle}
              />
            ) : null}

            <LatestNews
              articles={latestNews}
              className={cn("mb-6 border-t border-gray-850 pt-2 lg:mb-4", {
                "lg:border-none": !infographicLink,
              })}
            />

            {mtiArticle ? (
              <Booklet
                className="border-t border-gray-850 pt-2"
                article={mtiArticle}
              />
            ) : null}
          </Column>
        </Row>

        <Row rootClassName="mb-10">
          <Column rootClassName="w-full lg:w-8/12">
            <Views
              articles={views}
              className="border-top border-gray-850 pb-10 pt-2 lg:pb-0"
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 lg:px-5">
            <Whatsapp />
          </Column>
        </Row>

        <Videos rootClassName="border-top border-gray-850 pt-2 mb-10" />

        <Row rootClassName={"mb-10"}>
          <PodcastOverview podcastArticle={podcastsArticle} />
        </Row>

        <Row rootClassName="mb-10">
          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <RelatedStoriesOverview
              articles={relatedStories}
              className="border-top border-gray-850 pt-2"
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <Ad
              adType="imu2"
              className="flex items-center justify-center lg:sticky lg:top-20"
            />
          </Column>
        </Row>
      </Container>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
