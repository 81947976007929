import { useLocation } from "react-router-dom";
import { AuthorContext } from "@app/types/Page";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import { getAuthorJSONLD } from "@util/helpers";

import AuthorArticles from "./components/AuthorArticles";
import AuthorDetails from "./components/AuthorDetails";

export type AuthorPageContentProps = Pick<AuthorContext, "data">;

export default function AuthorPageContent({
  data: { title, profile, articles, seoFooterData },
}: AuthorPageContentProps): React.ReactElement {
  const breakingNews = useTrimmedBreakingNews();
  const { pathname } = useLocation();

  const {
    fields: { name, bio_raw },
    urlPath,
    headshotImage,
  } = profile;

  return (
    <>
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path="/authors"
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData title={name.replaceAll(" ", "_")} level2="authors" />

      <MetaTags
        title={title}
        description={bio_raw}
        ogType="article"
        slug={urlPath || pathname}
        jsonLd={getAuthorJSONLD(profile)}
        imageUrl={headshotImage?.[0]?.content.fields["original-caas"]?.url}
      />

      <div className="w-full" data-testid="author-page-content">
        <Ad
          adType="lb1"
          className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
        />

        <Header />

        <PulseEntry />

        <Container rootClassName="py-8">
          <Row>
            <Column rootClassName="w-full lg:w-8/12">
              <AuthorDetails {...profile} />

              <AuthorArticles articles={articles} profileId={profile.id} />
            </Column>

            <Column rootClassName="w-full lg:w-4/12">
              <div className="mb-10 lg:h-[800px]">
                <Ad
                  adType="imu2"
                  className="flex flex-col items-center justify-center lg:sticky lg:top-[76px]"
                />
              </div>

              <BreakingNews
                data={breakingNews}
                rootClassName="max-w-[300px] mx-auto mb-10"
              />
            </Column>
          </Row>
        </Container>
      </div>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </>
  );
}
