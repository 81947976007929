import { FormEvent, useState } from "react";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import {
  Newsletter,
  NEWSLETTER_REQUESTS_RESPONSES,
} from "@components/Newsletter/types/Newsletter";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Transition } from "@headlessui/react";
import { newsletterRequests } from "@pages/Newsletter/SignUp/Newsletter.server";
import { cn } from "@util/helpers";

import DefaultSuccessMessage from "./DefaultSuccessMessage";

export type NewsletterFormProps = {
  /** The class name of the component. */
  className?: string;
  /** List of newsletters. */
  newsletters: Newsletter[];
  /** The success message/component to display when user successfully signed up. */
  successMessage?: string | React.ReactNode;
};

/** The newsletter sign up form, which contains input email and a submit button. */
export default function NewsletterForm({
  className,
  newsletters,
  successMessage = <DefaultSuccessMessage />,
}: NewsletterFormProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();
  const sendDataToGTM = useGTMDispatch();

  const hasError = emarsysResponse?.status === "error";

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Display the loading icon.
    setIsLoading(true);

    // Register GA even for button click.
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "newsletter",
      eventAction: "click",
      eventLabel: "sign up",
    });

    // Get the value of email input field.
    const form = event.currentTarget;
    const formData = new FormData(form);
    const email = formData.get("email");

    // If by somehow the email value is empty.
    // Return an error.
    if (!email || typeof email !== "string") {
      setEmarsysResponse(NEWSLETTER_REQUESTS_RESPONSES.EMAILISREQUIRED);
      setIsLoading(false);
      return;
    }

    // Request to emarsys.
    const request = async () => {
      await newsletterRequests
        .processSubscription(email, newsletters)
        .then((response) => {
          setEmarsysResponse(response);

          if (response.status === "success") {
            sendDataToGTM({
              event: "custom_event",
              eventCategory: "newsletter",
              eventAction: "signup",
              eventLabel: "successful sign up",
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    // Call the request.
    request();
  };

  return (
    <div data-testid="newsletter-form-component" className={className}>
      <Transition show={emarsysResponse?.status === "success"}>
        <div
          className="ease py-2 transition duration-300 data-[closed]:translate-y-9 data-[closed]:opacity-0"
          data-testid="success-message-component"
        >
          {successMessage}
        </div>
      </Transition>

      {emarsysResponse?.status !== "success" ? (
        <form
          onSubmit={onSubmit}
          className="relative flex flex-nowrap items-center gap-4 bg-white pb-1"
        >
          <Input
            aria-label="Email"
            placeholder="Your email address"
            name="email"
            type="email"
            className="peer w-full py-2 font-poppins text-xs font-normal leading-7 outline-none placeholder:text-gray-515 autofill:bg-white"
            required
            autoComplete="off"
          />

          <Button
            type="submit"
            className={cn(
              "text-nowrap font-poppins text-xs font-semibold leading-normal tracking-tightest text-gray-850",
              "w-[103px] rounded-full border border-gray-175 bg-white px-6 py-2 text-center transition-colors",
              "hover:border-gray-850"
            )}
          >
            {isLoading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                size="lg"
                color="text-white"
                spin
              />
            ) : (
              "Sign up"
            )}
          </Button>

          <div
            className={cn(
              "absolute top-full w-full border-b border-gray-350 transition-colors peer-focus:border-gray-850",
              { "border-red": hasError }
            )}
          ></div>
        </form>
      ) : null}

      {hasError ? (
        <p className="py-2 font-poppins text-xs font-medium leading-normal text-red">
          {emarsysResponse.message}
        </p>
      ) : null}
    </div>
  );
}
