import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  RSS_FEEDS_PAGE_METATAGS,
  RSSFEEDS_DATA,
} from "@pages/Content/constants";
import { DefaultNavigationData } from "@typings/Page";
import { useDetectAdBlock } from "adblock-detect-react";

export type Item = {
  title: string;
  url: string;
  subItems?: Item[];
};

export type ContentData = {
  news: {
    title: string;
    items: Item[];
  };
  weekly: {
    title: string;
    items: Item[];
  };
  topStories: {
    title: string;
    items: Item[];
  };
};

export type SectionListProps = {
  title: string;
  items: Item[];
};

export function SectionList({
  title,
  items,
}: SectionListProps): React.ReactElement {
  return (
    <>
      <h3 className="mb-2 mt-8 font-public-sans text-2xl font-bold">{title}</h3>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <div className="title-link">
              <a href={item.url} className="text-base font-normal text-black">
                {item.title}
              </a>
            </div>
            {item.subItems && (
              <ul className="pl-8">
                {item.subItems.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <div className="title-link">
                      <a
                        href={subItem.url}
                        className="text-base font-normal text-black"
                      >
                        {subItem.title}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

export default function RSSFeedPage({
  seoFooterData,
}: DefaultNavigationData): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={RouteFactory.rssFeeds}
      />

      <MetaTags {...RSS_FEEDS_PAGE_METATAGS} />

      <GAData
        title="RSS_Feeds_Landing_Page"
        level2="miscellaneous"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <div className="container !mt-6 px-3 font-poppins">
        {/* RSS Feeds Section */}
        <div>
          <a href="/rss-feeds" target="_blank" rel="noreferrer">
            <h2 className="mx-0 my-2.5 mb-5 pb-2 font-lct text-8xl font-bold">
              RSS Feeds
            </h2>
          </a>
          <div>
            <h3 className="mb-2 font-public-sans text-2xl font-bold">
              What is RSS?
            </h3>
            <div className="mb-6 font-public-sans text-base font-normal">
              RSS or Really Simple Syndication is an easy way to keep updated
              automatically on websites you like. Instead of having to visit the
              site to see if it has fresh content, you will get alerts to check
              out the updates that have been added to your favourite sites. An
              RSS service, also called an RSS channel, uses an XML format to
              list the fresh headlines and live links to a web page.
            </div>
          </div>
          <div>
            <h3 className="mb-2 font-public-sans text-2xl font-bold">
              How do I use RSS?
            </h3>
            <div className="mb-6 font-public-sans text-base font-normal">
              You will need to install an app or program called a news reader on
              your PC, tablet or phone. This will display RSS information feeds
              from your favourite websites on your device. With the BT website,
              you&apos;ll need to choose which sections you want updates on -
              for example Banking & Finance, Companies & Markets, or Arts &
              Entertainment. You can add the channels you want from the list on
              the left after you&apos;ve installed a news reader.
            </div>
          </div>
          <div>
            <h3 className="mb-2 font-public-sans text-2xl font-bold">
              How do I get a News Reader?
            </h3>
            <div className="font-public-sans text-base font-normal">
              There are a range of these available online, some of which are
              free.{" "}
              <a
                target="_blank"
                className="underline"
                href="https://feedly.com/index.html"
                rel="noreferrer"
              >
                Feedly
              </a>{" "}
              is one of the more popular options, which can be used on various
              devices and platforms. Some news readers only work on specific
              operating systems, so please take this into account when you
              choose one, and follow the instructions.
            </div>
          </div>
        </div>

        {/* News and Weekly Sections */}
        <div className="flex flex-col md:flex-row">
          <div className="mb-8 md:mb-8 md:w-1/2">
            <SectionList
              title={RSSFEEDS_DATA.news.title}
              items={RSSFEEDS_DATA.news.items}
            />
          </div>
          <div className="mb-8 md:mb-8 md:w-1/2">
            <SectionList
              title={RSSFEEDS_DATA.weekly.title}
              items={RSSFEEDS_DATA.weekly.items}
            />

            <div>
              <h3 className="mt-12 font-public-sans text-2xl font-bold">
                {RSSFEEDS_DATA.topStories.title}
              </h3>
            </div>
            <div>
              <ul>
                {RSSFEEDS_DATA.topStories.items.map((item, index) => (
                  <li key={index}>
                    <div className="title-link">
                      <a
                        href={item.url}
                        className="text-base font-normal text-black"
                      >
                        {item.title}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
