import MyAccountFactoryAnonTooltip from "@pages/MyAccount/components/MyAccountHeader/MyAccountFactoryAnonTooltip";
import MyAccountHeaderFactoryAnonTriger from "@pages/MyAccount/components/MyAccountHeader/MyAccountHeaderFactoryAnonTrigger";
import { cn } from "@util/helpers";

export default function MyAccountAnonTrigger(): React.ReactElement {
  return (
    <div
      className="relative hidden flex-none"
      data-testid="my-account-header-anon-trigger-component"
      id="my-account-anon-trigger"
    >
      <div className={cn("relative z-10")}>
        <MyAccountHeaderFactoryAnonTriger />
      </div>

      <div className="absolute right-0 z-[10000]">
        <MyAccountFactoryAnonTooltip />
      </div>
    </div>
  );
}
