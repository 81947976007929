import React, { HTMLAttributes } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useWindowSize } from "@hooks/useWindowSize";
import {
  getFormattedUrlPath,
  getKickerFromArticleDataObject,
} from "@util/helpers";

export type HighlightsProps = {
  articles: SanitizedSectionArticle[];
  testId?: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function Highlights({
  testId,
  className,
  articles,
}: HighlightsProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const mainVariation: CardVariations = {
    image: {
      position: "left",
      width: "hidden lg:block lg:w-1/2",
    },
    content: {
      width: "w-full lg:w-1/2",
      extraClass:
        "bg-gray-850 text-center p-5 lg:pt-0 lg:pb-0 lg:pr-0 lg:pl-4 lg:bg-transparent lg:text-left",
    },
    title: {
      color: "!text-white lg:!text-gray-850",
      size: "text-4xl",
    },
    kicker: {
      color: "text-white lg:text-black",
    },
  };
  const otherVariation: CardVariations = {
    title: {
      color: "text-gray-850",
      size: "text-base lg:text-lg",
    },
  };

  const mainArticle = articles.slice(0, 1)?.[0];
  const mainArticleSectionUniqueName = mainArticle?.sections?.[0]?.uniqueName;

  const parameters = [
    {
      key: "ref",
      value: "budget2025-highlights",
    },
  ];

  return (
    <section className={className} data-testid={testId}>
      <BlockTitle text="HIGHLIGHTS" rootClassName="mb-4" />

      <div className="stories grid divide-y border-gray-175">
        {mainArticle ? (
          <BasicCard
            id={mainArticle.id}
            slug={getFormattedUrlPath(mainArticle.urlPath, parameters)}
            title={mainArticle.title}
            kicker={getKickerFromArticleDataObject(mainArticle)}
            media={mainArticle.media}
            variations={mainVariation}
            blurb={mainArticle.blurb}
            rootClassName="pb-4 story"
            {...(mainArticleSectionUniqueName
              ? {
                  defaultImage: {
                    directoryName: mainArticleSectionUniqueName,
                  },
                }
              : null)}
          />
        ) : null}

        <div className="stories grid divide-y !border-t-0 border-gray-175 lg:grid-cols-2 lg:gap-4 lg:divide-y-0 lg:!border-t lg:py-4">
          {articles.slice(1, 3).map((article) => {
            const { id, title, urlPath } = article;

            return (
              <BasicCard
                key={id}
                id={id}
                slug={getFormattedUrlPath(urlPath, parameters)}
                title={title}
                kicker={getKickerFromArticleDataObject(article)}
                variations={otherVariation}
                rootClassName="story lg:last:border-l lg:last:pl-4 lg:last:border-gray-175 py-2 lg:py-0"
              />
            );
          })}
        </div>

        {isScreenLG ? (
          <>
            <div className="stories grid gap-4 divide-y border-gray-175 py-4 lg:grid-cols-2 lg:divide-y-0">
              {articles.slice(3, 5).map((article) => {
                const { id, title, urlPath, kicker } = article;

                return (
                  <BasicCard
                    key={id}
                    id={id}
                    slug={getFormattedUrlPath(urlPath, parameters)}
                    title={title}
                    kicker={
                      kicker?.fields?.[0]?.value ||
                      getKickerFromArticleDataObject(article)
                    }
                    variations={otherVariation}
                    rootClassName="lg:last:border-l lg:last:pl-4 lg:last:border-gray-175"
                  />
                );
              })}
            </div>

            <div className="stories grid gap-4 divide-y border-gray-175 py-4 lg:grid-cols-2 lg:divide-y-0">
              {articles.slice(5, 7).map((article) => {
                const { id, title, urlPath, kicker } = article;

                return (
                  <BasicCard
                    key={id}
                    id={id}
                    slug={getFormattedUrlPath(urlPath, parameters)}
                    title={title}
                    kicker={
                      kicker?.fields?.[0]?.value ||
                      getKickerFromArticleDataObject(article)
                    }
                    variations={otherVariation}
                    rootClassName="lg:last:border-l lg:last:pl-4 lg:last:border-gray-175"
                  />
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
}
