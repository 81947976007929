import { Fragment, useState } from "react";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import MyAccountTabs from "@pages/MyAccount/components/MyAccountTabs";
import { MyAccountTabsEnum } from "@pages/MyAccount/utils/types";
import { cn } from "@util/helpers";

import MyAccountPopoverLoginActiveTabs from "./MyAccountPopoverLoginActiveTabs";

export default function MyAccountPopover(): React.ReactElement {
  const [activeTab, setActiveTab] = useState<MyAccountTabsEnum>(
    MyAccountTabsEnum.myBT
  );

  return (
    <Popover className="relative block flex-none">
      <PopoverButton
        data-testid="user-login-trigger"
        className={cn(
          "rounded-[25px] p-3 font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850 duration-300 lg:hover:bg-gray-250",
          REMOVE_FOCUS_CSS
        )}
      >
        <p className="flex-none">Sign in / Sign up for free</p>
      </PopoverButton>

      <Transition>
        <TransitionChild
          as={Fragment}
          enter="transition ease duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease duration-150 transform"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0"
        >
          <PopoverPanel
            data-testid="my-account-popover-panel"
            anchor={{ to: "bottom end" }}
            className="relative z-[100000] overflow-hidden rounded-md bg-white shadow-myAccountPopOver"
          >
            <MyAccountTabs
              handleTabChange={setActiveTab}
              activeTab={activeTab}
            />

            <MyAccountPopoverLoginActiveTabs activeTab={activeTab} />
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  );
}
