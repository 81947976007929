/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Element } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import iconForwardArrowBlack from "@assets/icon-forward-arrow-black.svg";
import iconForwardArrowWhite from "@assets/icon-forward-arrow-white.svg";
import {
  getPercentageChangeInfo,
  useStockData,
} from "@components/StockPickerWidget/helpers";
import StockPickerWidget from "@components/StockPickerWidget/StockPickerWidget";
import type { StockData } from "@components/StockPickerWidget/types";
import { Annotation } from "@pages/Article/components/StoryElements/Annotation/utils/types";
import { cn } from "@util/helpers";
import cx from "classnames";

export function Headline({ text }: { text: string }) {
  return <h1>{text}</h1>;
}

export type TextProps = {
  text: string;
  annotations?: Annotation[];
  annotationContext?: Element[];
  useStockData?: (code: string) => StockData | undefined;
  className?: string;
};

export function Image({
  src,
  height,
  width,
  caption,
  layout,
}: {
  src: string;
  /** Intrinsic height; specify this to avoid layout shift */
  height?: number;
  /** Intrinsic width; specify this to avoid layout shift */
  width?: number;
  caption?: string;
  layout?: string;
}) {
  return (
    <figure className="relative bg-gray-250">
      <img
        className="mx-auto max-h-[17rem] max-w-full blur-none md:max-h-[29rem] lg:max-h-[33rem]"
        src={src}
        height={height}
        width={width}
      />

      {caption ? (
        <figcaption
          className={cx("w-full p-2 text-2xs", {
            "absolute bottom-0 left-0 bg-black bg-opacity-50 text-white":
              layout == "overlay",
            "text-gray-550": layout != "overlay",
          })}
        >
          {caption}
        </figcaption>
      ) : null}
    </figure>
  );
}

export function RawHtml({ html }: { html: string }) {
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export function ExternalLink({
  src,
  className,
}: {
  className?: string;
  src: Array<string | undefined>;
}) {
  return (
    <div
      style={{
        border: "solid 3px var(--c-accent)",
        padding: "var(--m-m)",
        color: "var(--c-accent)",
        margin: "var(--m-m) 0",
      }}
      className={cx("break-words", className)}
      data-story-element={Type.GroupedRelation}
      data-testid="external-link-component"
    >
      <i>More on this</i>

      {src.filter(Boolean).map((s, i) => (
        <div key={i}>
          <Link to={s || ""} target="_blank" rel="noopener noreferrer">
            {s}
          </Link>
        </div>
      ))}
    </div>
  );
}

export function Note({
  value,
  className,
}: {
  value: string;
  className?: string;
}) {
  return (
    <div
      className={className}
      data-story-element={Type.Note}
      data-testid="note-component"
      style={{
        background: "var(--c-accent)",
        color: "white",
        padding: "var(--m-m)",
        fontFamily: "monospace",
      }}
    >
      <div style={{ fontSize: "50%" }}>NOTE</div>
      <p style={{ margin: 0 }}>{value}</p>
    </div>
  );
}

export interface StockPickerProps {
  code: string;
  children?: React.ReactNode;
  className?: string;
}

export function StockPicker({ code, children, className }: StockPickerProps) {
  const { stockData } = useStockData(code);

  const [openWidget, setOpenWidget] = useState(false);

  if (typeof stockData === "undefined") {
    return <>{children}</>;
  }

  const {
    percentageChange,
    percentageChangeColor,
    percentageChangeBGColor,
    percentageChangeSign,
  } = getPercentageChangeInfo(stockData.CHANGES, stockData.PREVIOUS);

  const gotChanges = stockData.CHANGES !== 0;

  return (
    <>
      <span
        className={cx(
          "cursor-pointer text-lg font-semibold underline",
          {
            [percentageChangeColor]: percentageChangeColor && gotChanges,
            [percentageChangeBGColor]: percentageChangeBGColor && gotChanges,
            "hover:text-white": gotChanges,
          },
          className
        )}
        data-testid={`stock-${code}`}
        onClick={() => {
          setOpenWidget(true);
        }}
      >
        {children} : {code} {percentageChangeSign}
        {percentageChange ?? 0}%
      </span>

      <StockPickerWidget
        code={stockData.STOCK_CODE}
        stockData={stockData}
        setIsModalOpen={setOpenWidget}
        isModalOpen={openWidget}
      />
    </>
  );
}

export type BlockProps = {
  rootClassName?: string;
} & React.PropsWithChildren;

export function Block({
  rootClassName,
  children,
}: BlockProps): React.ReactElement {
  return <div className={cx("block-component", rootClassName)}>{children}</div>;
}

export interface BlockTitleProps {
  rootClassName?: string;
  rootLinkClassName?: string;
  link?: string;
  text: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}
export function BlockTitle({
  rootClassName = "",
  rootLinkClassName = "",
  link = "",
  text,
  children,
  onClick,
}: BlockTitleProps): React.ReactElement {
  return (
    <h2
      className={cn(
        "font-poppins text-lg font-bold text-gray-850",
        "lg:text-3xl",
        rootClassName
      )}
      aria-label={text}
    >
      {link ? (
        <Link
          to={link}
          className={cx(rootLinkClassName, "hover:underline")}
          target="_blank"
          rel="noopener"
          onClick={onClick}
        >
          {text}
          {text !== "YOU MAY LIKE" ? (
            <img
              src={
                rootLinkClassName.includes("text-white")
                  ? iconForwardArrowWhite
                  : iconForwardArrowBlack
              }
              alt=""
              width="8"
              height="13"
              className="mb-1 ml-2 inline"
            />
          ) : null}
          {children}
        </Link>
      ) : (
        <>
          {text}
          {children}
        </>
      )}
    </h2>
  );
}
