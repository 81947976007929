import { AwardsMenu } from "@events-awards/utils/types";

export const e50NavigationMenu: AwardsMenu[] = [
  {
    text: "ABOUT",
    link: "/events-awards/enterprise-50/about",
    enabled: true,
  },
  {
    text: "AWARDS",
    link: "/events-awards/enterprise-50/awards",
    enabled: true,
  },
  {
    text: "WINNERS",
    link: "/events-awards/enterprise-50/winners",
    enabled: true,
  },
  {
    text: "NEWS",
    link: "/events-awards/enterprise-50/news",
    enabled: true,
  },
  {
    text: "GALLERY",
    link: "/events-awards/enterprise-50/gallery",
    enabled: true,
  },
  {
    text: "HOW TO APPLY",
    link: "/events-awards/enterprise-50/how-to-apply",
    enabled: true,
  },
];

export const e50QuickLinksMenu: AwardsMenu[] = [
  {
    text: "CONTACT US",
    link: "/events-awards/enterprise-50/contact-us",
    enabled: true,
  },
  {
    text: "FAQ",
    link: "/events-awards/enterprise-50/faq",
    enabled: true,
  },
];
