import { HtmlHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import type { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

type MoreInThriveProps = {
  articles: SanitizedSectionArticle[];
} & Pick<HtmlHTMLAttributes<HTMLDivElement>, "className">;

export default function MoreInThrive({
  articles,
  className,
}: MoreInThriveProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  if (articles.length === 0) return <></>;

  return (
    <section className={className} data-testid="more-in-thrive-component">
      <h3 className="mb-6 border-t-2 border-gray-850 pt-3 font-poppins text-lg font-semibold uppercase tracking-[0.05625rem]">
        <Link
          className="!text-gray-850"
          to={RouteFactory.thrive}
          reloadDocument
        >
          MORE IN THRIVE
        </Link>
      </h3>

      <div className="stories grid gap-6 lg:grid-cols-3 lg:gap-0">
        {articles?.slice(0, 6).map(({ id, title, urlPath, updated }, index) => {
          return (
            <div
              key={id}
              className={cn(
                "relative after:absolute after:-bottom-3 after:left-0 after:right-0 after:h-[1px] after:bg-gray-175 lg:after:hidden",
                {
                  "lg:mb-3 lg:border-b lg:border-gray-175 lg:pb-3": [
                    0, 1, 2,
                  ].includes(index),
                }
              )}
            >
              <BasicCard
                id={id}
                title={title}
                slug={urlPath}
                updated={updated}
                variations={{
                  title: { color: "!text-gray-850", size: "text-base" },
                  content: { width: "w-full", extraClass: "h-full" },
                }}
                rootClassName={cn("story h-full", {
                  "lg:border-l lg:border-gray-175 lg:pl-3": [
                    1, 2, 4, 5,
                  ].includes(index),
                })}
              />
            </div>
          );
        })}
      </div>

      {!isScreenLG ? (
        <div className="mt-7 text-right">
          <Link
            to={RouteFactory.thrive}
            reloadDocument
            className="font-poppins text-4xs font-bold leading-6 tracking-2% !text-gray-850"
          >
            SEE MORE
          </Link>
        </div>
      ) : null}
    </section>
  );
}
