import type { Config } from "@sphtech/neuron-core";

// Neuron Configuration
export const NEURON_URL: string = import.meta.env.VITE_NEURON_API_URL;
export const NEURON_TOKEN: string = import.meta.env.VITE_NEURON_API_TOKEN;

export const neuronConfig: Config.NeuronLegacyConfig = {
  trackedBrowserEvents: [
    { eventType: "click", selector: "button, a" },
    {
      eventType: "elementVisible",
      selector: "div[id^='sph_cdp_'], div[id^='dfp-ad-']",
    },
  ],
  sessionApiUrl: NEURON_URL,
  sessionApiToken: NEURON_TOKEN,
};
