import { useLocation } from "react-router-dom";
import type { ArticleDataObject, Section } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useWindowSize } from "@hooks/useWindowSize";
import { TagType } from "@pages/Section/types";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn, getArticleBlurb } from "@util/helpers";

export default function EventsAwardsNewsListingStories({
  article,
  index,
}: {
  article: ArticleDataObject;
  index: number;
}): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  const { isScreenLG, isScreenSM } = useWindowSize();
  const { pathname } = useLocation();
  const sectionName = pathname.substring(pathname.lastIndexOf("/") + 1);
  const inPodcastsLandingPage = sectionName === "podcasts";
  const isLeadArticle = index === 0 && !inPodcastsLandingPage;
  const firstImage = article.media?.[0];
  const media = isLeadArticle && !isScreenSM ? undefined : firstImage;
  const isVideo =
    article.media?.[0]?.content?.type === Type.ArticleBrightcoveVideo ||
    article.media?.[0]?.content?.type === Type.ArticleYoutubeVideo;
  const kicker = article.kicker?.fields?.[0]?.value;
  let section: Section | undefined;
  const pathArray = pathname.split("/");

  const uniqueName = article.sections?.[0]?.uniqueName || sectionName;

  if (pathArray.length > 1 && pathArray[1] === "keywords") {
    section = article.sections?.[0];
  }
  const variant: CardVariations = {
    kicker: {
      color: cn("text-gray-515"),
    },
    image: {
      width: "w-1/3",
      position: "left",
      extraClass: cn("lg:order-2"),
      filters: [
        { breakpoint: "(min-width: 992px)", w: 400, h: 267, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 300, h: 200, dpr: 1, f: "webp" },
        { w: 300, h: 200, dpr: 1, f: "webp" },
      ],
      isVideo: isVideo,
    },
    content: {
      width: "w-2/3",
      extraClass: cn("lg:order-1 pl-4 lg:pl-0 lg:pr-4"),
      blurb: {
        extraClass: "text-gray-850",
      },
      updatedTimeExtraClass: "font-public-sans",
    },
    title: {
      size: "",
      color: "text-gray-850",
      extraClass: "font-public-sans",
    },
  };

  return (
    <div className={cn("story border-b border-gray-175 py-3")}>
      <BasicCard
        id={article.id}
        title={article.displaySetting?.displayHeadline || article.title}
        section={section}
        storyThread={article.tags?.find(
          ({ type }) => type === TagType.StoryThread
        )}
        kicker={kicker}
        media={media}
        blurb={isScreenLG ? getArticleBlurb(article) : undefined}
        slug={article.urlPath}
        paywall={isSubscriber ? article.paywall?.contentAccess === "1" : false}
        variations={variant}
        created={article.created}
        edited={article.edited}
        updated={article.updated}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        defaultImage={{
          directoryName: uniqueName,
        }}
        rootClassName="items-stretch"
      />
    </div>
  );
}
