import { useEffect, useState } from "react";
import { useWindowSize } from "@hooks/useWindowSize";
import { getKeywordsFromPulseData } from "@pages/Search/utils/helpers";
import { useGetKeywordsFromPulseDataProps } from "@pages/Search/utils/types";

export const useGetKeywordsFromPulseData = ({
  pulseArticles,
}: useGetKeywordsFromPulseDataProps) => {
  const [trendingKeywords, setTrendingKeywords] = useState<string[]>([]);
  const { isScreenLG } = useWindowSize();

  useEffect(() => {
    const size = isScreenLG ? 20 : 10;
    const articlesSliceD = pulseArticles.slice(0, size);

    const keywords = getKeywordsFromPulseData(articlesSliceD);

    setTrendingKeywords(keywords);
  }, [isScreenLG, pulseArticles]);

  return trendingKeywords;
};
