import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { RelatedStoriesProps } from "@pages/SingaporeGe/2025/utils/types";
import { getFormattedUrlPath } from "@util/helpers";

export default function RelatedStories({
  article,
  index,
  parameters,
}: RelatedStoriesProps): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index || 0 });

  const variant: CardVariations = {
    image: {
      position: "right",
      width: "w-1/4",
      extraClass: "order-1 lg:order-2",
    },
    content: {
      width: "w-3/4",
      extraClass: "pl-4 lg:pr-4 lg:pl-0",
    },
    title: {
      color: "!text-gray-850",
      size: "text-base lg:text-lg",
    },
  };

  const {
    id,
    title,
    kicker,
    urlPath,
    media,
    updated,
    edited,
    sections,
    displaySetting,
    blurb,
  } = article;
  const sectionUniqueName = sections?.[0]?.uniqueName;

  return (
    <div ref={ref} data-testid="related-stories-component">
      <BasicCard
        key={id}
        id={id}
        title={title}
        slug={getFormattedUrlPath(urlPath, parameters)}
        media={media}
        blurb={blurb}
        kicker={kicker?.fields?.[0]?.value}
        variations={variant}
        rootClassName="py-4"
        updated={updated}
        edited={edited}
        hasUpdatedTimestampDisplay={displaySetting?.hasUpdatedTimeDisplayed}
        {...(sectionUniqueName
          ? {
              defaultImage: {
                directoryName: sectionUniqueName,
              },
            }
          : undefined)}
      />
    </div>
  );
}
