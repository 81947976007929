import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import { VideosPageContext } from "./utils/types";
import VideoPageContent from "./VideoPageContent";

export default function VideosPage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<VideosPageContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: VideosPageContext) => {
        if (kind !== "static") {
          return <CustomError statusCode={context.statusCode} />;
        }

        return <VideoPageContent data={data} />;
      }}
    </Await>
  );
}
