import { useLocation } from "react-router-dom";
import type { ArticleDataObject } from "@app/types/Cue";
import LoadMore from "@components/LoadMore/LoadMore";
import EventsAwardsNewsListingStories from "@events-awards/EventsAwardsNewsListingStories";
import { EventsAwardsNewsContextData } from "@events-awards/utils/types";
import { useLoadMoreEventsNewsByKeywords } from "@hooks/useLoadMoreEventsNewsByKeywords";
import { compact } from "lodash-es";

export default function EventsAwardsNews({
  articles,
}: EventsAwardsNewsContextData): React.ReactElement {
  const matchedCategory = useLocation().pathname.replace("/", "");
  const [parentCategory = "", childCategory = ""] = matchedCategory.split("/");

  //compact removes all falsey values
  const uniqueCategoryQuery = compact([
    parentCategory !== "keywords" && parentCategory,
    childCategory,
  ]).join("_");

  const { articleList, handleLoadMore, hasMoreArticles } =
    useLoadMoreEventsNewsByKeywords(
      articles,
      10,
      uniqueCategoryQuery,
      parentCategory
    );

  return (
    <section data-testid={"testId"}>
      <div className="stories">
        {articleList.map((article: ArticleDataObject, index: number) => {
          return (
            <EventsAwardsNewsListingStories
              key={index}
              index={index}
              article={article}
            />
          );
        })}
      </div>

      {hasMoreArticles ? (
        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreArticles}
        />
      ) : null}
    </section>
  );
}
