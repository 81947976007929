/**
 * Company: SPHMedia
 * Description: International Global Latest Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import VerticalBlockTitle from "@components/VerticalBlockTitle/VerticalBlockTitle";
import { useHandleMoreStories } from "@hooks/useHandleMoreStories";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import type { SectionDefaultProps } from "@pages/Section/types";

import AseanBanner from "./components/AseanBanner";
import AseanBusinessListingStories from "./components/AseanBusinessListingStories";
import AseanNewsletterDescription from "./components/AseanNewsletterDescription";

const PAGE_SIZE = 10;

export default function AseanBusinessVerticalLatest({
  data: { title, overview, urlPath, seoFooterData },
}: SectionDefaultProps): ReactElement {
  const uniqueCategoryQuery = urlPath
    .replace(/^\/+/, "")
    .replaceAll("/", "_")
    .replace("_latest", "");

  const { ref, loadMoreData } = useHandleMoreStories({
    data: overview,
    uniqueCategoryQuery,
    parentCategory: "",
    pageSize: PAGE_SIZE,
  });

  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={RouteFactory.aseanBusiness}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        ogType="article"
        slug={`${RouteFactory.aseanBusiness}/latest`}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <PulseEntry />

      {/* Top Stories */}
      <div className="container px-[var(--gutter-x-sm)] py-[var(--gutter-y)]">
        <AseanBanner />

        <VerticalBlockTitle
          id="asean-business-vertical-latest"
          rootClassName="mb-4"
          text="Latest"
        />
        <ul
          data-testid="asean-business-vertical-latest-list"
          className={"flex flex-col divide-y"}
        >
          {loadMoreData.map((article, index) => {
            const uniqueName = article.sections?.[0]?.uniqueName;

            if (!uniqueName) return null;

            return (
              <li className="py-4" key={article.id}>
                <AseanBusinessListingStories
                  article={article}
                  uniqueName={uniqueName}
                  index={index}
                />
              </li>
            );
          })}
        </ul>

        <div ref={ref} />
      </div>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<AseanNewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) =>
            newsletter.type === NEWSLETTER_TYPE.MORNING ||
            newsletter.type === NEWSLETTER_TYPE.EVENING
        )}
        btnColors="bg-verticals-ge text-white hover:bg-white hover:text-verticals-ge"
        linkColors="text-verticals-ge"
      />

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
