import { Link } from "react-router-dom";
import thumbnail from "@assets/image-mti-crop.jpg";
import { BlockTitle } from "@blocks/Blocks";
import { BUDGET2024_MTI_BOOKLET } from "@pages/SingaporeBudget/2024/constants";

type BookletProps = { rootClassName: string };

export default function Booklet({
  rootClassName,
}: BookletProps): React.ReactElement {
  return (
    <div className={rootClassName} data-testid="booklet-component">
      <BlockTitle
        text="MTI BUSINESS BUDGET BOOK"
        rootClassName="mb-4 !text-lg lg:!text-3xl"
      />

      <div className="group relative flex gap-4">
        <img
          src={thumbnail}
          width={100}
          height={67}
          alt="Singapore Budget 2024"
          className="aspect-3x2 object-contain"
        />

        <h2 className="font-lct text-base font-bold group-hover:underline lg:text-lg">
          {"Download MTI's Budget Booklet for Businesses 2024 here"}
        </h2>

        <Link
          to={BUDGET2024_MTI_BOOKLET}
          target="_blank"
          className="absolute inset-0"
        />
      </div>
    </div>
  );
}
