import { Link } from "react-router-dom";
import { ProfileContent } from "@app/types/Cue";
import btIconEmail from "@assets/icon-bt-email.svg";
import btIconX from "@assets/icon-bt-x.svg";
import OptimisedImage from "@src/app/components/OptimisedImage";
import cx from "classnames";
import { isEmpty } from "lodash-es";

export default function AuthorDetails({
  headshotImage,
  fields,
}: ProfileContent): React.ReactElement {
  const { name, designation, email, twitterHandle, bio_raw } = fields;
  const dp = headshotImage?.[0]?.content.fields["square_480-caas"]?.url;

  return (
    <div
      className={cx(
        "mb-8 flex flex-col gap-4 border-b-[5px] border-black pb-8 font-poppins",
        "md:flex-row"
      )}
    >
      {dp ? (
        <div className="w-full sm:w-4/20">
          <OptimisedImage
            src={dp}
            filters={[
              {
                breakpoint: "(min-width: 576px)",
                w: 300,
                h: 300,
                dpr: 1,
                f: "webp",
              },
              { w: 100, h: 100, dpr: 1, f: "webp" },
            ]}
            alt={name}
            className="mx-auto aspect-square w-[100px] rounded-full sm:w-[174px]"
          />
        </div>
      ) : null}

      <div className="flex w-full flex-col sm:w-16/20">
        <h1 className="text-center font-poppins text-xl font-semibold sm:text-start">
          {name}
        </h1>

        {!isEmpty(designation) ? (
          <p className="mb-2 text-center font-poppins text-4xs font-medium text-gray-850 sm:text-start">
            {designation.toUpperCase()}
          </p>
        ) : null}

        {!isEmpty(bio_raw) ? (
          <p className="mb-3 font-public-sans text-xs text-gray-850">
            {bio_raw}
          </p>
        ) : null}

        {!isEmpty(email) || !isEmpty(twitterHandle) ? (
          <div
            className={cx(
              "mt-2 flex gap-2 border-t border-gray-850 pt-3",
              "sm:gap-5"
            )}
          >
            {!isEmpty(email) ? (
              <div className="flex items-center">
                <img src={btIconEmail} height={15} width={15} alt="email" />

                <Link
                  to={`mailto:${email}`}
                  className="ml-2 font-public-sans text-4xs text-gray-550 transition-colors hover:text-black hover:underline"
                >
                  {email}
                </Link>
              </div>
            ) : null}

            {!isEmpty(twitterHandle) ? (
              <div className="flex items-center">
                <img src={btIconX} height={15} width={15} alt="email" />

                <Link
                  to={`https://x.com/${twitterHandle.replace("@", "")}`}
                  className="ml-1 font-public-sans text-4xs text-gray-550 transition-colors hover:text-black hover:underline"
                  target="_blank"
                >
                  {twitterHandle}
                </Link>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
