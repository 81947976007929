import { BrightcoveVideo } from "@components/Brightcove/utils/types";

import BrightcoveVideoPlaylistItem from "./BrightcoveVideoPlaylistItem";

export type BrightcoveVideoPlaylistPaginationProps = {
  videos: BrightcoveVideo[];
  activeVideo?: BrightcoveVideo;
  setActiveVideo: React.Dispatch<
    React.SetStateAction<BrightcoveVideo | undefined>
  >;
};

export default function BrightcoveVideoPlaylistPagination({
  videos,
  activeVideo,
  setActiveVideo,
}: BrightcoveVideoPlaylistPaginationProps): React.ReactElement {
  return (
    <div className="relative after:absolute after:bottom-0 after:right-0 after:top-0 after:w-[100px] after:bg-gradient-to-l after:from-white after:to-transparent">
      <div className="relative mt-4 overflow-x-auto">
        <div className="flex flex-nowrap gap-6">
          {videos.map((video, index) => {
            return (
              <BrightcoveVideoPlaylistItem
                key={`${video.id}-${index}`}
                video={video}
                setActiveVideo={setActiveVideo}
                isActive={video.id === activeVideo?.id}
                className="w-4/10 flex-shrink-0 lg:w-[20%]"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
