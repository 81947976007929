import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { ArticleDataObject, SanitizedSectionArticle } from "@app/types/Cue";
import { ARTICLE_INIFINITE_SCROLL_LIMIT } from "@util/constant";
import { checkIfFromPaidPressRelease } from "@util/helpers";
import { includes } from "lodash-es";

import { useFetchNextArticle } from "./useFetchNextArticle";

type storiesContext = {
  context: ArticleDataObject;
  sectionNews: SanitizedSectionArticle[];
};

export const useNextArticle = (
  context: ArticleDataObject,
  sectionNews: SanitizedSectionArticle[]
) => {
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [moreStories, setMoreStories] = useState<storiesContext[]>([
    { context, sectionNews },
  ]);

  const FetchTheNextArticle = async () => {
    const firstArticle = moreStories?.[0];
    const lastArticle = moreStories?.[moreStories.length - 1];

    setIsLoading(true);

    const sectionUniqueName = firstArticle.context.sections?.[0].uniqueName;
    const searchAfter = lastArticle.context.sort;

    const nextArticle = await useFetchNextArticle({
      sectionUniqueName: sectionUniqueName || "",
      search_after: searchAfter || [],
    });

    setMoreStories((prev) => [...prev, nextArticle]);
    setIsLoading(false);
  };

  const { ref } = useInView({
    triggerOnce: true,
    onChange: async (inView) => {
      if (typeof window === "undefined") return;
      if (!inView) return;
      if (moreStories.length >= ARTICLE_INIFINITE_SCROLL_LIMIT) return;
      if (pathname.startsWith(RouteFactory.aseanBusiness)) return;
      if (pathname.startsWith(RouteFactory.globalEnterprise)) return;

      const userAgent = window.navigator.userAgent;
      if (includes(userAgent.toLowerCase(), "googlebot")) return;

      // Fetch next article if its not paid-press-release.
      const isPaidPressRelease = checkIfFromPaidPressRelease(context?.sections);
      if (isPaidPressRelease) return;

      FetchTheNextArticle();
    },
  });

  return { moreStories, isLoading, ref };
};
