import logoACRA from "@assets/events-awards/sca/logo-acra.jpg";
import logoISCA from "@assets/events-awards/sca/logo-isca.png";
import logoSC from "@assets/events-awards/sca/logo-sc.svg";
import logoSGX from "@assets/events-awards/sca/logo-sgx.png";
import logoSID from "@assets/events-awards/sca/logo-sid.jpg";
import logoBT from "@assets/logo-masthead.svg";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import BasicCard from "@components/ArticleCard/BasicCard";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import EventsAwardsDTAQuickLinks from "@dta/components/EventsAwardsDTAQuickLinks";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import {
  AwardType,
  EventsAwardsLandingPageContextData,
} from "@events-awards/utils/types";
import {
  EVENTS_AWARDS_DTA_LABEL,
  EVENTS_AWARDS_DTA_PATH,
  EVENTS_AWARDS_SLOT,
} from "@pages/Section/layouts/EventsAwardsPage/utils/constants";
import { sectionNavigationItems } from "@util/constant";

export default function EventsAwardsDTAPageContent({
  title,
  article,
}: EventsAwardsLandingPageContextData): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();

  return (
    <div
      className="w-full text-gray-850 antialiased"
      data-testid="events-awards-dta-page-content-component"
    >
      <GAData title="Singapore_Corporate_Awards_Index" level2="events awards" />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${EVENTS_AWARDS_DTA_LABEL} - Find ${EVENTS_AWARDS_DTA_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_DTA_PATH}
      />

      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={EVENTS_AWARDS_DTA_PATH}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <EventsAwardsLogoMenu awardType={AwardType.DATA} />

            <>
              {article ? (
                <BasicCard
                  title={article.title}
                  id={article.id}
                  media={article.media}
                  slug={article.urlPath}
                  defaultImage={{ directoryName: "events-awards" }}
                  rootClassName="mt-6 md:-mx-3 mx-0 justify-center"
                  variations={{
                    title: {
                      color: "text-gray-850",
                      size: "text-4xl",
                      extraClass: "font-public-sans font-semibold",
                    },
                    content: {
                      width: "w-full",
                      extraClass: "px-6 lg:px-14 text-left",
                    },
                  }}
                />
              ) : null}
            </>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 lg:pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"SINGAPORE "}
                  <br className="hidden lg:inline" />
                  {"CORPORATE "}
                  <br className="hidden lg:inline" />
                  {"AWARDS"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <p className="font-public-sans text-lg leading-7">
                  {
                    "The Singapore Corporate Awards was launched on 19 September 2005 as the umbrella awards for exemplary corporate governance practices for listed companies in Singapore. It seeks to consolidate existing awards while introducing new awards in the area of excellent corporate governance."
                  }
                </p>

                <p className="font-public-sans text-lg leading-7">
                  {
                    "Since its inauguration in 2005, SCA has been the premier platform for awards that recognise exemplary corporate governance practices in Singapore's listed companies. In a year that has seen companies re-assess and reinvent themselves, the SCA team is committed to continuing the spirit embodied in this event, and will throw the spotlight on how good governance plays a fundamental role in underpinning corporate excellence."
                  }
                </p>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"JOINTLY ORGANISED "}
                  <br className="hidden lg:inline" />
                  {"BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoBT}
                    width={320}
                    height={32}
                    alt="The Business Times logo"
                    className="mx-auto"
                  />
                </div>

                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoISCA}
                    width={220}
                    height={48}
                    alt="ISCA logo"
                    className="mx-auto"
                  />
                </div>

                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoSID}
                    width={170}
                    height={79}
                    alt="SID logo"
                    className="mx-auto"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"PRESENTING "}
                  <br className="hidden lg:inline" />
                  {"SPONSOR"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoSC}
                    width={200}
                    height={105}
                    alt="Standard Chartered logo"
                    className="mx-auto"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"SUPPORTED BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoACRA}
                    width={120}
                    height={55}
                    alt="ACRA logo"
                    className="mx-auto"
                  />
                </div>

                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoSGX}
                    width={250}
                    height={46}
                    alt="SGX logo"
                    className="mx-auto"
                  />
                </div>
              </Column>
            </Row>

            <EventsAwardsDTAQuickLinks />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
