import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { myBTTagsWithArticle } from "@pages/MyAccount/utils/types";
import { cn } from "@util/helpers";

export default function MyAccountMyBTCardList({
  tag,
  articles,
}: myBTTagsWithArticle): React.ReactElement {
  return (
    <div
      data-testid="my-account-my-bt-page-card-list-component"
      className="mb-8 border-t border-gray-175 pt-4 last:mb-0"
    >
      <BlockTitle
        text={tag.name}
        rootClassName="lg:text-lg mb-4"
        link={tag.urlPath}
      />

      {articles.map(
        (
          {
            media,
            sections,
            created,
            edited,
            updated,
            urlPath,
            title,
            id,
            blurb,
          },
          index
        ) => {
          const uniqueName = sections?.[0]?.uniqueName || " ";

          const isLeadArticle = index === 0;

          const variant: CardVariations = {
            image: {
              width: "w-1/4",
              position: "right",
              filters: [
                {
                  breakpoint: "(min-width: 992px)",
                  w: 900,
                  h: 600,
                  dpr: 1,
                  f: "webp",
                },
                {
                  breakpoint: "(min-width: 768px)",
                  w: 600,
                  h: 400,
                  dpr: 1,
                  f: "webp",
                },
                { w: 300, h: 200, dpr: 1, f: "webp" },
              ],
            },
            content: {
              width: "w-3/4",
              extraClass: cn({
                "md:order-1 order-2 pl-4 md:pl-0 md:pr-4": isLeadArticle,
              }),
              blurb: {
                extraClass: cn("text-gray-850", {
                  "md:block": isLeadArticle,
                }),
              },
            },
            title: {
              size: "text-base",
              color: "text-gray-850",
            },
          };

          return (
            <BasicCard
              key={id}
              rootClassName="mb-4"
              id={id}
              title={title}
              media={index === 0 ? media : undefined}
              slug={`${urlPath}?ref=mybt`}
              created={created}
              edited={edited}
              updated={updated}
              variations={variant}
              defaultImage={
                index === 0 ? { directoryName: uniqueName } : undefined
              }
              blurb={index === 0 ? blurb : undefined}
            />
          );
        }
      )}
    </div>
  );
}
