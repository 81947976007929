import { useEffect, useRef, useState } from "react";

/**
 * Hook to scroll to a specific element.
 * @param offset - The offset to scroll to.
 * @returns A ref and a function to scroll to the element.
 */
const useScrollTo = <T extends Element>(offset: number = 40) => {
  const ref = useRef<T>(null);
  const [shouldScrollTo, setShouldScrollTo] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    if (ref.current && shouldScrollTo) {
      const elementTop = ref.current.getBoundingClientRect().top;
      const documentTop = document.body.getBoundingClientRect().top;
      const scrollTop = elementTop - documentTop - offset;

      window.scrollTo({
        behavior: "smooth",
        top: scrollTop,
      });
      setShouldScrollTo(false);
    }
  }, [shouldScrollTo, offset]);

  return [ref, setShouldScrollTo] as const;
};

export { useScrollTo };
