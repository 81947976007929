import isAppWebView from "@hooks/useIsAppWebView";

import ArticleContent, { ArticleContentProps } from "./ArticleContent";
import ArticleContentAppWebView from "./ArticleContentAppWebView";

export default function ArticleWrapper({
  data,
}: ArticleContentProps): React.ReactElement {
  const isWebView = isAppWebView();

  if (isWebView) return <ArticleContentAppWebView data={data} />;

  return <ArticleContent data={data} />;
}
