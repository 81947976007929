import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { ReadArticleObject } from "./types";

dayjs.extend(isBetween);

/**
 * Filters and reorders a list of recently read articles.
 *
 * This function filters out articles that were read more than 7 days ago and
 * reorders the list to place the specified article at the beginning if it exists
 * in the filtered list.
 *
 * @param articleId - The article object to be prioritized in the list.
 * @param articleList - The list of articles to be filtered and reordered.
 * @returns A new list of articles with the specified article at the beginning if it exists.
 */
export const filteredRecentReadArticles = (
  articleId: ReadArticleObject,
  articleList: ReadArticleObject[]
) => {
  const removedPassArticle = articleList.filter(({ dateRead }) => {
    if (!dateRead) return false;

    const from = dayjs().subtract(7, "day").toDate();
    const to = new Date();
    const dateReadDate = new Date(dateRead);

    return dayjs(dateReadDate).isBetween(from, to, null, "[]");
  });

  const filterActiveArticleId = removedPassArticle.filter(
    ({ id }) => id === articleId.id
  );

  if (filterActiveArticleId.length <= 0) return removedPassArticle;

  // filter out the articleId from the list
  const filteredArticles = removedPassArticle.filter(
    ({ id }) => id !== articleId.id
  );

  const firstFilteredArticle = filterActiveArticleId?.[0];

  // add it at the beginning
  filteredArticles.unshift(firstFilteredArticle);

  return filteredArticles;
};

/**
 * Filtered out articles from the storeArticleList that are present in the articleList.
 *
 * This function compares two lists of articles and removes any articles from the
 * storeArticleList that have matching IDs in the articleList.
 *
 * @param storeArticleList - The original list of articles to be filtered.
 * @param articleList - The list of articles to compare against.
 * @returns A new list of articles with the articles from articleList removed.
 */
export const filteredRemovedRecentReadArticles = (
  storeArticleList: ReadArticleObject[],
  articleList: ReadArticleObject[]
) => {
  const updatedArticles = storeArticleList.filter(
    ({ id }) => !articleList.some((article) => article.id === id)
  );

  return updatedArticles;
};
