import { ReactElement } from "react";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import Button from "@src/app/components/Button/Button";
import { ActionType } from "@src/app/components/Button/types";
import { getExploreMoreObjectByTags } from "@src/app/pages/Article/components/ArticleExploreMore/helpers";
import { Tag } from "@typings/Cue";

export type ArticleExploreMoreProps = {
  keyword: Tag[];
  testid?: string;
};

export default function ArticleExploreMore({
  keyword,
  testid = "article-explore-more-component",
}: ArticleExploreMoreProps): ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const dataObject = getExploreMoreObjectByTags(keyword)?.[0];

  if (!dataObject) return <></>;

  const handleOnClickLink = () => {
    sendDataToGTM(dataObject.trackingEvents);
  };

  return (
    <div
      data-testid={testid}
      className="mb-6 border border-gray-175 p-6 md:p-8"
    >
      <h5 className="mb-6 border-b border-gray-850 pb-2 !font-poppins text-base font-semibold uppercase leading-normal tracking-5% text-gray-850 md:text-lg">
        {dataObject.title}
      </h5>

      <div className="flex py-4 md:px-10 md:py-6">
        <img
          alt="Newsletter Img"
          className="hidden h-auto max-w-full self-start min-[321px]:block"
          width="75"
          height="75"
          src={dataObject.thumbnail}
        />

        <div className="min-[321px]:pl-4 md:pl-6">
          <p className="mb-3 font-public-sans text-base font-light tracking-normal text-gray-850 md:text-lg">
            {dataObject.description}
          </p>

          <Button
            actionType={ActionType.linkButton}
            text={dataObject.buttonLabel}
            shareUrl={dataObject.link}
            testid="explore-more-button-component"
            className="hidden w-full min-w-[155px] !text-gray-850 !no-underline md:flex md:max-w-[155px]"
            onClick={handleOnClickLink}
          />
        </div>
      </div>

      <Button
        actionType={ActionType.linkButton}
        text={dataObject.buttonLabel}
        shareUrl={dataObject.link}
        testid="explore-more-button-component"
        className="flex w-full !text-gray-850 !no-underline md:hidden"
      />
    </div>
  );
}
