import { SanitizedPulseData } from "@pages/Pulse/utils/types";
import { DefaultNavigationData } from "@typings/Page";

export type SearchContext = {
  kind: "static";
  data: {
    title: string;
    pulseArticles: SanitizedPulseData[];
  } & DefaultNavigationData;
};

export type SearchPageContentProps = Pick<SearchContext, "data">;

export type useGetKeywordsFromPulseDataProps = {
  pulseArticles: SanitizedPulseData[];
};

export type FetchSearchDataParams = {
  query: string;
  endindex: number;
  batchsize: number;
  sort: FilteringSortEnum;
  facetedkey?: string;
  facetedvalue?: string;
};

export interface CommonfetchSearchDataProps {
  query: string;
  endindex?: number;
  batchsize?: number;
  sortBy: FilteringSortEnum;
  facetedkey?: string;
  facetedvalue?: string;
}

export interface FetchSearchDataProps extends CommonfetchSearchDataProps {
  isHandleLoadMore?: boolean;
  isDefaultEndIndex?: boolean;
  selectedFacetedAllData?: SearchSelectedFacetedObj[];
}

export type SearchInputProps = {
  className?: string;
  value?: string;
  searchNewList: (query: string) => void;
  searchKeyword?: string;
  handleClearSearchData: () => void;
};

export type SearchBasicCardProps = {
  result: SearchResultsItems;
  cardCount?: number;
};

export type SearchResultProps = {
  result: SearchResultsItems;
  cardCount?: number;
  totalCount?: number;
};

export type SearchRecentResultsProps = {
  searchQuery: RecentSearchObject[];
  setSelectedKeyword: (query: string) => void;
};

export type TrendingKeywordsProps = {
  keywords: string[];
  setSelectedKeyword: (query: string) => void;
};

export interface CommonSearchAdvanceFilteringProps {
  facetedSearchAllData?: {
    [key in FilteringfacetedObjectEnum]?: facetedObject[];
  };
  selectedFacetedAllData?: SearchSelectedFacetedObj[];
  setSelectedFacetedObj: (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => void;
}

export type SearchFilteringSortProps = {
  sortBy: FilteringSortEnum;
  setSortBy: (sortBy: FilteringSortEnum) => void;
};

export interface SearchFilteringProps
  extends CommonSearchAdvanceFilteringProps {
  sortBy: FilteringSortEnum;
  setSortBy: (sortBy: FilteringSortEnum) => void;
}

export interface SearchIndividualFilteringProps
  extends CommonSearchAdvanceFilteringProps {
  advanceFilteringDisplayObj: {
    key: FilteringfacetedObjectEnum;
    value: string;
  };
  facetedSearchData?: facetedObject[];
  selectedFacetedData?: SearchSelectedFacetedObj;
}

export type SearchSelectedFacetedObj = {
  facetedKey: FilteringfacetedObjectEnum;
  facetedValue: string;
};

export type QuerylyTrendingSearchResponse = {
  query?: string;
  count?: number;
};

export type QuerylySearchFacetedDataResponse = {
  [key in FilteringfacetedObjectEnum]?: facetedObject[];
};

export type QuerylySearchResultsResponse = {
  metadata?: SearchResultsMetadata;
  related?: Array<string>;
  items: SearchResultsItems[];
  faceted?: QuerylySearchFacetedDataResponse;
  ads?: [];
};

export type SearchResultsItems = {
  _id: number;
  index: number;
  title: string;
  link: string;
  description: string;
  pubdate: string;
  pubdateunix: number;
  image: string;
  _type: number;
  feedname: string;
  creator: string;
  category: string;
};

export type facetedObject = {
  key: string;
  value: number;
};

type SearchResultsMetadata = {
  query?: string;
  total?: number;
  endindex?: number;
  correction?: string;
  suggest?: string;
  filters?: [];
};

export enum FilteringSortEnum {
  "Relevance" = "relevancydate",
  "Newest" = "date",
}

export enum FilteringfacetedObjectEnum {
  pubDate = "pubDate",
  creator = "creator",
  category = "category",
}

export type FilterByDateType = {
  [key: string]: string;
};

export type RecentSearchObject = {
  query: string;
  dateSearch: string;
};
