import { Fragment } from "react";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { FilteringByDate } from "@pages/Search/utils/contants";
import {
  facetedObject,
  FilteringfacetedObjectEnum,
  SearchIndividualFilteringProps,
} from "@pages/Search/utils/types";
import { cn } from "@util/helpers";

export default function SearchIndividualPopover({
  advanceFilteringDisplayObj,
  facetedSearchData,
  setSelectedFacetedObj,
}: SearchIndividualFilteringProps): React.ReactElement {
  const handleSelectedFaceted = (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => {
    setSelectedFacetedObj(facetedKey, facetedValue);
  };

  return (
    <Popover
      className="group relative text-gray-850"
      data-testid="search-individual-advance-filtering-popover-component"
    >
      <PopoverButton
        className={cn(
          "relative flex flex-none items-center border border-transparent text-gray-550 transition-all duration-150 hover:text-gray-850 group-data-[open]:text-gray-850",
          REMOVE_FOCUS_CSS
        )}
      >
        <p className="m-0 bg-white font-poppins text-xs leading-[3]">
          {advanceFilteringDisplayObj.value}
        </p>

        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={
            "rotate-180 transition-all duration-150 group-data-[open]:rotate-0"
          }
        >
          <path
            d="M6 15L12 9L18 15"
            style={{ stroke: "currentcolor" }}
            strokeWidth="1.5"
          />
        </svg>
      </PopoverButton>

      <Transition>
        <TransitionChild
          as={Fragment}
          enter="transition ease duration-500 transform "
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease duration-150 transform"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0"
        >
          <PopoverPanel
            anchor={{ to: "bottom end" }}
            className="z-50 flex min-w-[var(--button-width)] flex-col bg-white  shadow-searchPopover"
          >
            {({ close }) => (
              <>
                {facetedSearchData?.map((item: facetedObject) => {
                  return (
                    <button
                      key={item.key}
                      className={cn(
                        "w-full px-3 text-left font-poppins text-xs leading-[3] hover:bg-gray-250 md:leading-[2.3]",
                        REMOVE_FOCUS_CSS
                      )}
                      onClick={() => {
                        handleSelectedFaceted(
                          advanceFilteringDisplayObj.key,
                          item.key.replaceAll("&#039;", "'")
                        );
                        close();
                      }}
                    >
                      <span>
                        {FilteringByDate[item.key] ||
                          item.key.replaceAll("&#039;", "'")}
                      </span>
                      <span className="text-gray-515">{` · ${item.value}`}</span>
                    </button>
                  );
                })}
              </>
            )}
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  );
}
