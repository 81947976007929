import {
  MyAccountActiveProps,
  MyAccountTabsEnum,
} from "@pages/MyAccount/utils/types";

import MyAccountPopoverLoginMyBTLoginState from "./MyAccountPopoverLoginMyBTLoginState";
import MyAccountRecentReadPopoverLoginState from "./MyAccountRecentReadPopoverLoginState";

export default function MyAccountPopoverLoginActiveTabs({
  activeTab,
}: MyAccountActiveProps): React.ReactElement {
  if (activeTab === MyAccountTabsEnum.myBT)
    return <MyAccountPopoverLoginMyBTLoginState />;

  if (activeTab === MyAccountTabsEnum.recent)
    return <MyAccountRecentReadPopoverLoginState />;

  return <></>;
}
