import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import { GeContext } from "@pages/SingaporeGe/utils/types";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import { SingaporeGeLayoutFactory } from "./SingaporeGeLayoutFactory";

export default function SingaporeBudgetPage(): React.ReactElement {
  const { context } = useRouteContext<TRouteWithRedirect<GeContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: GeContext) => {
        if (kind === "static") {
          const GeLayout = SingaporeGeLayoutFactory(data);

          return (
            <>
              {GeLayout ? (
                <GeLayout
                  title={data.title}
                  data={data}
                  description={data.description}
                />
              ) : null}
            </>
          );
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
