import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { SanitizedSectionArticle, Tag } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { cn } from "@util/helpers";

type PropertySupplementsGroupProps = {
  tag: Tag;
  articles: SanitizedSectionArticle[];
  index?: number;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function PropertySupplementsGroup({
  tag,
  articles,
  index = 0,
  className,
}: PropertySupplementsGroupProps): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });
  return (
    <div
      data-testid="wealth-supplements-group-component"
      className={className}
      ref={ref}
    >
      <h2 className="mb-2 font-poppins text-base font-normal">
        <Link to={tag.urlPath} className="hover:underline">
          {tag.name.toUpperCase()}
        </Link>
      </h2>

      <div
        className={cn("grid flex-grow grid-cols-1", {
          "grid grid-cols-1 lg:grid-cols-2": index === 0,
        })}
      >
        {articles.map(
          ({ id, title, urlPath, media, blurb, kicker }, _index) => {
            const isHighlightArticle = index === 0 && _index === 0;
            const isHighlightExtra = index === 0 && _index !== 0;

            const variant: CardVariations = {
              image: {
                position: "left",
                width: cn("w-2/5", { "w-full lg:w-2/5": isHighlightArticle }),
              },
              content: {
                width: cn("w-3/5", { "w-full lg:w-3/5": isHighlightArticle }),
                extraClass: cn({
                  "pl-4": media.length > 0,
                  "pl-0 pt-4 lg:pl-4 lg:pt-0": isHighlightArticle,
                  "pl-0": isHighlightExtra,
                }),
              },
              title: {
                size: isHighlightArticle ? "text-lg lg:text-6xl" : "text-lg",
                color: "text-gray-850",
              },
              kicker: {
                color: "text-gray-515",
              },
            };

            return (
              <BasicCard
                key={id}
                id={id}
                title={title}
                slug={urlPath}
                kicker={kicker?.fields?.[0].value}
                media={!isHighlightExtra ? media : undefined}
                blurb={isHighlightArticle ? blurb : undefined}
                variations={variant}
                rootClassName={cn("bg-gray-150 p-4", {
                  "h-full": index !== 0,
                  "col-span-1 lg:col-span-2 border-b border-gray-175":
                    isHighlightArticle,
                  "border-b border-gray-175 lg:border-none":
                    index === 0 && _index === 1,
                })}
              />
            );
          }
        )}
      </div>
    </div>
  );
}
