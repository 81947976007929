import { useRef } from "react";
import { useGetScrollValue } from "@hooks/useGetScrollValue";

export const getProgressBarPercentage = (scrollPercentRounded: number) => {
  if (scrollPercentRounded >= 100) return 100;

  if (scrollPercentRounded < 0) return 0;

  return scrollPercentRounded;
};

/**
 * Used in article display example ArticleDisplayMainNewsDefault
 * To get the width of the progress bar based on the scroll value
 */
export const useGetProgressWidth = () => {
  const progressBarContainerRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const { prevScrollPos } = useGetScrollValue();

  const handleSetProgressBarWidth = (element: HTMLElement) => {
    if (!element) return;

    const height = element.offsetHeight;
    const scrollPercent = (prevScrollPos - element.offsetTop) / height;
    const scrollPercentRounded = Math.round(scrollPercent * 100);

    if (progressBarRef.current) {
      const percent = getProgressBarPercentage(scrollPercentRounded);
      progressBarRef.current.style.width = `${percent}%`;
    }

    if (progressBarContainerRef.current && scrollPercentRounded > 0) {
      progressBarContainerRef.current.style.opacity = "1";
    }

    if (
      progressBarContainerRef.current &&
      (scrollPercentRounded < 0 || scrollPercentRounded > 99)
    ) {
      progressBarContainerRef.current.style.opacity = "0";
    }
  };

  return { progressBarContainerRef, progressBarRef, handleSetProgressBarWidth };
};
