import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import chevronLeft from "@assets/icon-chevron-left.svg";
import chevronRight from "@assets/icon-chevron-right.svg";
import plusIcon from "@assets/icons/icon-plus.svg";
import {
  COMMON_BUTTON_CSS,
  REMOVE_FOCUS_CSS,
} from "@components/Button/constants";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { useWindowSize } from "@hooks/useWindowSize";
import { MyAccountMyBTTagFilterProps } from "@pages/MyAccount/utils/types";
import { cn } from "@util/helpers";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@greglaisph/react-splide/css";

export default function MyAccountMyBTTagFilter({
  tagList,
  activeFilter,
  setActiveFilter,
}: MyAccountMyBTTagFilterProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const options: Options = {
    perMove: 1,
    pagination: false,
    arrows: isScreenLG,
    snap: true,
    autoHeight: true,
    autoWidth: true,
    focus: 0,
    omitEnd: true,
    gap: "1rem",
    start: 0,
  };

  const handleSelectedTag = (tagName: string) => {
    setActiveFilter(tagName);
  };

  const handleOnSetSessionMyBT = () => {
    sessionStorage.setItem("from-account-page", "true");
  };

  return (
    <div
      className="mb-3 flex"
      data-testid="my-account-my-bt-tag-filter-component"
    >
      {isScreenLG ? (
        <Link
          to={RouteFactory.myBTOnboard}
          className="flex aspect-square h-[36px] flex-none items-center justify-center rounded-[50px] border border-gray-175"
          onClick={handleOnSetSessionMyBT}
        >
          <img src={plusIcon} alt="plus" width={24} height={24} />
        </Link>
      ) : null}

      <div className="w-full lg:ml-3 lg:w-[calc(100%-36px)] lg:border-l lg:border-gray-175 lg:pl-3">
        <Splide
          options={options}
          hasTrack={false}
          ref={(element) => {
            element?.go(tagList.indexOf(activeFilter));
          }}
        >
          <SplideTrack className="lg:w-9/10 [&>ul>li:last-child]:!mr-0 [&>ul>li:last-child]:lg:pr-10">
            {!isScreenLG ? (
              <SplideSlide className="border-r border-gray-175 pr-4">
                <Link
                  to={RouteFactory.myBTOnboard}
                  className="flex aspect-square h-[36px] flex-none items-center justify-center rounded-[50px] border border-gray-175"
                  onClick={handleOnSetSessionMyBT}
                >
                  <img src={plusIcon} alt="plus" width={24} height={24} />
                </Link>
              </SplideSlide>
            ) : null}

            {tagList.map((tag) => (
              <SplideSlide key={tag}>
                <button
                  className={cn(
                    "block h-[36px] rounded-[50px] border border-gray-175 px-4 font-poppins text-xs font-semibold  tracking-2%",
                    {
                      "border-gray-850 bg-gray-850 text-white":
                        activeFilter === tag,
                    }
                  )}
                  onClick={() => {
                    handleSelectedTag(tag);
                  }}
                >
                  {tag}
                </button>
              </SplideSlide>
            ))}
          </SplideTrack>

          <div
            className={cn(
              "splide__arrows absolute right-0 top-0 flex h-full items-center justify-end pl-10 before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:h-full before:bg-gradient-to-l before:from-white before:from-80% before:to-transparent"
            )}
          >
            <button
              className={cn(
                "splide__arrow splide__arrow--prev relative bottom-auto left-auto right-auto top-auto mr-2 w-6 flex-none transform-none bg-transparent opacity-100 data-[disabled]:opacity-50",
                REMOVE_FOCUS_CSS,
                COMMON_BUTTON_CSS,
                "border-gray-850"
              )}
              data-size="xs"
            >
              <img src={chevronLeft} alt="left" width={12} height={12} />
            </button>

            <button
              className={cn(
                "splide__arrow splide__arrow--next relative bottom-auto left-auto right-auto top-auto aspect-square w-6 flex-none transform-none bg-transparent opacity-100 data-[disabled]:opacity-50",
                REMOVE_FOCUS_CSS,
                COMMON_BUTTON_CSS,
                "border-gray-850"
              )}
              data-size="xs"
            >
              <img src={chevronRight} alt="right" width={12} height={12} />
            </button>
          </div>
        </Splide>
      </div>
    </div>
  );
}
