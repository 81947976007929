import { EventsAwardsLayoutFactory } from "@pages/Section/layouts/EventsAwardsPage/SCA/utils/helpers";
import { AwardType } from "@pages/Section/layouts/EventsAwardsPage/utils/types";

type EventsAwardsLogoMenuProps = {
  awardType: AwardType;
};

export default function EventsAwardsLogoMenu({
  awardType,
}: EventsAwardsLogoMenuProps): React.ReactElement {
  const Layout = EventsAwardsLayoutFactory(awardType);

  return <>{Layout ? <Layout /> : null}</>;
}
