import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import EventsAwardsQuickLinks from "@events-awards/components/EventsAwardsQuickLinks";
import EventsAwardsNews from "@events-awards/EventsAwardsNews";
import { AWARDS_EVENTS_PAGE_ROW_DATA } from "@events-awards/utils/constants";
import { EventsAwardsWinnersContextData } from "@events-awards/utils/types";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsNewsPageContent({
  title,
  articles,
  awardType,
}: EventsAwardsWinnersContextData): React.ReactElement {
  const awardData = AWARDS_EVENTS_PAGE_ROW_DATA.find(
    (data) => data.awardType === awardType
  );
  // Get the path and slot from award data
  const awardPath = awardData?.blockTitleProps?.link || "";

  const adBlockDetected = useDetectAdBlock();

  return (
    <div
      className="min-h-screen w-full bg-gray-50"
      data-testid="events-news-page-component"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={`${awardPath}/news`}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title={`${awardData?.blockTitleProps?.text}_News`}
        level2="events news"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title || "The Business Times"}
        description={`The Business Times ${awardData?.blockTitleProps?.text} - ${awardData?.description}`}
        ogType="website"
        slug={awardPath}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 my-8">
            {awardType ? <EventsAwardsLogoMenu awardType={awardType} /> : null}

            <EventsAwardsNews
              articles={articles}
              title="test"
              awardType={awardType}
            />

            <div className="mt-[9rem]">
              {awardType ? (
                <EventsAwardsQuickLinks awardType={awardType} />
              ) : null}
            </div>
          </Column>
        </Row>
      </Container>
      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
