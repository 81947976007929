import { useEffect } from "react";
import { useFirstMountState } from "react-use";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetMyBTArticleList } from "@pages/MyAccount/hooks/useGetMyBTArticleList";
import { GaObject } from "@pages/MyAccount/utils/types";
import useMyBtStore from "@store/useMyBtStore";
import useOKTAUserStore from "@store/useOKTAUserStore";

import MyAccountPopoverMyBTEmptyState from "./MyAccounMyBTPopoverEmptyState";
import MyAccounMyBTPopoverList from "./MyAccounMyBTPopoverList";

export default function MyAccounMyBTPopover(): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userLoginId = OKTAUserInfo?.loginid;

  const sendDataToGTM = useGTMDispatch();
  const isFirstMount = useFirstMountState();

  const userTags = useMyBtStore((store) => store.tags);
  const { myBTArticleList, isLoading } = useGetMyBTArticleList({
    tags: userTags,
    size: 2,
    tagSize: 2,
  });

  const isEmptyState = myBTArticleList.length === 0;

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!isFirstMount) return;

    const userKeywords = userTags?.map(({ name }) => name).join("|");

    const gaObject: GaObject = {
      event: "custom_event",
      eventCategory: "mybt",
      eventAction: "visible",
      eventLabel: window.location.href,
    };

    if (userKeywords) {
      gaObject["user_keyword"] = userKeywords;
    }

    sendDataToGTM(gaObject);
  }, [userTags, sendDataToGTM, isFirstMount]);

  return (
    <div
      data-testid="my-account-more-info-read-component"
      className="w-[360px] p-6"
    >
      {!userLoginId || (isEmptyState && !isLoading) ? (
        <MyAccountPopoverMyBTEmptyState />
      ) : null}

      {!isEmptyState && userLoginId && !isLoading ? (
        <MyAccounMyBTPopoverList tagWithArticleList={myBTArticleList} />
      ) : null}

      {isLoading && userLoginId ? (
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} size="2x" spin className="mb-6" />
        </div>
      ) : null}
    </div>
  );
}
