import logoSIA from "@assets/events-awards/e50/icon-info.svg";
import logoACRA from "@assets/events-awards/sca/logo-acra.jpg";
import logoISCA from "@assets/events-awards/sca/logo-isca.png";
import logoSC from "@assets/events-awards/sca/logo-sc.svg";
import logoSGX from "@assets/events-awards/sca/logo-sgx.png";
import logoSID from "@assets/events-awards/sca/logo-sid.jpg";
import logoBT from "@assets/logo-masthead.svg";
import { Block } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import BasicCard from "@components/ArticleCard/BasicCard";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import {
  EVENTS_AWARDS_SIA_LABEL,
  EVENTS_AWARDS_SIA_PATH,
  EVENTS_AWARDS_SLOT,
} from "@events-awards/utils/constants";
import {
  AwardType,
  EventsAwardsLandingPageContextData,
} from "@events-awards/utils/types";
import EventsAwardsSIAQuickLinks from "@sia/components/EventsAwardsSIAQuickLinks";
import { sectionNavigationItems } from "@util/constant";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsSIAPageContent({
  title,
  article,
}: EventsAwardsLandingPageContextData): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();

  return (
    <div
      className="w-full"
      data-testid="events-awards-sia-page-content-component"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={EVENTS_AWARDS_SIA_PATH}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title="Sustainability_Impact_Awards_Index"
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${EVENTS_AWARDS_SIA_LABEL} - Find ${EVENTS_AWARDS_SIA_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_SIA_PATH}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <EventsAwardsLogoMenu awardType={AwardType.SIA} />

            <Block rootClassName="p-6 text-center border border-eventAwards-sia-accent">
              <img
                src={logoSIA}
                alt="Info icon"
                width={16}
                height={16}
                className="mr-4 inline-block"
              />

              <span className="align-middle font-public-sans text-base uppercase">
                Nominations for SI Awards 2024 are closed
              </span>
            </Block>

            <>
              {article ? (
                <BasicCard
                  title={article.title}
                  id={article.id}
                  media={article.media}
                  slug={article.urlPath}
                  defaultImage={{ directoryName: "events-awards" }}
                  rootClassName="mt-6 md:-mx-3 mx-0 justify-center"
                  variations={{
                    title: {
                      color: "text-gray-850",
                      size: "text-4xl",
                      extraClass: "font-public-sans font-semibold",
                    },
                    content: {
                      width: "w-full",
                      extraClass: "px-6 lg:px-14 text-left",
                    },
                  }}
                />
              ) : null}
            </>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 lg:pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold uppercase leading-5 lg:mb-0 lg:text-right">
                  {"Sustainability "}
                  <br className="hidden lg:inline" />
                  {"Impact "}
                  <br className="hidden lg:inline" />
                  {"AWARDS"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <p className="font-public-sans text-lg leading-7">
                  {
                    "The Sustainability Impact Awards (SI Awards), launched on 1 February 2023, is jointly presented by The Business Times and UOB, with the Centre for Governance and Sustainability (CGS) at the National University of Singapore Business School as the knowledge partner."
                  }
                </p>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"JOINTLY ORGANISED "}
                  <br className="hidden lg:inline" />
                  {"BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoBT}
                    width={320}
                    height={32}
                    alt="The Business Times logo"
                    className="mx-auto"
                  />
                </div>

                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoISCA}
                    width={220}
                    height={48}
                    alt="ISCA logo"
                    className="mx-auto"
                  />
                </div>

                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoSID}
                    width={170}
                    height={79}
                    alt="SID logo"
                    className="mx-auto"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"PRESENTING "}
                  <br className="hidden lg:inline" />
                  {"SPONSOR"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoSC}
                    width={200}
                    height={105}
                    alt="Standard Chartered logo"
                    className="mx-auto"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"SUPPORTED BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoACRA}
                    width={120}
                    height={55}
                    alt="ACRA logo"
                    className="mx-auto"
                  />
                </div>

                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoSGX}
                    width={250}
                    height={46}
                    alt="SGX logo"
                    className="mx-auto"
                  />
                </div>
              </Column>
            </Row>

            <EventsAwardsSIAQuickLinks />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
