import { RouteFactory } from "@app/routePaths";
import { NEWSLETTER_TYPE } from "@components/Newsletter/types/Newsletter";

import { Listing, NewsletterConfig } from "./types";

export const LISTING_NEWSLETTER_CONFIG: NewsletterConfig<Listing>[] = [
  {
    pageURLs: ["/property", "/companies-markets/reits-property"],
    name: { desktop: "Property Insights" },
    tagLine: { mobile: "STRAIGHT TO YOUR INBOX" },
    liner: "Tuesdays: Exclusive real estate and property news analysis.",
    types: [NEWSLETTER_TYPE.PROPERTY],
  },
  {
    pageURLs: [RouteFactory.esg],
    name: { desktop: "ESG Insights" },
    tagLine: { mobile: "STRAIGHT TO YOUR INBOX" },
    liner: "Fridays: Latest environmental, social & governance issues.",
    types: [NEWSLETTER_TYPE.ESG],
  },
  {
    pageURLs: [RouteFactory.aseanBusiness],
    name: { desktop: "Asean Business" },
    tagLine: { mobile: "STRAIGHT TO YOUR INBOX" },
    liner:
      "Fridays: Business insights on Southeast Asia's fast-growing economies.",
    types: [NEWSLETTER_TYPE.ASEAN],
  },
  {
    pageURLs: [RouteFactory.sgsme, "/startups-tech"],
    name: { desktop: "SGSME Newsletter" },
    tagLine: { mobile: "STRAIGHT TO YOUR INBOX" },
    liner:
      "Fridays: Hottest updates on the local SME scene to wrap up your week.",
    types: [NEWSLETTER_TYPE.SGSME],
  },
  {
    pageURLs: [
      "/singapore",
      "/singapore/economy-policy",
      "/companies-markets",
      "/companies-markets/banking-finance",
    ],
    tagLine: { mobile: "STRAIGHT TO YOUR INBOX" },
    liner: "Start and end each day with the latest news stories and analyses.",
    types: [NEWSLETTER_TYPE.MORNING, NEWSLETTER_TYPE.EVENING],
  },
];
