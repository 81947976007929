import { useState } from "react";
import { Element, Storyline } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import Button from "@components/AccessibleComponents/Button";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import { AccordionBody } from "@pages/Article/components/StoryElements/AccordionProcessor";
import { getArticleElementsWithAnnotationReference } from "@pages/Article/utils/helpers";
import { cn, getBoxHeight } from "@util/helpers";
import cx from "classnames";

import { BOX_CHARACTER_THRESHOLD } from "./utils/constants";

export type BoxProps = {
  className?: string;
  elements: Element[];
  storyline: Storyline;
  isAccordion?: boolean;
};

export default function Box({
  className,
  isAccordion,
  elements,
  storyline,
}: BoxProps): React.ReactElement {
  const boxElements = getArticleElementsWithAnnotationReference(
    storyline,
    elements
  );

  const [boxHeight, setBoxHeight] = useState(0);
  const [isShowing, setIsShowing] = useState(false);

  const handlePress = () => {
    setIsShowing((prev) => !prev);
  };

  if (isAccordion) {
    return (
      <AccordionBody
        element={boxElements}
        elements={elements}
        isColumnDisplay={true}
      />
    );
  }

  const characterCounter = boxElements.reduce((acc, element) => {
    const elementTypeToCount = [
      Type.Paragraph,
      Type.BoxTitle,
      Type.ListBulleted,
      Type.ListNumbered,
    ];
    if (!elementTypeToCount.some((type) => element.type === type)) return acc;

    const paragraph = element.fields.find((field) => {
      return field.name === Type.Paragraph || field.name === Type.BoxTitle;
    });

    let childParagraphChars = 0;
    if (
      (element.type === Type.ListBulleted ||
        element.type === Type.ListNumbered) &&
      element.children
    ) {
      element.children.forEach((childId) => {
        const childElement = elements.find((el) => el.id === childId);

        if (childElement && childElement.fields) {
          const childParagraph = childElement.fields.find((field) => {
            return (
              field.name === Type.Paragraph || field.name === Type.BoxTitle
            );
          });

          childParagraphChars =
            childParagraphChars + (childParagraph?.value?.length ?? 0);
        }
      });
      acc = acc + childParagraphChars;
    }

    if (typeof paragraph === "undefined") return acc;

    const paragraphChars = paragraph.value?.length || 0;

    return acc + paragraphChars;
  }, 0);

  return (
    <div
      className={cx(
        "rounded-[.25rem] border border-gray-175 bg-gray-50 p-8 after:clear-both after:block",
        className
      )}
      data-story-element={Type.Box}
      data-testid="box-component"
    >
      <div
        ref={(e) => {
          if (setBoxHeight && e) {
            setBoxHeight(e.scrollHeight);
          }
        }}
        className={cn(
          "overflow-hidden transition-all duration-300 ease-in-out"
        )}
        style={{ height: getBoxHeight(characterCounter, isShowing, boxHeight) }}
      >
        <ArticleParagraphs
          {...{
            id: "",
            elements,
            isPremium: false,
            isBoxWrapped: true,
            storyline,
            forceHeadingCss: true,
          }}
        />
      </div>

      <div className="relative">
        {characterCounter > BOX_CHARACTER_THRESHOLD ? (
          <>
            {!isShowing ? (
              <div className="absolute bottom-full left-0 right-0 h-[3.75rem] bg-gradient-to-b from-transparent to-gray-50"></div>
            ) : null}

            <Button
              className="mt-3 block w-full border-t border-gray-175 pt-3 !font-poppins text-base font-semibold leading-6 outline-none"
              onPress={handlePress}
            >
              {isShowing ? "Collapse" : "Expand"}

              <FontAwesomeIcon
                icon={isShowing ? faChevronUp : faChevronDown}
                className="ml-1"
              />
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
}
