import { HtmlHTMLAttributes } from "react";

type HeadingProps = {
  text: string;
  level?: "1" | "2" | "3" | "4" | "5" | "6";
} & Pick<HtmlHTMLAttributes<HTMLHeadingElement>, "className">;

export default function Heading({
  text,
  level,
  className,
}: HeadingProps): React.ReactElement {
  const H = `h${level}` as keyof JSX.IntrinsicElements;

  return <H className={className}>{text}</H>;
}
