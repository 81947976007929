import { useEffect, useState } from "react";

const useIsAppWebView = (): boolean => {
  const [isAppWebView, setIsAppWebView] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const userAgent = window.navigator.userAgent;
    const iphoneWebView = "com.sph.btiphone";
    const androidWebView = "com.ps.bt";

    if (
      userAgent.includes(iphoneWebView) ||
      userAgent.includes(androidWebView)
    ) {
      setIsAppWebView(true);
    }
  }, [setIsAppWebView]);

  return isAppWebView;
};

export default useIsAppWebView;
