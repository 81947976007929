import useScript from "@hooks/useScript";

export default function useAddBrightcoveCustomScript() {
  useScript({
    id: "brightcove-player",
    src: "https://players.brightcove.net/1752604059001/decfdaf5-0a6e-43f6-82d3-39d125c413c4_default/index.min.js",
  });

  useScript({
    id: "brightcove-player-ga-plugin",
    src: "https://players.brightcove.net/videojs-bc-ga/2/videojs-bc-ga.min.js",
  });
}
