import { useEffect, useState } from "react";

type useDetectParentContainerVisibleProps = {
  parentId: string;
};
export const useDetectParentContainerVisible = ({
  parentId,
}: useDetectParentContainerVisibleProps) => {
  // Handles state for parent container visibility
  const [isParentDisplay, setIsParentDisplay] = useState(true);

  const setParentDisplayed = (mutationList: MutationRecord[]) => {
    for (const mutation of mutationList) {
      if (mutation.type !== "attributes") return;

      const target = mutation.target as HTMLElement;
      if (!target.classList.contains("hidden")) return;
      if (target.style.display !== "none") return;

      setIsParentDisplay(false);
    }
  };

  useEffect(() => {
    // Add mutation observer to detect changes if there a change in parent container
    if (typeof window === "undefined") return;

    const appElement = window.document.querySelector(parentId);

    if (!appElement) return;
    const observer = new MutationObserver(setParentDisplayed);
    observer.observe(appElement, {
      attributes: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [parentId]);

  return { isParentDisplay };
};
