import { KeywordContext } from "@app/types/Page";
import { BlockTitle } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import Description from "@pages/Keyword/components/Description";
import Highlights from "@pages/Keyword/components/Highlights";
import MoreStories from "@pages/Keyword/components/MoreStories";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { sectionNavigationItems } from "@util/constant";

export type WithHighlightsProps = Pick<KeywordContext, "data">;

export default function WithHighlights({
  data: { title, entity, highlightArticles, overview },
}: WithHighlightsProps): React.ReactElement {
  const termName = entity.name;
  const termPath = entity.urlPath;

  return (
    <div className="w-full" data-testid="with-highlight-component">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={termPath}
        adTargets={[
          { key: "page", value: "listing" },
          { key: "bttags", value: getFormattedTextForAds(termName) },
        ]}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={`/${termPath}`}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        parentCategory="keywords"
        childCategory={termPath.replace("/keywords/", "")}
        categoryDataMapping={sectionNavigationItems}
      />

      <Container rootClassName="pt-6">
        <Description className="mb-3" tag={entity} />

        <Highlights
          articles={highlightArticles}
          className="mb-16 mt-8 lg:mt-0 lg:border-t lg:border-gray-850 lg:pt-8"
        />

        <>
          {highlightArticles &&
          highlightArticles.length > 0 &&
          overview.length > 0 ? (
            <BlockTitle
              text="MORE STORIES"
              rootClassName="pt-2 border-t border-gray-850"
            />
          ) : null}
        </>

        <MoreStories articles={overview} className="mb-16 mt-8" />
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
