import { useRef, useState } from "react";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  DAILY_NEWSLETTERS,
  PREMIUM_NEWSLETTERS,
  WEEKLY_NEWSLETTERS,
} from "@components/Newsletter/constants";
import NewsletterSubscriptionPanel from "@components/Newsletter/NewsletterSubscriptionPanel";
import useHandleSelectedNewsletter from "@hooks/useHandleSelectedNewsletters";
import NewsletterAnchorLinks from "@pages/Newsletter/SignUp/NewsletterAnchorLinks";
import NewsletterSections from "@pages/Newsletter/SignUp/NewsletterSections";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { DefaultNavigationData } from "@typings/Page";
import { scrollToNewsletterSection } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import cx from "classnames";

export default function NewsletterSignupPage({
  seoFooterData,
}: DefaultNavigationData) {
  const adBlockDetected = useDetectAdBlock();

  const [email, setEmail] = useState("");
  const premiumNewsletterRef = useRef<HTMLDivElement>(null);
  const dailyNewsletterRef = useRef<HTMLDivElement>(null);
  const weeklyNewsletterRef = useRef<HTMLDivElement>(null);

  const [selectedNewsletters, handleSelectedNewsletter] =
    useHandleSelectedNewsletter();

  return (
    <div className="w-full" data-testid="newsletter-sign-up-page-component">
      <AdSettings
        adNames={["lb1", "catfish", "abm"]}
        path={RouteFactory.newsletterSignup}
      />

      <MetaTags
        title={renderPageTitleV2({
          kind: "static",
          title: "Newsletter signup",
        })}
        description="Read more at The Business Times."
        slug={RouteFactory.newsletterSignup}
      />

      <GAData
        title="Newsletter_signup_landing_page"
        level2="newsletter"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <PulseEntry />

      <Container>
        <h1
          className={cx(
            "border-b border-gray-850 pb-3 pt-6 font-playfair text-8xl uppercase"
          )}
        >
          Newsletters
        </h1>
      </Container>

      <div
        className="newsletter-signup-page container !mt-6 px-3"
        data-testid="newsletter-signup-page"
      >
        <div className="grid grid-cols-12 gap-3 md:gap-0">
          <div className="col-span-12 xl:col-span-3">
            <p className="font-public-sans text-lg font-normal text-gray-850">
              Select the newsletters that suit your needs and get them sent
              straight to your inbox.
            </p>

            <NewsletterAnchorLinks
              scrollToNewsletterSection={(newsletter) => {
                scrollToNewsletterSection(newsletter, weeklyNewsletterRef);
              }}
            />
          </div>

          <div className="col-span-12 xl:col-span-9">
            <NewsletterSections
              title="Premium NEWSLETTERS"
              newsletters={PREMIUM_NEWSLETTERS}
              selectedNewsletters={selectedNewsletters}
              handleSelectedNewsletter={handleSelectedNewsletter}
              sectionRef={premiumNewsletterRef}
            />

            <NewsletterSections
              title="Daily NEWSLETTERS"
              newsletters={DAILY_NEWSLETTERS}
              selectedNewsletters={selectedNewsletters}
              handleSelectedNewsletter={handleSelectedNewsletter}
              sectionRef={dailyNewsletterRef}
              rootClassname={"xl:pt-0 xl:border-none pt-3 border-t"}
            />

            <NewsletterSections
              title="Weekly NEWSLETTERS"
              newsletters={WEEKLY_NEWSLETTERS}
              selectedNewsletters={selectedNewsletters}
              handleSelectedNewsletter={handleSelectedNewsletter}
              sectionRef={weeklyNewsletterRef}
            />
          </div>
        </div>
      </div>

      {selectedNewsletters.length > 0 ? (
        <NewsletterSubscriptionPanel
          selectedNewsletters={selectedNewsletters}
          handleSelectedNewsletter={handleSelectedNewsletter}
          email={email}
          setEmail={setEmail}
        />
      ) : null}

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
