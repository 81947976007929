import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import ThriveFavicon from "@pages/Thrive/components/ThriveFavicon";

export default function Favicon(): React.ReactElement {
  const { pathname } = useLocation();

  if (pathname.startsWith(RouteFactory.thrive)) return <ThriveFavicon />;

  return (
    <Helmet>
      <link href="/favicon.ico" rel="icon" type="image/x-icon" />
    </Helmet>
  );
}
