import { ArticleDataObject } from "@app/types/Cue";
import { useLoadMoreArticlesByProfileId } from "@hooks/useLoadMoreArticlesByProfileId";

import AuthorListingArticles from "./AuthorListingArticles";

export type AuthorArticlesProps = {
  articles: ArticleDataObject[];
  profileId: string;
};

export default function AuthorArticles({
  articles,
  profileId,
}: AuthorArticlesProps): React.ReactElement {
  const { articleList, ref } = useLoadMoreArticlesByProfileId(
    profileId,
    articles
  );

  return (
    <div data-testid="author-articles-component">
      <div className="stories">
        {articleList.map((article, index) => {
          return (
            <AuthorListingArticles key={article.id} {...{ article, index }} />
          );
        })}
      </div>

      <div ref={ref} />
    </div>
  );
}
