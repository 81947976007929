import { PropsWithChildren } from "react";
import { IS_PLAYWRIGHT } from "@util/constant";
import { useFeatureFlagEnabled } from "posthog-js/react";

import { POSTHOG_API_KEY } from "./utils/constants";

type PosthogFeatureProps = {
  /** The unique name of the feature flag. */
  flagName: string;
} & PropsWithChildren;

/** Main component to use in place of PostHogFeature from posthog-js/react */
export default function PosthogFeature({
  flagName,
  children,
}: PosthogFeatureProps): React.ReactElement {
  const flag = useFeatureFlagEnabled(flagName);

  console.log("[posthog] key", POSTHOG_API_KEY); // eslint-disable-line no-console
  console.log("[posthog]", flagName, flag); // eslint-disable-line no-console
  console.log("[posthog] ---"); // eslint-disable-line no-console

  // Always enable the feature flag in Playwright tests.
  if (IS_PLAYWRIGHT) return <>{children}</>;

  return <>{flag ? children : null}</>;
}
