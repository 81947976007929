import EventsAwardsDTALogoMenu from "@dta/components/EventsAwardsDTALogoMenu";
import EventsAwardsE50LogoMenu from "@e50/components/EventsAwardsE50LogoMenu";
import EventsAwardsEELogoMenu from "@ee/components/EventsAwardsEELogoMenu";
import { AwardType } from "@events-awards/utils/types";
import EventsAwardsSBALogoMenu from "@sba/components/EventsAwardsSBALogoMenu";
import EventsAwardsSCALogoMenu from "@sca/components/EventsAwardsSCALogoMenu";
import EventsAwardsSIALogoMenu from "@sia/components/EventsAwardsSIALogoMenu";

export const EventsAwardsLayoutFactory = (awardType: AwardType) => {
  switch (awardType) {
    case AwardType.SCA:
      return EventsAwardsSCALogoMenu;
    case AwardType.E50:
      return EventsAwardsE50LogoMenu;
    case AwardType.SBA:
      return EventsAwardsSBALogoMenu;
    case AwardType.SIA:
      return EventsAwardsSIALogoMenu;
    case AwardType.EE:
      return EventsAwardsEELogoMenu;
    case AwardType.DATA:
      return EventsAwardsDTALogoMenu;
  }
};
