import { TestIdClassNameProps } from "@app/types/Common";
import { BlockTitle } from "@blocks/Blocks";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import useBudget2024Videos from "@hooks/useBudget2024Videos";

import VideoCard from "./VideoCard";

import "@greglaisph/react-splide/css";

export default function Videos({
  testId,
  rootClassName,
}: TestIdClassNameProps): React.ReactElement {
  const videos = useBudget2024Videos();

  const options: Options = {
    perPage: 1,
    gap: "1.5rem",
    pagination: false,
    mediaQuery: "min",
    fixedWidth: "70%",
    arrows: false,
    snap: true,
    breakpoints: {
      992: {
        arrows: videos.length > 3,
        perPage: 3,
        fixedWidth: undefined,
      },
    },
  };

  return (
    <>
      {videos.length > 0 ? (
        <div className={rootClassName} data-testid={testId}>
          <BlockTitle text="VIDEOS" rootClassName="mb-4" />

          <Splide options={options} hasTrack={false}>
            {videos.length > 3 ? (
              <div className="splide__arrows absolute bottom-full right-0 flex gap-2">
                <button className="splide__arrow splide__arrow--prev !static !bg-white">
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="sm"
                    className="block !h-4 !w-4 cursor-pointer rounded-full border border-black fill-gray-650 p-1.5 hover:opacity-100"
                  />
                </button>
                <button className="splide__arrow splide__arrow--next !static !bg-white">
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="sm"
                    className="block !h-4 !w-4 cursor-pointer rounded-full border border-black fill-gray-650 p-1.5 hover:opacity-100"
                  />
                </button>
              </div>
            ) : null}

            <SplideTrack>
              {videos.map((video) => {
                return (
                  <SplideSlide key={`${video.id}-splide-slide`}>
                    <VideoCard {...video} />
                  </SplideSlide>
                );
              })}
            </SplideTrack>
          </Splide>
        </div>
      ) : null}
    </>
  );
}
