import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { Tag } from "@app/types/Cue";
import { cn } from "@util/helpers";

export type StoryThreadProps = {
  storyThread: Tag;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function StoryThreadLink({
  className,
  storyThread,
}: StoryThreadProps): React.ReactElement {
  return (
    <Link
      className={cn(
        "font-poppins text-4xs font-light uppercase leading-4 tracking-[1px] hover:underline",
        className
      )}
      to={storyThread.urlPath}
      target="_blank"
      title={storyThread.name}
      reloadDocument
      data-testid="story-thread-link-component"
    >
      <span className="inline-block hover:underline">{storyThread.name}</span>
    </Link>
  );
}
