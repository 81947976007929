import { useCallback, useEffect, useRef, useState } from "react";
import { Field } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import { getDataWarapperSource } from "@pages/Article/components/StoryElements/helpers";

export type DataWrapperProps = {
  fields: Field[];
  className?: string;
};

export default function DataWrapper({ className, fields }: DataWrapperProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(500);
  const source = fields.filter(
    (field) => field.name === Name.DataWrapperSrc
  )?.[0]?.value;

  const parsedSource = getDataWarapperSource(source);

  // DataWrapper listens to this event to set the height of the iframe
  // @see https://academy.datawrapper.de/category/171-exporting-charts
  const onMessage = useCallback(
    ({ data = {}, source }: MessageEvent) => {
      if (
        source !== iframeRef.current?.contentWindow ||
        typeof data === "string" ||
        !data["datawrapper-height"]
      ) {
        return;
      }

      const actualHeight = Object.values(data["datawrapper-height"])?.[0];

      if (actualHeight && typeof actualHeight === "number") {
        setHeight(actualHeight);
      }
    },
    [iframeRef]
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  return (
    <>
      {parsedSource ? (
        <div
          className={className}
          data-story-element={Type.DataWrapper}
          data-testid="data-wrapper-component"
        >
          <iframe
            ref={iframeRef}
            data-height={height}
            style={{
              width: "100%",
              height,
              border: 0,
              boxSizing: "content-box",
            }}
            src={parsedSource}
            title="data-wrapper-title"
            scrolling="no"
          />
        </div>
      ) : null}
    </>
  );
}
