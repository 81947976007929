import { ReactElement } from "react";
import { Await } from "react-router-dom";
import { AuthorContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import AuthorPageContent from "./AuthorPageContent";

export default function AuthorPage(): ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<AuthorContext, string>>();

  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: AuthorContext) => {
        if (kind !== "author") {
          return <CustomError statusCode={context.statusCode} />;
        }

        return <AuthorPageContent data={data} />;
      }}
    </Await>
  );
}
