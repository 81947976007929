import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { getKickerFromArticleDataObject } from "@util/helpers";

export type WealthRealEstateProps = {
  data: SanitizedSectionArticle[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function WealthRealEstate({
  data = [],
}: WealthRealEstateProps): React.ReactElement {
  const topStory = data?.slice(0, 1);
  const subStories = data?.slice(1, 4);

  const variantTop: CardVariations = {
    content: {
      width: "w-7/10 lg:w-full",
      extraClass: "p-4",
    },
    image: {
      width: "w-3/10 lg:w-full",
      position: "left",
    },
    kicker: {
      color: "text-gray-515",
    },
  };

  const variant: CardVariations = {
    content: {
      width: "w-7/10 lg:w-full",
    },
    image: {
      width: "w-3/10 lg:w-full",
      position: "left",
    },
    kicker: {
      color: "text-gray-515",
    },
  };

  return (
    <>
      <Row data-testid="wealth-real-estate-component">
        <Column rootClassName="w-full">
          <BlockTitle
            text="REAL ESTATE"
            link="/property"
            rootClassName="mb-3 border-t border-black pt-3"
          />
        </Column>

        <Column rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12">
          {topStory?.map((article) => {
            const { id, title, media, paywall, sections, slug } = article;

            return (
              <BasicCard
                slug={slug}
                rootClassName="items-start lg:flex-col bg-gray-150 lg:bg-none"
                id={id}
                title={title}
                key={id}
                media={media}
                kicker={getKickerFromArticleDataObject(article)}
                paywall={paywall?.contentAccess === "1"}
                variations={variantTop}
                section={sections?.[0]}
                defaultImage={{
                  directoryName: "property",
                }}
              />
            );
          })}
        </Column>

        <Column rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12 flex flex-col">
          {subStories.map((article, index: number) => {
            const { id, title, paywall, slug } = article;

            return (
              <BasicCard
                key={`${id}_${index}`}
                id={id}
                slug={slug}
                title={title}
                kicker={getKickerFromArticleDataObject(article)}
                paywall={paywall?.contentAccess === "1"}
                variations={variant}
                rootClassName={"border-b border-gray-175 bg-gray-150 p-4"}
              />
            );
          })}
        </Column>

        <Column rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12">
          <Ad
            adType="imu2"
            className="flex flex-col items-center justify-center lg:sticky lg:top-[70px]"
          />
        </Column>
      </Row>
    </>
  );
}
