import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import {
  MyAccountTabsEnum,
  MyAccountTabsProps,
} from "@pages/MyAccount/utils/types";
import { cn } from "@util/helpers";

export default function MyAccountTabs({
  activeTab,
  handleTabChange,
  className,
}: MyAccountTabsProps): React.ReactElement {
  const tabsList = Object.entries(MyAccountTabsEnum).map(([key, value]) => ({
    key,
    value,
  }));

  return (
    <div
      className={cn(
        "mx-6 mt-4 flex border-b border-gray-850 py-3 font-poppins text-gray-850",
        className
      )}
      data-testid="my-account-tabs-component"
    >
      {tabsList.map((tab) => {
        const isActive = tab.value === activeTab;
        return (
          <button
            data-testid={`my-account-tab-button-${tab.key}`}
            className={cn(
              "block border-r border-gray-175 pl-3 pr-3 first:pl-0 last:border-r-0 last:pr-0",
              REMOVE_FOCUS_CSS
            )}
            key={tab.key}
            onClick={() => {
              handleTabChange(tab.value);
            }}
          >
            <p
              data-text={tab.value}
              className={cn("hover:font-bold", {
                "font-bold": isActive,
              })}
            >
              {tab.value}
            </p>
          </button>
        );
      })}
    </div>
  );
}
