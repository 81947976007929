import { Fragment } from "react";
import AccordionProcessor from "@app/pages/Article/components/StoryElements/AccordionProcessor";
import ArticleInlineImage from "@app/pages/Article/components/StoryElements/ArticleInlineImage";
import Box from "@app/pages/Article/components/StoryElements/Box/Box";
import DataWrapper from "@app/pages/Article/components/StoryElements/DataWrapper";
import DynamicIframeWrapper from "@app/pages/Article/components/StoryElements/DynamicIframe/DynamicIframeWrapper";
import Embed from "@app/pages/Article/components/StoryElements/Embed";
import Graphic from "@app/pages/Article/components/StoryElements/Graphic";
import ListBulleted from "@app/pages/Article/components/StoryElements/ListBulleted";
import ListNumbered from "@app/pages/Article/components/StoryElements/ListNumbered";
import OmnyPodcast from "@app/pages/Article/components/StoryElements/OmnyPodcast";
import ParagraphWrapper from "@app/pages/Article/components/StoryElements/ParagraphWrapper";
import PodcastWrapper from "@app/pages/Article/components/StoryElements/PodcastWrapper";
import PullQuote from "@app/pages/Article/components/StoryElements/PullQuote";
import SubHeading from "@app/pages/Article/components/StoryElements/SubHeading";
import Table from "@app/pages/Article/components/StoryElements/Table";
import YouTubeVideo from "@app/pages/Article/components/StoryElements/YouTubeVideo";
import type {
  Element,
  EmbedCode,
  KeyValuePair,
  SanitizedRelatedStory,
  Section,
  seeAlso,
  Storyline,
  Tag,
} from "@app/types/Cue";
import { Type } from "@app/types/enums";
import { ExternalLink, Note } from "@blocks/Blocks";
import FallbackPaywall from "@blocks/FallbackPaywall";
import DynamicImu from "@components/Advertisement/DynamicImu";
import CASBlock from "@components/CASBlock";
import DelayedComponentsLocationChange from "@components/DelayedComponents/DelayedComponentsLocationChange";
import PosthogFeature from "@components/Posthog/PosthogFeature";
import { useArticleElements } from "@hooks/useArticleElements";
import usePublishedTime from "@hooks/usePublishedTime";
import ArticlePurchaseLink from "@pages/Article/components/ArticlePurchaseLink/ArticlePurchaseLink";
import { Versions } from "@pages/Article/components/ArticlePurchaseLink/types";
import AnnotationComponentStockPicker from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentStockPicker";
import { BrightcoveVideo } from "@pages/Article/components/StoryElements/BrightcoveVideo";
import { getResolvedArticleElements } from "@pages/Article/utils/helpers";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import {
  CDP_PAYWALL_180_DAYS_MODULE_ID,
  CDP_PAYWALL_MODULE_ID,
} from "@util/constant";
import { checkIfFromPaidPressRelease, cn, isBoxAccordion } from "@util/helpers";
import { useFeatureFlagEnabled } from "posthog-js/react";

type PurchaseLinkProps = {
  title: string;
  publishedDate: string;
  isDisplayed: boolean;
};

export type ArticleParagraphsProps = {
  className?: string;
  id: string;
  elements: Element[];
  seeAlso?: seeAlso;
  embedCodes?: EmbedCode[];
  displayRelatedLinksAndNewsletterSignupForm?: boolean;
  keywords?: Tag[];
  sections?: Section[];
  index?: number;
  gsChannels?: string;
  storyline: Storyline;
  isPremium: boolean;
  isBoxWrapped?: boolean;
  isGifted?: boolean;
  adsWrapperClass?: string;
  isBrandedContent?: boolean;
  readMoreArticle?: SanitizedRelatedStory[];
  fullScreenAds?: boolean;
  displayNewVersionNewsletter?: boolean;
  displayArticleReadMore?: boolean;
  elementsClass?: string;
  forceHeadingCss?: boolean;
  displayPurchase?: PurchaseLinkProps;
  isPreview?: boolean;
  displayStyleForDefaultLayout?: boolean;
  urlPath?: string;
  accordionHeaderClass?: string;
  parameters?: KeyValuePair[];
  publishedDate?: string;
};

export default function ArticleParagraphs({
  id,
  className,
  elements = [],
  seeAlso,
  displayRelatedLinksAndNewsletterSignupForm = false,
  keywords = [],
  sections = [],
  index = 0,
  gsChannels = "no_analysis",
  storyline,
  isPremium,
  isBoxWrapped = false,
  isGifted = false,
  adsWrapperClass,
  isBrandedContent = false,
  readMoreArticle = [],
  fullScreenAds = false,
  elementsClass,
  forceHeadingCss,
  displayPurchase,
  isPreview = false,
  displayStyleForDefaultLayout,
  accordionHeaderClass = "",
  urlPath,
  parameters,
  publishedDate = "",
}: ArticleParagraphsProps): React.ReactElement | null {
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const isSubscriber = OktaUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  const isUserLoginEnabled = useFeatureFlagEnabled("feature_enable_user_login");
  const isFeature180DaysAgo = useFeatureFlagEnabled("feature_180_days_ago");
  const { isMoreThan180DaysAgo } = usePublishedTime(publishedDate);
  const fetchedElements = useArticleElements(id, elements);

  // Truncate the article if feature is enabled and the user is not a subscriber or premium user.
  const truncated180DaysAgo =
    isFeature180DaysAgo && !isSubscriber && !isPremium && isMoreThan180DaysAgo;
  // Determine the paywall module to load.
  const cdpPaywallModuleId = truncated180DaysAgo
    ? CDP_PAYWALL_180_DAYS_MODULE_ID
    : CDP_PAYWALL_MODULE_ID;
  // Conditions to determine if article should show full content.
  const isFullContent =
    isSubscriber || // User is a subscriber
    isGifted || // Article is gifted
    isPreview || // Article is in preview mode
    !isUserLoginEnabled || // Feature flag is disabled
    (!truncated180DaysAgo && !isPremium); // Feature truncated180DaysAgo is false

  const articleElements = getResolvedArticleElements(
    storyline,
    fetchedElements,
    !isFullContent
  );

  let paragraphCount = 1;

  const isPaidPressRelease = checkIfFromPaidPressRelease(sections);

  const mainSection = sections?.[0];
  const totalParagraphs = articleElements.filter(
    (element) => element.type === Type.Paragraph
  ).length;

  return (
    <div className={className} data-testid="article-paragraphs-component">
      <div
        className={cn(
          "relative",
          {
            "[&>*:last-child]:relative [&>*:last-child]:after:absolute [&>*:last-child]:after:inset-0 [&>*:last-child]:after:bg-gradient-to-t [&>*:last-child]:after:from-white [&>*:last-child]:after:to-transparent":
              !isFullContent,
          },
          elementsClass
        )}
      >
        {articleElements.map((element) => {
          switch (element.type) {
            case Type.SubHead:
              return (
                <SubHeading
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                  forceHeadingCss={forceHeadingCss}
                />
              );

            case Type.Paragraph:
              return (
                <ParagraphWrapper
                  key={element.id}
                  {...{
                    element,
                    index,
                    id,
                    keywords,
                    gsChannels,
                    displayRelatedLinksAndNewsletterSignupForm,
                    seeAlso,
                    sections,
                    isBoxWrapped,
                    totalParagraphs,
                    adsWrapperClass,
                    isBrandedContent,
                    readMoreArticle,
                    fullScreenAds,
                    elementsClass: cn(elementsClass, {
                      "relative after:absolute after:inset-0 after:bg-gradient-to-t after:from-white after:to-transparent":
                        totalParagraphs <= 5 &&
                        paragraphCount === 3 &&
                        !isSubscriber &&
                        !isBoxWrapped &&
                        isPremium,
                      hidden:
                        [4, 5].includes(paragraphCount) &&
                        !isSubscriber &&
                        isPremium,
                    }),
                    displayStyleForDefaultLayout,
                    paragraphCount: paragraphCount++,
                    parameters,
                    isUserLoginEnabled,
                  }}
                />
              );

            case Type.PodcastWrapper:
              return (
                <PodcastWrapper
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Image:
              return (
                <Fragment key={element.id}>
                  {element.relation ? (
                    <ArticleInlineImage
                      fields={element.fields}
                      relation={element.relation}
                      className={cn("mb-6", elementsClass)}
                    />
                  ) : null}
                </Fragment>
              );

            case Type.BrightcoveVideo:
              return (
                <BrightcoveVideo
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.DataWrapper:
              return (
                <DataWrapper
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.DynamicIframeWrapper:
              return (
                <DynamicIframeWrapper
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Graphic:
              return (
                <Graphic
                  key={element.id}
                  attachment={element.relation}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Note:
              return (
                <Note
                  key={element.id}
                  value={element?.fields?.map((f) => f.value).join("\n")}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.GroupedRelation:
              return (
                <ExternalLink
                  key={element.id}
                  // @ts-expect-error incomplete typedef
                  src={element.children.map((group) => {
                    const rel = articleElements?.find?.(
                      (el) => el.id === group
                    );

                    return rel?.relation?.id;
                  })}
                />
              );

            case Type.Embed:
              return (
                <Embed
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.ListBulleted:
              return (
                <ListBulleted
                  key={element.id}
                  elements={element.reference || []}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.ListNumbered: {
              return (
                <ListNumbered
                  key={element.id}
                  elements={element.reference || []}
                  className={cn("mb-6", elementsClass)}
                />
              );
            }

            case Type.PullQuote:
              return (
                <PullQuote
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Table:
              return (
                <Table
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.HorizontalRow:
              return (
                <hr
                  key={element.id}
                  className={cn("mb-6", elementsClass)}
                  data-story-element={Type.HorizontalRow}
                />
              );

            case Type.YoutubeVideo:
              return (
                <YouTubeVideo
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.OmnyPodcast:
              return (
                <OmnyPodcast
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Box: {
              const isAccordion = isBoxAccordion(
                element.fields,
                Type.Accordion
              );

              return (
                <Fragment key={element.id}>
                  {element.reference && element.children ? (
                    <Box
                      elements={elements}
                      isAccordion={isAccordion}
                      storyline={element.children}
                      className={cn("mb-6", elementsClass)}
                    />
                  ) : null}
                </Fragment>
              );
            }

            case Type.BoxTitle:
              return (
                <p key={element.id} className="mb-6 font-bold">
                  {element.fields?.[0]?.value || ""}
                </p>
              );

            case Type.StockPicker:
              return (
                <AnnotationComponentStockPicker
                  key={element.id}
                  element={element}
                  className="mb-6 inline-block"
                >
                  {element.fields?.[0]?.value || ""}
                </AnnotationComponentStockPicker>
              );

            case Type.Accordion: {
              const isColumnDisplay = element.fields?.some(
                (field) => field.name === "columnDisplay" && field.booleanValue
              );
              return (
                <AccordionProcessor
                  key={element.id}
                  elements={elements}
                  AccordionElement={element.reference || []}
                  className="mx-auto max-w-3xl"
                  elementsClass={elementsClass}
                  accordionHeaderClass={accordionHeaderClass}
                  isColumnDisplay={isColumnDisplay}
                />
              );
            }

            default:
              return null;
          }
        })}
      </div>

      {!isBoxWrapped ? (
        <PosthogFeature flagName="feature_enable_user_login">
          <DelayedComponentsLocationChange
            waitBeforeShow={1000}
            urlPath={urlPath}
          >
            <CASBlock
              cdpId={cdpPaywallModuleId}
              index={index}
              className="lg:-mx-6"
              fallback={
                !isSubscriber ? (
                  <FallbackPaywall
                    className="mb-6 transition duration-300 ease-in data-[closed]:opacity-0"
                    isPremium={isPremium}
                  />
                ) : null
              }
            />
          </DelayedComponentsLocationChange>
        </PosthogFeature>
      ) : null}

      <>
        {displayPurchase?.publishedDate ? (
          <div className="mb-6 border-t border-gray-175">
            <ArticlePurchaseLink
              title={displayPurchase.title}
              publishedDate={displayPurchase.publishedDate}
              versions={Versions.version2}
              displayPurchase={displayPurchase.isDisplayed}
              urlPath={urlPath}
            />
          </div>
        ) : null}
      </>

      {mainSection && !isPaidPressRelease ? (
        <DynamicImu
          index={index}
          totalParagraphs={totalParagraphs}
          adsWrapperClass={adsWrapperClass}
          fullScreenAds={fullScreenAds}
          articleId={id}
          keywords={keywords}
        />
      ) : null}
    </div>
  );
}
