import { HtmlHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import Button from "@components/AccessibleComponents/Button";
import { useWindowSize } from "@hooks/useWindowSize";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn, mySPHOpenLogin } from "@util/helpers";

import {
  FALLBACK_PAYWALL_CTA_FREE_LINK,
  FALLBACK_PAYWALL_CTA_PREMIUM_LINK,
  FALLBACK_PAYWALL_DESC_FREE_TEXT,
  FALLBACK_PAYWALL_DESC_PREMIUM_TEXT,
  FALLBACK_PAYWALL_HEADER_FREE_TEXT,
  FALLBACK_PAYWALL_HEADER_PREMIUM_TEXT,
} from "./utils/constants";

type FallbackPaywallProps = {
  isPremium: boolean;
} & Pick<HtmlHTMLAttributes<HTMLDivElement>, "className">;

export default function FallbackPaywall({
  className,
  isPremium,
}: FallbackPaywallProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const isAnoy = ![
    OKTAUserTypeEnum.SUBSCRIBER,
    OKTAUserTypeEnum.REGISTERED,
  ].includes(OktaUserInfo?.usertype || OKTAUserTypeEnum.ANONYMOUS);

  const header = isPremium
    ? FALLBACK_PAYWALL_HEADER_PREMIUM_TEXT
    : FALLBACK_PAYWALL_HEADER_FREE_TEXT;

  const description = isPremium
    ? FALLBACK_PAYWALL_DESC_PREMIUM_TEXT
    : FALLBACK_PAYWALL_DESC_FREE_TEXT;

  const href = isPremium
    ? FALLBACK_PAYWALL_CTA_PREMIUM_LINK
    : FALLBACK_PAYWALL_CTA_FREE_LINK;

  return (
    <div className={className} data-testid="fallback-paywall-component">
      <div
        className={cn(
          "relative z-10 rounded-lg border border-gray-175 bg-white px-7 py-8 text-center shadow-[0px_4px_32px_0px_rgba(0,0,0,0.05)]",
          "md:px-10",
          "lg:px-18 lg:py-9",
          "xl:px-36"
        )}
      >
        <h2
          className={cn(
            "-track-[0.075rem] font-lct text-7xl font-normal leading-9",
            "lg:text-10xl"
          )}
        >
          {header}
        </h2>

        <p className="font-lct text-2xl font-normal leading-8 lg:px-4">
          {description}
        </p>

        <Link
          to={href}
          className="inline-block rounded-full border border-gray-850 bg-gray-850 px-11 py-4 font-poppins text-xs font-semibold leading-none tracking-[0.28px] !text-white !no-underline transition-colors hover:bg-white hover:!text-gray-850"
          target="_blank"
        >
          Pick a plan
        </Link>

        {!isScreenLG && isAnoy ? (
          <>
            <hr className="my-7" />

            <PaywallLogin />
          </>
        ) : null}
      </div>

      {isScreenLG && isAnoy ? <PaywallLogin /> : null}
    </div>
  );
}

const PaywallLogin = (): React.ReactElement => {
  return (
    <p className="mt-3 text-center font-poppins text-base font-normal tracking-normal">
      Already have a subscription?
      <Button
        className="ml-2 font-semibold outline-none hover:underline"
        onPress={mySPHOpenLogin}
      >
        Log in
      </Button>
    </p>
  );
};
