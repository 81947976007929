import { getIconSrc } from "@components/Icon/helpers";

import { IconTypeEnum } from "./types";

export type IconImgProps = {
  /** Icon name */
  icon: IconTypeEnum;
  /** Size of the icon */
  iconSize: number;
  /** Alt text for the icon */
  imgAlt?: string;
};

/**
 * IconImg component is used to display an icon image.
 * @param {IconImgProps} props - The component props.
 * @param {IconTypeEnum} props.icon - The icon name.
 * @param {number} props.iconSize - The size of the icon.
 * @param {string} [props.imgAlt] - The alt text for the icon.
 * @returns {React.ReactElement} - The rendered component.
 */
export default function IconImg({
  icon,
  iconSize,
  imgAlt = "",
}: IconImgProps): React.ReactElement {
  return (
    <img
      src={getIconSrc(icon)}
      width={iconSize}
      height={iconSize}
      alt={imgAlt}
    />
  );
}
