/**
 * Company: SPHMedia
 * Description: LifestyleNews to display the news in a grid style
 */
import { Fragment, ReactElement } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import TopStoryCard from "@components/ArticleCard/TopStoryCard";

export type LifestyleNewsProps = TestIdClassNameProps & {
  data?: ArticleDataObject[];
  parentCategory: string;
  childCategory: string;
};

/**
 * LifestyleNews
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function LifestyleNews({
  testId = "lifestyle-news",
  data = [],
  parentCategory,
  childCategory,
}: LifestyleNewsProps): ReactElement {
  return (
    <div
      className="mb-12 grid grid-cols-1 gap-x-6 gap-y-[72px] lg:grid-cols-3"
      data-testid={testId}
    >
      {parentCategory === "lifestyle" &&
      childCategory === undefined &&
      data.length ? (
        <Ad
          adType="imu2"
          className="mb-5 flex h-auto flex-col items-center justify-center"
        />
      ) : null}

      {data?.map((article: ArticleDataObject, index: number) => {
        const {
          id,
          title,
          media,
          sections,
          paywall,
          slug,
          kicker,
          blurb,
          displaySetting,
          tags,
        } = article;
        const uniqueName = sections?.[0]?.uniqueName ?? "";

        return (
          <Fragment key={id}>
            {parentCategory === "lifestyle" &&
            childCategory === "bt-luxe" &&
            index == 2 ? (
              <Ad
                adType="imu2"
                className="bg-stripes mb-6 py-5 lg:sticky lg:top-[120px] lg:mb-0"
              />
            ) : null}

            {media || uniqueName ? (
              <TopStoryCard
                id={id}
                title={displaySetting?.displayHeadline || title}
                media={media}
                slug={slug}
                kicker={kicker?.fields?.[0]?.value}
                tag={tags?.[0]}
                paywall={paywall?.contentAccess === "1"}
                blurb={blurb}
                defaultImage={{
                  directoryName: uniqueName,
                }}
              />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}
