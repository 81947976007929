import { Link } from "react-router-dom";
import btTelegram from "@assets/img-bt-telegram.png";
import telegramIcon from "@assets/telegram-icon.png";

export default function Telegram(): React.ReactElement {
  return (
    <div className="border border-gray-850 p-2">
      <div className="relative bg-gray-250 px-3 py-6">
        <img
          src={telegramIcon}
          width={72}
          height={72}
          alt="Telegram Icon"
          className="mx-auto block"
        />

        <h3 className="mt-4 text-center font-lct text-5xl font-semibold text-gray-850">
          Follow BT on Telegram
        </h3>

        <p className="text-center font-poppins text-sm font-normal leading-none">
          For the latest Budget and business stories
        </p>

        <img
          src={btTelegram}
          width={173}
          height={195}
          alt="BT Telegram"
          className="mx-auto block"
        />

        <Link
          to={"https://telegram.me/biztimes"}
          target="_blank"
          className="absolute inset-0"
        />
      </div>
    </div>
  );
}
