import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import useCasScript from "@hooks/useCasScript";
import { CDP_HASH, CDP_URL } from "@util/constant";

import { CAS_BLOCK_TEST_ID } from "./utils/constants";
import { CASBlockProps } from "./utils/types";

export default function CASBlock({
  className,
  cdpId,
  index,
  fallback,
}: CASBlockProps): React.ReactElement {
  const { isScriptReady: isCasScriptReady } = useCasScript();
  const initId = index ? `${cdpId}_${index}` : `${cdpId}`;
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);

  useEffect(() => {
    if (!isCasScriptReady) return;
    if (typeof window === "undefined") return;
    if (typeof window._data === "undefined") return;
    if (typeof window._data.level2 === "undefined") return;
    if (typeof window.SPH_CDP !== "function") return;

    try {
      window.SPH_CDP().init(initId, CDP_URL, CDP_HASH, "bt_sg").run();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    document.addEventListener("CASCampaignLoaded", () => {
      setIsCampaignLoaded(true);
    });
  }, [isCasScriptReady, initId]);

  return (
    <div
      data-testid={CAS_BLOCK_TEST_ID}
      id={`sph_cdp_${initId}`}
      className={className}
    >
      <Transition show={!isCampaignLoaded} as="div">
        {fallback}
      </Transition>
    </div>
  );
}
