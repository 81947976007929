import LoginTrigger from "@pages/MyAccount/components/MyAccountLoginTrigger";
import { MyAccountEmptyStateMyBTConstants } from "@pages/MyAccount/utils/constants";

export default function MyAccountPopoverLoginMyBTLoginState(): React.ReactElement {
  return (
    <div
      data-testid="my-account-my-bt-login-state-component"
      className="w-[360px] p-6 text-center"
    >
      <img
        src={MyAccountEmptyStateMyBTConstants.src}
        alt="my account myBT"
        width={180}
        height={170}
        className="mx-auto mb-8 mt-10"
      />

      <div className="mb-4">
        <p className="mb-0 font-poppins font-bold text-gray-850">
          {MyAccountEmptyStateMyBTConstants.title}
        </p>
        <p className="font-xs mb-0 font-poppins text-gray-850">
          {MyAccountEmptyStateMyBTConstants.description}
        </p>
      </div>

      <LoginTrigger className="mx-auto rounded-[50px] border border-gray-175 px-10 py-4" />
    </div>
  );
}
