import { RouteFactory } from "@app/routePaths";
import budget2025Logo from "@assets/budget/logo-2025-v2.svg";

export const EXPLORE_MORE = [
  {
    title: "More on Budget 2025",
    link: RouteFactory.keywordsBudget2025,
    description: "Visit our Budget 2025 site for more stories and analyses.",
    buttonLabel: "Explore Now",
    thumbnail: budget2025Logo,
    tags: [
      {
        name: "Singapore Budget 2025",
        urlPath: RouteFactory.keywordsBudget2025,
      },
    ],
    trackingEvents: {
      event: "custom_event",
      eventCategory: "budget 2025",
      eventAction: "click",
      eventLabel: "more on budget 2025",
    },
  },
];
