import { AwardsMenu } from "@events-awards/utils/types";

export const eENavigationMenu: AwardsMenu[] = [
  {
    text: "ABOUT",
    link: "/events-awards/emerging-enterprise/about",
    enabled: true,
  },
  {
    text: "AWARDS",
    link: "/events-awards/emerging-enterprise/awards",
    enabled: true,
  },
  {
    text: "WINNERS",
    link: "/events-awards/emerging-enterprise/winners",
    enabled: true,
  },
  {
    text: "NEWS",
    link: "/events-awards/emerging-enterprise/news",
    enabled: true,
  },
  {
    text: "GALLERY",
    link: "/events-awards/emerging-enterprise/gallery",
    enabled: true,
  },
  {
    text: "HOW TO APPLY",
    link: "/events-awards/emerging-enterprise/how-to-apply",
    enabled: true,
  },
];

export const eEQuickLinksMenu: AwardsMenu[] = [
  {
    text: "CONTACT US",
    link: "/events-awards/emerging-enterprise/contact-us",
    enabled: true,
  },
  {
    text: "FAQ",
    link: "/events-awards/emerging-enterprise/faq",
    enabled: true,
  },
];
