import iconCheckWhite from "@assets/icon-check-white.svg";

export default function DefaultSuccessMessage(): React.ReactElement {
  return (
    <div data-testid="default-success-message-component">
      <img
        src={iconCheckWhite}
        width={24}
        height={24}
        className="mr-2 inline-block aspect-square rounded-full bg-gray-850 p-1"
      />

      <span className="inline-block font-poppins text-xs font-medium leading-7 text-gray-850">
        {"Thank you! You're now signed up."}
      </span>
    </div>
  );
}
