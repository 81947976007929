import { useState } from "react";
import Button from "@components/AccessibleComponents/Button";
import Accordion from "@components/Accordion/Accordion";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mySPHOpenSignUp } from "@util/helpers";

export default function Section5(): React.ReactElement {
  const [activePanel, setActivePanel] = useState(0);
  const buttonClass =
    "flex items-center w-full px-6 py-4 text-left font-poppins text-4xl font-semibold outline-none";

  const handleClick = (panelNumber: number) => {
    setActivePanel((current) => (current === panelNumber ? 0 : panelNumber));
  };

  return (
    <div className="mb-36 px-3 py-18">
      <h2 className="mb-14 text-center font-poppins text-10xl font-semibold lg:text-14xl">
        FAQs
      </h2>

      <div className="mx-auto w-[800px] max-w-full">
        <div className="border-b border-gray-175">
          <Button
            onPress={() => {
              handleClick(1);
            }}
            className={buttonClass}
          >
            What is myBT?
            <FontAwesomeIcon
              icon={faChevronRight}
              rotation={activePanel === 1 ? 90 : undefined}
              className="ml-auto transition-transform"
            />
          </Button>

          <Accordion
            rootChildrenClassName="!bg-white"
            isExpanded={activePanel === 1}
            renderHeader={() => <></>}
            rootClassName="font-public-sans text-lg"
          >
            <p className="mb-6">
              myBT is a feature exclusive to registered users and subscribers of
              The Business Times (BT).
            </p>
            <p className="mb-0">
              They can customise their daily reading experience with keywords
              and get curated content through their personalised myBT pages on
              the BT website and app.
            </p>
          </Accordion>
        </div>

        <div className="border-b border-gray-175">
          <Button
            onPress={() => {
              handleClick(2);
            }}
            className={buttonClass}
          >
            Is myBT available to everyone?
            <FontAwesomeIcon
              icon={faChevronRight}
              rotation={activePanel === 2 ? 90 : undefined}
              className="ml-auto transition-transform"
            />
          </Button>

          <Accordion
            rootChildrenClassName="!bg-white"
            isExpanded={activePanel === 2}
            renderHeader={() => <></>}
            rootClassName="font-public-sans text-lg"
          >
            <p>
              {
                "myBT is a feature exclusive to BT's registered users and subscribers. "
              }
              <Button
                className="mr-2 text-verticals-btblue"
                onPress={mySPHOpenSignUp}
              >
                Create a mySPH account
              </Button>
              {
                "to become a registered user. Once you've registered, you can log in to BT's website or app to access the content and follow keywords."
              }
            </p>

            <p>
              You can also use social logins. If you have a Google or Facebook
              account, you can use myBT after signing in with your own
              credentials. We will create a mySPH account for you automatically.
            </p>

            <p>
              {
                "To access myBT on the BT app, go to the Google Play Store or Apple's App Store to download the latest version of the app."
              }
            </p>
          </Accordion>
        </div>

        <div className="border-b border-gray-175">
          <Button
            onPress={() => {
              handleClick(3);
            }}
            className={buttonClass}
          >
            How many keywords can I follow?
            <FontAwesomeIcon
              icon={faChevronRight}
              rotation={activePanel === 3 ? 90 : undefined}
              className="ml-auto transition-transform"
            />
          </Button>

          <Accordion
            rootChildrenClassName="!bg-white"
            isExpanded={activePanel === 3}
            renderHeader={() => <></>}
            rootClassName="font-public-sans text-lg"
          >
            <p>Registered users can select up to three keywords to follow.</p>

            <p>
              BT subscribers can select up to 30 keywords, for a more curated
              reading experience.
            </p>
          </Accordion>
        </div>

        <div>
          <Button
            onPress={() => {
              handleClick(4);
            }}
            className={buttonClass}
          >
            How much does myBT cost?
            <FontAwesomeIcon
              icon={faChevronRight}
              rotation={activePanel === 4 ? 90 : undefined}
              className="ml-auto transition-transform"
            />
          </Button>

          <Accordion
            rootChildrenClassName="!bg-white"
            isExpanded={activePanel === 4}
            renderHeader={() => <></>}
            rootClassName="font-public-sans text-lg"
          >
            <p>
              {
                "The feature is free for registered users. However, registered users who haven't bought a BT subscription will have limited access to BT content and may only follow three keywords."
              }
            </p>

            <p>
              Buying a BT subscription unlocks full access to BT content and the
              ability to follow up to 30 keywords, for a more customised
              experience.
            </p>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
