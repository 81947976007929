import { useEffect, useState } from "react";
import { dayjsSingaporeTimezone } from "@util/constant";
import dayjs from "dayjs";

/** Custom hook to check if the published date is more than 180 days ago. */
const usePublishedTime = (publishedDate: string) => {
  const [isMoreThan180DaysAgo, setIs180DaysAgo] = useState(false);

  useEffect(() => {
    const now = dayjs().tz(dayjsSingaporeTimezone);
    const pub = dayjs(publishedDate).tz(dayjsSingaporeTimezone);
    const diff = now.diff(pub, "days");

    setIs180DaysAgo(diff > 180);
  }, [publishedDate]);

  return { isMoreThan180DaysAgo };
};

export default usePublishedTime;
