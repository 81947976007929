import { Dispatch, SetStateAction } from "react";
import { useWindowSize } from "@hooks/useWindowSize";
import type { Thriver } from "@pages/Thrive/AboutUs/components/types";
import { cn } from "@util/helpers";

type ThriveAboutUsProps = {
  thrivers: Thriver;
  isFlipped?: boolean;
  handleOnClick: Dispatch<SetStateAction<string>>;
};

export default function ThriveAboutUsIndividualCard({
  thrivers,
  handleOnClick,
  isFlipped,
}: ThriveAboutUsProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  return (
    <div
      className="group mx-auto h-auto min-h-[450px] w-full max-w-[400px] cursor-pointer bg-transparent perspective"
      data-testid="thrive-about-us-individual-card-component"
    >
      <div
        className={cn(
          "relative mx-auto h-full w-full duration-500 preserve-3d group-hover:lg:rotate-y-180",
          { "rotate-y-180": isFlipped }
        )}
        onClick={
          !isScreenLG
            ? () => {
                handleOnClick(thrivers.name);
              }
            : undefined
        }
      >
        <div className="relative h-full w-full border border-black bg-white shadow-thriveButton transition-all duration-150 backface-hidden">
          <img
            src={thrivers.image}
            alt={thrivers.name}
            width={400}
            height={455}
          />

          <div className="relative min-h-[110px]">
            <div className="absolute bottom-2 left-4 font-interSemibold text-orange-400 ">
              <p className="mb-3 w-fit bg-white p-1 text-10xl leading-none">
                {thrivers.name.split(" ")[0]}
                <br />
                {thrivers.name.split(" ")[1]}
              </p>

              <p className="px-1 pb-2">{thrivers.frontText}</p>
            </div>
          </div>

          <div className="absolute -bottom-2 -right-1 h-[50px] w-[50px] border-[25px] border-b-verticals-thrive border-l-orange-400 border-r-verticals-thrive border-t-orange-400"></div>
        </div>

        <div
          className={cn(
            "absolute top-0 h-full w-full overflow-hidden border border-black bg-orange-400 p-4 shadow-thriveButton transition-all rotate-y-180 backface-hidden "
          )}
        >
          <div className="h-auto">
            {thrivers.backText.map((backText) => {
              return (
                <div
                  className="mb-3 text-white lg:text-xs"
                  key={backText.bottomText}
                >
                  <p className="mb-0 font-interBold lg:text-xs">
                    {backText.topText}
                  </p>

                  <p className="lg:text-xs">{backText.bottomText}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
