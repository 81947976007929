import { SanitizedSectionArticle } from "@app/types/Cue";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import { AwardType } from "@events-awards/utils/types";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import EventsAwardsSBAQuickLinks from "@sba/components/EventsAwardsSBAQuickLinks";

type EventsAwardsSBAStaticPagesProps = {
  content: SanitizedSectionArticle;
};
export default function EventsAwardsSBAStaticPages({
  content,
}: EventsAwardsSBAStaticPagesProps): React.ReactElement {
  const { id, elements, storyLine } = content;
  return (
    <Container data-testid="events-awards-sia-static-pages-component">
      <Row rootClassName="justify-center">
        <Column rootClassName="w-full lg:w-8/12 text-center my-8">
          <EventsAwardsLogoMenu awardType={AwardType.SBA} />

          <ArticleParagraphs
            elements={elements}
            id={id}
            storyline={storyLine || []}
            isPremium={false}
            className="mb-16 pb-15 text-left"
            isBoxWrapped
            accordionHeaderClass="border-none"
          />

          <EventsAwardsSBAQuickLinks />
        </Column>
      </Row>
    </Container>
  );
}
