import { Link } from "react-router-dom";
import iconE50Info from "@assets/events-awards/e50/icon-info.svg";
import logoKPMG from "@assets/events-awards/e50/logo-kpmg.png";
import logoSGXGroup from "@assets/events-awards/e50/logo-sgx.png";
import logoSingaporeBusinessFederation from "@assets/events-awards/e50/logo-singapore-business-federation.png";
import logoE50 from "@assets/events-awards/logo-e50.png";
import logoEnterpriseSingapore from "@assets/events-awards/logo-enterprise-singapore.png";
import logoOCBC from "@assets/events-awards/logo-ocbc.png";
import logoBT from "@assets/logo-masthead.svg";
import { Block } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import BasicCard from "@components/ArticleCard/BasicCard";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { e50NavigationMenu, e50QuickLinksMenu } from "@e50/utils/constants";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import {
  EVENTS_AWARDS_E50_LABEL,
  EVENTS_AWARDS_E50_PATH,
  EVENTS_AWARDS_SLOT,
} from "@events-awards/utils/constants";
import { EventsAwardsLandingPageContextData } from "@events-awards/utils/types";
import { sectionNavigationItems } from "@util/constant";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsE50PageContent({
  title,
  article,
}: EventsAwardsLandingPageContextData): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();

  return (
    <div
      className="w-full"
      data-testid="events-awards-e50-page-content-component"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={EVENTS_AWARDS_E50_PATH}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title="Enterprise_50_Index"
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${EVENTS_AWARDS_E50_LABEL} - Find ${EVENTS_AWARDS_E50_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_E50_PATH}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <Link to={EVENTS_AWARDS_E50_PATH}>
              <img
                src={logoE50}
                alt="Enterprise 50 logo"
                width={120}
                height={162}
                className="mx-auto"
              />
            </Link>

            <EventsAwardsNavigationMenu
              menuData={e50NavigationMenu}
              linkClassName="after:bg-eventAwards-e50-accent"
            />

            <Block rootClassName="p-6 text-center border border-eventAwards-sca-accent">
              <img
                src={iconE50Info}
                alt="Info icon"
                width={16}
                height={16}
                className="mr-4 inline-block"
              />

              <span className="align-middle font-public-sans text-base">
                NOMINATIONS FOR 2024 ARE CLOSED
              </span>
            </Block>

            <>
              {article ? (
                <BasicCard
                  title={article.title}
                  id={article.id}
                  media={article.media}
                  slug={article.urlPath}
                  rootClassName="mt-6 md:-mx-3 mx-0 justify-center"
                  variations={{
                    title: {
                      color: "text-gray-850",
                      size: "text-4xl",
                      extraClass: "font-public-sans font-semibold",
                    },
                    content: {
                      width: "w-full",
                      extraClass: "px-9 md:px-6 lg:px-14 text-left",
                    },
                  }}
                />
              ) : null}
            </>

            <Row rootClassName="flex py-9 px-6">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold lg:mb-0 lg:text-right">
                  {"CELEBRATING "}
                  <br className="hidden lg:inline" />
                  {"LEGACIES AND "}
                  <br className="hidden lg:inline" />
                  {"INSPIRING THE FUTURE"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <p>
                  {
                    "Commemorating the 30th anniversary of the Enterprise 50 Awards, we celebrate champions of change and ignite the future of Singapore's private enterprise community. This momentous occasion will set the stage for a future of unparalleled excellence. Prepare to be recognised and celebrated for your contributions. Join us and champion the legacy of enterprise in Singapore!"
                  }
                </p>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"JOINTLY ORGANISED "}
                  <br className="hidden lg:inline" />
                  {"BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="py-auto mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoBT}
                    alt="The Business Times logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>

                <div className="py-auto flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoKPMG}
                    alt="KPMG logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"SPONSORED BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="py-auto flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoOCBC}
                    alt="OCBC logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"SUPPORTED BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoEnterpriseSingapore}
                    alt="Standard Chartered logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
                <div className="py-auto mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoSingaporeBusinessFederation}
                    alt="ACRA logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>

                <div className="mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoSGXGroup}
                    alt="SGX logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Block>
              <h2 className="mb-6 border-b border-gray-175 pb-3 text-left font-public-sans text-base font-bold">
                {"ENTERPRISE 50 ©"}
              </h2>

              <div className="flex">
                <h3 className="mr-auto text-left font-public-sans text-lg font-normal">
                  Quicklinks
                </h3>

                <EventsAwardsNavigationMenu
                  menuData={e50QuickLinksMenu}
                  className="m-0 border-0 p-0"
                  linkClassName="after:bg-eventAwards-e50-accent text-base font-public-sans font-light"
                />
              </div>
            </Block>
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
