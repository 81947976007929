import { BRIGHTCOVE_PLAYER_API } from "@components/Brightcove/utils/constants";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import useBrightcovePlayerId from "@hooks/useBrightcovePlayerId";

export default function BrightcoveVideoPlaylistFeatured({
  id,
  account_id,
  name,
  description,
}: BrightcoveVideo): React.ReactElement {
  const playerId = useBrightcovePlayerId();

  return (
    <div>
      {playerId ? (
        <div className="relative mb-4">
          <iframe
            src={`${BRIGHTCOVE_PLAYER_API}${account_id}/${playerId}_default/index.html?videoId=${id}`}
            allow="autoplay;encrypted-media;fullscreen"
            allowFullScreen
            className="aspect-video w-full"
            loading="lazy"
            title={name}
          ></iframe>
        </div>
      ) : null}

      <div>
        <h2 className="font-lct text-base font-bold text-gray-850">{name}</h2>
        <p className="mt-2 font-public-sans text-xs text-gray-850">
          {description}
        </p>
      </div>
    </div>
  );
}
