import { RouteFactory } from "@app/routePaths";
import defaultMastheadImage from "@assets/logo-masthead.svg";
import thriveLogo from "@assets/thrive-logo.svg";
import { LogoMastheadProps } from "@components/Header/LogoMasthead/LogoMasthead";

/**
 * Gets the appropriate masthead logo configuration based on the current path
 * @param path - The current URL path
 * @returns LogoMastheadProps object containing the logo image source, dimensions and title
 */
export const getMastheadLogo = (path: string): LogoMastheadProps => {
  if (path.startsWith(RouteFactory.thrive)) {
    return {
      src: thriveLogo,
      imgWidth: 116,
      imgHeight: 28,
      title: "Thrive",
      link: RouteFactory.thrive,
    };
  }

  return {
    src: defaultMastheadImage,
    imgWidth: 220,
    imgHeight: 19,
    title: "The Business Times",
    link: RouteFactory.home,
  };
};
