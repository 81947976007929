import { RouteFactory } from "@app/routePaths";
import internationalGlobalLogo from "@assets/verticals/international-global-logo-blue.svg";
import SCLogo from "@assets/verticals/standard-chartered-logo.svg";
import SectionMasthead from "@components/SectionMasthead/SectionMasthead";

export default function GlobalEnterpriseBanner(): React.ReactElement {
  return (
    <div className="my-6 flex w-full flex-wrap items-center justify-center gap-2 border-b border-gray-850 pb-2 md:justify-between">
      <SectionMasthead
        link={RouteFactory.globalEnterprise}
        masthead={{
          alt: "Global Enterprise logo",
          src: internationalGlobalLogo,
          width: 150,
          height: 50,
        }}
      />
      <div className="block h-[0.0625rem] w-full bg-gray-850 md:hidden "></div>
      <div className="flex items-center justify-end gap-x-4">
        <span className="font-poppins text-4xs font-light">
          BROUGHT TO YOU BY
        </span>
        <img src={SCLogo} alt="UOB logo" className="w-[5rem]" width="90" />
      </div>
    </div>
  );
}
