import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import ContentLayoutFactory from "@pages/Content/ContentLayoutFactory";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { DefaultNavigationContext } from "@typings/Page";
import { HttpStatusCode } from "axios";

export default function ContentPage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<DefaultNavigationContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {(context: DefaultNavigationContext) => {
        const { kind, data } = context;

        // Load article page content.
        if (kind === "none") {
          return <ContentLayoutFactory data={data} />;
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
