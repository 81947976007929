import { BreakingNewsGroup } from "@app/types/BreakingNews";
import { ArticleDataObject } from "@app/types/Cue";
import dayjs from "dayjs";

export function getGroupTitle(group: BreakingNewsGroup) {
  switch (group) {
    case "today":
    case "yesterday":
      return group;
    case "thedaybefore":
      return dayjs().subtract(2, "day").format("MMM DD");
  }
}

export function getFilteredArticles(
  articles: ArticleDataObject[],
  filter: string
) {
  if (!filter) return articles;

  return articles?.filter((article) => {
    const sections = article.sections?.map((section) => section.uniqueName);
    return sections?.includes(filter.replaceAll("/", "_"));
  });
}

/**
 * Helper function to determine which date is later
 * @param dateA The first date to compare.
 * @param dateB The second date to compare.
 * @returns {string}
 */
export const getLaterTime = (
  dateA: string,
  dateB: string
): string | undefined => {
  if (!dayjs(dateA).isValid()) return dateB;
  if (!dayjs(dateB).isValid()) return dateA;

  if (dayjs(dateA).isAfter(dayjs(dateB))) return dateA;

  return dateB;
};
