import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";
import { SanitizedSectionArticle } from "@typings/Cue";

export type UseHandleMoreStoriesProps = {
  data: SanitizedSectionArticle[];
  uniqueCategoryQuery: string;
  parentCategory: string;
  pageSize: number;
};

export const useHandleMoreStories = ({
  data,
  uniqueCategoryQuery,
  parentCategory,
  pageSize,
}: UseHandleMoreStoriesProps) => {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [loadMoreData, setLoadMoreData] = useState<SanitizedSectionArticle[]>(
    []
  );
  const [clickableHandleLoadMore, setClickableHandleLoadMore] =
    useState<boolean>(true);

  const handleLoadMore = async (
    data: SanitizedSectionArticle[],
    uniqueCategoryQuery: string,
    parentCategory: string
  ) => {
    setClickableHandleLoadMore(false);
    const page = [...data, ...loadMoreData].length / pageSize + 1;

    const moreArticles = await fetchMoreStoriesData(
      uniqueCategoryQuery,
      parentCategory,
      page
    );

    setLoadMoreData([...loadMoreData, ...moreArticles]);

    if (moreArticles.length < pageSize) {
      setHasMoreStories(false);
    }

    setClickableHandleLoadMore(true);
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (!inView) return;
      if (!hasMoreStories) return;

      handleLoadMore(data, uniqueCategoryQuery, parentCategory);
    },
  });

  return {
    ref,
    handleLoadMore,
    hasMoreStories,
    loadMoreData,
    clickableHandleLoadMore,
  };
};
