import { Await } from "react-router-dom";
import { KeywordContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import KeywordPageContent from "./templates/KeywordPageContent";
import WithHighlights from "./templates/WithHighlights";

export default function KeywordPage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<KeywordContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: KeywordContext) => {
        const { template } = data;

        // Checker to make sure kind is from Keyword.
        if (kind !== "keyword") {
          return <CustomError statusCode={HttpStatusCode.NotFound} />;
        }

        // Checker to which template to load.
        if (template === "withHighlight") {
          return <WithHighlights data={data} />;
        }

        // Default template.
        return <KeywordPageContent data={data} />;
      }}
    </Await>
  );
}
