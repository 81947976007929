import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import iconPlus from "@assets/icons/icon-plus.svg";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import { MyAccountMyBTAccordionProps } from "@pages/MyAccount/utils/types";
import { cn, mySPHOpenLogin } from "@util/helpers";

export default function MyAccountMyBtPageEmptyState(): React.ReactElement {
  const handleOnSetSessionMyBT = () => {
    sessionStorage.setItem("from-account-page", "true");
  };

  return (
    <div data-testid="my-account-my-bt-page-empty-state-component">
      <div className="relative mb-15 h-auto w-full bg-gray-850 bg-[url('/src/app/assets/my-account/my-account-empty-state-banner.jpg')] bg-cover bg-[80%_100%] lg:bg-[70%_100%]">
        <div className="p-8">
          <p className="mb-3 max-w-[200px] font-poppins text-xl font-semibold leading-tight text-white md:max-w-[290px] md:text-7xl">
            Follow keywords. Own your news.
          </p>

          <Link
            to={RouteFactory.myBTOnboard}
            reloadDocument
            className="inline-flex w-max items-center rounded-[50px] bg-white px-6 py-2 text-center text-xs"
            onClick={handleOnSetSessionMyBT}
            aria-label="myBTOnboard"
          >
            <img src={iconPlus} width={24} height={24} alt="add keyword" />
            <p className="leading-0 font-poppins text-xs font-bold tracking-2%">
              Add keyword
            </p>
          </Link>
        </div>
      </div>

      <MyAccountMyBTPageEmptyStateAccordion title="What is myBT?">
        <div className="font-xs pb-5 font-public-sans text-gray-850">
          <p>
            myBT is a feature exclusive to registered users and subscribers of
            The Business Times (BT).
          </p>

          <p>
            They can customise their daily reading experience with keywords and
            get curated content through their personalised myBT pages on the BT
            website and app.
          </p>
        </div>
      </MyAccountMyBTPageEmptyStateAccordion>

      <MyAccountMyBTPageEmptyStateAccordion
        className="pt-6"
        title="Is myBT available to everyone?"
      >
        <div className="font-xs pb-5 font-public-sans text-gray-850">
          <p>
            {
              "myBT is a feature exclusive to BT's registered users and subscribers."
            }
            <button
              className="mr-2 text-verticals-btblue"
              onClick={() => {
                mySPHOpenLogin();
              }}
            >
              Create a mySPH account
            </button>
            {
              "to become a registered user. Once you've registered, you can log in to BT's website or app to access the content and follow keywords."
            }
          </p>

          <p>
            You can also use social logins. If you have a Google or Facebook
            account, you can use myBT after signing in with your own
            credentials. We will create a mySPH account for you automatically.
          </p>

          <p>
            {
              "To access myBT on the BT app, go to the Google Play Store or Apple's App Store to download the latest version of the app."
            }
          </p>
        </div>
      </MyAccountMyBTPageEmptyStateAccordion>

      <MyAccountMyBTPageEmptyStateAccordion
        className="pt-6"
        title="How many keywords can I follow?"
      >
        <div className="font-xs pb-5 font-public-sans text-gray-850">
          <p>Registered users can select up to three keywords to follow.</p>

          <p>
            BT subscribers can select up to 30 keywords, for a more curated
            reading experience.
          </p>
        </div>
      </MyAccountMyBTPageEmptyStateAccordion>

      <MyAccountMyBTPageEmptyStateAccordion
        className="pt-6"
        title="How much does myBT cost?"
      >
        <div className="font-xs pb-5 font-public-sans text-gray-850">
          <p>
            {
              "The feature is free for registered users. However, registered users who haven't bought a BT subscription will have limited access to BT content and may only follow three keywords."
            }
          </p>

          <p>
            Buying a BT subscription unlocks full access to BT content and the
            ability to follow up to 30 keywords, for a more customised
            experience.
          </p>
        </div>
      </MyAccountMyBTPageEmptyStateAccordion>
    </div>
  );
}

export function MyAccountMyBTPageEmptyStateAccordion({
  children,
  title,
  className,
}: MyAccountMyBTAccordionProps): React.ReactElement {
  return (
    <div
      className="border-b border-gray-175"
      data-testid="my-account-my-bt-accordion"
    >
      <Disclosure as={"div"}>
        {({ open }) => (
          <>
            <DisclosureButton
              className={cn(
                "flex w-full items-center justify-between pb-5",
                className
              )}
            >
              <p className="mb-0 font-poppins text-xs font-semibold tracking-2%">
                {title}
              </p>

              <div className="flex h-[42px] w-[42px] items-center justify-center rounded-full border border-gray-175 lg:border-none">
                <svg
                  className={cn(
                    "w-[15px] text-gray-850 transition-all duration-300 lg:text-gray-650",
                    {
                      "rotate-180": open,
                    }
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 12 8"
                  fill="none"
                >
                  <path
                    d="M1 1.33398C5 5.33398 6 6.33398 6 6.33398L11 1.33398"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </DisclosureButton>

            <Transition
              as={"div"}
              show={open}
              className="overflow-hidden transition-all duration-500"
              enterFrom="opacity-0 max-h-0"
              enterTo="opacity-100 max-h-96"
              leaveFrom="opacity-100 max-h-96"
              leaveTo="opacity-0 max-h-0"
            >
              <DisclosurePanel as={"div"}>{children}</DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
