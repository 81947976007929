import type { Element, SanitizedSectionArticle } from "@app/types/Cue";
import { getEnvStoryQueueId } from "@caas/getEnvStoryQueueId";
import EventsAwardsDTALogoMenu from "@dta/components/EventsAwardsDTALogoMenu";
import EventsAwardsE50LogoMenu from "@e50/components/EventsAwardsE50LogoMenu";
import EventsAwardsE50QuickLinks from "@e50/components/EventsAwardsE50QuickLinks";
import EventsAwardsEELogoMenu from "@ee/components/EventsAwardsEELogoMenu";
import EventsAwardsEEQuickLinks from "@ee/components/EventsAwardsEEQuickLinks";
import { AwardType, Winner } from "@events-awards/utils/types";
import { AWARDS_EVENTS_PAGE_ROW_DATA } from "@pages/Section/layouts/EventsAwardsPage/utils/constants";
import EventsAwardsSBALogoMenu from "@sba/components/EventsAwardsSBALogoMenu";
import EventsAwardsSBAQuickLinks from "@sba/components/EventsAwardsSBAQuickLinks";
import EventsAwardsSCALogoMenu from "@sca/components/EventsAwardsSCALogoMenu";
import EventsAwardsSCAQuickLinks from "@sca/components/EventsAwardsSCAQuickLinks";
import EventsAwardsSIALogoMenu from "@sia/components/EventsAwardsSIALogoMenu";
import EventsAwardsSIAQuickLinks from "@sia/components/EventsAwardsSIAQuickLinks";
import { ENVIRONMENT } from "@util/constant";

export const getAwardType = (
  sectionUniqueName: string
): AwardType | undefined => {
  switch (sectionUniqueName) {
    case "events-awards_enterprise-50":
      return AwardType.E50;
    case "events-awards_emerging-enterprise":
      return AwardType.EE;
    case "events-awards_singapore-business-awards":
      return AwardType.SBA;
    case "events-awards_singapore-corporate-awards":
      return AwardType.SCA;
    case "events-awards_sustainability-impact-awards":
      return AwardType.SIA;
    case "events-awards_design-ai-and-tech-awards":
      return AwardType.DATA;
  }
};

export const EventsAwardsLayoutFactory = (awardType: AwardType) => {
  switch (awardType) {
    case AwardType.SBA:
      return EventsAwardsSBALogoMenu;
    case AwardType.SCA:
      return EventsAwardsSCALogoMenu;
    case AwardType.SIA:
      return EventsAwardsSIALogoMenu;
    case AwardType.E50:
      return EventsAwardsE50LogoMenu;
    case AwardType.EE:
      return EventsAwardsEELogoMenu;
    case AwardType.DATA:
      return EventsAwardsDTALogoMenu;
  }
};

export const EventsAwardsQuickLinksFactory = (awardType: AwardType) => {
  switch (awardType) {
    case AwardType.SBA:
      return EventsAwardsSBAQuickLinks;
    case AwardType.SCA:
      return EventsAwardsSCAQuickLinks;
    case AwardType.SIA:
      return EventsAwardsSIAQuickLinks;
    case AwardType.E50:
      return EventsAwardsE50QuickLinks;
    case AwardType.EE:
      return EventsAwardsEEQuickLinks;
  }
};

/**
 * Helper function to change all dash to
 * space and capitalize every word
 * @param text
 * @returns toEventsAwardsMicrositeTitleCase
 */
export function toEventsAwardsMicrositeTitleCase(text: string) {
  return text
    .replace("-", " ") // Replace hyphen with space
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
}

export const eventsAwardsMainPageStoryQueueId = (
  awardType: AwardType
): string => {
  switch (awardType) {
    case AwardType.SBA:
      return getEnvStoryQueueId("1295782", "1295782", "1295782", ENVIRONMENT);
    case AwardType.SCA:
      return getEnvStoryQueueId("1295790", "1295790", "1295790", ENVIRONMENT);
    case AwardType.SIA:
      return getEnvStoryQueueId("1295781", "1295781", "1295781", ENVIRONMENT);
    case AwardType.E50:
      return getEnvStoryQueueId("1295479", "1295479", "1295479", ENVIRONMENT);
    case AwardType.EE:
      return getEnvStoryQueueId("1295783", "1295783", "1295783", ENVIRONMENT);
    case AwardType.DATA:
      return "";
  }
};

// Utility: Get Award Data
export const getAwardData = (awardType: AwardType) => {
  return AWARDS_EVENTS_PAGE_ROW_DATA.find(
    (data) => data.awardType === awardType
  );
};

export const processArticleElements = (elements: Element[]): Winner[] => {
  const awards: Winner[] = [];
  let currentAward: Winner = {};

  elements.forEach((element) => {
    if (element.type === "paragraph") {
      const value = element.fields[0]?.value;
      const isBold = element.fields[0]?.annotations?.some(
        (a) => a.name === "bold" && a.value === "true"
      );

      if (isBold) {
        // If we have a previous award, push it to awards array
        if (currentAward.title) {
          awards.push({ ...currentAward });
        }
        // Start a new award
        currentAward = { title: value, type: "paragraph" };
      } else {
        // Add details to current award
        if (!currentAward.recipient) {
          currentAward.recipient = value;
        } else if (!currentAward.position) {
          currentAward.position = value;
        } else if (!currentAward.organization) {
          currentAward.organization = value;
        }
      }
    }
    if (element.type === "table") {
      currentAward.recipient = element.fields[0]?.value;
      currentAward.type = "table";
    }
  });

  // Push the last award
  if (currentAward.title) {
    awards.push(currentAward);
  }

  return awards;
};

export type Image = Element & {
  type: "image";
  relation: {
    fields: {
      "landscape-caas": {
        url: string;
        width: number;
        height: number;
      };
    };
  };
};

export const isImageElement = (element: Element): element is Image => {
  return (
    element.type === "image" &&
    element.relation?.fields?.["landscape-caas"]?.url !== undefined
  );
};

export const getAllImages = (
  articles: SanitizedSectionArticle[] = []
): Image[] => {
  return (
    articles?.flatMap(
      (article) => article.elements?.filter(isImageElement) ?? []
    ) ?? []
  );
};

/**
 * Maps an award slug to its corresponding AwardType enum value.
 * This function is used to convert URL friendly award names to their internal enum representation.
 *
 * @param awards - The URL friendly award name (e.g., 'enterprise-50', 'singapore-business-awards')
 * @returns The corresponding AwardType enum value, or null if no matching award type is found
 *
 * @example
 * mapAwardType('enterprise-50') // returns AwardType.E50
 * mapAwardType('unknown-award') // returns null
 */
export const mapAwardType = (awards: string): AwardType | null => {
  const awardTypeMap: Record<string, AwardType> = {
    "singapore-corporate-awards": AwardType.SCA,
    "enterprise-50": AwardType.E50,
    "emerging-enterprise": AwardType.EE,
    "singapore-business-awards": AwardType.SBA,
    "sustainability-impact-awards": AwardType.SIA,
    "design-ai-and-tech-awards": AwardType.DATA,
  };
  return awardTypeMap[awards] || null;
};
