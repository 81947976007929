import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { textToSlug } from "@util/helpers";
import dayjs from "dayjs";

export type MoreVideosCardProps = {
  video: BrightcoveVideo;
  index: number;
};

export default function MoreVideosCard({
  video,
  index,
}: MoreVideosCardProps): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });
  const { id, name, images, duration } = video;

  const thumbnail = {
    content: {
      type: "picture",
      url: images.thumbnail.src,
    },
  };
  const variant: CardVariations = {
    image: {
      isVideo: true,
      position: "left",
      width: "w-120px lg:w-1/4",
      aspectRatio: "aspect-ratio-16/9",
    },
    content: {
      width: "w-full-120px lg:w-3/4",
      extraClass: "pl-4",
    },
    title: {
      color: "text-gray-850",
      size: "text-base",
      extraClass: "font-lct",
    },
  };

  return (
    <div ref={ref}>
      <BasicCard
        key={id}
        id={id}
        slug={`/videos/${textToSlug(name)}/${id}`}
        title={name}
        media={thumbnail}
        variations={variant}
        kicker={dayjs.duration(duration).format("mm:ss")}
      />
    </div>
  );
}
