import { useEffect, useState } from "react";
import {
  BrightcovePlaylistResponse,
  BrightcoveVideo,
} from "@components/Brightcove/utils/types";
import * as Sentry from "@sentry/react";
import axios from "axios";

const fetchVideoPlaylist = async (): Promise<BrightcoveVideo[]> => {
  const response = await axios.post<BrightcovePlaylistResponse>(
    "/_plat/api/v1/budget-2025-videos"
  );

  return response.data || [];
};

export default function useBudget2025Videos(): BrightcoveVideo[] {
  const [data, setData] = useState<BrightcoveVideo[]>([]);

  useEffect(() => {
    const callFetch = async () => {
      setData(await fetchVideoPlaylist());
    };

    try {
      callFetch();
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return data;
}
