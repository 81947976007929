import { MyAccountEmptyStateReadConstants } from "@pages/MyAccount/utils/constants";

export default function MyAccountRecentReadPopoverEmptyState(): React.ReactElement {
  return (
    <div
      data-testid="my-account-recent-read-popover-empty-state-component"
      className="text-center"
    >
      <img
        src={MyAccountEmptyStateReadConstants.src}
        alt="my account myBT"
        width={180}
        height={170}
        className="mx-auto mb-8 mt-10"
      />

      <div className="mb-4">
        <p className="font-xs mb-0 font-public-sans text-gray-850">
          {MyAccountEmptyStateReadConstants.afterLoginDescription}
        </p>
      </div>
    </div>
  );
}
