import { HTMLAttributes } from "react";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { SanitizedSectionArticle } from "@typings/Cue";

export type InfographicsProps = {
  testId?: string;
  article: SanitizedSectionArticle;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function Infographics({
  testId,
  className,
  article,
}: InfographicsProps): React.ReactElement {
  const link = article?.displaySetting.externalURL;

  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-3/10",
      filters: [
        {
          breakpoint: "(min-width: 1200px)",
          w: 228,
          h: 152,
          dpr: 1,
          f: "webp",
        },
        {
          breakpoint: "(min-width: 992px)",
          w: 186,
          h: 124,
          dpr: 1,
          f: "webp",
        },
        { w: 420, h: 280, dpr: 1, f: "webp" },
      ],
      loading: "eager",
    },
    content: {
      width: "w-7/10",
      extraClass: "pl-4",
    },
    kicker: {
      color: "text-gray-515",
    },
    title: {
      size: "text-lg",
      color: "text-gray-850",
    },
  };

  if (!link) return <></>;

  return (
    <div className={className} data-testid={testId}>
      <BlockTitle text="INFOGRAPHICS" rootClassName="mb-4" />

      <div className="group relative flex gap-4">
        <BasicCard
          id={article.id}
          title={article.displaySetting?.displayHeadline || article.title}
          media={article.media}
          slug={link}
          rootClassName="items-stretch"
          variations={variant}
        />
      </div>
    </div>
  );
}
