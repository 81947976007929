import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

/**
 * Custom hook to scroll an element to center within a container
 */
export default function useScrollToCenter<
  TContainer extends HTMLElement = HTMLElement,
  TTarget extends HTMLElement = HTMLElement,
>(delay: number = 100) {
  const containerRef = useRef<TContainer | null>(null);
  const targetRef = useRef<TTarget | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const containerElement = containerRef.current;
    const targetElement = targetRef.current;

    if (!containerElement || !targetElement) return;

    const timer = setTimeout(() => {
      const containerRect = containerElement.getBoundingClientRect();
      const targetRect = targetElement.getBoundingClientRect();

      const scrollLeft =
        targetRect.left +
        containerElement.scrollLeft -
        containerRect.left -
        (containerRect.width - targetRect.width) / 2;

      containerElement.scrollTo({
        left: scrollLeft,
        behavior: "auto",
      });
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [pathname, delay]);

  return { containerRef, targetRef };
}
