import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import EventsAwardsPageContent from "@events-awards/EventsAwardsPageContent";
import {
  EventsAwardsPageContext,
  EventsAwardsPageContextData,
} from "@events-awards/utils/types";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

export default function EventsAwardsPage(): React.ReactElement {
  const { context } =
    useRouteContext<
      TRouteWithRedirect<
        EventsAwardsPageContext<EventsAwardsPageContextData>,
        string
      >
    >();

  if (context && context.statusCode !== HttpStatusCode.Ok) {
    // StatusCode is not 200
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }) => {
        if (kind !== "section") {
          // Error or kind is not "art"
          return <CustomError statusCode={context.statusCode} />;
        }

        // StatusCode is 200 and kind is "art"
        return <EventsAwardsPageContent {...data} />;
      }}
    </Await>
  );
}
