import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useScrollTo } from "@hooks/useScrollTo";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import ThriveNewsletter from "@pages/Article/components/ArticleNewsletter/ThriveNewsletter";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import SectionHeading from "@pages/Section/components/SectionHeading";
import {
  getSectionHeadingClass,
  getSectionHeadingText,
} from "@pages/Section/helpers";
import ThriveFavicon from "@pages/Thrive/components/ThriveFavicon";
import { thriveEventsPageMetaTags } from "@pages/Thrive/constants";
import { sectionNavigationItems } from "@util/constant";
import { cn } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import ThriveBanner from "./components/ThriveBanner";
import ThrivePastEvents from "./components/ThrivePastEvents";

export default function ThriveEventsPage(): React.ReactElement {
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  const mostPopular = useMostReadArticleList();
  const breakingNews = useTrimmedBreakingNews();
  const adBlockDetected = useDetectAdBlock();
  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLDivElement>();
  const sectionHeading = getSectionHeadingText(
    RouteFactory.thriveAboutUs,
    "THRIVE"
  );
  const sectionClass = getSectionHeadingClass(RouteFactory.thriveAboutUs);

  const thriveMetatags = thriveEventsPageMetaTags;
  const title = renderPageTitleV2({
    kind: "custom",
    title:
      "Events, thrive - The Business Times | Career, Money and Life for Gen Zs in Singapore",
  });

  return (
    <div className="w-full" data-testid="thrive-events-page">
      <ThriveFavicon />

      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.thriveAboutUs}
      />

      <MetaTags
        title={title}
        ogSiteName={title}
        description={thriveMetatags.description}
        ogDescription={thriveMetatags.description}
        ogImageAlt={thriveMetatags.description}
        ogImageUrl={thriveMetatags.ogImageUrl}
        slug={RouteFactory.thriveEvents}
      />

      <GAData
        title="Thrive_Events"
        level2="miscellaneous"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={RouteFactory.thrive.replace("/", "")}
        childCategory={RouteFactory.thriveEvents.replace("/", "")}
        categoryDataMapping={sectionNavigationItems}
      />

      <Container>
        <SectionHeading text={sectionHeading} className={sectionClass} />

        <div ref={breadcrumbsRef}>
          <Breadcrumb
            className="m-auto"
            parentCategory={RouteFactory.thrive.replace("/", "")}
            childCategory={RouteFactory.thriveAboutUs
              .replace(RouteFactory.thrive, "")
              .replace("/", "")}
            categoryDataMapping={sectionNavigationItems}
            extraBreadcrumbItems={
              <div
                className={cn(
                  "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm tracking-tightest text-gray-850 hover:opacity-80"
                )}
              >
                <Link
                  to={`${RouteFactory.thrive}?ref=listing-menubar`}
                  reloadDocument
                >
                  Top stories
                </Link>
              </div>
            }
          />
        </div>
      </Container>

      <Container rootClassName="pt-8">
        <Row>
          <Column rootClassName="w-full mb-6 lg:w-2/3">
            <div className="bg-verticals-thrive">
              <ThriveBanner setShouldScrollTo={setShouldScrollTo} />

              <ThrivePastEvents scrollToRef={scrollToRef} />
            </div>
          </Column>

          <Column rootClassName="w-full mb-6 lg:w-1/3">
            <ThriveNewsletter
              className="mx-auto mb-6 max-w-[300px]"
              tagLine="Straight to your inbox."
              liner="Money, career and life hacks to help young adults stay ahead."
            />

            <div className="mb-6 lg:h-[800px]">
              <Ad
                adType="imu2"
                className="flex flex-col items-center justify-center lg:!sticky lg:top-18"
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <PulseEntry />

      <Ad adType="catfish" />
      <Ad adType="abm" />
      <Ad adType="prestitial" />
    </div>
  );
}
