import * as React from "react";

import { iconPaths } from "./constant";
import { IconTypeEnum } from "./types";

export type IIcon = React.SVGProps<SVGSVGElement> & {
  type?: IconTypeEnum;
  viewbox?: number;
  size?: number;
  onClick?: (e: React.SyntheticEvent) => void;
  className?: string;
};

/**
 * @see https://heroicons.com/
 * @description default fill white color; override with className
 */
export default function Icon({
  type,
  className = "",
  onClick,
  viewbox = 20,
  size = 20,
  ...rest
}: IIcon): React.ReactElement | null {
  const handleClick = (e: React.SyntheticEvent) => {
    if (onClick) onClick(e);
  };

  return type && iconPaths[type] ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewbox} ${viewbox}`}
      className={className}
      onClick={handleClick}
      fill="white"
      style={{ width: size, height: size }}
      {...rest}
    >
      {iconPaths[type]}
    </svg>
  ) : null;
}
