import { useLocation } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { DefaultNavigationData } from "@typings/Page";
import { ENVIRONMENT } from "@util/constant";
import { addParamsToURL, getEnvironmentDomain } from "@util/helpers";
import IframeResizer from "iframe-resizer-react";

import { ADVERTISE_PAGE_METATAGS, IFRAME_URL_SRC } from "./constants";

const ENVIRONMENT_DOMAIN = getEnvironmentDomain(ENVIRONMENT);

export default function Advertise({
  seoFooterData,
}: DefaultNavigationData): React.ReactElement {
  const { pathname, search, hash } = useLocation();
  const url = new URL(`${ENVIRONMENT_DOMAIN}${pathname}${search}${hash}`);
  const urlParams = new URLSearchParams(url.search);

  const utmParams = [
    { key: "utm_source", value: urlParams.get("utm_source") || "" },
    { key: "utm_medium", value: urlParams.get("utm_medium") || "" },
    { key: "utm_campaign", value: urlParams.get("utm_campaign") || "" },
    { key: "utm_term", value: urlParams.get("utm_term") || "" },
    { key: "utm_content", value: urlParams.get("utm_content") || "" },
  ];

  const advertisePageIframeUrl = addParamsToURL(
    IFRAME_URL_SRC,
    utmParams
  ).toString();

  const iframeResizerAttribs = {
    src: advertisePageIframeUrl,
    inPageLinks: true,
  };
  const MetaTagsProps = ADVERTISE_PAGE_METATAGS;

  return (
    <>
      <AdSettings
        adNames={["lb1", "catfish", "abm"]}
        path={RouteFactory.advertise}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags {...MetaTagsProps} />

      <GAData title="Advertise" level2="miscellaneous" />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <div className="w-full flex-none pb-4">
        <Container>
          <h1 className="border-b border-gray-850 pb-3 pt-6 font-playfair text-8xl uppercase">
            ADVERTISE
          </h1>
        </Container>
      </div>

      <div className="w-full" data-testid="advertise-component">
        <IframeResizer {...iframeResizerAttribs} className="w-full" />
      </div>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </>
  );
}
