import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { sortByPublishedDate } from "@caas/queries/constant";
import axios, { HttpStatusCode } from "axios";

import LifestyleLuxeArticles from "./LifestyleLuxeArticles";

type LifestyleLuxeArticlesProps = {
  articles: SanitizedSectionArticle[];
  articleIds: string[];
  todaysSeason?: string;
};

const PAGE_SIZE = 12;

export default function LifestyleLuxeArticlesOverview({
  articles,
  articleIds,
}: LifestyleLuxeArticlesProps): React.ReactElement {
  const [stories, setStories] = useState<SanitizedSectionArticle[]>(articles);

  const loadMore = async () => {
    const page =
      stories.length === articles.length ? 1 : stories.length / PAGE_SIZE + 1;

    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/more-stories",
      {
        category: "lifestyle_bt-luxe",
        parentCategory: "lifestyle",
        size: PAGE_SIZE,
        page,
        excludeIds: articleIds,
        sort: sortByPublishedDate,
      }
    );

    if (response.status !== HttpStatusCode.Ok) return;

    setStories([...stories, ...response.data]);
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (!inView) return;

      loadMore();
    },
  });

  return (
    <>
      <div className="grid grid-cols-1 gap-0 md:grid-cols-6 md:gap-x-6 md:gap-y-12 md:pt-9 lg:gap-x-8">
        {stories.map((story, index) => {
          return (
            <LifestyleLuxeArticles
              key={story.id}
              article={story}
              index={index}
              initialArticles={articles}
              overviewArticles={stories}
            />
          );
        })}
      </div>

      <div ref={ref} />
    </>
  );
}
