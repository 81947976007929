import { cn } from "@util/helpers";

import { generateAdId } from "./utils/helpers";
import { AdUnitNames } from "./utils/types";

type AdProps = {
  adType: AdUnitNames;
  setAdsHeight?: React.Dispatch<React.SetStateAction<number>>;
  counter?: number;
  articleId?: string;
  articleTags?: string;
  gsChannels?: string;
} & Pick<React.HTMLAttributes<HTMLDivElement>, "className">;

export default function Ad({
  adType,
  className,
  setAdsHeight,
  counter = 0,
  articleId,
  articleTags,
  gsChannels,
}: AdProps): React.ReactElement {
  const id = generateAdId(adType, counter);

  const attributes = {
    id,
    className: cn("ad-placeholder", className),
    "data-testid": id,
    "data-ad-type": adType,
    "data-ad-index": counter,
    "data-ad-article-id": articleId,
    "data-ad-article-tags": articleTags,
    "data-ad-gs-channels": gsChannels,
    ref: (e: HTMLDivElement | null) => {
      if (setAdsHeight && e) {
        setAdsHeight(e.scrollHeight);
      }
    },
  };

  return <div {...attributes}></div>;
}
