import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { getKickerFromArticleDataObject } from "@util/helpers";
import cx from "classnames";

export type OtherArticlesProps = TestIdClassNameProps & {
  articles: ArticleDataObject[];
};
export default function OtherArticles({
  testId,
  articles,
}: OtherArticlesProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  return (
    <>
      {articles.length > 0 ? (
        <div
          className="grid grid-cols-1 gap-6 pb-4 lg:grid-cols-4 lg:border-b lg:border-gray-175"
          data-testid={testId}
        >
          {articles.map((article, index) => {
            const { id, title, urlPath, sections, created, updated } = article;

            const options: CardVariations = {
              kicker: {
                color: "text-gray-515",
              },
            };

            return (
              <BasicCard
                key={id}
                id={id}
                title={title}
                slug={urlPath}
                section={sections?.[0]}
                paywall={
                  isSubscriber ? article.paywall?.contentAccess === "1" : false
                }
                kicker={getKickerFromArticleDataObject(article)}
                created={created}
                updated={updated}
                rootClassName={cx(
                  "relative before:absolute before:top-[calc(100%+.75rem)] before:w-full before:border-b before:border-gray-175",
                  {
                    "lg:before:hidden": index === 0,
                    "lg:before:right-[calc(100%+.75rem)] lg:before:top-0 lg:before:h-full lg:before:w-auto lg:before:border-r ":
                      index > 0,
                  }
                )}
                hasUpdatedTimestampDisplay
                variations={options}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
