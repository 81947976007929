import { Fragment, useState } from "react";
import { RouteFactory } from "@app/routePaths";
import { ArticleDataObject, Tag } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import { AdUnitNames } from "@components/Advertisement/utils/types";
import Container from "@components/Container/Container";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useNextArticle } from "@hooks/useNextArticle";
import { getFormattedTextForAds } from "@pages/Section/helpers";

import { ArticleFactory, VerticalHeaderFactory } from "./utils/helpers";
import { ArticleContentProps } from "./ArticleContent";

export default function ArticleContentAppWebView({
  data: {
    cook: {
      data: { context },
    },
    sectionNews,
    seeAlso,
    isPreview,
  },
}: ArticleContentProps): React.ReactElement {
  const [articleInView, setArticleInView] =
    useState<ArticleDataObject>(context);

  const { moreStories } = useNextArticle(context, sectionNews);

  const { gsChannels } = useGrapeshotData(moreStories[0].context.urlPath, -1);

  // Article data de-construct
  const { sections } = context;

  const mainSection = sections?.[0];
  const uniqueName = mainSection?.uniqueName || "";
  const uniqueNameAds = uniqueName?.replace("_", "/") || "";
  const verticalPath = "/" + uniqueNameAds;

  const VerticalHeaderLayout = VerticalHeaderFactory({ verticalPath });

  const handleSetArticle = (e: ArticleDataObject) => {
    setArticleInView(e);
  };

  const disablePrestitialBasedOnUrlPath = [
    RouteFactory.paidPressRelease, // paid press release pages
    RouteFactory.section("events-awards"), // events-awards pages
  ].some((path) => context?.urlPath.startsWith(path));

  const disablePrestitialBasedOnTags = [RouteFactory.keywordsBudget2025].some(
    (tags) => context?.tags?.some((tag) => tag.urlPath === tags)
  );

  const adsNames: AdUnitNames[] =
    disablePrestitialBasedOnUrlPath || disablePrestitialBasedOnTags
      ? ["lb1", "imu1", "imu2", "imu3", "midarticlespecial", "catfish", "abm"]
      : [
          "lb1",
          "imu1",
          "imu2",
          "imu3",
          "midarticlespecial",
          "prestitial",
          "catfish",
          "abm",
        ];

  return (
    <div className="w-full" data-testid="article-content-component">
      <AdSettings
        adNames={adsNames}
        path={`/${uniqueNameAds}`}
        pageType="articles"
        adTargets={[{ key: "page", value: "article" }]}
        slotTargets={[
          { key: "btarticleid", value: context?.id },
          {
            key: "bttags",
            value:
              context?.tags
                ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                .join(",") || "",
          },
          { key: "gs_channels", value: gsChannels },
        ]}
      />
      <>
        {VerticalHeaderLayout ? (
          <Container rootClassName="py-2">
            <VerticalHeaderLayout />
          </Container>
        ) : null}

        <div id="article-content-wrapper" className="w-full">
          {moreStories.map((article, index) => {
            // Check if article is undefined
            if (!article.context) return null;

            const displayType = article.context.displaySetting?.displayType;
            const uniqueName = article.context.sections?.[0].uniqueName;
            const ArticleLayout = ArticleFactory({ displayType, uniqueName });

            if (!ArticleLayout) return null;

            return (
              <Fragment key={article.context.id}>
                <ArticleLayout
                  article={article.context}
                  isGiftReceived={false}
                  sectionNews={article.sectionNews}
                  variant="default"
                  index={index}
                  seeAlso={seeAlso}
                  isNavigate={moreStories.length > 1}
                  setArticleObj={(e) => {
                    handleSetArticle(e);
                  }}
                  // isOutbrainEnabled={isOutbrainEnabled}
                  isPreview={isPreview}
                  gsChannels={gsChannels}
                  articleInView={articleInView}
                />
              </Fragment>
            );
          })}
        </div>

        <Ad adType="catfish" />
        <Ad adType="abm" />
        <Ad adType="prestitial" />
      </>
    </div>
  );
}
