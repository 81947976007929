import { useEffect, useState } from "react";
import deleteIcon from "@assets/icons/icon-bin.svg";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { useWindowSize } from "@hooks/useWindowSize";
import {
  RecentSearchObject,
  SearchRecentResultsProps,
} from "@pages/Search/utils/types";
import useRecentSearchStore from "@store/useRecentSearchStore";
import { cn } from "@util/helpers";

export default function SearchRecentResults({
  setSelectedKeyword,
  searchQuery,
}: SearchRecentResultsProps): React.ReactElement {
  const [query, setQueryList] = useState<RecentSearchObject[]>(searchQuery);
  const deleteAllSearchQuery = useRecentSearchStore(
    (state) => state.deleteAllSearchQuery
  );
  const { isScreenLG } = useWindowSize();

  useEffect(() => {
    const size = isScreenLG ? 10 : 5;
    const querySlice = searchQuery.slice(0, size);

    setQueryList(querySlice);
  }, [isScreenLG, searchQuery]);

  const handleDeleteAllSearch = () => {
    deleteAllSearchQuery();
  };

  return (
    <div
      data-testid="search-trending-keywords-component"
      className="mt-6 md:mt-18"
    >
      <div className="mb-6 flex w-full justify-between border-b border-gray-175 py-2">
        <div className="flex items-center">
          <p className="font-poppins text-4xs font-semibold uppercase tracking-5% md:text-xs">
            SEARCH HISTORY
          </p>
        </div>

        <button
          onClick={handleDeleteAllSearch}
          className={cn(
            "font-4xs mb-0 font-poppins font-semibold uppercase md:text-lg",
            REMOVE_FOCUS_CSS
          )}
        >
          <img
            src={deleteIcon}
            width={24}
            height={24}
            alt="delete"
            className="mr-2"
          />
        </button>
      </div>

      <div className={cn("flex w-full flex-wrap")}>
        {query.map((search) => {
          return (
            <button
              key={search.query}
              onClick={() => {
                setSelectedKeyword(search.query || "");
              }}
              className={cn(
                "mb-4 mr-4 rounded-[4px] border border-gray-175 px-4 py-3 font-poppins font-medium transition-all duration-300 hover:border-gray-850 md:text-lg",
                REMOVE_FOCUS_CSS
              )}
            >
              {search.query}
            </button>
          );
        })}
      </div>
    </div>
  );
}
