import { getIconSrc } from "@components/Icon/helpers";
import { IconTypeEnum } from "@components/Icon/types";
import cx from "classnames";

export type ToggleSideMenuButtonProps = {
  onClick: () => void;
  rootClassName?: string;
};

export default function ToggleSideMenuButton({
  onClick,
  rootClassName,
}: ToggleSideMenuButtonProps): React.ReactElement {
  return (
    <button
      aria-label="Toggle side menu"
      className={cx(
        "block flex-none cursor-pointer opacity-100 duration-300",
        "h-[48px] w-[48px] border-0 !outline-none focus-within:outline-0 focus:outline-0 lg:h-9 lg:w-9 lg:hover:rounded-full lg:hover:bg-gray-250",
        rootClassName
      )}
      onClick={onClick}
    >
      <img
        className="block h-full w-full px-[6px] lg:hidden"
        src={getIconSrc(IconTypeEnum.hamburgerForMobile)}
        width={36}
        height={36}
        alt="hamburger icon"
      />

      <img
        className="hidden h-full w-full lg:block"
        src={getIconSrc(IconTypeEnum.hamburgerForDesktop)}
        width={36}
        height={36}
        alt="hamburger icon"
      />
    </button>
  );
}
