import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  myBTTagsWithArticle,
  useGetMyBTArticleListProps,
} from "@pages/MyAccount/utils/types";
import axios from "axios";

export const useGetMyBTArticleList = ({
  tags,
  tagSize,
  size = 5,
}: useGetMyBTArticleListProps) => {
  const [myBTArticleList, setMyBTArticleList] = useState<myBTTagsWithArticle[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const tagPageSize = page * 10;
      const tagPageSizeNext = (page + 1) * 10;
      const urlPaths = tagSize
        ? tags.slice(0, tagSize).map((tag) => tag.urlPath)
        : tags.slice(tagPageSize, tagPageSizeNext).map((tag) => tag.urlPath);

      const response = await axios
        .post("/_plat/api/v1/mybt", {
          urlPaths,
          size,
        })
        .then((response) => response.data.body)
        .then((response) => {
          return Object.keys(response).reduce<myBTTagsWithArticle[]>(
            (accumulator, current) => {
              const tag = tags.find(
                ({ urlPath }) => urlPath === `/keywords/${current}`
              );

              if (!tag) return accumulator;

              return [
                ...accumulator,
                {
                  tag: tag,
                  articles: response[current].body,
                },
              ];
            },
            []
          );
        });

      const newMyBtArticleList = [...myBTArticleList, ...response];
      setMyBTArticleList(newMyBtArticleList);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error my bt article list", error);
      return [];
    } finally {
      setIsLoading(false);
      setPage(page + 1);
    }
  }, [tags, tagSize, size, page, myBTArticleList]);

  useEffect(() => {
    if (tags.length === 0) return;

    if (myBTArticleList.length === tags.length) return;

    if (
      tagSize &&
      tags.length <= tagSize &&
      myBTArticleList.length === tags.length
    )
      return;

    if (tagSize && tags.length > tagSize && myBTArticleList.length === tagSize)
      return;

    fetchData();
  }, [myBTArticleList, tags, fetchData, tagSize]);

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (tags.length === myBTArticleList.length) return;
      if (!inView) return;

      fetchData();
    },
  });

  return { ref, myBTArticleList, isLoading };
};
