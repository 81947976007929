import { useEffect, useState } from "react";
import caretDownIcon from "@assets/icon-caret-down.svg";
import caretUpIcon from "@assets/icon-caret-up.svg";
import axios from "axios";
import { isEmpty } from "lodash-es";

import { DEFAULT_PERCENTAGE_INFO } from "./constants";
import type { StockData, StockHistoricalData } from "./types";

export const useStockHistoricalData = (code: string) => {
  const [stockHistoricalData, setStockHistoricalData] =
    useState<StockHistoricalData>();

  useEffect(() => {
    if (isEmpty(code)) return;

    const callFetchHistoricalStockData = async () => {
      const response = await axios.post<StockHistoricalData>(
        "/_plat/api/v1/stock-historical-data",
        { stockCode: code }
      );

      const jsonData = response.data;
      if (jsonData) setStockHistoricalData(jsonData);
    };

    callFetchHistoricalStockData();
  }, [code]);

  return stockHistoricalData;
};

export function useStockData(code: string) {
  const [stockData, setStockData] = useState<StockData>();

  useEffect(() => {
    if (isEmpty(code)) return;

    const callFetchStockData = async () => {
      try {
        const response = await axios.post<StockData>(
          "/_plat/api/v1/stock-data",
          { stockCode: code }
        );

        const jsonData = response.data;
        if (jsonData) setStockData(jsonData);
      } catch (error) {
        return;
      }
    };

    callFetchStockData();
  }, [code]);

  return { stockData };
}

export const getPercentageChangeInfo = (
  changes?: number,
  previous?: number
) => {
  if (typeof changes === "undefined" || typeof previous === "undefined") {
    return DEFAULT_PERCENTAGE_INFO;
  }

  const value = (changes / previous) * 100;
  const percentageChange = Math.round((value + Number.EPSILON) * 100) / 100;

  if (percentageChange > 0) {
    return {
      percentageChange,
      percentageChangeColor: "text-green-300",
      percentageChangeSign: "+",
      percentageChangeIcon: caretUpIcon,
      percentageChangeBGColor: "hover:bg-green-300",
    };
  }

  if (percentageChange < 0) {
    return {
      percentageChange,
      percentageChangeColor: "text-red-400",
      percentageChangeSign: "",
      percentageChangeIcon: caretDownIcon,
      percentageChangeBGColor: "hover:bg-red-400",
    };
  }

  return DEFAULT_PERCENTAGE_INFO;
};

export const getTableData = (stockData: StockData) => {
  return [
    { title: "Buying price", value: stockData.BID },
    { title: "Selling price", value: stockData.OFFER },
    { title: "Amount to be purchased", value: stockData.BID_VOLUME },
    { title: "Amount for sale", value: stockData.OFFER_VOLUME },
    { title: "Opening price", value: stockData.OPEN },
    { title: "Previous closing price", value: stockData.PREVIOUS },
    { title: "Today's high", value: stockData.HIGH },
    { title: "Low today", value: stockData.LOW },
    { title: "P/E ratio", value: stockData.PE },
    { title: "Market value (M)", value: stockData.MARKET_CAP },
    { title: "52 weeks high", value: stockData.YEAR_HIGH },
    { title: "52 weeks low", value: stockData.YEAR_LOW },
    { title: "Dividend yield", value: stockData.DIVIDEND_YIELD },
  ];
};
