import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import { useLoadMoreArticles } from "@hooks/useLoadMoreArticles";
import HubBrandedContentStories from "@pages/Hub/components/HubBrandedListingStories";
import { brandedContentTestId } from "@pages/Hub/utils/constants";
import { fetchMoreBrandedContentData } from "@pages/Hub/utils/helpers";
import { cn } from "@util/helpers";

type HubBrandedContentArticlesProps = {
  articles: SanitizedSectionArticle[];
} & Pick<React.HTMLAttributes<HTMLDivElement>, "className">;

export default function HubBrandedContentArticles({
  articles,
  className,
}: HubBrandedContentArticlesProps): React.ReactElement {
  const { articleList, ref } = useLoadMoreArticles(articles, 10, () =>
    fetchMoreBrandedContentData(articles.at(-1)?.sort || [])
  );

  return (
    <>
      {articles.length > 0 ? (
        <div className={cn(className)} data-testid={brandedContentTestId}>
          <BlockTitle
            text="BRANDED CONTENT"
            rootClassName="text-lg antialiased mb-4"
          />

          <div className="stories divide-y divide-gray-175">
            {articleList.map((article, index) => {
              return (
                <HubBrandedContentStories
                  key={article.id}
                  article={article}
                  articles={articles}
                  index={index}
                />
              );
            })}
          </div>

          <div ref={ref} />
        </div>
      ) : null}
    </>
  );
}
