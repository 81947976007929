import { KeyValuePair } from "@app/types/Page";
import { GoogleAdsSlotFactory } from "@util/helpers";

import {
  adPositions,
  adSizeMappings,
  adSlotSizes,
  adWeights,
} from "./constants";
import { AdUnitNames } from "./types";

/**
 * Helper function to get ad unit data.
 *
 * @param adNames The list of ad names.
 * @param path The current path.
 * @param counter The counter.
 * @returns The ad unit data array.
 */
export const getAdUnitData = (
  adNames: AdUnitNames[],
  path: string,
  slotTargets: KeyValuePair<string, string>[] = []
) => {
  return adNames.map((adName) => {
    return {
      id: generateAdId(adName),
      type: adName,
      pattern: GoogleAdsSlotFactory.prefix + "/" + adName + path,
      slotSize: adSlotSizes[adName] || {},
      sizeMapping: adSizeMappings[adName] || [],
      position: adPositions[adName] || "1",
      weight: adWeights[adName] || "1",
      slotTargets: slotTargets,
    };
  });
};

/**
 * Helper function to generate ad id.
 * @param adType The ad type.
 * @param counter The counter.
 * @returns {string} The ad id.
 */
export const generateAdId = (
  adType: AdUnitNames,
  counter: number = 0
): string => {
  if (counter === 0) return `dfp-ad-${adType}`;

  return `dfp-ad-${adType}-${counter}`;
};
