/**
 * Company: SPHMedia
 * Description: The section block title in vertical pages
 */

import { ReactElement } from "react";
import cx from "classnames";

export type VerticalBlockTitleProps = {
  /**
   * @description The class name for the root element.
   */
  rootClassName?: string;
  /**
   * @description The id for the root element.
   */
  id?: string;
  /**
   * @description The text for the root element.
   * @example "Latest"
   * @example "Most Read"
   */
  text: string;
  /**
   * @description The link for the root element.
   */
  link?: string;
};

/**
 * VerticalBlockTitle
 * @description The section block title in vertical pages
 * @param {VerticalBlockTitleProps} props
 * @returns {ReactElement}
 */
export default function VerticalBlockTitle({
  id = "",
  rootClassName = "",
  text = "",
  link = "",
}: VerticalBlockTitleProps): ReactElement {
  const cls = cx("vertical-block-title", rootClassName);

  return (
    <>
      {link ? (
        <a
          href={link}
          id={id}
          data-testid={id}
          className={cls}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ) : (
        <div id={id} data-testid={id} className={cls}>
          {text}
        </div>
      )}
    </>
  );
}
