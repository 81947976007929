import EventsAwardsDTAStaticPages from "@dta/components/EventsAwardsDTAStaticPages";
import EventsAwardsEEStaticPages from "@ee/components/EventsAwardsEEStaticPages";
import EventsAwardsE50StaticPages from "@events-awards/E50/components/EventsAwardsE50StaticPages";
import {
  DTA_URL_PATTERN,
  E50_URL_PATTERN,
  EE_URL_PATTERN,
  SBA_URL_PATTERN,
  SCA_URL_PATTERN,
  SIA_URL_PATTERN,
} from "@events-awards/utils/constants";
import DefaultStaticLayout from "@pages/Static/components/DefaultStaticLayout";
import EventsAwardsSBAStaticPages from "@sba/components/EventsAwardsSBAStaticPages";
import EventsAwardsSCAStaticPages from "@sca/components/EventsAwardsSCAStaticPages";
import EventsAwardsSIAStaticPages from "@sia/components/EventsAwardsSIAStaticPages";

/**
 * Helper function to get the level 2 for static pages
 * @param urlPath The url path
 * @returns {string} The level 2 for static pages
 */
export const getLevel2ForStaticPages = (urlPath: string): string => {
  switch (urlPath) {
    default:
      return "miscellaneous";
  }
};

/**
 * Helper function to get the header breadcrumb for static pages
 * @param urlPath The url path
 * @returns {string} The header breadcrumb for static pages
 */
export const getHeaderBreadcrumbForStaticPages = (urlPath: string): string => {
  if (urlPath.includes("events-awards")) return "events-awards";

  return "";
};

/**
 * Factory function to return the layout component for static pages
 * @param urlPath The url path
 * @returns
 */
export const StaticPageContentLayoutFactory = (urlPath: string) => {
  if (SCA_URL_PATTERN.test(urlPath)) return EventsAwardsSCAStaticPages;
  if (SIA_URL_PATTERN.test(urlPath)) return EventsAwardsSIAStaticPages;
  if (SBA_URL_PATTERN.test(urlPath)) return EventsAwardsSBAStaticPages;
  if (E50_URL_PATTERN.test(urlPath)) return EventsAwardsE50StaticPages;
  if (EE_URL_PATTERN.test(urlPath)) return EventsAwardsEEStaticPages;
  if (DTA_URL_PATTERN.test(urlPath)) return EventsAwardsDTAStaticPages;

  return DefaultStaticLayout;
};
