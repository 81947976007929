import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import cx from "classnames";

export type ViewsProps = TestIdClassNameProps & {
  articles: ArticleDataObject[];
};
export default function Views({
  testId,
  rootClassName,
  articles,
}: ViewsProps): React.ReactElement {
  const firstArticle = articles.slice(0, 1);
  const restArticle = articles.slice(1, 3);

  return (
    <div className={rootClassName} data-testid={testId}>
      <BlockTitle text="VIEWS" rootClassName="lg:text-center" />

      <div className="border-gray-175">
        {firstArticle.map((article) => {
          const { id, blurb, slug, title, authors, relatedContributorProfile } =
            article;

          return (
            <BasicCard
              key={id}
              id={id}
              slug={slug}
              title={title}
              blurb={blurb}
              rootClassName="py-2"
              author={authors}
              contributorProfile={relatedContributorProfile}
              variations={{
                content: {
                  width: "w-full",
                  extraClass: "text-start lg:text-center",
                  blurb: {
                    extraClass: "lg:!text-lg !block",
                  },
                },
                title: {
                  color: "text-gray-850",
                  size: "text-lg lg:text-4xl",
                },
                byline: {
                  size: "text-xs",
                  imageWidth: 32,
                  imageHeight: 32,
                  extraClass: "flex justify-center",
                },
              }}
            />
          );
        })}

        <div className="grid py-4 lg:grid-cols-2 lg:gap-4 lg:divide-x lg:pb-0">
          {restArticle.map((article, index: number) => {
            const {
              id,
              slug,
              title,
              authors,
              relatedContributorProfile,
              blurb,
            } = article;

            return (
              <div key={id} className="lg:grid-cols-1">
                <BasicCard
                  rootClassName={cx({ "lg:pl-2": index !== 0 })}
                  id={id}
                  slug={slug}
                  title={title}
                  blurb={blurb}
                  author={authors}
                  contributorProfile={relatedContributorProfile}
                  variations={{
                    title: {
                      color: "text-gray-850",
                      size: "text-lg",
                    },
                    content: {
                      width: "w-full",
                      blurb: {
                        extraClass: "lg:!text-base !block",
                      },
                    },
                    byline: {
                      size: "text-xs",
                      imageWidth: 32,
                      imageHeight: 32,
                    },
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
