import { Block } from "@blocks/Blocks";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import { sbaQuickLinksMenu } from "@events-awards/utils/constants";

export default function EventsAwardsSBAQuickLinks(): React.ReactElement {
  return (
    <Block rootClassName="py-18">
      <h2 className="mb-6 border-b border-gray-175 pb-3 text-left font-public-sans text-base font-bold leading-5">
        {"SINGAPORE BUSINESS AWARDS ©"}
      </h2>

      <div className="flex">
        <h3 className="mr-auto text-left font-public-sans text-lg font-normal">
          Quicklinks
        </h3>

        <EventsAwardsNavigationMenu
          menuData={sbaQuickLinksMenu}
          className="m-0 border-0 p-0"
          linkClassName="after:bg-eventAwards-sba-accent text-base font-public-sans font-light after:right-0"
          listClassName="flex-col gap-4 lg:flex-row lg:gap-0"
          listItemClassName="text-right lg:text-center px-0 lg:px-4"
        />
      </div>
    </Block>
  );
}
