export const FALLBACK_PAYWALL_HEADER_PREMIUM_TEXT = "Want the full story?";
export const FALLBACK_PAYWALL_DESC_PREMIUM_TEXT =
  "Become a premium member to unlock this article, and more.";
export const FALLBACK_PAYWALL_CTA_PREMIUM_LINK =
  "https://subscribe.sph.com.sg/publications-bt/?utm_campaign=bt_subscription&utm_medium=sph-publication&utm_source=paywall-bt&utm_content=subscribebutton-pay-fall-back-campaign";

export const FALLBACK_PAYWALL_HEADER_FREE_TEXT =
  "What's moving the markets? BT knows.";
export const FALLBACK_PAYWALL_DESC_FREE_TEXT =
  "Unlock full access with a premium membership.";
export const FALLBACK_PAYWALL_CTA_FREE_LINK =
  "https://subscribe.sph.com.sg/publications-bt/?utm_campaign=bt_subscription&utm_medium=sph-publication&utm_source=bt&utm_content=subscribebutton-free-fall-back-campaign";
