import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import EventsAwardsEEPageContent from "@ee/EventsAwardsEEPageContent";
import {
  EventsAwardsLandingPageContextData,
  EventsAwardsPageContext,
} from "@pages/Section/layouts/EventsAwardsPage/utils/types";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

export default function EventsAwardsEEPage(): React.ReactElement {
  const { context } =
    useRouteContext<
      TRouteWithRedirect<
        EventsAwardsPageContext<EventsAwardsLandingPageContextData>,
        string
      >
    >();

  if (context && context.statusCode !== HttpStatusCode.Ok) {
    // StatusCode is not 200
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }) => {
        if (kind !== "section") {
          // Error or kind is not "art"
          return <CustomError statusCode={context.statusCode} />;
        }

        // StatusCode is 200 and kind is "art"
        return <EventsAwardsEEPageContent {...data} />;
      }}
    </Await>
  );
}
