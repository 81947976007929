import { Await } from "react-router-dom";
import { BudgetContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import { isBudget2024Data, isBudget2025Data } from "./helpers";
import { SingaporeBudgetLayoutFactory } from "./SingaporeBudgetLayoutFactory";

export default function SingaporeBudgetPage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<BudgetContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <>
      <Await resolve={context.payload} errorElement={<CustomError />}>
        {({ kind, data }: BudgetContext) => {
          if (kind === "static") {
            const BudgetLayout = SingaporeBudgetLayoutFactory(data);

            return (
              <>
                {BudgetLayout &&
                (isBudget2024Data(data) || isBudget2025Data(data)) ? (
                  <BudgetLayout title={data.title} data={data} />
                ) : null}
              </>
            );
          }

          // Default error page.
          return <CustomError statusCode={HttpStatusCode.NotFound} />;
        }}
      </Await>
    </>
  );
}
