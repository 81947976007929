import myBTLogoLight from "@assets/logo-mybt-light.svg";

import GetStartedButton from "./GetStartedButton";

export default function Section1(): React.ReactElement {
  return (
    <div className="my-36 px-3 pb-36 text-center">
      <img
        src={myBTLogoLight}
        width={112}
        height={42}
        alt="myBT"
        className="mx-auto mb-6"
      />

      <h2 className="mb-6 font-poppins text-10xl font-semibold lg:text-14xl">
        Follow keywords.
        <br />
        Own your news.
      </h2>

      <GetStartedButton rootClassName="inline-block border-gray-850 bg-gray-850 hover:bg-white hover:text-gray-850 text-white" />
    </div>
  );
}
