import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import BasicCard from "@components/ArticleCard/BasicCard";
import {
  MyAccountRecentReadBasicCardPopoverProps,
  MyAccountRecentReadPopoverProps,
} from "@pages/MyAccount/utils/types";

export default function MyAccountRecentReadList({
  articleList,
  displayViewMore,
}: MyAccountRecentReadPopoverProps): React.ReactElement {
  const handleSessionStorage = () => {
    sessionStorage.setItem("account-recent-read", "true");
  };

  return (
    <div data-testid="my-account-recent-read-popover-list-component">
      <div>
        {articleList.map((article) => {
          if (!article.id) return <></>;
          return (
            <MyAccountRecentReadPopoverBasicCard
              key={`recent-read-popover-${article.id}`}
              article={article}
            />
          );
        })}
      </div>

      {displayViewMore ? (
        <Link
          to={RouteFactory.myAccount}
          onClick={handleSessionStorage}
          reloadDocument
          className="mx-auto mt-4 flex w-max justify-center rounded-[50px] border border-gray-175 px-6 py-4 text-center font-poppins text-xs font-semibold text-gray-850"
        >
          View More
        </Link>
      ) : null}
    </div>
  );
}

export function MyAccountRecentReadPopoverBasicCard({
  article,
}: MyAccountRecentReadBasicCardPopoverProps): React.ReactElement {
  return (
    <div
      className="flex justify-between border-b border-gray-175 py-2 first:pt-0 last:border-b-0"
      data-testid={`recent-read-popover-basic-card-${article.id}`}
    >
      <BasicCard
        id={article.id}
        title={article.displaySetting?.displayHeadline || article.title}
        slug={`${article.urlPath}?ref=recent_read`}
        updated={article.updated}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        variations={{
          section: {
            extraClass: "text-gray-850",
          },
          title: {
            size: "text-base",
            color: "text-gray-850",
            extraClass: "font-semibold",
          },
        }}
      />
    </div>
  );
}
