import { HTMLAttributes } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { cn } from "@util/helpers";

export type HighlightsProps = {
  articles?: SanitizedSectionArticle[];
} & HTMLAttributes<HTMLDivElement>;

export default function Highlights({
  articles,
  className,
}: HighlightsProps): React.ReactElement {
  if (typeof articles === "undefined") return <></>;
  if (articles.length === 0) return <></>;

  const firstArticleVariant: CardVariations = {
    image: {
      position: "",
      width: "w-full",
    },
    content: {
      width: "w-full",
      extraClass: "pt-4",
      blurb: {
        extraClass: "!block",
      },
    },
    kicker: {
      color: "text-gray-875",
      extraClass: "tracking-[.0625rem] leading-[1rem]",
    },
    title: {
      color: "text-gray-875",
      size: "text-lg lg:text-7xl",
    },
  };

  const otherVariant: CardVariations = {
    image: {
      position: "left",
      width: "w-full lg:w-1/2",
    },
    content: {
      width: "w-full lg:w-1/2",
      extraClass: "pt-4 lg:pl-4 lg:pt-0",
      blurb: {
        extraClass: "!block lg:!hidden",
      },
    },
    kicker: {
      color: "text-gray-875",
      extraClass: "tracking-[.0625rem] leading-[1rem]",
    },
    title: {
      color: "text-gray-875",
      size: "text-lg",
    },
  };

  return (
    <div data-testid="highlights-component" className={className}>
      <div className="stories grid grid-cols-1 gap-8 lg:grid-cols-2 lg:grid-rows-3">
        {articles
          .slice(0, 4)
          .map(
            ({ title, id, media, kicker, urlPath, blurb, sections }, index) => {
              return (
                <div
                  key={id}
                  className={cn("story relative", {
                    "lg:row-span-3": index === 0,
                    "lg:after:absolute lg:after:-right-4 lg:after:top-0 lg:after:h-full lg:after:w-[1px] lg:after:bg-gray-175":
                      index === 0,
                    "before:absolute before:-bottom-4 before:left-0 before:h-[1px] before:w-full before:bg-gray-175 lg:before:hidden":
                      index === 0,
                    "after:absolute after:-bottom-4 after:left-0 after:h-[1px] after:w-full after:bg-gray-175":
                      index !== 0 && index !== articles.length - 1,
                  })}
                >
                  <BasicCard
                    title={title}
                    id={id}
                    media={media.at(0)}
                    kicker={kicker?.fields[0].value}
                    slug={urlPath}
                    variations={
                      index === 0 ? firstArticleVariant : otherVariant
                    }
                    blurb={blurb}
                    defaultImage={{
                      directoryName: sections?.[0]?.uniqueName || "",
                    }}
                  />
                </div>
              );
            }
          )}
      </div>
    </div>
  );
}
