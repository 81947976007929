import { RouteFactory } from "@app/routePaths";
import type { SanitizedSectionArticle, Tag } from "@app/types/Cue";
import { useLoadMoreStoriesBySectionKeyword } from "@hooks/useLoadMoreStoriesByCategory";

import PodcastsListingStories from "./PodcastsListingStories";

export type PodcastsOverviewProps = {
  articles: SanitizedSectionArticle[];
  tag: Tag;
};

export default function PodcastsOverview({
  articles = [],
  tag,
}: PodcastsOverviewProps): React.ReactElement {
  const podcastSection = RouteFactory.podcasts.replace("/", "");
  const podcastKeyword =
    tag.urlPath?.replace("/keywords/", "") ||
    tag.uri?.replace("main/", "") ||
    "";

  const { articleList, ref } = useLoadMoreStoriesBySectionKeyword(
    podcastSection,
    `/keywords/${podcastKeyword}`,
    articles,
    5
  );

  return (
    <section data-testid="podcasts-overview-component">
      <div className="stories">
        {articleList.map((article, index) => {
          return (
            <PodcastsListingStories
              key={article.id}
              {...article}
              index={index}
            />
          );
        })}
      </div>

      <div ref={ref} />
    </section>
  );
}
