export const IDLE_TIMER_TIMEOUT = 10000; // 10 seconds idle before timer starts counting.
export const SAMPLE_ARTICLES_STG = [
  "/companies-markets/vestibulum-fringilla-pede-sit-amet-praesent-congue-erat-at-massa-proin-viverra-ligula-sit-amet",
  "/property/maecenas-ullamcorper-dui-et-placerat-integer-ante-arcu-accumsan-a-nam-pretium-turpis-et-arcu",
];
export const TOP_100_ARTICLES_PRD = [
  "/companies-markets/economists-weigh-after-tesla-ceo-elon-musk-says-singapore-going-extinct-amid-low-birth-rate",
  "/companies-markets/latest-singapore-six-month-t-bill-cut-yield-inches-3-02",
  "/companies-markets/capitaland-investment-sells-4-9-stake-capitaland-ascott-trust-s162-million",
  "/companies-markets/police-called-office-ubi-techpark-after-unhappy-investors-demand-refund-gemstone-scheme",
  "/singapore/economy-policy/acra-remove-nric-numbers-bizfile-portal-search-government-apologises-lapse",
  "/companies-markets/property-tycoon-gordon-tang-and-wife-make-conditional-offer-s1-16-each-acquire-all-units-suntec-reit",
  "/events-awards/emerging-enterprise/emerging-enterprise-2024/plana-embracing-thepowdershampoo-eliminating-plastic-startups-green-living-ocbc",
  "/singapore/hdb-starts-work-flats-not-announced-sale-yet-clementi-toa-payoh-and-bukit-merah",
  "/lifestyle/100-years-solitude-magic-fades-tv-adaptation",
  "/international/global/alibaba-blackberry-among-companies-selling-assets-peanuts-after-splurging-billions-buying-them",
  "/international/asean/vietnams-death-row-property-mogul-seeks-lifeline-malaysian-tycoon-vincent-tan-and-foreign-investors",
  "/companies-markets/transport-logistics/scoot-apologises-after-kl-bound-flight-took-11-2-hours-earlier-scheduled-nov-1",
  "/property/singapores-residential-rents-climb-november-against-seasonal-volume-decline-srx-99-co",
  "/companies-markets/telcos-media-tech/tiktok-runs-out-options-billionaire-has-plan-save-it",
  "/companies-markets/s-reits-fall-despite-feds-25-basis-point-rate-cut",
  "/companies-markets/return-reits-singapores-ipo-scene-set-come-alive-after-subdued-year",
  "/opinion-features/disappointment-over-spotifys-flat-year-end-wrap-warning-ai-not-everything",
  "/international/global/singapore-3-top-10-busiest-international-flight-routes-2024-report",
  "/lifestyle/rise-unknown-watch-brands",
  "/singapore/smes/hainan-story-comes-full-circle-it-takes-singapore-hainanese-cuisine-china",
  "/companies-markets/yeo-hiap-seng-lays-25-employees-oatly-shuts-its-singapore-plant",
  "/opinion-features/more-property-cooling-measures-locals-buying-their-first-homes-could-be-hit",
  "/companies-markets/stocks-watch-cdlht-cromwell-e-reit-cli-clas-ireit-global-elite-uk-reit",
  "/opinion-features/agencies-apologise-over-nric-confusion-concerns-over-scam-risk-and-privacy-loss-remain",
  "/international/asean/tech-in-asia/nvidias-vietnam-deal-game-changer-or-just-noise",
  "/companies-markets/telcos-media-tech/amazon-delays-return-office-mandate-thousands-workers-due-space",
  "/singapore/economy-policy/acra-remove-nric-numbers-bizfile-portal-search-government-apologises-lapse",
  "/opinion-features/disappointment-over-spotifys-flat-year-end-wrap-warning-ai-not-everything",
  "/opinion-features/us-central-banks-new-phase-suggests-party-over-wall-street",
  "/companies-markets/han-kwee-juan-replace-tan-su-shan-dbs-head-institutional-banking",
  "/companies-markets/cdl-hospitality-trusts-ventures-student-accommodation-ps37-3-million-uk-deal",
  "/singapore/eight-cpf-related-changes-take-place-2025",
  "/companies-markets/over-8000-platform-workers-opt-increased-cpf-contributions",
  "/companies-markets/telcos-media-tech/huaweis-new-mate-70-phone-shows-its-chip-advances-are-stalling",
  "/companies-markets/ron-sim-and-osim-send-legal-letter-demand-trek-2000-internationals-current-and-former-chairmen",
  "/companies-markets/yeo-hiap-seng-lays-25-employees-oatly-shuts-its-singapore-plant",
  "/wealth/silver-lining-scrapping-allianzs-offer-income-insurance",
  "/companies-markets/st-engineering-wins-s180-million-contracts-bangkok-rail-project",
  "/international/asean/malaysian-resources-has-exited-high-speed-rail-bid-says-partner-berjaya",
  "/international/asean/wife-malaysias-jailed-ex-pm-najib-acquitted-latest-graft-case",
  "/companies-markets/analysts-optimistic-asian-equities-bonds-even-fed-signals-fewer-rate-cuts",
  "/companies-markets/ireit-global-inks-lease-agreement-second-hospitality-company-less-month",
  "/companies-markets/consumer-healthcare/chinas-old-shop-jeweller-bucks-slowdown-437-stock-rally",
  "/companies-markets/consumer-healthcare/strong-singapore-dollar-malaysian-thai-medical-tourism-push-bitter-pill-singapore-swallow",
  "/companies-markets/capital-markets-currencies/all-eyes-mas-review-group-potential-issuers-wait-sidelines",
  "/companies-markets/banking-finance/asia-pacific-bankers-expect-their-2025-bonuses-jump-52-3-survey",
  "/companies-markets/keppel-dc-reits-newly-issued-preferential-offering-units-start-trading-sgx-9-am",
  "/lifestyle/cosy-comforts-italian-bakery-restaurant-atipico",
  "/international/asean/thailand-faces-uphill-battle-revive-growth-momentum",
  "/companies-markets/consumer-healthcare/nike-results-beat-low-expectations-ceo-warns-turnaround-will-be-slog",
  "/companies-markets/capital-markets-currencies/stocks-watch-cosco-shipping-keppel-dc-reit-sembcorp-industries-cromwell-e-reit",
  "/international/global/chinas-economic-rebound-hangs-fate-its-richest-cities",
  "/companies-markets/capitaland-investment-sells-4-9-stake-capitaland-ascott-trust-s162-million",
  "/property/chinese-students-give-boost-hong-kong-commercial-property",
  "/companies-markets/cromwell-e-reit-divests-2-office-assets-italy-20-9-million-euros",
  "/opinion-features/showing-extraordinary-leadership-2025-mental-health-and-inclusive-workplace",
  "/companies-markets/banking-finance/us-fed-lowers-rate-quarter-point-projects-slower-pace-further-cuts-firmer-inflation",
  "/companies-markets/banking-finance/losses-crypto-hacks-jump-us2-2-billion-2024-report",
  "/singapore/smes/south-east-asias-smes-risk-being-excluded-growing-green-economy",
  "/companies-markets/energy-commodities/oil-drops-dour-economic-outlook-adds-oversupply-concerns",
  "/companies-markets/latest-singapore-six-month-t-bill-cut-yield-inches-3-02",
  "/companies-markets/capital-markets-currencies/us-stocks-end-flat-rebound-effort-losses-steam",
  "/opinion-features/fed-clueless-markets",
  "/esg/green-finance-asset-managers-see-potential-high-emitters-impact-investing-brookfield",
  "/lifestyle/bt-luxe/truffle-feasts-fireworks-views-where-discerning-diners-celebrate-marinabaysands",
  "/startups-tech/funding-societies-raises-us25-million-sovereign-wealth-fund-cool-japan-fund",
  "/companies-markets/elite-uk-reit-divesting-property-england-ps1-6-million",
  "/property/tis-season-musical-chairs-city-office-landlords-get-new-tenants-while-others-vacate",
  "/property/market-watchers-see-value-branded-residences-singapore-despite-slow-take",
  "/international/asean/indonesias-largest-party-expels-jokowi-after-he-backed-prabowo",
  "/international/global/south-east-asias-renewable-energy-future-faces-hurdles-financing-momentum-growing",
  "/opinion-features/how-elon-musk-and-taylor-swift-can-resolve-us-china-relations",
  "/companies-markets/feds-hawkish-tilt-has-emerging-markets-scurrying-save-currencies",
  "/companies-markets/capital-markets-currencies/singapore-shares-open-lower-friday-sti-down-0-5",
  "/international/asean/singapore-investors-flock-balis-new-green-sez-plan-build-luxury-resort-and-school",
  "/companies-markets/telcos-media-tech/apple-talks-tencent-bytedance-roll-out-ai-features-china-sources",
  "/lifestyle/last-minute-christmas-gift-guide",
  "/property/singapores-residential-rents-climb-november-against-seasonal-volume-decline-srx-99-co",
  "/singapore/no-need-for-further-gst-increases-up-to-2030-dpm-lawrence-wong1",
  "/startups-tech/startups/worldcoin-must-delete-all-iris-scan-data-watchdog-says",
  "/companies-markets/hot-stock-wee-hur-falls-over-8-after-exit-australian-student-dorm-business",
  "/companies-markets/transport-logistics/fedex-spin-its-freight-trucking-business",
  "/property/lvmh-buys-50-million-euro-villa-cannes-brand-events",
  "/companies-markets/sti-continues-losing-streak-falls-0-4-cent-after-us-fed-signals-fewer-rate-cuts",
  "/companies-markets/capital-markets-currencies/us-wall-st-jumps-open-after-selloff-fed-rate-cut-caution",
  "/international/asean/indonesian-arm-malaysias-mr-diy-climbs-market-debut",
  "/companies-markets/energy-commodities/gold-set-weekly-drop-market-awaits-more-us-data-economic-cues",
  "/esg/gics-senior-vp-sustainability-joins-board-committee-climate-focused-investor-group",
  "/property/big-investors-such-warburg-pincus-reits-capitaland-and-keppel-lead-action-singapore-commercial-real",
  "/companies-markets/transport-logistics/saudi-global-ports-co-owned-psa-taps-banks-us1-billion-ipo-sources",
  "/companies-markets/transport-logistics/behind-potential-honda-and-nissan-tie-existential-threat-posed-chinese-evs",
  "/companies-markets/italy-probes-ck-hutchison-unit-tax-3-4-billion-euros-asset-sale",
  "/companies-markets/transport-logistics/honda-nissan-move-deepen-ties-including-possible-merger-sources",
  "/companies-markets/acra-names-new-chief-executive-ong-khiaw-hong-retire-public-service",
  "/lifestyle/bt-luxe/andre-chiang-chef-hotelier",
  "/companies-markets/transport-logistics/foxconn-said-have-approached-nissan-take-controlling-stake",
  "/international/us-economy-eyes-strong-finish-ahead-heightened-policy-uncertainty-2025",
  "/international/global/hong-kongs-expat-nightlife-hub-reshaped-chinese-influx",
  "/companies-markets/allianz-pull-out-provides-clarity-incomes-special-position",
  "/companies-markets/consumer-healthcare/chinas-old-shop-jeweller-bucks-slowdown-437-stock-rally",
];
