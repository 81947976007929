import { useEffect, useRef } from "react";
import Tooltip from "@components/Tooltip/Tooltip";
import TooltipTriangle from "@components/Tooltip/TooltipTriangle";
import { useDetectWhiteOverlay } from "@hooks/useDetectWhiteOverlay";
import useSendPulseGaData from "@pages/Pulse/hooks/useSendPulseGaData";
import { cn } from "@util/helpers";

import PulseEntryButton from "./PulseEntryButton";

export default function PulseEntry(): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const { sendCustomEvent } = useSendPulseGaData();
  const { isOverlayDisplay } = useDetectWhiteOverlay();

  useEffect(() => {
    if (typeof window === "undefined") return;

    if (containerRef.current) {
      const getContainerWidth =
        window.document.getElementsByClassName("container")?.[0].clientWidth;
      containerRef.current.style.right = `calc((100svw - ${getContainerWidth}px)/ 2)`;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window === "undefined") return;

      const getContainerWidth =
        window.document.getElementsByClassName("container")?.[0].clientWidth;

      if (containerRef.current) {
        containerRef.current.style.right = `calc((100svw - ${getContainerWidth}px)/ 2)`;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePulseTooltipVisibility = () => {
    sendCustomEvent("click", "tooltip");
  };

  return (
    <div
      className={cn("fixed bottom-40 right-4 z-[99999] px-3 lg:px-0")}
      data-testid="pulse-entry-component"
      ref={containerRef}
    >
      {!isOverlayDisplay ? (
        <>
          <Tooltip
            handleExtraFunction={handlePulseTooltipVisibility}
            className="-top-4"
            cookieName="BTPulseTooltip"
            text="Quickly catch up on the news with our bite-sized summaries"
            bottomTriangle={
              <TooltipTriangle className="absolute right-12 h-0 w-0 border-l-[10px] border-r-[10px] border-t-[15px] border-l-transparent border-r-transparent border-t-gray-850" />
            }
          />

          <PulseEntryButton />
        </>
      ) : null}
    </div>
  );
}
