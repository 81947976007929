import { Name } from "@app/types/enums";
import {
  getRelAttribute,
  getTargetAttribute,
} from "@pages/Article/components/StoryElements/Annotation/utils/helpers";
import { AnnotationComponentProps } from "@pages/Article/components/StoryElements/Annotation/utils/types";
import { getFieldValue, getFormattedUrlPath } from "@util/helpers";
import { isEmpty } from "lodash-es";

/**
 * Renders an internal link for an annotation component.
 *
 * @param {AnnotationComponentProps} props - The component props.
 * @param {React.ReactNode} props.children - The children of the component.
 * @param {Annotation} props.element - The annotation element.
 * @returns {React.ReactElement} The rendered internal link component.
 */
export default function AnnotationComponentInternalLink({
  children,
  element,
  parameters,
}: AnnotationComponentProps): React.ReactElement {
  const uri = element?.relation?.urlPath;
  const isNewWindow = !!getFieldValue(element?.fields || [], Name.NewWindow);
  const isNoFollow = !!getFieldValue(element?.fields || [], Name.NoFollow);
  const relAttribute = getRelAttribute(isNewWindow, isNoFollow);
  const targetAttribute = getTargetAttribute(isNewWindow);

  const urlPath = getFormattedUrlPath(uri || "", parameters || []);

  return (
    <>
      {typeof uri === "string" && !isEmpty(urlPath) ? (
        <a href={urlPath} target={targetAttribute} rel={relAttribute}>
          {children}
        </a>
      ) : null}
    </>
  );
}
