import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import Ad from "@src/app/components/Advertisement/Ad";
import AdSettings from "@src/app/components/Advertisement/AdSettings";
import { AdUnitNames } from "@src/app/components/Advertisement/utils/types";
import { sectionNavigationItems } from "@util/constant";

import {
  getHeaderBreadcrumbForStaticPages,
  getLevel2ForStaticPages,
  StaticPageContentLayoutFactory,
} from "./utils/helpers";
import { StaticPageContext } from "./utils/types";

type StaticPageContentProps = Pick<StaticPageContext, "data">;

export default function StaticPage({
  data: { title, content, urlPath },
}: StaticPageContentProps): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();

  const level2 = getLevel2ForStaticPages(urlPath);
  const headerBreadcrumb = getHeaderBreadcrumbForStaticPages(urlPath);
  const Layout = StaticPageContentLayoutFactory(urlPath);

  const adsNames: AdUnitNames[] = [
    "lb1",
    "imu1",
    "imu2",
    "imu3",
    "midarticlespecial",
    "prestitial",
    "catfish",
    "abm",
  ];

  return (
    <div className="w-full" data-testid="static-page-content-component">
      <GAData title={title.split(" ").join("_")} level2={level2} />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES - Find News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={urlPath}
      />

      <AdSettings
        adNames={adsNames}
        path={urlPath}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 md:py-3 lg:min-h-[250px]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={headerBreadcrumb}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <>{Layout ? <Layout content={content} /> : null}</>
    </div>
  );
}
