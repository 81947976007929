import type { Author, ContributorProfile } from "@app/types/Cue";
import Byline from "@components/Byline/Byline";
import { getAllProfiles } from "@components/Byline/helpers";
import cx from "classnames";

import ArticleTimeStamp from "./ArticleTimeStamp";

type ArticleAuthorsProps = {
  rootClassName?: string;
  authors: Author[];
  contributors: ContributorProfile[];
  publishedDate: string;
  updatedDate: string;
  hasUpdatedTimeDisplayed?: boolean;
  imgWidth?: number;
  imgHeight?: number;
  alwaysShowDefaultPhoto?: boolean;
  backdrop?: string;
};

export default function ArticleAuthorsList({
  rootClassName,
  authors,
  contributors,
  publishedDate,
  updatedDate,
  hasUpdatedTimeDisplayed,
  imgWidth = 65,
  imgHeight = 65,
  alwaysShowDefaultPhoto = false,
  backdrop,
}: ArticleAuthorsProps): React.ReactElement {
  const profiles = getAllProfiles(authors, contributors);

  return (
    <div
      className={cx(
        rootClassName,
        "mt-4 space-y-4",
        "md:flex md:flex-wrap md:space-y-0"
      )}
      data-testid="article-authors-list-component"
    >
      <Byline
        profiles={profiles}
        imageWidth={imgWidth}
        imageHeight={imgHeight}
        alwaysShowDefaultPhoto={alwaysShowDefaultPhoto}
        backdrop={backdrop}
        timeStamp={
          <ArticleTimeStamp
            publishedDate={publishedDate}
            updatedDate={updatedDate}
            hasUpdatedTimeDisplayed={hasUpdatedTimeDisplayed}
          />
        }
      />
    </div>
  );
}
