import { getIconSrc } from "@components/Icon/helpers";
import { cn } from "@util/helpers";

import { COMMON_BUTTON_CSS, REMOVE_FOCUS_CSS } from "./constants";
import SocialEmbedButtonFactory from "./SocialMediaButtonFactory";
import { ActionType, ButtonProps, ButtonSize, SocialMediaType } from "./types";

export default function Button({
  className = "",
  whichSocialMedia = SocialMediaType.facebook,
  shareUrl = "",
  actionType = ActionType.actionButton,
  icon,
  text,
  imgAlt = "icon",
  title = "",
  onClick,
  buttonSize = ButtonSize.small,
  iconSize = 24,
  testid = "",
  children,
}: ButtonProps) {
  const commonCss = `${REMOVE_FOCUS_CSS} ${COMMON_BUTTON_CSS}`;

  switch (actionType) {
    case ActionType.shareButton:
      return (
        <SocialEmbedButtonFactory
          commonCss={commonCss}
          whichSocialMedia={whichSocialMedia}
          shareUrl={shareUrl}
          className={className}
          title={title}
          iconSize={iconSize}
          buttonSize={buttonSize}
        />
      );

    case ActionType.linkButton:
      return (
        <a
          className={cn(
            {
              "aspect-square": icon && !text,
            },
            commonCss,
            className
          )}
          href={shareUrl}
          target="_blank"
          rel="noreferrer"
          referrerPolicy="strict-origin-when-cross-origin"
          title={icon}
          data-testid={testid}
          aria-label={icon}
          data-size={buttonSize}
          onClick={onClick}
        >
          {children ? (
            children
          ) : (
            <>
              {icon ? (
                <img
                  src={getIconSrc(icon)}
                  width={iconSize}
                  height={iconSize}
                  alt={imgAlt}
                />
              ) : null}

              {text ? text : null}
            </>
          )}
        </a>
      );

    case ActionType.actionButton:
    default:
      return (
        <button
          className={cn(
            commonCss,
            {
              "aspect-square": icon && !text,
            },
            className
          )}
          onClick={onClick}
          data-size={buttonSize}
          data-testid={testid}
        >
          {children}
        </button>
      );
  }
}
