import { BudgetData } from "@src/app/types/Page";

import SingaporeBudget2024 from "./2024/SingaporeBudget2024";
import SingaporeBudget2025 from "./2025/SingaporeBudget2025";
import { isBudget2024Data, isBudget2025Data } from "./helpers";

export const SingaporeBudgetLayoutFactory = (data: BudgetData) => {
  if (isBudget2025Data(data)) {
    return SingaporeBudget2025;
  }

  if (isBudget2024Data(data)) {
    return SingaporeBudget2024;
  }

  // switch (year) {
  //   case 2024:
  //     return SingaporeBudget2024;
  // }
};
