import { PropsWithChildren } from "react";
import { IconTypeEnum } from "@components/Icon/types";

export enum SocialMediaType {
  facebook = "facebook",
  telegram = "telegram",
  email = "email",
  linkedin = "linkedin",
  twitter = "twitter",
  whatsapp = "whatsapp",
  instagram = "instagram",
}

export type ButtonProps = {
  className?: string;
  whichSocialMedia?: SocialMediaType;
  shareUrl?: string;
  icon?: IconTypeEnum;
  text?: string;
  actionType?: ActionType;
  imgAlt?: string;
  title?: string;
  buttonSize?: ButtonSize;
  iconSize?: number;
  onClick?: (e: React.SyntheticEvent) => void;
  testid?: string;
} & PropsWithChildren;

export enum ButtonSize {
  small = "sm",
  medium = "md",
  large = "lg",
}

export enum ActionType {
  linkButton = "linkButton",
  shareButton = "shareButton",
  actionButton = "actionButton",
  tabButton = "tabButton",
}
