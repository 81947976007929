import { EventsAwardsQuickLinksFactory } from "@events-awards/utils/helpers";
import { AwardType } from "@events-awards/utils/types";

type EventsAwardsLogoMenuProps = {
  awardType: AwardType;
};

export default function EventsAwardsQuickLinks({
  awardType,
}: EventsAwardsLogoMenuProps): React.ReactElement {
  const Layout = EventsAwardsQuickLinksFactory(awardType);

  return <>{Layout ? <Layout /> : null}</>;
}
