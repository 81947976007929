import { HTMLAttributes } from "react";
import Button from "@components/AccessibleComponents/Button";
import { getIconSrc } from "@components/Icon/helpers";
import { IconTypeEnum } from "@components/Icon/types";
import { PressEvent } from "@react-types/shared";
import { cn, mySPHOpenLogin } from "@util/helpers";

export type LoginTriggerProps = {
  onToggleLogin?: (e: PressEvent) => void;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function MyAccountLoginTrigger({
  onToggleLogin,
  className = "lg:p-3",
}: LoginTriggerProps): React.ReactElement {
  return (
    <Button
      className={cn(
        "cursor-pointer font-poppins text-2xs font-semibold opacity-100",
        "flex items-center justify-center rounded-[25px] leading-none tracking-tightest focus-within:outline-0 focus:outline-0 lg:hover:bg-gray-175",
        className
      )}
      onPress={(e) => {
        mySPHOpenLogin();

        onToggleLogin && onToggleLogin(e);
      }}
      data-testid="user-login-trigger"
    >
      <span className="hidden lg:block">Sign in / Sign up for free</span>
      <img
        className="block lg:hidden"
        src={getIconSrc(IconTypeEnum.profile)}
        width={48}
        height={48}
        alt="my-account"
      />
    </Button>
  );
}
