import { mySPHOpenLogin } from "@app/util/helpers";
import { getIconSrc } from "@components/Icon/helpers";
import { IconTypeEnum } from "@components/Icon/types";
import { PressEvent } from "@react-types/shared";
import Button from "@src/app/components/AccessibleComponents/Button";
import cx from "classnames";

export type LoginTriggerProps = {
  title?: string;
  onToggleLogin?: ((e: PressEvent) => void) | null;
  white?: boolean;
};
// TODO: implement mySPH login: feature/lightbox-https: https://github.com/SPHTech/bt-web/tree/feature/lightbox-https
// https://sph.atlassian.net/browse/NGDP-183
export default function LoginTrigger({
  title = "LOG IN",
  onToggleLogin = null,
  white = false,
}: LoginTriggerProps): React.ReactElement {
  const textColor = white ? "text-white" : "text-gray-850";

  return (
    <Button
      className={cx(
        textColor,
        "cursor-pointer font-poppins text-2xs font-semibold opacity-100",
        "flex items-center justify-center rounded-[25px] leading-none tracking-tightest focus-within:outline-0 focus:outline-0 lg:p-3 lg:hover:bg-gray-175"
      )}
      onPress={(e) => {
        /**
         * @description this function will open up the mysph login lightbox
         * @see https://docs.google.com/document/d/1obM9zIuU0Cj6UlI3mkHXGWDEIAGpCx029iiuV_kEwUU for API usage of window._mySPHObj
         */
        mySPHOpenLogin();

        onToggleLogin && onToggleLogin(e);
      }}
      data-testid="user-login-trigger"
    >
      <p className="m-0 hidden lg:block">{title}</p>

      <img
        className="block lg:hidden"
        src={getIconSrc(IconTypeEnum.profile)}
        width={48}
        height={48}
        alt="account"
      />
    </Button>
  );
}
