import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { BT_NEWSLETTERS } from "@components/Newsletter/types/Newsletter";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Button, Transition } from "@headlessui/react";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

import NewsletterForm from "./components/NewsletterForm";
import { LISTING_NEWSLETTER_CONFIG } from "./utils/constants";

type ListingPageNewsletterProps = {
  /** The classname of the component. */
  className?: string;
};

/** The component to use to display newsletter sign up on listign pages. */
export default function ListingPageNewsletter({
  className,
}: ListingPageNewsletterProps): React.ReactElement {
  const { pathname } = useLocation();
  const { isScreenLG } = useWindowSize();
  const sendDataToGTM = useGTMDispatch();

  const [isShowForm, setIsShowForm] = useState(false);

  // Get the config based on path.
  const config = LISTING_NEWSLETTER_CONFIG.find(({ pageURLs }) =>
    pageURLs.includes(pathname)
  );

  // Get the newsletter(s) based on the config above.
  const newsletters = BT_NEWSLETTERS.filter(({ type }) =>
    config?.types.includes(type)
  );

  const { ref } = useInView({
    threshold: 1,
    onChange: (inView) => {
      if (!inView) return;

      sendDataToGTM({
        event: "custom_event",
        eventCategory: "newsletter",
        eventAction: "visible",
        eventLabel: "sign up",
      });
    },
  });

  // If we didnt find any config, dont render component.
  if (typeof config === "undefined") return <></>;

  return (
    <div
      data-testid="listing-page-newsletter-component"
      className={className}
      ref={ref}
    >
      {(isScreenLG && config.name?.desktop) ||
      (!isScreenLG && config.name?.mobile) ? (
        <h3 className="mb-2 font-poppins text-lg font-semibold leading-5">
          {isScreenLG ? config.name?.desktop : config.name?.mobile}
        </h3>
      ) : null}

      {(isScreenLG && config.tagLine.desktop) ||
      (!isScreenLG && config.tagLine.mobile) ? (
        <h4 className="mb-4 font-poppins text-xs font-semibold leading-normal -tracking-[0.00875rem]">
          {isScreenLG ? config.tagLine.desktop : config.tagLine.mobile}
        </h4>
      ) : null}

      <p className="font-public-sans text-sm font-normal leading-normal">
        {config?.liner}
      </p>

      <div className={cn("relative", { "pb-[48px]": !isShowForm })}>
        <Transition show={!isShowForm && !isScreenLG}>
          <Button
            onClick={() => {
              setIsShowForm(!isShowForm);
            }}
            className={cn(
              "text-nowrap font-poppins text-xs font-semibold leading-normal tracking-tightest text-gray-850",
              "w-[103px] rounded-full border border-gray-175 bg-white px-6 py-2 text-center transition-colors",
              "hover:border-gray-850 hover:bg-gray-850 hover:text-white",
              "transition duration-300 ease-in data-[closed]:translate-x-full data-[closed]:opacity-0",
              "absolute left-0 top-0 z-50"
            )}
          >
            Sign up
          </Button>
        </Transition>

        <Transition show={isShowForm || isScreenLG} appear>
          <div className="transition-all duration-300 ease-in data-[closed]:translate-x-full data-[closed]:opacity-0">
            <NewsletterForm newsletters={newsletters} />
          </div>
        </Transition>
      </div>
    </div>
  );
}
