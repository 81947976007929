import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { getKickerFromArticleDataObject } from "@util/helpers";

export type LatestNewsProps = TestIdClassNameProps & {
  articles: ArticleDataObject[];
};
export default function LatestNews({
  testId,
  rootClassName,
  articles,
}: LatestNewsProps): React.ReactElement {
  const variant: CardVariations = {
    title: {
      color: "text-gray-850",
      size: "text-base lg:text-lg",
    },
  };

  return (
    <div className={rootClassName} data-testid={testId}>
      <BlockTitle text="LATEST NEWS" />

      <div className="divide-y border-gray-175">
        {articles.map((article) => {
          const { id, title, kicker, slug, edited, displaySetting } = article;

          return (
            <BasicCard
              key={id}
              id={id}
              title={title}
              slug={slug}
              kicker={
                kicker?.fields?.[0]?.value ||
                getKickerFromArticleDataObject(article)
              }
              variations={variant}
              rootClassName="py-4"
              edited={edited}
              hasUpdatedTimestampDisplay={
                displaySetting?.hasUpdatedTimeDisplayed
              }
            />
          );
        })}
      </div>
    </div>
  );
}
