/**
 * Company: SPHMedia
 * Description: LifestyleCard Component
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { BasicCardProps } from "@components/ArticleCard/types";
import Category from "@components/ArticleMeta/Category";
import Description from "@components/ArticleMeta/Description";
import Kicker from "@components/ArticleMeta/Kicker";
import ReadMore from "@components/ArticleMeta/ReadMore";
import SubscriberFlag from "@components/ArticleMeta/SubscriberFlag";
import TitleLink from "@components/ArticleMeta/TitleLink";

/**
 * LifestyleCard
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function LifestyleCard({
  id,
  kicker = "",
  section,
  title = "",
  paywall = false,
  blurb = "",
  slug = "",
}: BasicCardProps): ReactElement {
  return (
    <>
      <div className="space-y-3">
        {section?.name ? (
          <Category
            categoryName={section.name}
            rootClassName="text-4xs bg-lifestyle-primary decoration-clone px-[10px]"
            hrefLink={RouteFactory.section(
              section.uniqueName.replace("_", "/")
            )}
          />
        ) : null}

        {kicker && (
          <Kicker name={kicker} rootClassName="font-pt-sans text-gray-515" />
        )}

        <TitleLink
          title={title}
          articleId={id}
          rootClassName="text-6xl lg:text-11xl !leading-[1.2] font-pt-serif text-white"
          linkRootClassName="!inline bg-black-100 decoration-clone px-[10px]"
          spanClassName="inline"
          slug={slug}
        />

        {paywall ? (
          <div className="inline-block bg-black-100 decoration-clone px-[10px]">
            <SubscriberFlag rootClassName="leading-1.4 text-4xs font-normal uppercase !text-white" />
          </div>
        ) : null}

        <Description
          text={blurb}
          rootClassName="text-xl bg-black-100 text-white hidden leading-6 mb-4 decoration-clone px-[10px] py-[2px] lg:inline"
        />

        <ReadMore articleId={id} slug={slug} isAnimated />
      </div>
    </>
  );
}
