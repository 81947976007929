// AccordionProcessor.tsx
import React from "react";
import type { Element } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import Accordion from "@components/Accordion/Accordion";
import ArticleInlineImage from "@pages/Article/components/StoryElements/ArticleInlineImage";
import ListBulleted from "@pages/Article/components/StoryElements/ListBulleted";
import ListNumbered from "@pages/Article/components/StoryElements/ListNumbered";
import Paragraph from "@pages/Article/components/StoryElements/Paragraph";
import PullQuote from "@pages/Article/components/StoryElements/PullQuote";
// Components
import SubHeading from "@pages/Article/components/StoryElements/SubHeading";
import { getResolvedArticleElements } from "@pages/Article/utils/helpers";
import { cn } from "@util/helpers";

type AccordionProcessorProps = {
  className?: string;
  elements: Element[];
  AccordionElement: Element[];
  elementsClass?: string;
  isColumnDisplay?: boolean;
  accordionHeaderClass?: string;
};

type AccordionItemProps = {
  element: Element;
  isExpanded?: boolean;
  accordionHeaderClass?: string;
  handleDrawer?: (key: string) => void;
};

const AccordionHead = ({
  element,
  isExpanded,
  handleDrawer,
  accordionHeaderClass,
}: AccordionItemProps): React.ReactElement => {
  const headingText = element.fields.find(
    (field) => field.name === "accordionhead"
  )?.value;
  const headingType = element.fields.find(
    (field) => field.name === "headings"
  )?.value;

  return (
    <div
      className={cn(
        "flex w-full cursor-pointer items-center justify-between border-b border-gray-200 pt-8",
        { "mb-8": isExpanded }
      )}
      onClick={() => handleDrawer?.(element.id)}
      data-story-element={Type.AccordionHead}
      data-testid="accordion-head-component"
    >
      {headingType === "h2" && (
        <h2 className="text-base font-semibold">{headingText}</h2>
      )}
      <div
        className={cn(
          "flex h-[42px] w-[42px] items-center justify-center rounded-full border border-gray-175 hover:opacity-70",
          accordionHeaderClass
        )}
      >
        <svg
          className={cn(
            "block w-[12px] transition-all duration-300 group-hover:fill-gray-850",
            { "-rotate-180": isExpanded }
          )}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 8"
          fill="none"
        >
          <path
            d="M1 1.33398C5 5.33398 6 6.33398 6 6.33398L11 1.33398"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

type AccordionBodyProps = {
  elements: Element[];
  element: Element[];
  elementsClass?: string;
  isColumnDisplay?: boolean;
};

export const AccordionBody = ({
  elements,
  element,
  elementsClass,
  isColumnDisplay,
}: AccordionBodyProps): React.ReactElement => {
  if (!isColumnDisplay) {
    return (
      <div className="space-y-4">
        {element.map((element) => {
          switch (element.type) {
            case Type.SubHead:
              return (
                <SubHeading
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-4 text-lg font-bold", elementsClass)}
                />
              );
            case Type.Paragraph:
              return (
                <Paragraph
                  key={element.id}
                  element={element}
                  className="mb-4 font-public-sans"
                />
              );
            case Type.ListBulleted: {
              const bulletedArticleElements = getResolvedArticleElements(
                element.children,
                elements
              );
              return (
                <ListBulleted
                  key={element.id}
                  elements={bulletedArticleElements || []}
                  className="mb-4 font-public-sans"
                />
              );
            }
            case Type.ListNumbered: {
              const numberedArticleElements = getResolvedArticleElements(
                element.children,
                elements
              );
              return (
                <ListNumbered
                  key={element.id}
                  elements={numberedArticleElements || []}
                  className="mb-4"
                />
              );
            }
            default:
              return null;
          }
        })}
      </div>
    );
  }

  const contentGroups: Element[][] = [];
  let currentContent: Element[] = [];

  element.forEach((el) => {
    if (el.type === Type.SubHead) {
      if (currentContent.length > 0) {
        contentGroups.push(currentContent);
      }
      currentContent = [el];
    } else {
      currentContent.push(el);
    }
  });

  if (currentContent.length > 0) {
    contentGroups.push(currentContent);
  }

  return (
    <div className="divide-y divide-gray-100">
      {contentGroups.map((group, index) => {
        const subHead = group.find((el) => el.type === Type.SubHead);
        const content = group.filter((el) => el.type !== Type.SubHead);

        if (content.length === 0) return null;

        if (!subHead) {
          return (
            <div key={index} className="md:pl-[280px]">
              <div>
                {content.map((contentElement) => {
                  switch (contentElement.type) {
                    case Type.Paragraph:
                      return (
                        <Paragraph
                          key={contentElement.id}
                          element={contentElement}
                          className="font-public-sans text-base font-normal"
                        />
                      );
                    case Type.ListBulleted: {
                      const bulletedElements = getResolvedArticleElements(
                        contentElement.children,
                        elements
                      );
                      return (
                        <ListBulleted
                          key={contentElement.id}
                          className="font-public-sans text-base font-normal"
                          elements={bulletedElements || []}
                        />
                      );
                    }
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          );
        }

        return (
          <div key={index} className="flex flex-col pb-8 pt-3 md:flex-row">
            <div className="mb-4 w-full md:mb-0 md:w-[280px] md:pr-14">
              <SubHeading
                key={subHead.id}
                fields={subHead.fields}
                className="tracking-wider text-left font-public-sans text-xs font-semibold uppercase text-gray-850 md:text-right md:text-base"
              />
            </div>
            <div className="max-w-full flex-1 md:max-w-[calc(100%-288px)]">
              {content.map((contentElement) => {
                switch (contentElement.type) {
                  case Type.Paragraph:
                    return (
                      <Paragraph
                        key={contentElement.id}
                        element={contentElement}
                        className="font-public-sans text-lg font-light text-gray-850"
                      />
                    );
                  case Type.ListBulleted: {
                    const bulletedElements = getResolvedArticleElements(
                      contentElement.children,
                      elements
                    );
                    return (
                      <ListBulleted
                        key={contentElement.id}
                        className="!pl-4 font-public-sans text-lg font-light text-gray-850"
                        elements={bulletedElements || []}
                      />
                    );
                  }
                  case Type.PullQuote:
                    return (
                      <PullQuote
                        key={contentElement.id}
                        fields={contentElement.fields}
                        className="!pl-4 font-public-sans text-lg font-light text-gray-850"
                      />
                    );
                  case Type.Image:
                    return contentElement.relation ? (
                      <ArticleInlineImage
                        key={contentElement.id}
                        fields={contentElement.fields}
                        relation={contentElement.relation}
                        className="font-public-sans text-base font-normal"
                      />
                    ) : null;
                  case Type.ListNumbered: {
                    const numberedElements = getResolvedArticleElements(
                      contentElement.children,
                      elements
                    );
                    return (
                      <ListNumbered
                        key={contentElement.id}
                        className="font-public-sans text-lg font-light text-gray-850"
                        elements={numberedElements || []}
                      />
                    );
                  }
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default function AccordionProcessor({
  className,
  elements,
  AccordionElement,
  elementsClass,
  accordionHeaderClass,
  isColumnDisplay = false,
}: AccordionProcessorProps): React.ReactElement {
  const [expandedItems, setExpandedItems] = React.useState<
    Record<string, boolean>
  >({});

  const processElements = (AccordionElement: Element[]) => {
    const processedAccordions: Array<{
      header: Element;
      content: Element[];
    }> = [];

    let currentHeader: Element | null = null;
    let currentContent: Element[] = [];

    AccordionElement.forEach((element) => {
      if (element.type === Type.AccordionHead) {
        if (currentHeader) {
          processedAccordions.push({
            header: currentHeader,
            content: currentContent,
          });
          currentContent = [];
        }
        currentHeader = element;
      } else if (currentHeader) {
        currentContent.push(element);
      }
    });

    if (currentHeader) {
      processedAccordions.push({
        header: currentHeader,
        content: currentContent,
      });
    }

    return processedAccordions;
  };

  const handleToggle = (key: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const processedData = processElements(AccordionElement);

  return (
    <div
      className={cn("space-y-4", className)}
      data-story-element={Type.Accordion}
      data-testid="accordion-processor-component"
    >
      {processedData.map(({ header, content }) => (
        <Accordion
          key={header.id}
          rootClassName="text-gray-850"
          isExpanded={expandedItems[header.id] || false}
          rootChildrenClassName="!bg-transparent !px-0 before:!h-0 after:!h-0"
          id={header.id}
          renderHeader={() => (
            <AccordionHead
              element={header}
              accordionHeaderClass={accordionHeaderClass}
              handleDrawer={handleToggle}
              isExpanded={expandedItems[header.id] || false}
            />
          )}
        >
          <AccordionBody
            element={content}
            elements={elements}
            elementsClass={elementsClass}
            isColumnDisplay={isColumnDisplay}
          />
        </Accordion>
      ))}
    </div>
  );
}
