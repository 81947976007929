import { Link } from "react-router-dom";
import logoSCA from "@assets/events-awards/logo-sca.png";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import {
  EVENTS_AWARDS_SCA_PATH,
  scaNavigationMenu,
} from "@events-awards/utils/constants";

export default function EventsAwardsSCALogoMenu(): React.ReactElement {
  return (
    <>
      <Link to={EVENTS_AWARDS_SCA_PATH}>
        <img
          src={logoSCA}
          alt="Singapore Corporate Awards logo"
          width={120}
          height={162}
          className="mx-auto"
        />
      </Link>

      <EventsAwardsNavigationMenu
        menuData={scaNavigationMenu}
        linkClassName="after:bg-eventAwards-sca-accent"
      />
    </>
  );
}
