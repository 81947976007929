import { HTMLAttributes } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { getKickerFromArticleDataObject } from "@util/helpers";

export type RelatedStoriesProps = {
  article: SanitizedSectionArticle;
  index: number;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function RelatedStories({
  article,
  index,
}: RelatedStoriesProps): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });

  const variant: CardVariations = {
    image: {
      position: "right",
      width: "w-1/4",
      extraClass: "order-1 lg:order-2",
    },
    content: {
      width: "w-3/4",
      extraClass: "pl-4 lg:pr-4 lg:pl-0",
    },
    title: {
      color: "text-gray-850",
      size: "text-base lg:text-lg",
    },
  };

  const {
    id,
    title,
    kicker,
    slug,
    media,
    updated,
    edited,
    sections,
    displaySetting,
    blurb,
  } = article;
  const sectionUniqueName = sections?.[0]?.uniqueName;

  return (
    <div ref={ref}>
      <BasicCard
        key={id}
        id={id}
        title={title}
        slug={slug}
        media={media}
        blurb={blurb}
        kicker={
          kicker?.fields?.[0]?.value || getKickerFromArticleDataObject(article)
        }
        variations={variant}
        rootClassName="py-4"
        updated={updated}
        edited={edited}
        hasUpdatedTimestampDisplay={displaySetting?.hasUpdatedTimeDisplayed}
        {...(sectionUniqueName
          ? {
              defaultImage: {
                directoryName: sectionUniqueName,
              },
            }
          : undefined)}
      />
    </div>
  );
}
