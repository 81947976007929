import { events } from "@pages/Thrive/constants";

import ThrivePastEventsItem from "./ThrivePastEventsItem";

type ThrivePastEventsProps = {
  scrollToRef: React.RefObject<HTMLDivElement>;
};

export default function ThrivePastEvents({
  scrollToRef,
}: ThrivePastEventsProps): React.ReactElement {
  return (
    <div
      className="px-5 pb-12 pt-8 font-poppins md:px-0"
      id="past-events"
      ref={scrollToRef}
    >
      <h3 className="flex justify-center py-12 font-inter text-10xl font-normal text-gray-850 lg:border-none lg:py-0 lg:pb-12">
        Past events<span className="text-green-400">.</span>
      </h3>

      <div>
        {events.map(({ title, link, date, linkText }, index) => (
          <ThrivePastEventsItem
            key={`${title} - ${index}`}
            title={title}
            link={link}
            date={date}
            linkText={linkText}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}
