import { useState } from "react";
import { ArticleDataObject } from "@app/types/Cue";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";

export const useLoadMoreEventsNewsByKeywords = (
  initialList: ArticleDataObject[],
  pageSize: number,
  uniqueCategoryQuery: string,
  parentCategory: string
) => {
  const [articleList, setMoreArticlesList] = useState(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length >= pageSize
  );

  const handleLoadMore = async () => {
    const page = articleList.length / pageSize + 1;

    if (articleList.length === 0) return;

    const newMoreArticles = await fetchMoreStoriesData(
      uniqueCategoryQuery,
      parentCategory,
      page
    );

    if (newMoreArticles.length < pageSize) setHasMoreArticles(false);

    setMoreArticlesList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};
