import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { SanitizedSectionArticle } from "@app/types/Cue";

export const useLoadMoreArticles = (
  initialList: SanitizedSectionArticle[],
  size: number,
  fetchArticles: () => Promise<SanitizedSectionArticle[]>
) => {
  const [articleList, setArticleList] =
    useState<SanitizedSectionArticle[]>(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length === size
  );
  const [clickableHandleLoadMore, setClickableHandleLoadMore] =
    useState<boolean>(true);

  const handleLoadMore = async () => {
    if (initialList.length === 0) return;
    setClickableHandleLoadMore(false);
    const newMoreArticles = await fetchArticles();

    if (newMoreArticles.length < size) setHasMoreArticles(false);

    setArticleList((prev) => [...prev, ...newMoreArticles]);
    setClickableHandleLoadMore(true);
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (!inView) return;
      if (!hasMoreArticles) return;

      handleLoadMore();
    },
  });

  return {
    articleList,
    handleLoadMore,
    hasMoreArticles,
    ref,
    clickableHandleLoadMore,
  };
};
