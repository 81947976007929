import { HTMLAttributes, PropsWithChildren } from "react";
import { OKTAUserType } from "@store/useOKTAUserStore";
import { SanitizedSectionArticle, Tag } from "@typings/Cue";

export enum MyAccountTabsEnum {
  "myBT" = "myBT",
  "recent" = "Recently Read",
}

export type ReadArticleObject = {
  id: string;
  dateRead?: string;
};

export type useGetRecentArticleListProps = {
  articleIdList: ReadArticleObject[];
  size?: number;
};

export type useGetMyBTArticleListProps = {
  tags: Tag[];
  tagSize?: number;
  size?: number;
};

export type myBTTagsWithArticle = {
  tag: Tag;
  articles: SanitizedSectionArticle[];
};

export type MyAccountActiveProps = {
  activeTab: MyAccountTabsEnum;
};

export type MyAccountTabsProps = {
  activeTab: MyAccountTabsEnum;
  handleTabChange: React.Dispatch<React.SetStateAction<MyAccountTabsEnum>>;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export type MyAccountPopoverMyBTProps = {
  firstCharOfLogin?: string;
};

export type MyAccountMyBTAccordionProps = {
  title: string;
} & PropsWithChildren &
  Pick<HTMLAttributes<HTMLDivElement>, "className">;

export type MyAccountMyBTPageListProps = {
  tagWithArticleList: myBTTagsWithArticle[];
  loadMoreRef?: (node?: Element | null | undefined) => void;
};

export type MyAccounMyBTPopoverListProps = {
  tagWithArticleList: myBTTagsWithArticle[];
};

export type MyAccountMyBTBasicCardProps = {
  article: SanitizedSectionArticle;
};

export type MyAccountMyBTTagFilterProps = {
  tagList: string[];
  activeFilter: string;
  setActiveFilter: (tagName: string) => void;
};

export type MyAccountRecentReadPopoverProps = {
  articleList: SanitizedSectionArticle[];
  displayViewMore?: boolean;
};

export type MyAccountRecentReadBasicCardPopoverProps = {
  article: SanitizedSectionArticle;
};

export type MyAccountRecentReadProps = {
  articleList: SanitizedSectionArticle[];
  displayViewMore?: boolean;
  loadMoreRef?: (node?: Element | null | undefined) => void;
  handleDeleteArticle: (articleId: string) => void;
};

export type MyAccountRecentReadBasicCardProps = {
  article: SanitizedSectionArticle;
  handleDeleteArticle: (articleId: string) => void;
};

export type MyAccountPageSettingProps = {
  oktaUserInfo?: OKTAUserType;
};

export type GaObject = {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  user_keyword?: string;
};
