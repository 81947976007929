import { Link } from "react-router-dom";
import logoDTA from "@assets/events-awards/logo-dta.png";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import {
  dtaNavigationMenu,
  EVENTS_AWARDS_DTA_PATH,
} from "@events-awards/utils/constants";

export default function EventsAwardsDTALogoMenu(): React.ReactElement {
  return (
    <>
      <Link to={EVENTS_AWARDS_DTA_PATH}>
        <img
          src={logoDTA}
          alt="Design AI and Tech Awards logo"
          width={120}
          height={162}
          className="mx-auto"
        />
      </Link>

      <EventsAwardsNavigationMenu
        menuData={dtaNavigationMenu}
        linkClassName="after:bg-eventAwards-dta-accent"
      />
    </>
  );
}
