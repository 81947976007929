import { RouteFactory } from "@src/app/routePaths";

const refObject = [
  {
    urlPath: RouteFactory.keywordsBudget2025,
    ref: "more-budget2025-stories",
  },
];

export const getUrlPathWithRefParams = (
  parentPath: string,
  childPath: string
) => {
  const refParams = refObject.find((obj) => obj.urlPath === parentPath)?.ref;

  if (refParams) {
    return `${childPath}?ref=${refParams}`;
  }

  return childPath;
};
