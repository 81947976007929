import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { watchSupplementsTestId } from "@pages/Hub/utils/constants";
import { TagType } from "@pages/Section/types";
import { cn } from "@util/helpers";

export type HubWatchSupplementsArticlesProps = {
  articles: SanitizedSectionArticle[];
} & Pick<React.HTMLAttributes<HTMLDivElement>, "className">;

export default function HubWatchSupplementsArticles({
  articles,
  className,
}: HubWatchSupplementsArticlesProps): React.ReactElement {
  return (
    <>
      {articles.length > 0 ? (
        <div className={cn(className)} data-testid={watchSupplementsTestId}>
          <BlockTitle
            text="WATCH SUPPLEMENTS"
            rootClassName="text-lg antialiased mb-4"
          />

          <div className="stories divide-y divide-gray-175">
            {articles.map(
              (
                { title, id, kicker, media, sections, urlPath, tags },
                index
              ) => {
                return (
                  <BasicCard
                    rootClassName={cn("story pt-6 pb-6", {
                      "pt-0": index === 0,
                      "pb-0": index === articles.length - 1,
                    })}
                    key={id}
                    id={id}
                    title={title}
                    kicker={kicker?.fields?.[0].value}
                    media={media?.[0]}
                    slug={urlPath}
                    defaultImage={{
                      directoryName: sections?.[0]?.uniqueName || "",
                    }}
                    storyThread={tags.find(
                      ({ type }) => type === TagType.StoryThread
                    )}
                    variations={{
                      image: {
                        position: "right",
                        width: "w-120px md:w-3/10",
                        extraClass: "order-1 md:order-2",
                      },
                      content: {
                        width: "w-full-120px md:w-7/10",
                        extraClass: "order-2 pl-4 md:order-1 md:pr-4 md:pl-0",
                      },
                      title: {
                        size: "text-base",
                        color: "text-gray-850",
                      },
                      kicker: {
                        color: "text-gray-550",
                      },
                    }}
                  />
                );
              }
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
