import { Media } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import { textToSlug } from "@util/helpers";

export default function VideoCard({
  id,
  name,
  images,
}: BrightcoveVideo): React.ReactElement {
  const media: Media = {
    content: {
      type: "picture",
      fields: {
        landscape: {
          url: images.poster.src,
        },
      },
    },
  };

  const variant: CardVariations = {
    image: {
      position: "",
      width: "w-full",
      isVideo: true,
      playIconPosition: "lower-left",
      aspectRatio: "aspect-video",
      loading: "eager",
    },
    content: {
      width: "w-full",
      extraClass: "pt-2",
    },
    title: {
      color: "text-gray-850",
      size: "text-xl",
    },
  };

  return (
    <BasicCard
      id={id}
      slug={`/videos/${textToSlug(name)}/${id}`}
      title={name}
      variations={variant}
      media={media}
      rootClassName="flex-col"
    />
  );
}
