import { useState } from "react";
import { MyAccountMyBTPageListProps } from "@pages/MyAccount/utils/types";

import MyAccountMyBTCardList from "./MyAccountMyBTCardList";
import MyAccountMyBTTagFilter from "./MyAccountMyBTTagFilter";

export default function MyAccountMyBTPageList({
  tagWithArticleList,
  loadMoreRef,
}: MyAccountMyBTPageListProps): React.ReactElement {
  const tagName = tagWithArticleList.map((tag) => tag.tag.name);

  const allTagNameList = ["All", ...tagName];

  const [activeFilter, setActiveFilter] = useState("All");
  const [tagWithArticleListFiltered, setTagWithArticleListFiltered] =
    useState(tagWithArticleList);

  const handleSetActiveFilter = (filter: string) => {
    setActiveFilter(filter);

    if (filter === "All") {
      setTagWithArticleListFiltered(tagWithArticleList);
    } else {
      setTagWithArticleListFiltered(
        tagWithArticleList.filter((tag) => tag.tag.name === filter)
      );
    }
  };

  return (
    <div data-testid="my-account-my-bt-page-list-component">
      <MyAccountMyBTTagFilter
        tagList={allTagNameList}
        activeFilter={activeFilter}
        setActiveFilter={(tagName) => {
          handleSetActiveFilter(tagName);
        }}
      />

      <div className="pt-8">
        {tagWithArticleListFiltered.map(({ tag, articles }, index) => {
          return (
            <div key={tag.id}>
              <MyAccountMyBTCardList
                key={tag.id}
                tag={tag}
                articles={articles}
              />

              {index + (1 % 10) === 0 ? <div ref={loadMoreRef} /> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}
