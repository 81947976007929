import { BlockTitle } from "@blocks/Blocks";
import { type RegisteredComponent } from "@builder.io/sdk-react";
import Heading from "@components/Builder/Heading";

// TODO: Add more components here.
export const customComponents: RegisteredComponent[] = [
  {
    component: BlockTitle,
    name: "BlockTitle",
    inputs: [
      {
        name: "text",
        type: "string",
        defaultValue: "This is the block title",
      },
    ],
  },
  {
    component: Heading,
    name: "Heading",
    inputs: [
      {
        name: "text",
        type: "string",
        defaultValue: "Enter your heading text here...",
      },
      {
        name: "level",
        type: "string",
        enum: ["1", "2", "3", "4", "5", "6"],
        defaultValue: "1",
      },
    ],
  },
];
