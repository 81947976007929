import { WebFetcher } from "@app/data";
import { MyBTOnboardContext } from "@app/types/Page";
import { queryByKeywords } from "@caas/queries/queryByKeywords";
import { queryCaasOpenSearch } from "@caas/queryCaasOpenSearch";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import { myBTRegionsKeywords, myBTStocksKeywords } from "@pages/MyBT/constants";
import { MyBtSearchResponse } from "@pages/MyBT/types";
import { defaultSourceForListing } from "@pages/Section/constants";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import axios, { HttpStatusCode } from "axios";

export const myBTOnboardRequests = {
  fetchMyBTStocksArticles: async () => {
    const urlPaths = myBTStocksKeywords.map((keyword) => keyword.urlPath);
    const queries = urlPaths.map((urlPath) =>
      queryByKeywords(urlPath, 3, 0, defaultSourceForListing)
    );
    const responses = await Promise.all(
      queries.map((query) => queryCaasOpenSearch(query))
    );
    const allHits = responses.map((response) => response.payload?.hits.hits);
    const articleContexts = allHits?.map((hits) =>
      hits?.map((article) => {
        const articleContext = article._source.data.context;
        return { ...articleContext, slug: articleContext.urlPath };
      })
    );

    const myBTStocksArticles = articleContexts.reduce(
      (total, currentValue, index) => {
        return {
          ...total,
          [myBTStocksKeywords[index].urlPath || ""]: { body: currentValue },
        };
      },
      {}
    );

    return myBTStocksArticles;
  },

  fetchMyBTRegionsArticles: async () => {
    const urlPaths = myBTRegionsKeywords.map((keyword) => keyword.urlPath);
    const queries = urlPaths.map((urlPath) =>
      queryByKeywords(urlPath, 3, 0, defaultSourceForListing)
    );
    const responses = await Promise.all(
      queries.map((query) => queryCaasOpenSearch(query))
    );
    const allHits = responses.map((response) => response.payload?.hits.hits);
    const articleContexts = allHits?.map((hits) =>
      hits?.map((article) => {
        const articleContext = article._source.data.context;
        return { ...articleContext, slug: articleContext.urlPath };
      })
    );

    const myBTRegionsArticles = articleContexts.reduce(
      (total, currentValue, index) => {
        return {
          ...total,
          [myBTRegionsKeywords[index].urlPath || ""]: { body: currentValue },
        };
      },
      {}
    );

    return myBTRegionsArticles;
  },

  fetchMyBtSearchKeywords: async (search: string) => {
    const response = await axios.post<MyBtSearchResponse>(
      "/_plat/api/v1/mybt/search",
      { tagName: search }
    );
    return response.status === HttpStatusCode.Ok ? response.data : [];
  },
};

export const fetchMyBTOnboardData: WebFetcher<
  TRouteWithRedirect<MyBTOnboardContext, string>
> = async () => {
  const [myBTStocksArticles, myBTRegionsArticles] = await Promise.all([
    myBTOnboardRequests.fetchMyBTStocksArticles(),
    myBTOnboardRequests.fetchMyBTRegionsArticles(),
  ]);

  return {
    type: ResponseType.SUCCESS,
    statusCode: 200,
    payload: {
      kind: "static",
      data: {
        title: renderPageTitleV2({
          kind: "static",
          title: "myBT Onboard",
        }),
        myBTStocksData: myBTStocksArticles ?? {},
        myBTRegionsData: myBTRegionsArticles ?? {},
      },
    },
  };
};
