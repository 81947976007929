import { SanitizedPulseData } from "@pages/Pulse/utils/types";
import {
  FetchSearchDataProps,
  FilteringSortEnum,
  RecentSearchObject,
  SearchSelectedFacetedObj,
} from "@pages/Search/utils/types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export const getFacetedKeyAndValue = (
  selectedFacetedData: SearchSelectedFacetedObj[]
) => {
  const facetedkey = selectedFacetedData
    .map((facetedObj) => facetedObj.facetedKey)
    .filter((key) => key !== undefined)
    .join("|");

  const facetedvalue = selectedFacetedData
    .map((facetedObj) => facetedObj.facetedValue)
    .filter((key) => key !== "" && key !== undefined)
    .join("|");

  return { facetedkey, facetedvalue };
};

export const getQuerylyParmsList = ({
  query,
  sortBy,
  selectedFacetedAllData,
  endindex,
}: FetchSearchDataProps) => {
  const batchsize = 10;

  const { facetedkey, facetedvalue } = getFacetedKeyAndValue(
    selectedFacetedAllData || []
  );

  const list = {
    query,
    endindex: endindex || 0,
    batchsize: batchsize,
    sort: sortBy || FilteringSortEnum.Relevance,
  };

  if (facetedkey && facetedvalue) {
    const facetedList = {
      facetedkey,
      facetedvalue,
    };

    const newList = Object.assign(list, facetedList);
    return newList;
  }

  return list;
};

/**
 * Filters the recent search queries to include only the first 20 entries and ensures that the
 * specified search query is at the beginning of the list if it exists.
 *
 * @param {RecentSearchObject} searchQuery - The search query object to be filtered.
 * @param {RecentSearchObject[]} searchList - The list of recent search query objects.
 * @returns {RecentSearchObject[]} - The filtered list of recent search queries with the specified
 * search query at the beginning if it exists.
 */
export const filteredRecentSearchQuery = (
  searchQuery: RecentSearchObject,
  searchList: RecentSearchObject[]
) => {
  const filterActiveKeywords = searchList.filter(
    ({ query }) => query === searchQuery.query
  );

  if (filterActiveKeywords.length <= 0)
    return filterActiveKeywords.slice(0, 10);

  // filter out the articleId from the list
  const filteredArticles = searchList.filter(
    ({ query }) => query !== searchQuery.query
  );

  const firstFilteredArticle = filterActiveKeywords?.[0];

  // add it at the beginning
  filteredArticles.unshift(firstFilteredArticle);

  return filteredArticles.slice(0, 10);
};

/**
 * Extracts unique keywords from an array of pulse articles.
 *
 * @param {SanitizedPulseData[]} pulseArticles - An array of sanitized pulse data objects.
 * @returns {string[]} An array of unique keywords extracted from the pulse articles.
 */
export const getKeywordsFromPulseData = (
  pulseArticles: SanitizedPulseData[]
) => {
  const keywords = pulseArticles
    .map((article) => article.keyword)
    .filter(function (item, pos, self) {
      return self.indexOf(item) == pos && item !== undefined && item !== "";
    });

  return keywords as string[];
};
