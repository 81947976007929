import { KeyValuePair } from "@typings/Cue";

import { AnnotationComponentFactory } from "./utils/helpers";
import { Annotation } from "./utils/types";

export type AnnotationWrapperProps = {
  children: React.ReactNode;
  annotations: Annotation[];
  parameters?: KeyValuePair[];
};

export default function AnnotationWrapper({
  children,
  annotations,
  parameters,
}: AnnotationWrapperProps) {
  return annotations.reduce((children, annotation) => {
    const AnnotationComponent = AnnotationComponentFactory(annotation.name);

    return (
      <AnnotationComponent
        element={annotation.reference}
        parameters={parameters}
      >
        {children}
      </AnnotationComponent>
    );
  }, children);
}
