import type { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useSetPageParam } from "@hooks/useSetPageParam";

const variant: CardVariations = {
  image: {
    position: "right",
    width: "w-4/10 md:w-2/10 lg:w-3/20",
    extraClass: "order-3",
  },
  content: {
    width: "w-6/10 md:w-8/10 lg:17/20",
    extraClass: "pr-4",
    blurb: {
      extraClass: "!font-poppins text-gray-850",
    },
  },
  kicker: {
    color: "text-gray-515",
    extraClass: "font-poppins text-4xs",
  },
  title: {
    color: "!text-gray-850",
    size: "text-xs",
    extraClass:
      "font-poppins font-semibold !leading-normal hover:text-verticals-ge-hover",
  },
};

export default function GlobalEnterpriseListingStories({
  uniqueName,
  article,
  index,
}: {
  uniqueName: string;
  article: SanitizedSectionArticle;
  index: number;
}): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });

  return (
    <div data-testid="global-enterprise-listing-stories" ref={ref}>
      <BasicCard
        rootClassName="text-gray-850"
        id={article.id}
        slug={article.urlPath}
        media={article.media}
        title={article.displaySetting?.displayHeadline || article.title}
        kicker={article.kicker?.fields?.[0]?.value}
        blurb={article.blurb}
        paywall={article.paywall?.contentAccess === "1"}
        variations={variant}
        created={article.created}
        edited={article.edited}
        updated={article.updated}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        defaultImage={{ directoryName: uniqueName }}
      />
    </div>
  );
}
