import { ConfigObj } from "@typings/Cue";
import { ConfigSizeEnums } from "@typings/enums";

/**
 * Returns the appropriate screen iframe size configuration based on the screen size.
 *
 * @param {ConfigObj} data - The configuration object containing size settings for different screen types.
 * @param {boolean} isScreenLG - Flag indicating if the screen is large (desktop).
 * @param {boolean} isScreenMD - Flag indicating if the screen is medium (tablet).
 * @returns {object} The screen size configuration object with width and height properties.
 *                    If no specific configuration is found, returns a default configuration with width and height set to "100%".
 */
export const getScreenIframeSize = (
  data: ConfigObj,
  isScreenLG: boolean,
  isScreenMD: boolean
) => {
  const desktopD = data[ConfigSizeEnums.desktop];
  const mobileD = data[ConfigSizeEnums.mobile];
  const tabletD = data[ConfigSizeEnums.tablet];

  const defaultConfig = {
    width: "100%",
    height: "100%",
  };

  if (!desktopD && !mobileD && !tabletD) {
    return defaultConfig;
  }

  if (isScreenLG) {
    return desktopD || defaultConfig;
  }

  if (isScreenMD) {
    return tabletD || mobileD || desktopD || defaultConfig;
  }

  return mobileD || tabletD || desktopD || defaultConfig;
};
