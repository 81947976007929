import { GeData } from "@pages/SingaporeGe/utils/types";

import SingaporeGe2025 from "./2025/SingaporeGe2025Page";
import { isGe2025Data } from "./utils/helpers";

export const SingaporeGeLayoutFactory = (data: GeData) => {
  if (isGe2025Data(data)) {
    return SingaporeGe2025;
  }
};
