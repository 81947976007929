import { RouteFactory } from "@app/routePaths";
import SMELogo from "@assets/logo-sme.svg";
import SectionMasthead from "@components/SectionMasthead/SectionMasthead";

export default function SMEBanner(): React.ReactElement {
  return (
    <div className="my-6 flex w-full flex-wrap items-center justify-center gap-2 border-b border-gray-850 pb-2 md:justify-between">
      <SectionMasthead
        link={RouteFactory.sgsme}
        masthead={{
          alt: "SGSME logo",
          src: SMELogo,
          width: 126,
          height: 31,
        }}
      />
    </div>
  );
}
