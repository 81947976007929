import { useInView } from "react-intersection-observer";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { CommonGaObject } from "@typings/Common";

export type SendInViewGTMProps = {
  eventsObject?: CommonGaObject;
};

export const useSendInViewGTM = ({ eventsObject }: SendInViewGTMProps) => {
  const sendDataToGTM = useGTMDispatch();
  const { ref } = useInView({
    threshold: 0.2,
    triggerOnce: true,
    onChange: (inView) => {
      if (!inView) return;
      if (!eventsObject) return;

      sendDataToGTM(eventsObject);
    },
  });

  return { ref };
};
