import { cn } from "@util/helpers";

type SectionHeadingProps = {
  /** The classname to be added to the section heading. */
  className?: string;
  /** The text to be displayed. */
  text: string;
  /** Flag to hide/show the border bottom. */
  hasBorder?: boolean;
};

/** The section heading that is displayed on top of each section. */
export default function SectionHeading({
  className,
  text,
  hasBorder = false,
}: SectionHeadingProps): React.ReactElement {
  return (
    <h1
      className={cn(
        "pb-3 pt-6 font-playfair text-6xl font-semibold text-gray-850 md:text-8xl",
        { "border-b border-gray-850": hasBorder },
        className
      )}
    >
      {text}
    </h1>
  );
}
