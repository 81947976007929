import { useEffect, useState } from "react";

const useDelayedComponents = (waitBeforeShow: number) => {
  const [isShown, setIsShown] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
      setIsTimeout(true);
    }, waitBeforeShow);

    return () => {
      clearTimeout(timer);
    };
  }, [waitBeforeShow]);

  const handleSetIsShown = (value: boolean) => {
    setIsShown(value);
  };

  return {
    isShown,
    handleSetIsShown,
    isTimeout,
  };
};

export { useDelayedComponents };
