import React from "react";
import { PulsePageProps } from "@pages/Pulse/utils/types";

import PulseMobileCarousel from "./components/PulseMobileCarousel/PulseMobileCarousel";

export default function PulseMobilePage({
  sortedPulseData,
  currentSlide,
  currentSlideId,
  setCurrentSlide,
  setCurrentSlideId,
  backToBt = "/",
}: PulsePageProps): React.ReactElement {
  if (sortedPulseData.length === 0) return <></>;

  return (
    <div
      data-testid="pulse-mobile-page"
      className="block h-full w-full lg:hidden"
    >
      <PulseMobileCarousel
        sortedPulseData={sortedPulseData}
        currentSlide={currentSlide}
        currentSlideId={currentSlideId || ""}
        setCurrentSlide={setCurrentSlide}
        setCurrentSlideId={setCurrentSlideId}
        backToBt={backToBt}
      />
    </div>
  );
}
