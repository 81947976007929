import { Fragment, ReactElement } from "react";
import type { NavigationA } from "@components/Header/types";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

import SideMenu from "./SideMenu";

export type ModalProps = {
  isOpen: boolean;
  setIsClose: () => void;
  headerData: NavigationA;
};

export default function SideMenuModal({
  isOpen,
  setIsClose,
  headerData,
}: ModalProps): ReactElement {
  const handleOnClose = () => {
    setIsClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[210000000]"
        onClose={handleOnClose}
        aria-label="side-menu-dialog"
        data-testid={"side-menu-dialog"}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0  bg-black/25" />
        </TransitionChild>

        <div
          className="fixed inset-0 overflow-y-auto"
          data-testid={"side-menu-dialog-container"}
        >
          <TransitionChild
            as={Fragment}
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0 -translate-x-12"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-12"
          >
            <DialogPanel className="w-full transform transition-all lg:max-w-[260px]">
              <SideMenu
                data={headerData}
                isSideMenuOpen={isOpen}
                handleOnClose={handleOnClose}
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
