import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import dayjs from "dayjs";

export type BrightcoveVideoPlaylistItemProps = {
  className?: string;
  video: BrightcoveVideo;
  isActive?: boolean;
  setActiveVideo: React.Dispatch<
    React.SetStateAction<BrightcoveVideo | undefined>
  >;
};

export default function BrightcoveVideoPlaylistItem({
  className,
  video,
  isActive,
  setActiveVideo,
}: BrightcoveVideoPlaylistItemProps): React.ReactElement {
  const thumbnail = video.images.thumbnail.src;
  const title = video.name;
  const duration = dayjs.duration(video.duration).format("mm:ss");

  return (
    <div
      onClick={() => {
        setActiveVideo(video);
      }}
      className={cx("cursor-pointer", className)}
    >
      <div className="relative">
        <img src={thumbnail} width={147} height={83} className="w-full" />

        {isActive ? (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <FontAwesomeIcon icon={faPlay} color="#ffffff" size="xs" />
          </div>
        ) : null}
      </div>

      <time className="mt-2 block font-poppins text-3xs font-light">
        {duration}
      </time>

      <h3 className="mt-1 font-lct text-base font-bold text-gray-850">
        {title}
      </h3>
    </div>
  );
}
