import { RouteFactory } from "@app/routePaths";

/**
 * Returns the appropriate redirection link based on the provided conditions.
 *
 * @param {boolean} isFromAccountPage - Indicates if the redirection is from the account page.
 * @param {boolean} disableButton - Indicates if the button is disabled.
 * @returns {string} - The redirection link.
 */
export const getRedirectionLink = (
  isFromAccountPage: boolean,
  disableButton: boolean
): string => {
  if (isFromAccountPage) {
    return RouteFactory.myAccount;
  }

  if (disableButton) {
    return RouteFactory.myBTIntro;
  }

  return RouteFactory.myBT;
};
