import {
  filteredRecentReadArticles,
  filteredRemovedRecentReadArticles,
} from "@pages/MyAccount/utils/helpers";
import type { ReadArticleObject } from "@pages/MyAccount/utils/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface BtRecentReadStore {
  articleIdList: ReadArticleObject[];
  setArticleId: (article: ReadArticleObject) => void;
  deleteArticleId: (article: ReadArticleObject[]) => void;
}

const useRecentReadStore = create(
  persist<BtRecentReadStore>(
    (set) => ({
      articleIdList: [],
      setArticleId: (article: ReadArticleObject) => {
        set((store) => {
          const updatedArticles = [article, ...store.articleIdList];

          const filteredArticles = filteredRecentReadArticles(
            article,
            updatedArticles
          );

          localStorage.setItem(
            "bt-recent-read",
            JSON.stringify({ articleIdList: filteredArticles })
          );
          return { articleIdList: filteredArticles };
        });
      },
      deleteArticleId: (articleList: ReadArticleObject[]) => {
        set((store) => {
          const updatedArticles = filteredRemovedRecentReadArticles(
            store.articleIdList,
            articleList
          );

          localStorage.setItem(
            "bt-recent-read",
            JSON.stringify({ articleIdList: updatedArticles })
          );
          return { articleIdList: updatedArticles };
        });
      },
    }),
    {
      name: "bt-recent-read", // unique name
      storage: createJSONStorage(() => localStorage), // Specify localStorage as the storage option
    }
  )
);

export default useRecentReadStore;
