import Tooltip from "@components/Tooltip/Tooltip";
import TooltipTriangle from "@components/Tooltip/TooltipTriangle";
import { useDetectWhiteOverlay } from "@hooks/useDetectWhiteOverlay";
import MyAccountUserHeader from "@pages/MyAccount/components/MyAccountHeader/MyAccountUserHeader";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn } from "@util/helpers";

export default function MyAccountHeader(): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const displaySubscribe =
    OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER;

  const { isOverlayDisplay } = useDetectWhiteOverlay();

  return (
    <div
      className="relative flex-none"
      data-testid="my-account-header-component"
    >
      <div className={cn("relative z-10", { "lg:mr-4": displaySubscribe })}>
        <MyAccountUserHeader />
      </div>

      <div className="absolute right-0 z-[10000]">
        {!isOverlayDisplay ? (
          <Tooltip
            className="top-3"
            cookieName="my-account-login-tooltip"
            text="Update: You can now access myBT under your profile!."
            topTriangle={
              <TooltipTriangle
                className={cn(
                  "absolute -top-2 right-[6px] h-0 w-0 border-b-[15px] border-l-[10px] border-r-[10px] border-b-gray-850 border-l-transparent border-r-transparent",
                  { "right-[22px]": displaySubscribe }
                )}
              />
            }
          />
        ) : null}
      </div>
    </div>
  );
}
