import { useState } from "react";
import Container from "@components/Container/Container";
import SeoFooterList from "@components/Footer/SeoFooter/components/SeoFooterList";
import SeoFooterTabsFiliter from "@components/Footer/SeoFooter/components/SeoFooterTabsFiliter";
import { SeoFooterProps } from "@components/Footer/utils/types";
import { cn } from "@util/helpers";

/**
 * SeoFooter component to display SEO footer links and tabs.
 * @param {SeoFooterProps} props - The component props.
 * @param {Array} props.data - The data for the SEO footer.
 * @param {string} [props.className] - Additional class names for styling.
 * @param {string}
 *   [props.testId] - Test ID for testing purposes.
 * @returns {React.ReactElement} - The rendered component.
 */

export default function SeoFooter({
  data,
  className,
  testId = "seo-footer-components",
}: SeoFooterProps): React.ReactElement {
  const dataFirstSecton = data?.[0]?.section || "";
  const [seoTabs, setSeoTabs] = useState<string>(dataFirstSecton);

  if (!data) return <></>;
  if (data.length === 0) return <></>;

  const tabList = data.map((item) => item.section) || [];

  return (
    <Container data-testid={testId} rootClassName={cn("!mt-10", className)}>
      <SeoFooterTabsFiliter
        tabsList={tabList}
        activeFilter={seoTabs}
        setActiveFilter={(tag) => {
          setSeoTabs(tag);
        }}
      />

      <>
        {data.map((item) => {
          return (
            <SeoFooterList
              key={item.section}
              seoList={item.links}
              activeTab={seoTabs}
              section={item.section}
            />
          );
        })}
      </>
    </Container>
  );
}
