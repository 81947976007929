import {
  MyAccountActiveProps,
  MyAccountTabsEnum,
} from "@pages/MyAccount/utils/types";

import MyAccounMyBTPopover from "./MyAccountMyBTPopover/MyAccounMyBTPopover";
import MyAccounRecentReadPopover from "./MyAccountRecentReadPopover/MyAccountRecentReadPopover";

export default function MyAccountPopoverActiveTabs({
  activeTab,
}: MyAccountActiveProps): React.ReactElement {
  if (activeTab === MyAccountTabsEnum.myBT) return <MyAccounMyBTPopover />;

  if (activeTab === MyAccountTabsEnum.recent)
    return <MyAccounRecentReadPopover />;

  return <></>;
}
