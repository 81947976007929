import { RouteFactory } from "@app/routePaths";
import Advertise from "@pages/Content/Advertise";
import FaqPage from "@pages/Content/FaqPage";
import RSSFeedPage from "@pages/Content/RSSFeedPage";
import { DefaultNavigationContext } from "@typings/Page";

export type ContentLayoutProps = Pick<DefaultNavigationContext, "data">;

export default function ContentLayoutFactory({
  data: { urlPath, seoFooterData },
}: ContentLayoutProps): React.ReactElement {
  switch (urlPath) {
    case RouteFactory.advertise:
      return <Advertise seoFooterData={seoFooterData} />;

    case RouteFactory.faq:
      return <FaqPage seoFooterData={seoFooterData} />;

    case RouteFactory.rssFeeds:
      return <RSSFeedPage seoFooterData={seoFooterData} />;

    default:
      return <></>;
  }
}
