import { Fragment } from "react";
import settingIcon from "@assets/icons/icon-setting.svg";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import MyAccountPageSetting from "@pages/MyAccount/components/MyAccountPageSetting";
import { MyAccountPageSettingProps } from "@pages/MyAccount/utils/types";
import { cn } from "@util/helpers";

export default function MyAccountPageSettingPopover({
  oktaUserInfo,
}: MyAccountPageSettingProps): React.ReactElement {
  return (
    <Popover
      className={"group block lg:hidden"}
      data-testid="my-account-setting-popover-component"
    >
      <PopoverButton
        data-testid="my-account-setting-popover-button"
        className={cn(
          "flex h-[42px] w-[42px] items-center justify-center rounded-full border border-gray-175 group-data-[open]:border-gray-850",
          REMOVE_FOCUS_CSS
        )}
        aria-label="setting"
      >
        <img src={settingIcon} alt="Setting Icon" width="24" height="24" />
      </PopoverButton>

      <Transition>
        <TransitionChild
          as={Fragment}
          enter="transition ease duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease duration-150 transform"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0"
        >
          <PopoverPanel
            data-tedstid="my-account-setting-popover-panel"
            anchor={{ to: "bottom end" }}
            className="relative z-50 overflow-hidden rounded-md bg-white p-5 shadow-myAccountPopOver"
          >
            <MyAccountPageSetting oktaUserInfo={oktaUserInfo} />
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  );
}
