/* eslint-disable @typescript-eslint/unbound-method */

import { useCallback } from "react";
import { useBeforeUnload, useLocation } from "react-router-dom";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useIdleTimer } from "@karltud123/react-idle-timer";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import {
  IDLE_TIMER_TIMEOUT,
  SAMPLE_ARTICLES_STG,
  TOP_100_ARTICLES_PRD,
} from "./utils/constants";

dayjs.extend(duration);

export default function IdleTimer(): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const { pathname } = useLocation();
  const { getTotalIdleTime } = useIdleTimer({ timeout: IDLE_TIMER_TIMEOUT });

  const beforeClosing = useCallback(() => {
    // List of articles to enable timer.
    const urlList = [...SAMPLE_ARTICLES_STG, ...TOP_100_ARTICLES_PRD];

    // Check if the current URL is in the list and return if not.
    if (!urlList.includes(pathname)) return;

    // Get the total idle time in milliseconds.
    const totalIdleTime = dayjs.duration(getTotalIdleTime()).asMilliseconds();

    // Send the total idle time to Google Tag Manager.
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "idle time",
      eventAction: totalIdleTime,
      eventLabel: pathname,
      nonInteraction: false,
    });
  }, [getTotalIdleTime, pathname, sendDataToGTM]);

  // Call the beforeClosing function when the user tries to close/reload the tab.
  useBeforeUnload(beforeClosing);

  return <></>;
}
