import Button from "@components/Button/Button";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { CommonClassNameAndTestId } from "@typings/Common";
import { cn } from "@util/helpers";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@greglaisph/react-splide/css";

export type SeoFooterTabsFiliterProps = {
  /** list of sections */
  tabsList: string[];
  /** which section is active */
  activeFilter: string;
  /** set section which is active */
  setActiveFilter: React.Dispatch<React.SetStateAction<string>>;
} & CommonClassNameAndTestId;

/**
 * SeoFooterTabsFiliter component to display tabs for filtering SEO footer links.
 * @param {SeoFooterTabsFiliterProps} props - The component props.
 * @param {Array} props.tabsList - The list of tabs.
 * @param {string} props.activeFilter - The currently active tab.
 * @param {Function} props.setActiveFilter - Function to set the active tab.
 * @returns {React.ReactElement} - The rendered component.
 * */

export default function SeoFooterTabsFiliter({
  tabsList,
  activeFilter,
  setActiveFilter,
  testId = "seo-footer-tabs-filter-components",
}: SeoFooterTabsFiliterProps): React.ReactElement {
  const options: Options = {
    perMove: 1,
    pagination: false,
    arrows: false,
    snap: true,
    autoHeight: true,
    autoWidth: true,
    focus: 0,
    omitEnd: true,
    gap: "0.5rem",
    start: 0,
  };

  return (
    <Splide
      options={options}
      hasTrack={false}
      ref={(element) => {
        element?.go(tabsList.indexOf(activeFilter));
      }}
      data-testid={testId}
    >
      <SplideTrack>
        {tabsList.map((tab) => (
          <SplideSlide key={tab}>
            <Button
              className={cn({
                "border-gray-850 bg-gray-850 text-white": activeFilter === tab,
              })}
              testid="seo-footer-individual-tab"
              onClick={() => {
                setActiveFilter(tab);
              }}
            >
              <p className="px-4 text-4xs">{tab}</p>
            </Button>
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
}
