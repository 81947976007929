import { YouTubeEmbed } from "react-social-media-embed";
import FacebookEmbedCustom from "@components/Embeds/FacebookEmbedCustom";
import FlourishEmbedCustom from "@components/Embeds/FlourishEmbedCustom";
import InstagramEmbedCustom from "@components/Embeds/InstagramEmbedCustom";
import TiktokEmbedCustom from "@components/Embeds/TiktokEmbedCustom";
import XEmbedCustom from "@components/Embeds/XEmbedCustom";
import { isThisUrlFrom } from "@util/helpers";

export const SocialEmbedFactory = (source: string) => {
  if (isThisUrlFrom(source, "tiktok.com")) return TiktokEmbedCustom;

  if (isThisUrlFrom(source, "youtube.com")) return YouTubeEmbed;
  if (isThisUrlFrom(source, "youtu.be")) return YouTubeEmbed;

  if (isThisUrlFrom(source, "facebook.com")) return FacebookEmbedCustom;

  if (isThisUrlFrom(source, "twitter.com")) return XEmbedCustom;
  if (isThisUrlFrom(source, "x.com")) return XEmbedCustom;

  if (isThisUrlFrom(source, "instagram.com")) return InstagramEmbedCustom;

  if (isThisUrlFrom(source, "public.flourish.studio"))
    return FlourishEmbedCustom;
};

/**
 * Helper function to get the flourish data source.
 * @param src The flourish SRC added in CUE.
 * @returns {string|undefined} The flourish data source.
 */
export const getFlourishDataSrc = (src: string): string | undefined => {
  const FLOURISH_PATTERN =
    /https:\/\/public\.flourish\.studio\/(visualisation|story)\/(\d+)/;

  const match = src.match(FLOURISH_PATTERN);

  if (!match) return;

  if (match[1] && match[2]) {
    return `${match[1]}/${match[2]}`;
  }

  return;
};

/**
 * Parses the input string as JSON and extracts the 'source' field if available.
 * If the input is not valid JSON, it returns the input as-is.
 *
 * @param {string} [input] - The input string to be parsed.
 * @returns {string | null} - The extracted 'source' if present, the original input if invalid JSON, or null if input is empty.
 */
export function getDataWarapperSource(input?: string) {
  if (!input) return null;
  try {
    const parsed = JSON.parse(input);
    return parsed?.source || input; // Return 'source' if available, otherwise fallback
  } catch (error) {
    return input; // Use as-is if not valid JSON
  }
}

/**
 * Parses the input string as JSON and extracts the 'source' field if available.
 * If the input is not valid JSON or missing 'source', it returns a default object.
 *
 * @param {string} [input] - The input string to be parsed.
 * @returns {{ source: string, size: string } | null} - The extracted object with 'source' and 'size', or null if input is empty.
 */
export function getPodcastSource(input?: string) {
  if (!input) return null;
  try {
    const parsed = JSON.parse(input);
    return parsed?.source ? parsed : { source: input, size: "default" }; // Fallback if JSON is missing source
  } catch (error) {
    return { source: input, size: "default" }; // Use as-is if not valid JSON
  }
}
