import { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { sponsoredContentTestId } from "@pages/Hub/utils/constants";
import { TagType } from "@pages/Section/types";
import { cn } from "@util/helpers";

export type HubSponsoredContentArticlesProps = {
  articles: SanitizedSectionArticle[];
} & Pick<React.HTMLAttributes<HTMLDivElement>, "className">;

export default function HubSponsoredContentArticles({
  articles,
  className,
}: HubSponsoredContentArticlesProps): React.ReactElement {
  return (
    <>
      {articles.length > 0 ? (
        <div className={cn(className)} data-testid={sponsoredContentTestId}>
          <div
            className={cn(
              "stories grid divide-y divide-gray-175",
              "md:grid-cols-2 md:gap-4 md:divide-y-0"
            )}
          >
            {articles.map(
              ({ title, id, kicker, media, urlPath, sections, tags }) => {
                return (
                  <BasicCard
                    rootClassName={cn("story flex-col pt-4 pb-2", "md:p-0")}
                    key={id}
                    id={id}
                    slug={urlPath}
                    title={title}
                    kicker={kicker?.fields?.[0].value}
                    media={media?.[0]}
                    defaultImage={{
                      directoryName: sections?.[0]?.uniqueName || "",
                    }}
                    storyThread={tags.find(
                      ({ type }) => type === TagType.StoryThread
                    )}
                    variations={{
                      image: {
                        position: "",
                        width: "w-full",
                      },
                      content: {
                        width: "w-full",
                        extraClass: "pt-3",
                      },
                      kicker: {
                        color: "text-gray-550",
                      },
                    }}
                  />
                );
              }
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
