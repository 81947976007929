import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import EventsAwardsWinnerPageContent from "@events-awards/EventsAwardsWinnerPageContent";
import {
  EventsAwardsPageContext,
  EventsAwardsWinnersContextData,
} from "@events-awards/utils/types";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

export default function EventsAwardsWinnerPage(): React.ReactElement {
  const { context } =
    useRouteContext<
      TRouteWithRedirect<
        EventsAwardsPageContext<EventsAwardsWinnersContextData>,
        string
      >
    >();

  if (context && context.statusCode !== HttpStatusCode.Ok) {
    // StatusCode is not 200
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }) => {
        if (kind !== "section") {
          return <CustomError statusCode={context.statusCode} />;
        }

        return <EventsAwardsWinnerPageContent {...data} />;
      }}
    </Await>
  );
}
