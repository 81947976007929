import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFirstMountState } from "react-use";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import { Loading } from "@components/LoadMore/LoadMore";
import MetaTags from "@components/MetaTags/MetaTags";
import SearchRecentSearch from "@pages/Search/components/SearchRecentSearch";
import { useGetKeywordsFromPulseData } from "@pages/Search/hooks/useGetKeywordsFromPulseData";
import { useQuerylySearchFetchData } from "@pages/Search/hooks/useQuerylySearchFetchData";
import useRecentSearchStore from "@store/useRecentSearchStore";
import { useDetectAdBlock } from "adblock-detect-react";

import SearchFiltering from "./components/SearchFiltering/SearchFiltering";
import SearchInput from "./components/SearchInput";
import SearchResult from "./components/SearchResult";
import SearchTrendingKeywords from "./components/SearchTrendingKeywords";
import {
  FilteringfacetedObjectEnum,
  FilteringSortEnum,
  SearchPageContentProps,
  SearchSelectedFacetedObj,
} from "./utils/types";

export default function SearchPageContent({
  data: { title, pulseArticles, seoFooterData },
}: SearchPageContentProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const isFirstMount = useFirstMountState();
  const [query, setSearchKeyword] = useState("");
  const [sortBy, setSortBy] = useState<FilteringSortEnum>(
    FilteringSortEnum.Relevance
  );
  const [selectedFacetedAllData, setSelectedFacetedData] =
    useState<SearchSelectedFacetedObj[]>();

  const [searchParams, _setSearchParams] = useSearchParams();
  const searchQuery = useRecentSearchStore((state) => state.searchQuery);
  const keywords = useGetKeywordsFromPulseData({ pulseArticles });

  const {
    fetchSearchData,
    searchData,
    isLoading,
    facetedData,
    ref,
    handleClearSearchData,
  } = useQuerylySearchFetchData({
    query,
    sortBy,
    selectedFacetedAllData,
  });

  const handleSelectedKeyword = useCallback(
    (query: string) => {
      setSearchKeyword(query);
      fetchSearchData({
        query,
        isDefaultEndIndex: true,
        sortBy,
        selectedFacetedAllData,
        isHandleLoadMore: false,
      });
    },
    [fetchSearchData, selectedFacetedAllData, sortBy]
  );

  const handleSearchNewList = (query: string) => {
    setSearchKeyword(query);
    fetchSearchData({
      query: query,
      isDefaultEndIndex: true,
      sortBy,
      selectedFacetedAllData,
      isHandleLoadMore: false,
    });
  };

  const handleSetSortBy = async (sortBy: FilteringSortEnum) => {
    setSortBy(sortBy);
    fetchSearchData({
      query,
      isDefaultEndIndex: true,
      sortBy,
      selectedFacetedAllData,
      isHandleLoadMore: false,
    });
  };

  const handleSelectedFaceted = (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => {
    const tmpFacetedData = selectedFacetedAllData || [];
    const tmpFacetedDataIndex = tmpFacetedData?.findIndex(
      (x) => x.facetedKey === facetedKey
    );

    if (tmpFacetedDataIndex >= 0 && facetedValue === "") {
      tmpFacetedData.splice(tmpFacetedDataIndex, 1);
    } else if (tmpFacetedDataIndex >= 0) {
      tmpFacetedData[tmpFacetedDataIndex].facetedValue = facetedValue;
    } else {
      tmpFacetedData.push({
        facetedKey,
        facetedValue,
      });
    }

    setSelectedFacetedData(tmpFacetedData);

    fetchSearchData({
      query,
      isDefaultEndIndex: true,
      sortBy,
      selectedFacetedAllData: tmpFacetedData,
      isHandleLoadMore: false,
    });
  };

  const handleClearSearch = () => {
    handleClearSearchData();
    setSearchKeyword("");
    setSelectedFacetedData(undefined);
  };

  useEffect(() => {
    if (!isFirstMount) return;
    const query = searchParams.getAll("query")?.[0];

    if (!query) return;

    handleSelectedKeyword(query);
  }, [isFirstMount, searchParams, handleSelectedKeyword]);

  return (
    <div className="w-full" data-testid="search-page-component">
      <AdSettings
        adNames={["lb1", "bn1", "prestitial", "catfish", "abm"]}
        path={RouteFactory.search}
        adTargets={[{ key: "page", value: "listing" }]}
        pageType="static"
      />

      <MetaTags
        title={query ? `Search Results: ${query}` : title}
        description="Read more at The Business Times."
        slug={RouteFactory.search}
      />

      <GAData
        title="Search_Index"
        level2="search"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <div data-testid="search-page-content" id="search-page-component">
        <Ad
          adType="lb1"
          className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
        />

        <Header />

        <Container rootClassName="pt-6 pb-18">
          <div
            id="search-results-container" // This id is used in rendering ads. Dont remove.
            className="mx-auto mb-4 w-full px-0 text-gray-850 md:w-10/12 md:px-3 lg:w-8/12"
          >
            <SearchInput
              value={query}
              searchNewList={(query) => {
                handleSearchNewList(query);
              }}
              handleClearSearchData={handleClearSearch}
            />

            <Loading isLoading={isLoading} />

            {searchData?.length === 0 && !isLoading ? (
              <div className="py-6 lg:pb-0">
                <p className="mb-1 font-poppins text-lg font-semibold lg:text-xl">
                  No results found for {query}
                </p>
                <p className="mb-0 font-poppins text-base lg:text-lg">
                  Make sure all words are spelled correctly or try different
                  keywords.
                </p>
              </div>
            ) : null}

            <>
              {(searchQuery.length > 0 && !searchData) ||
              searchData?.length === 0 ? (
                <SearchRecentSearch
                  searchQuery={searchQuery}
                  setSelectedKeyword={(query: string) => {
                    handleSelectedKeyword(query);
                  }}
                />
              ) : null}
            </>

            <>
              {(keywords.length > 0 && !searchData) ||
              searchData?.length === 0 ? (
                <SearchTrendingKeywords
                  keywords={keywords}
                  setSelectedKeyword={(query: string) => {
                    handleSelectedKeyword(query);
                  }}
                />
              ) : null}
            </>

            <>
              {searchData && searchData.length > 0 ? (
                <>
                  <SearchFiltering
                    sortBy={sortBy}
                    setSortBy={(sortBy) => handleSetSortBy(sortBy)}
                    facetedSearchAllData={facetedData}
                    setSelectedFacetedObj={(
                      facetedKey: FilteringfacetedObjectEnum,
                      facetedValue: string
                    ) => {
                      handleSelectedFaceted(facetedKey, facetedValue);
                    }}
                    selectedFacetedAllData={selectedFacetedAllData}
                  />

                  {searchData.map((result, index) => {
                    return (
                      <SearchResult
                        key={`${result.title}${index}`}
                        result={result}
                        cardCount={index}
                      />
                    );
                  })}

                  <div ref={ref} />
                </>
              ) : null}
            </>
          </div>
        </Container>
      </div>

      <SeoFooter data={seoFooterData} />
      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
