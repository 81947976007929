import React from "react";
import { PulsePageProps } from "@pages/Pulse/utils/types";

import PulseDesktopCarousel from "./components/PulseDesktopCarousel/PulseDesktopCarousel";

export default function PulseDesktopPage({
  sortedPulseData,
  setCurrentSlide,
  currentSlide,
  setCurrentSlideId,
  backToBt = "/",
}: PulsePageProps): React.ReactElement {
  if (sortedPulseData.length === 0) return <></>;

  return (
    <div
      data-testid="pulse-desktop-page"
      className="w-svh hidden h-svh items-center justify-center overflow-x-hidden lg:flex"
    >
      <PulseDesktopCarousel
        sortedPulseData={sortedPulseData}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        setCurrentSlideId={setCurrentSlideId}
        backToBt={backToBt}
      />
    </div>
  );
}
