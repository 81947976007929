import BasicCard from "@components/ArticleCard/BasicCard";
import SplideArrows from "@components/Slider/components/SplideArrows";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { Grid } from "@splidejs/splide-extension-grid";

import "@greglaisph/react-splide/css/core";

import { SkyboxItem } from "./utils/types";

type HomeSkyboxProps = {
  articles: SkyboxItem[];
} & React.HTMLAttributes<HTMLDivElement>;

export default function HomeSkybox({
  className,
  articles,
}: HomeSkyboxProps): React.ReactElement {
  const options: Options = {
    pagination: false,
    type: "slide",
    autoplay: true,
    interval: 10000,
    perMove: 2,
    gap: "1rem",
    focus: 0,
    omitEnd: true,
    mediaQuery: "min",
    perPage: 2,
    fixedWidth: "90%",
    arrows: false,
    grid: { rows: 2, cols: 1, gap: { row: "1rem", col: "1rem" } },
    breakpoints: {
      768: { grid: null, fixedWidth: undefined, arrows: true },
      992: { perPage: 3, perMove: 3 },
      1200: { perPage: 4, perMove: 4 },
    },
  };

  return (
    <>
      {articles.length > 0 ? (
        <div data-testid="home-skybox-component" className={className}>
          <Splide
            options={options}
            extensions={{ Grid }}
            tag="div"
            hasTrack={false}
            className="relative md:pr-9"
          >
            <SplideTrack>
              {articles.map(({ title, meta, link, media, blurb }) => {
                return (
                  <SplideSlide key={link}>
                    <BasicCard
                      id={link}
                      title={title}
                      media={media}
                      kicker={meta}
                      blurb={blurb}
                      slug={link}
                      variations={{
                        image: {
                          position: "left",
                          width: "w-[80px]",
                          aspectRatio: "aspect-square",
                          filters: [{ w: 320, h: 320, dpr: 1, f: "webp" }],
                          orientation: "square",
                        },
                        content: {
                          width: "w-[calc(100%-80px)]",
                          extraClass: "pl-4",
                        },
                        title: {
                          color: "text-gray-850 !line-clamp-3",
                          size: "text-base",
                          extraClass: "font-lct font-bold leading-[1.3125rem]",
                        },
                        kicker: {
                          color: "text-gray-850",
                          extraClass: "tracking-[1px]",
                        },
                      }}
                    />
                  </SplideSlide>
                );
              })}
            </SplideTrack>

            <SplideArrows className="absolute bottom-0 right-0 top-0 flex flex-col items-center justify-center gap-2" />
          </Splide>
        </div>
      ) : null}
    </>
  );
}
