import { Link } from "react-router-dom";
import logoEE from "@assets/events-awards/logo-ee.png";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import { eENavigationMenu } from "@events-awards/EE/utils/constants";
import { EVENTS_AWARDS_EE_PATH } from "@events-awards/utils/constants";

export default function EventsAwardsEELogoMenu(): React.ReactElement {
  return (
    <>
      <Link to={EVENTS_AWARDS_EE_PATH}>
        <img
          src={logoEE}
          alt="EE logo"
          width={120}
          height={162}
          className="mx-auto"
        />
      </Link>

      <EventsAwardsNavigationMenu
        menuData={eENavigationMenu}
        linkClassName="after:bg-eventAwards-ee-accent"
      />
    </>
  );
}
