import { useState } from "react";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import Modal from "@components/Modal/Modal";
import { FilteringByDate } from "@pages/Search/utils/contants";
import {
  facetedObject,
  FilteringfacetedObjectEnum,
  SearchIndividualFilteringProps,
} from "@pages/Search/utils/types";
import { cn } from "@util/helpers";

export default function SearchIndividualModal({
  advanceFilteringDisplayObj,
  facetedSearchData,
  setSelectedFacetedObj,
}: SearchIndividualFilteringProps): React.ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSelectedFaceted = (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => {
    setSelectedFacetedObj(facetedKey, facetedValue);
    setIsModalOpen(false);
  };

  return (
    <div data-testid="search-individual-advance-filtering-modal-component">
      <button
        onClick={() => {
          setIsModalOpen(true);
        }}
        className={cn(
          "relative flex flex-none items-center border border-transparent text-gray-550 transition-all duration-150 hover:text-gray-850",
          REMOVE_FOCUS_CSS
        )}
      >
        <p className="m-0 bg-white font-poppins text-xs leading-[3]">
          {advanceFilteringDisplayObj.value}
        </p>

        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={
            "transition-all duration-150 group-data-[open]:-rotate-180"
          }
        >
          <path
            d="M6 15L12 9L18 15"
            style={{ stroke: "currentcolor" }}
            strokeWidth="1.5"
          />
        </svg>
      </button>

      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dialogPanelClassNames="rounded-[4px] mx-5 max-h-[600px]"
      >
        <div className="px-3 py-6">
          <div className="mb-3 flex items-center justify-between border-b border-gray-175 px-3 pb-3">
            <p className="m-0 bg-white py-2 font-poppins text-xs font-medium leading-[3] text-gray-850">
              {advanceFilteringDisplayObj.value}
            </p>

            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
              className={cn(REMOVE_FOCUS_CSS)}
            >
              <Icon type={IconTypeEnum.close} fill="#2b2b2b" size={24} />
            </button>
          </div>

          {facetedSearchData?.map((item: facetedObject) => {
            return (
              <button
                key={item.key}
                className={cn(
                  "flex w-full px-3 py-2 text-left font-poppins text-xs text-gray-850 hover:bg-gray-250",
                  REMOVE_FOCUS_CSS
                )}
                onClick={() => {
                  handleSelectedFaceted(
                    advanceFilteringDisplayObj.key,
                    item.key.replaceAll("&#039;", "'")
                  );
                }}
              >
                <p className="mb-0 mr-2">
                  {FilteringByDate[item.key] ||
                    item.key.replaceAll("&#039;", "'")}
                </p>
                <p className="mb-0 ml-auto text-gray-515">{item.value}</p>
              </button>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
