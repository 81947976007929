import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { TrendingKeywordsProps } from "@pages/Search/utils/types";
import { cn } from "@util/helpers";

export default function SearchTrendingKeywords({
  keywords,
  setSelectedKeyword,
}: TrendingKeywordsProps): React.ReactElement {
  return (
    <div
      data-testid="search-trending-keywords-component"
      className="mt-6 md:mt-18"
    >
      <div className="mb-6 flex border-b border-gray-175 py-2">
        <p className="font-poppins text-4xs font-semibold uppercase tracking-5% md:text-xs">
          Trending searches
        </p>
      </div>

      <div className={cn("flex w-full flex-wrap")}>
        {keywords.map((keyword) => {
          return (
            <button
              key={keyword}
              onClick={() => {
                setSelectedKeyword(keyword || "");
              }}
              className={cn(
                "mb-4 mr-4 rounded-[4px] border border-gray-175 px-4 py-3 font-poppins font-medium transition-all duration-300 hover:border-gray-850 md:text-lg",
                REMOVE_FOCUS_CSS
              )}
            >
              {keyword}
            </button>
          );
        })}
      </div>
    </div>
  );
}
