import { ReactElement } from "react";
import { MyAccountRecentReadPageEmptyState } from "@pages/MyAccount/utils/constants";

export default function MyAccountRecentReadEmptyState(): ReactElement {
  return (
    <div
      data-testid="my-account-recent-read-page-empty-state-component"
      className="my-15"
    >
      <img
        className="mx-auto mb-8"
        src={MyAccountRecentReadPageEmptyState.src}
        alt="Recent read"
        width={180}
        height={170}
      />

      <p className="mb-0 text-center font-poppins font-semibold text-gray-850">
        {MyAccountRecentReadPageEmptyState.description}
      </p>

      <p className="mb-0 text-center font-poppins text-gray-850">
        {MyAccountRecentReadPageEmptyState.descriptionLine2}
      </p>
    </div>
  );
}
