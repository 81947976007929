export const sponsoredContentTestId =
  "hub-sponsored-content-articles-component";
export const brandedContentTestId = "hub-branded-content-articles-component";
export const propertySupplementsTestId =
  "hub-property-supplements-articles-component";
export const wealthSupplementsTestId =
  "hub-wealth-supplements-articles-component";
export const fintechSupplementsTestId =
  "hub-fintech-supplements-articles-component";
export const watchSupplementsTestId =
  "hub-watch-supplements-articles-component";
export const futureOfFinaceSupplementsTestId =
  "hub-future-of-finance-supplements-articles-component";
