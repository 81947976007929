import { AdUnitNames } from "./types";

export const SMX_PREBID_JS: string = import.meta.env.VITE_ADTAG_SMX_PREBID;

export const adSlotSizes: Record<AdUnitNames, googletag.GeneralSize> = {
  imu1: [["fluid"], [300, 250]],
  imu2: [[300, 250], [300, 600], ["fluid"]],
  imu3: [[300, 250]],
  lb1: [
    [970, 90],
    [728, 90],
    [970, 250],
    [320, 50],
    [320, 100],
    [320, 250],
    ["fluid"],
  ],
  lb2: [
    [970, 90],
    [728, 90],
    [970, 250],
    [320, 50],
    [320, 100],
    [320, 250],
    ["fluid"],
  ],
  prestitial: [1, 1],
  midarticlespecial: [
    [1, 1],
    ["fluid"],
    [640, 480],
    [300, 169],
    [300, 225],
    [300, 250],
    [480, 270],
    [480, 360],
    [640, 36],
  ],
  catfish: [1, 1],
  abm: [1, 1],
  bn1: [["fluid"], [1, 1], [728, 90], [320, 50], [320, 100]],
  bn2: [["fluid"], [1, 1], [728, 90], [320, 50], [320, 100]],
  bn3: [["fluid"], [1, 1], [728, 90], [320, 50], [320, 100]],
  bn4: [["fluid"], [1, 1], [728, 90], [320, 50], [320, 100]],
};

export const adSizeMappings: Partial<
  Record<AdUnitNames, googletag.SizeMappingArray>
> = {
  lb1: [
    [
      [1024, 0],
      [[970, 90], [728, 90], [970, 250], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
  lb2: [
    [
      [1024, 0],
      [[970, 90], [728, 90], [970, 250], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
  bn1: [
    [
      [1024, 0],
      [["fluid"], [1, 1], [728, 90]],
    ],
    [
      [0, 0],
      [["fluid"], [1, 1], [320, 50], [320, 100]],
    ],
  ],
  bn2: [
    [
      [1024, 0],
      [["fluid"], [1, 1], [728, 90]],
    ],
    [
      [0, 0],
      [["fluid"], [1, 1], [320, 50], [320, 100]],
    ],
  ],
  bn3: [
    [
      [1024, 0],
      [["fluid"], [1, 1], [728, 90]],
    ],
    [
      [0, 0],
      [["fluid"], [1, 1], [320, 50], [320, 100]],
    ],
  ],
  bn4: [
    [
      [1024, 0],
      [["fluid"], [1, 1], [728, 90]],
    ],
    [
      [0, 0],
      [["fluid"], [1, 1], [320, 50], [320, 100]],
    ],
  ],
};

export const adPositions: Record<AdUnitNames, string> = {
  abm: "1",
  bn1: "1",
  bn2: "2",
  bn3: "3",
  bn4: "4",
  catfish: "catfish",
  imu1: "1",
  imu2: "2",
  imu3: "3",
  lb1: "1",
  lb2: "1",
  midarticlespecial: "midarticlespecial",
  prestitial: "prestitial",
};

export const adWeights: Record<AdUnitNames, string> = {
  abm: "1",
  bn1: "1",
  bn2: "2",
  bn3: "3",
  bn4: "4",
  catfish: "3",
  imu1: "5",
  imu2: "6",
  imu3: "7",
  lb1: "1",
  lb2: "1",
  midarticlespecial: "4",
  prestitial: "3",
};
