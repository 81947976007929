import { MyAccountPopoverMyBTProps } from "@pages/MyAccount/utils/types";

export default function MyAccountLoginProfile({
  firstCharOfLogin,
}: MyAccountPopoverMyBTProps): React.ReactElement {
  return (
    <>
      <div className="absolute -right-1 top-0 h-1 w-1 rounded-full bg-red" />

      <p className="font-poppins text-lg">{firstCharOfLogin}</p>
    </>
  );
}
