/**
 * Company: SPHMedia
 * Description: Default Section Layout
 */

import { Link, useLocation } from "react-router-dom";
import { getActiveSectionPath } from "@app/components/Breadcrumb/helpers";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import { AdUnitNames } from "@components/Advertisement/utils/types";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import KeywordFilter from "@components/KeywordFilter";
import { KeywordType } from "@components/KeywordFilter/types";
import {
  renderCustomOgImageObject,
  renderMetaDescription,
} from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import ListingPageNewsletter from "@features/newsletter/ListingPageNewsletter";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import { useWindowSize } from "@hooks/useWindowSize";
import ThriveNewsletter from "@pages/Article/components/ArticleNewsletter/ThriveNewsletter";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import Overview from "@pages/Section/components/Overview/Overview";
import PrPageIntroduction from "@pages/Section/components/PrPageIntroduction";
import SectionHeading from "@pages/Section/components/SectionHeading";
import {
  getFormattedTextForAds,
  getSectionHeadingClass,
  getSectionHeadingText,
  getTermPath,
  isEntityTagType,
} from "@pages/Section/helpers";
import type { SectionDefaultProps } from "@pages/Section/types";
import { sectionNavigationItems } from "@util/constant";
import { cn, getSectionJSONLD } from "@util/helpers";

export default function DefaultLayout({
  data: { entity, title, overview, seoFooterData },
}: SectionDefaultProps): React.ReactElement {
  const { pathname } = useLocation();
  const mostPopular = useMostReadArticleList();
  const breakingNews = useTrimmedBreakingNews();
  const { isScreenLG } = useWindowSize();

  const termName = entity.name;
  const termPath = getTermPath(entity) || pathname;
  const slot = isEntityTagType(entity) ? "/keywords" : `/${termPath}`;
  const parentCategoryPath = termPath.split("/")?.[0];
  const childCategoryPath = termPath.split("/")?.[1];
  const activeSectionPath = getActiveSectionPath(pathname, termPath);

  const parentCategoryData = sectionNavigationItems?.[parentCategoryPath];
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();

  const adNames: AdUnitNames[] =
    slot === RouteFactory.paidPressRelease
      ? ["lb1", "imu2", "bn1", "catfish", "abm"]
      : ["lb1", "imu2", "bn1", "prestitial", "catfish", "abm"];

  const ogImageData = renderCustomOgImageObject({
    kind: "section",
    sectionName: entity.name,
  });

  const sectionHeadingClass = getSectionHeadingClass(`/${termPath}`);
  const sectionHeadingText = getSectionHeadingText(
    `/${termPath}`,
    parentCategoryData.label
  );

  return (
    <div className="w-full">
      <AdSettings
        adNames={adNames}
        path={slot}
        adTargets={[
          { key: "page", value: "listing" },
          ...(isEntityTagType(entity)
            ? [{ key: "bttags", value: getFormattedTextForAds(termName) }]
            : []),
        ]}
      />

      <MetaTags
        title={title}
        description={renderMetaDescription({
          kind: "section",
          sectionName: entity.name,
        })}
        ogType="article"
        slug={`/${termPath}`}
        jsonLd={getSectionJSONLD(entity)}
        ogImageUrl={ogImageData.ogImageUrl}
        ogImageAlt={ogImageData.ogImageAlt}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={!termPath.includes("keywords") && isShowBreadCrumb}
        parentCategory={parentCategoryPath}
        childCategory={childCategoryPath}
        categoryDataMapping={sectionNavigationItems}
      />

      {pathname !== RouteFactory.paidPressRelease ? <PulseEntry /> : null}

      {termPath &&
      sectionNavigationItems[termPath] &&
      !termPath.includes("keywords") ? (
        <div className="flex-none">
          <Container>
            <SectionHeading
              text={sectionHeadingText}
              className={sectionHeadingClass}
              hasBorder={!parentCategoryData?.children?.length}
            />

            <div ref={breadcrumbsRef}>
              <Breadcrumb
                className="m-auto"
                parentCategory={parentCategoryPath}
                childCategory={childCategoryPath}
                categoryDataMapping={sectionNavigationItems}
                extraBreadcrumbItems={
                  <div
                    className={cn(
                      "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm tracking-tightest text-gray-850 hover:opacity-80",
                      {
                        "font-bold":
                          activeSectionPath === parentCategoryData?.link,
                      }
                    )}
                  >
                    <Link
                      to={`${parentCategoryData?.link}?ref=listing-menubar`}
                      reloadDocument
                    >
                      Top stories
                    </Link>
                  </div>
                }
              />
            </div>
          </Container>
        </div>
      ) : null}

      <Container>
        <>
          {isEntityTagType(entity) ? (
            <KeywordFilter
              followtextButton
              tag={entity}
              rootClassName="mb-7 py-3 pt-6 inline-block w-full border-b border-gray-850 "
              tagClassName="border-none !font-playfair !text-6xl md:!text-8xl !p-0 text-gray-850 !uppercase !text-left !text-normal"
              toolTipClassName={
                "!right-0 top-full min-[425px]:!left-auto min-[425px]:top-1/2 min-[425px]:flex min-[425px]:-translate-y-1/2 min-[425px]:-translate-x-0 min-[425px]:items-center xl:left-auto xl:top-full xl:block xl:-translate-x-0 xl:translate-y-0 top-full min-[425px]:top-full min-[425px]:!block left-auto w-[200px] xs:w-[250px] xl:translate-x-0"
              }
              toolTipArrowClassName={
                "xl:ml-0 xl:mr-10 md:mr-10 border-b-[8px] border-l-[6px] border-r-[6px] border-x-transparent border-b-black mr-3 min-[425px]:border-r-transparent min-[425px]:!border-b-black min-[425px]:border-t-transparent relative left-[90%] md:left-[80%] mr-0 border-t-transparent ml-0"
              }
              disableTooltip
              keywordType={KeywordType.listing}
            />
          ) : null}
        </>
      </Container>

      <Container rootClassName="pt-6">
        <>
          {pathname === RouteFactory.paidPressRelease ? (
            <PrPageIntroduction />
          ) : null}
        </>

        <Row>
          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <Overview data={overview} testId={"section-listing-component"} />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-6">
            {isScreenLG && pathname.startsWith(RouteFactory.thrive) ? (
              <ThriveNewsletter
                className="mx-auto mb-6 max-w-[300px]"
                tagLine="Straight to your inbox."
                liner="Money, career and life hacks to help young adults stay ahead."
              />
            ) : null}

            {isScreenLG ? (
              <ListingPageNewsletter className="mx-auto mb-6 max-w-[300px]" />
            ) : null}

            <div className="mb-6 lg:h-[800px]">
              <Ad
                adType="imu2"
                className="flex flex-col items-center justify-center lg:!sticky lg:top-18"
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
