import Tooltip from "@components/Tooltip/Tooltip";
import TooltipTriangle from "@components/Tooltip/TooltipTriangle";
import { useDetectParentContainerVisible } from "@hooks/useDetectParentContainerVisible";
import { useDetectWhiteOverlay } from "@hooks/useDetectWhiteOverlay";

export default function MyAccountFactoryAnonTooltip(): React.ReactElement {
  const { isOverlayDisplay } = useDetectWhiteOverlay();
  const { isParentDisplay } = useDetectParentContainerVisible({
    parentId: "#my-account-anon-trigger",
  });

  if (!isOverlayDisplay && isParentDisplay)
    return (
      <Tooltip
        cookieName="my-account-anon-tooltip"
        text={
          "Find your recently read articles and latest followed keyword updates here."
        }
        topTriangle={
          <TooltipTriangle className="absolute -top-2 right-[14px] h-0 w-0 border-b-[15px] border-l-[10px] border-r-[10px] border-b-gray-850 border-l-transparent border-r-transparent lg:right-12" />
        }
      />
    );

  return <></>;
}
