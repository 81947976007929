import { useWindowSize } from "@hooks/useWindowSize";
import MyAccountLoginTrigger from "@pages/MyAccount/components/MyAccountLoginTrigger";
import MyAccountPopoverLogin from "@pages/MyAccount/components/MyAccountPopoverLogin/MyAccountPopoverLogin";

export default function MyAccountHeaderFactoryAnonTriger(): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  if (isScreenLG) return <MyAccountPopoverLogin />;

  return <MyAccountLoginTrigger />;
}
