import { BlockTitle } from "@blocks/Blocks";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import { useSendInViewGTM } from "@pages/SingaporeGe/2025/hooks/useSendInViewGTM";
import { ComingUpProps } from "@pages/SingaporeGe/2025/utils/types";

export default function ComingUp({
  article,
  className,
  testId,
  gaObject,
}: ComingUpProps): React.ReactElement {
  const { ref: comingUpRef } = useSendInViewGTM({
    eventsObject: gaObject?.eventObject,
  });

  const { id, storyLine, elements } = article;

  const parameters = gaObject?.parameters || [];

  return (
    <div data-testid={testId} className={className} ref={comingUpRef}>
      <BlockTitle text="COMING UP" rootClassName="mb-4" />

      {elements && storyLine ? (
        <ArticleParagraphs
          className="md:font-base font-xs font-public-sans"
          {...{
            id: id,
            storyline: storyLine,
            elements: elements,
            isPremium: false,
            elementsClass:
              "mb-2 [&>a]:underline [&>a]:underline-offset-2 text-gray-850",
            parameters: parameters,
            isBoxWrapped: true,
          }}
        />
      ) : null}
    </div>
  );
}
