import { useLocation } from "react-router-dom";
import type { ArticleDataObject, Section } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { useWindowSize } from "@hooks/useWindowSize";
import { TagType } from "@pages/Section/types";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import cx from "classnames";

export default function AuthorListingArticles({
  article,
  index,
}: {
  article: ArticleDataObject;
  index: number;
}): React.ReactElement {
  const { ref } = useSetPageParam({ cardIndex: index });
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  const { isScreenLG, isScreenSM } = useWindowSize();
  const { pathname } = useLocation();
  const sectionName = pathname.substring(pathname.lastIndexOf("/") + 1);
  const isLeadArticle = index === 0;

  const firstImage = article.media?.[0];
  const media = isLeadArticle && !isScreenSM ? undefined : firstImage;

  const kicker = article.kicker?.fields?.[0]?.value;
  let section: Section | undefined;
  const pathArray = pathname.split("/");

  const uniqueName = article.sections?.[0]?.uniqueName || sectionName;

  if (pathArray.length > 1 && pathArray[1] === "keywords") {
    section = article.sections?.[0];
  }
  const variant: CardVariations = {
    kicker: {
      color: cx("text-gray-515"),
    },
    image: {
      width: "w-1/4",
      position: "right",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 600, h: 400, dpr: 1, f: "webp" },
        { w: 300, h: 200, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: "w-3/4",
      extraClass: "pr-4",
      blurb: {
        extraClass: "text-gray-850",
      },
    },
    title: {
      size: "text-base",
      color: "text-gray-850",
    },
  };

  return (
    <div
      className={cx("story border-b border-gray-175", {
        "py-3": !isLeadArticle,
        "pb-3": isLeadArticle,
      })}
      ref={ref}
    >
      <BasicCard
        id={article.id}
        title={article.displaySetting?.displayHeadline || article.title}
        section={section}
        storyThread={article.tags?.find(
          ({ type }) => type === TagType.StoryThread
        )}
        kicker={kicker}
        media={media}
        blurb={isScreenLG ? article.blurb : undefined}
        slug={article.slug}
        paywall={isSubscriber ? article.paywall?.contentAccess === "1" : false}
        variations={variant}
        created={article.created}
        edited={article.edited}
        updated={article.updated}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        defaultImage={{
          directoryName: uniqueName,
        }}
      />
    </div>
  );
}
