import {
  CaaSImageFilters,
  OptimisedImageProps,
} from "@app/types/OptimisedImage";

import { getFormattedCaaSImageUrl } from "./helper";
import PreloadLCPImage from "./PreloadLCPImage";

const defaultFilters: CaaSImageFilters[] = [
  { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
  { w: 300, h: 200, dpr: 1, f: "webp" },
];

export default function OptimisedImage({
  src,
  filters = defaultFilters,
  preloadLCPImage = false,
  ...rest
}: OptimisedImageProps): React.ReactElement {
  const fallbackFilter: CaaSImageFilters = { q: 20, w: 3, h: 2, f: "webp" };
  const fallbackSrc = getFormattedCaaSImageUrl(src, fallbackFilter);
  const imgSrcSets = filters.map(({ breakpoint, ...imageFilters }) => {
    return getFormattedCaaSImageUrl(src, { ...imageFilters });
  });

  return (
    <>
      {preloadLCPImage
        ? imgSrcSets.map((src, index) => {
            return <PreloadLCPImage imageSource={src} key={src + index} />;
          })
        : null}

      <picture>
        {filters.map(({ breakpoint, ...imageFilters }) => {
          const imageDpr = imageFilters.dpr ? `${imageFilters.dpr}x` : "";
          const imageSrcSet = getFormattedCaaSImageUrl(src, {
            ...imageFilters,
          });

          return (
            <source
              key={imageSrcSet + breakpoint}
              media={breakpoint}
              srcSet={`${imageSrcSet} ${imageDpr}`}
            />
          );
        })}

        <img src={fallbackSrc} {...rest} />
      </picture>
    </>
  );
}
