import { useState } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import axios from "axios";

export const useLoadMoreEventsGallery = (
  initialList: SanitizedSectionArticle[],
  pageSize: number,
  awardType: string,
  year: string | undefined
) => {
  const [articleList, setMoreArticlesList] = useState(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length >= pageSize
  );

  const handleLoadMore = async () => {
    const page = articleList.length / pageSize + 1;

    if (articleList.length === 0) return;

    const newMoreArticles = await fetchEventsAwardsGalleryMoreData(
      page,
      awardType,
      year
    );

    if (newMoreArticles.length < pageSize) setHasMoreArticles(false);

    setMoreArticlesList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};

const fetchEventsAwardsGalleryMoreData = async function (
  page: number,
  awardType: string,
  year?: string
): Promise<SanitizedSectionArticle[]> {
  try {
    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/event-awards-gallery-more-stories",
      { page, awardType, year }
    );
    return response.data || [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "fetchEventsAwardsGalleryMoreData: - Error in fetching pulse data",
      error
    );
    return [];
  }
};
