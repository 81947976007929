import { Link } from "react-router-dom";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ThrivePastEventsItemProps } from "./ThrivePastEventsItem";

type ThrivePastEventsButtonProps = Pick<
  ThrivePastEventsItemProps,
  "link" | "linkText"
>;

export default function ThrivePastEventsButton({
  link,
  linkText,
}: ThrivePastEventsButtonProps): React.ReactElement {
  if (typeof link === "undefined") {
    return (
      <div className="border-2 border-orange-400 bg-white px-4 py-4 transition-colors">
        No Photos Available
      </div>
    );
  }

  return (
    <Link
      target="_blank"
      to={link}
      className="flex items-center justify-center gap-2 border-2 border-orange-400 bg-white px-8 py-4 text-gray-850 transition-colors hover:bg-orange-400 hover:text-white sm:px-12 lg:px-10"
    >
      {linkText ? (
        <span className="text-center">{linkText}</span>
      ) : (
        <>
          <FontAwesomeIcon
            icon={faInstagram}
            size="2xl"
            className="h-[27px] w-[26px]"
          />
          <span>View Photos</span>
        </>
      )}
    </Link>
  );
}
