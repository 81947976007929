import { useCallback } from "react";
import { ARTICLE_ERROR_GENERIC } from "@pages/Article/components/ArticleSubshare/constants";
import {
  AlacrityPayload,
  LAMBDA_REQUEST_ACTION_TYPES,
  LambdaResponsePayload,
} from "@pages/Article/types/Alacrity";
import axios from "axios";

export default function useAlacrity() {
  /**
   * Used in Social Sharing buttons
   */
  const generateShortnerURL = useCallback(
    async (articleURL: string): Promise<LambdaResponsePayload | undefined> => {
      const apiPayload: AlacrityPayload = {
        service: "alacrity",
        payload: {
          action: LAMBDA_REQUEST_ACTION_TYPES.GENERATE_SHORTEN_URL,
          data: {
            articleURL,
          },
        },
      };

      let responsePayload;
      try {
        const response = await axios.post("/_plat/api/v1/bff", apiPayload, {
          timeout: 10000,
        });

        // Guard clauses
        if (response.status !== 200) return ARTICLE_ERROR_GENERIC;
        if (response.data.statusCode !== 200) return ARTICLE_ERROR_GENERIC;
        responsePayload = response?.data?.body;
      } catch (err) {
        responsePayload = ARTICLE_ERROR_GENERIC;
      }

      //SAMPLE payload
      // const responsePayload = ARTICLE_SUCCESS_SHORTNER;

      return responsePayload;
    },
    []
  );

  /**
   * Used in Gift an Article flow for GIFTING articles
   */
  const generateGiftURL = async (
    articleURL: string,
    aovisitorID: string
  ): Promise<LambdaResponsePayload> => {
    const apiPayload: AlacrityPayload = {
      service: "alacrity",
      payload: {
        action: LAMBDA_REQUEST_ACTION_TYPES.GENERATE_GIFT_URL,
        data: {
          articleURL,
          aovisitorID,
        },
      },
    };

    const response = await axios.post("/_plat/api/v1/bff", apiPayload);

    // Guard clauses
    if (response.status !== 200) return ARTICLE_ERROR_GENERIC;
    if (response.data.statusCode !== 200) return ARTICLE_ERROR_GENERIC;
    const responsePayload = response.data.body;

    //SAMPLE payload
    // const responsePayload = ARTICLE_SUCCESS_GIFT;

    return responsePayload;
  };

  /**
   * Used in Gift an Article flow for RECEIVING articles
   */
  const verifyGiftToken = useCallback(
    async (
      articleURL: string,
      giftToken: string,
      aovisitorID: string
    ): Promise<LambdaResponsePayload> => {
      const apiPayload: AlacrityPayload = {
        service: "alacrity",
        payload: {
          action: LAMBDA_REQUEST_ACTION_TYPES.VERIFY_GIFT_TOKEN,
          data: {
            articleURL,
            giftToken,
            aovisitorID,
          },
        },
      };

      const response = await axios.post("/_plat/api/v1/bff", apiPayload);

      // Guard clauses
      if (response.status !== 200) return ARTICLE_ERROR_GENERIC;
      if (response.data.statusCode !== 200) return ARTICLE_ERROR_GENERIC;
      const responsePayload = response.data.body;

      //SAMPLE payload
      // const responsePayload = ARTICLE_SUCCESS_VALID_GIFT_TOKEN;

      return responsePayload;
    },
    []
  );

  return { generateShortnerURL, generateGiftURL, verifyGiftToken };
}
