import { RouteFactory } from "@app/routePaths";
import { BlockTitle } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import HubFintechSupplementsArticles from "@pages/Hub/components/HubFintechSupplementsArticles";
import HubFutureOfFinanceSupplementsArticles from "@pages/Hub/components/HubFutureOfFinanceSupplementsArticles";
import HubWatchSupplementsArticles from "@pages/Hub/components/HubWatchSupplementsArticles";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { cn } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import HubBrandedContentArticles from "./components/HubBrandedContentArticles";
import HubPropertySupplementsArticles from "./components/HubPropertySupplementsArticles";
import HubSponsoredContentArticles from "./components/HubSponsoredContentArticles";
import HubWealthSupplementsArticles from "./components/HubWealthSupplementsArticles";
import { HubContext } from "./utils/types";

export type HubPageContentProps = Pick<HubContext, "data">;
export default function HubPageContent({
  data: {
    title,
    brandedContentArticles,
    propertySupplementsArticles,
    sponsoredContentArticles,
    wealthSupplementsArticles,
    watchSupplementsArticles,
    fintechSupplementsArticles,
    futureOfFinanceSupplementsArticles,
    seoFooterData,
  },
}: HubPageContentProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="w-full" data-testid="hub-page-content-component">
      <AdSettings
        adNames={["lb1", "imu1", "catfish", "abm"]}
        path={RouteFactory.hub}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title="Hub_Index"
        level2="hub"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description="THE BUSINESS TIMES Hub - Find Hub News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="website"
        slug={RouteFactory.hub}
        robots="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="pt-6">
        <Row>
          <Column rootClassName="w-full">
            <h1
              className={cn(
                "mb-2 border-b border-gray-850 pb-3 pt-6 font-playfair text-6xl font-semibold text-gray-850",
                "md:text-8xl"
              )}
              aria-label="CONTENT HUB"
            >
              <span className="text-8xl md:text-10xl">C</span>ONTENT{" "}
              <span className="text-8xl md:text-10xl">H</span>UB
            </h1>
          </Column>

          <>
            {sponsoredContentArticles.length > 0 ? (
              <>
                <Column rootClassName="w-full mt-6">
                  <BlockTitle
                    text="SPONSORED CONTENT"
                    rootClassName="text-lg antialiased md:mb-4"
                  />
                </Column>

                <Column rootClassName="w-full lg:w-2/3 mb-6">
                  <HubSponsoredContentArticles
                    articles={sponsoredContentArticles}
                  />
                </Column>

                <Column rootClassName="w-full lg:w-1/3 mb-6">
                  <Ad
                    adType="imu1"
                    className="sticky top-[70px] flex flex-col items-center justify-center border-y border-gray-175 bg-gray-250 py-6"
                  />
                </Column>
              </>
            ) : null}
          </>
        </Row>

        <Row rootClassName="my-6">
          <Column rootClassName="w-full order-2 lg:order-1 lg:w-2/3 mb-14">
            <HubBrandedContentArticles
              articles={brandedContentArticles}
              className={cn({
                "border-t border-gray-850 pt-2":
                  sponsoredContentArticles.length > 0,
              })}
            />
          </Column>

          <Column rootClassName="w-full flex flex-col order-1 lg:order-2 lg:w-1/3">
            <div className="sticky top-[70px] flex flex-col">
              {sponsoredContentArticles.length === 0 ? (
                <Ad
                  adType="imu1"
                  className="flex flex-col items-center justify-center border-y border-gray-175 bg-gray-250 py-6"
                />
              ) : null}

              <HubPropertySupplementsArticles
                articles={propertySupplementsArticles}
                className={cn("mb-14", {
                  "border-t border-gray-850 pt-2":
                    sponsoredContentArticles.length > 0,
                  "order-1 lg:order-2": sponsoredContentArticles.length === 0,
                })}
              />

              <HubWealthSupplementsArticles
                articles={wealthSupplementsArticles}
                className={cn("mb-14 border-t border-gray-850 pt-2", {
                  "order-2 lg:order-3": sponsoredContentArticles.length === 0,
                })}
              />

              <HubWatchSupplementsArticles
                articles={watchSupplementsArticles}
                className={cn("mb-14 border-t border-gray-850 pt-2", {
                  "order-2 lg:order-3": sponsoredContentArticles.length === 0,
                })}
              />

              <HubFintechSupplementsArticles
                articles={fintechSupplementsArticles}
                className={cn("mb-14 border-t border-gray-850 pt-2", {
                  "order-2 lg:order-3": sponsoredContentArticles.length === 0,
                })}
              />

              <HubFutureOfFinanceSupplementsArticles
                articles={futureOfFinanceSupplementsArticles}
                className={cn("mb-14 border-t border-gray-850 pt-2", {
                  "order-2 lg:order-3": sponsoredContentArticles.length === 0,
                })}
              />
            </div>
          </Column>
        </Row>
      </Container>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
