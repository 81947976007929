import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import type { NavChildren } from "@components/Header/types";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { cn } from "@util/helpers";

export type DefaultIndividualSideMenuProps = {
  sideNavigationItem: NavChildren;
  index: number;
} & Pick<HTMLAttributes<HTMLElement>, "className">;

export default function DefaultIndiviualSideMenu({
  index,
  sideNavigationItem,
  className,
}: DefaultIndividualSideMenuProps) {
  const { key, link, label, children, mobileLabel, target, trackingData } =
    sideNavigationItem;

  const sendDataToGTM = useGTMDispatch();

  const onClick = () => {
    if (trackingData) {
      sendDataToGTM(trackingData);
    }
  };

  return (
    <div
      key={`side-menu-${index}`}
      id={`side-drawer-navigation-${key}`}
      data-testid={`side-drawer-navigation-${key}`}
      className={cn(
        "group relative text-gray-850 hover:bg-gray-175",
        className
      )}
    >
      <Link
        className={cn(
          {
            "pl-8": index % 2 === 0,
            "pr-8": index % 2 !== 0,
          },
          "flex items-center justify-between py-3 font-poppins text-2xs font-semibold tracking-[0.01875rem] lg:py-2 lg:pl-5 lg:pr-4"
        )}
        to={`${link}?ref=hamburger-menu`}
        reloadDocument
        target={target ? target : undefined}
        aria-label={label}
        onClick={onClick}
      >
        <span className="hidden lg:block">{label}</span>
        <span className="block lg:hidden">{mobileLabel || label}</span>
        {children?.length ? (
          <svg
            className="hidden h-[10px] w-[5px] fill-gray-350 lg:block lg:group-hover:fill-gray-850"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 5.50624L0.947059 11L0 10.1385L5.09216 5.50624L0 0.861521L0.947059 0L7 5.49376V5.50624Z" />
          </svg>
        ) : null}
      </Link>

      {children?.length ? (
        <div
          className={cn(
            "invisible absolute left-full top-0 hidden w-max min-w-[160px] bg-white opacity-0 shadow-2xl duration-300 lg:block",
            "lg:group-hover:visible lg:group-hover:opacity-100 lg:group-hover:delay-100"
          )}
        >
          {children.map((obj, index) => {
            return (
              <Link
                key={`${obj.key}${index}`}
                className="block px-3 py-2 font-poppins text-2xs font-semibold hover:bg-gray-175"
                to={`${obj.link}?ref=hamburger-menu`}
                reloadDocument
              >
                {obj.label}
              </Link>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
