import { useEffect, useState } from "react";
import { BreakingNewsCardProps } from "@app/types/BreakingNews";
import CreateTime from "@components/ArticleMeta/CreateTime";
import SectionLink from "@components/ArticleMeta/SectionLink";
import SubscriberFlag from "@components/ArticleMeta/SubscriberFlag";
import TitleLink from "@components/ArticleMeta/TitleLink";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";
import dayjs from "dayjs";

export default function BreakingNewsCard({
  testId,
  rootClassName,
  basicCardProps,
  isLast = false,
}: BreakingNewsCardProps): React.ReactElement {
  const {
    id,
    title,
    updated = "",
    edited = "",
    section,
    slug,
    paywall,
    hasUpdatedTimestampDisplay,
  } = basicCardProps;
  const { isScreenLG } = useWindowSize();

  const [isUpdatedWithinADay, setIsUpdatedWithinADay] = useState(false);
  const hasArticleBeenUpdated = updated < edited;

  const latestUpdatedOrEdited = dayjs(updated).isAfter(edited)
    ? updated
    : edited;

  useEffect(() => {
    const isLessThanOneDay = dayjs().diff(edited, "hours") <= 23;
    setIsUpdatedWithinADay(isLessThanOneDay && hasArticleBeenUpdated);
  }, [hasArticleBeenUpdated, edited]);

  return (
    <div
      className={cn("story relative flex flex-grow flex-col", rootClassName)}
      data-testid={testId}
    >
      <div className="flex lg:space-x-2">
        {latestUpdatedOrEdited ? (
          <CreateTime
            created={latestUpdatedOrEdited}
            dateFormat="hh:mm A"
            rootClassName={cn(
              "text-gray-650 font-medium font-poppins font text-4xs",
              "absolute right-full bg-white py-2 mt-4 mr-4",
              "lg:static lg:p-0 lg:m-0",
              {
                "pb-24 lg:pb-0": isLast,
              },
              { "text-red": hasUpdatedTimestampDisplay && isUpdatedWithinADay }
            )}
          />
        ) : null}

        {hasUpdatedTimestampDisplay && isUpdatedWithinADay ? (
          <div
            className={cn(
              "font-poppins text-4xs font-light uppercase tracking-[1px] text-gray-650 lg:border-l lg:border-gray-175 lg:pl-2"
            )}
          >
            Updated
          </div>
        ) : null}

        {section ? (
          <SectionLink
            sectionName={section.name}
            sectionUniqueName={section.uniqueName}
            rootClassName={cn(
              "lg:border-l lg:border-gray-175 lg:pl-2 text-gray-650",
              {
                "ml-2 border-l border-gray-175 pl-2 text-gray-650":
                  hasUpdatedTimestampDisplay && isUpdatedWithinADay,
              }
            )}
          />
        ) : null}

        {paywall && isScreenLG ? (
          <div className="flex border-l border-gray-175 pl-2">
            <SubscriberFlag rootClassName="font-light !mb-0" />
          </div>
        ) : null}
      </div>

      <TitleLink
        title={title}
        articleId={id}
        slug={slug}
        rootClassName="text-base"
      />

      {!isScreenLG ? (
        <div className="flex gap-x-4 divide-x">
          {paywall ? <SubscriberFlag rootClassName="font-light !mb-0" /> : null}
        </div>
      ) : null}
    </div>
  );
}
