import * as React from "react";
import { useState } from "react";
import { Link, To, useLocation } from "react-router-dom";
import { useWindowSize } from "@app/hooks/useWindowSize";
import type { NavigationItem } from "@app/types/Navigation";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { cn } from "@util/helpers";
import cx from "classnames";

import { getActiveSectionPath } from "./helpers";
import { ActiveType } from "./types";

import "@greglaisph/react-splide/css";

export type BreadcrumbProps = {
  parentCategory: string;
  childCategory?: string;
  categoryDataMapping: Record<string, NavigationItem>;
  fromHeader?: boolean;
  extraBreadcrumbItems?: React.ReactNode;
  activeFilter?: string;
  activeType?: ActiveType;
  className?: string;
};

const excludeCategories = ["story-threads", "tags"];

export default function Breadcrumb({
  parentCategory,
  childCategory,
  categoryDataMapping,
  fromHeader,
  extraBreadcrumbItems,
  activeFilter = "",
  activeType = ActiveType.PATH,
  className,
}: BreadcrumbProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const { pathname } = useLocation();
  const active_section_path = getActiveSectionPath(pathname, childCategory);
  const [contentWidth, setContentWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const parentCategoryData = categoryDataMapping?.[parentCategory];
  const shouldRenderCarousel = contentWidth > containerWidth;
  const activeNavigationItemIndex = childCategory
    ? parentCategoryData?.children?.findIndex(
        (fullChildCategoryPath: string) => {
          if (activeType === ActiveType.PATH) {
            return (
              `${parentCategory}/${childCategory}` === fullChildCategoryPath
            );
          }

          if (activeType === ActiveType.QUERY) {
            return activeFilter === fullChildCategoryPath;
          }
        }
      ) ?? 0
    : 0;

  const isLinkActive = (link: To, key: string) => {
    switch (activeType) {
      case ActiveType.QUERY:
        return activeFilter === key;
      case ActiveType.PATH:
      default:
        return active_section_path === link;
    }
  };

  const options: Options = {
    perMove: 1,
    pagination: false,
    arrows: !(!isScreenLG || !shouldRenderCarousel),
    snap: true,
    autoHeight: true,
    autoWidth: true,
    focus: 0,
    omitEnd: true,
  };

  const renderBreadCrumbs = () => {
    return (
      <div
        className={cn(
          { "border-b border-gray-850 pb-3": !fromHeader },
          className
        )}
      >
        <Splide
          options={options}
          hasTrack={false}
          ref={(element) => {
            setTimeout(function () {
              const contenW =
                element?.splideRef.current?.getElementsByTagName("ul")[0]
                  .scrollWidth || 0;
              setContentWidth(contenW);
              element?.go(activeNavigationItemIndex);
            }, 100);
          }}
        >
          <div className="splide__arrows absolute bottom-1/2 flex w-full gap-2">
            <button
              className={cx(
                "splide__arrow splide__arrow--prev left-0 !bg-white disabled:opacity-0 min-[1040px]:-left-10",
                { "!left-0": fromHeader }
              )}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                className="block !h-4 !w-4 cursor-pointer rounded-full border border-black fill-gray-650 p-1.5 hover:opacity-100 "
              />
            </button>
            <button
              className={cx(
                "splide__arrow splide__arrow--next right-0 !bg-white disabled:opacity-0  min-[1040px]:-right-10",
                { "!right-0": fromHeader }
              )}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                className="block !h-4 !w-4 cursor-pointer rounded-full border  border-black fill-gray-650 p-1.5 hover:opacity-100"
              />
            </button>
          </div>
          <SplideTrack className="[&>ul>li:first-child>div]:pl-0 [&>ul>li:last-child>div]:border-r-0 [&>ul>li:last-child>div]:pr-0">
            {extraBreadcrumbItems ? (
              <SplideSlide>{extraBreadcrumbItems}</SplideSlide>
            ) : null}

            {parentCategoryData?.children?.map(
              (fullChildCategoryPath: string) => {
                let isCategoryExcluded = false;

                excludeCategories.forEach((category) => {
                  if (fullChildCategoryPath.startsWith(category)) {
                    isCategoryExcluded = true;
                  }
                });

                const childCategoryData =
                  categoryDataMapping?.[fullChildCategoryPath];
                const pathArray = childCategoryData?.link.split("?");
                const link = pathArray?.[0];
                const params = new URLSearchParams(pathArray?.[1]);
                params.append("ref", "listing-menubar");

                return !isCategoryExcluded && childCategoryData ? (
                  <SplideSlide key={fullChildCategoryPath}>
                    <div
                      className={cx(
                        fullChildCategoryPath.replace("/", "-"),
                        "relative box-border flex shrink-0 items-center border-r border-gray-175 font-poppins text-sm font-normal tracking-tightest text-gray-850",
                        {
                          "!font-bold": isLinkActive(
                            link,
                            childCategoryData.key
                          ),
                        },
                        { "px-3 hover:opacity-80 ": !fromHeader },
                        {
                          "ml-[0.938rem] rounded-[25px] border-none p-3 !text-2xs leading-none hover:bg-gray-250":
                            fromHeader,
                        }
                      )}
                      key={fullChildCategoryPath}
                    >
                      <Link to={`${link}?${params.toString()}`} reloadDocument>
                        {childCategoryData.label}
                      </Link>
                    </div>
                  </SplideSlide>
                ) : null;
              }
            )}
          </SplideTrack>
        </Splide>
      </div>
    );
  };

  return (
    <div>
      {parentCategoryData &&
      parentCategoryData?.children &&
      parentCategoryData?.children.length > 0 ? (
        <div
          ref={(element) => {
            if (element && element.clientWidth) {
              setContainerWidth(element.clientWidth);
            }
          }}
          className="w-auto"
        >
          {renderBreadCrumbs()}
        </div>
      ) : null}
    </div>
  );
}
