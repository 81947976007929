import { Await, useLocation } from "react-router-dom";
import { VerticalContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { VerticalLayoutFactory } from "@pages/Section/helpers";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { sectionNavigationItems } from "@util/constant";
import { toTitleCase } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import { HttpStatusCode } from "axios";

export default function Verticals() {
  const { context } =
    useRouteContext<TRouteWithRedirect<VerticalContext, string>>();

  const mostReadArticles = useMostReadArticleList();
  const { pathname } = useLocation();
  const adBlockDetected = useDetectAdBlock();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }) => {
        if (kind === "section") {
          const VerticalLayout = VerticalLayoutFactory(data.vertical);
          const [parentCategory, childCategory] = pathname
            .replace("/", "")
            .split("/");

          return (
            <>
              <GAData
                chapter1={childCategory.replaceAll("-", " ")}
                level2={parentCategory.replaceAll("-", " ")}
                title={toTitleCase(
                  `${
                    sectionNavigationItems[pathname.replace(/^\/+/g, "")].label
                  } Index`
                )}
                adblocker={adBlockDetected ? 1 : 0}
              />

              <VerticalLayout data={data} mostReadArticles={mostReadArticles} />
            </>
          );
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
