import { ContributorProfile, Profile } from "@app/types/Cue";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import bylineDefaultImage from "@assets/defaultImages/byline/default.png";
import OptimisedImage from "@components/OptimisedImage";
import { cn } from "@util/helpers";

type BylineImageProps = {
  rootClassName?: string;
  profile?: Profile | ContributorProfile;
  width?: number;
  height?: number;
  backdrop?: string;
};

export default function BylineImage({
  rootClassName,
  profile,
  width = 65,
  height = 65,
  backdrop = "white",
}: BylineImageProps): React.ReactElement {
  const byline_image =
    profile?.content.headshotImage?.[0]?.content.fields["square_320-caas"]
      ?.url || bylineDefaultImage;

  // Using 100x100 for better image sharpness, as 50x50 appears blurry
  const filters: CaaSImageFilters[] = [{ w: 100, h: 100, dpr: 1, f: "webp" }];

  return (
    <OptimisedImage
      className={cn("rounded-full", rootClassName, backdrop)}
      src={byline_image}
      alt={profile?.content.fields.name}
      filters={filters}
      width={width}
      height={height}
    />
  );
}
