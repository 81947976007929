import { BlockTitle } from "@blocks/Blocks";
import { useWindowSize } from "@hooks/useWindowSize";
import { useSendInViewGTM } from "@pages/SingaporeGe/2025/hooks/useSendInViewGTM";
import { getScreenIframeSize } from "@pages/SingaporeGe/2025/utils/helpers";
import { IframeEmbedProps } from "@pages/SingaporeGe/2025/utils/types";
import { cn } from "@util/helpers";
import IframeResizer from "iframe-resizer-react";

export default function IframeEmbed({
  configData: data,
  index,
  gaObject,
}: IframeEmbedProps): React.ReactElement {
  const { isScreenLG, isScreenMD } = useWindowSize();

  const { ref: iframeRef } = useSendInViewGTM({
    eventsObject: gaObject?.eventObject,
  });

  if (!data) return <></>;

  const screenObj = getScreenIframeSize(data, isScreenLG, isScreenMD);

  const iframeAttributes = {
    className: cn("box-content w-full overflow-auto border-none"),
    src: data.src,
    ...screenObj,
  };

  return (
    <div
      data-testid="iframe-embed-component"
      className={cn({ "border-t border-gray-850 pt-2": index !== 0 })}
      ref={iframeRef}
    >
      {data.title ? (
        <BlockTitle text={data.title} rootClassName="mb-4" />
      ) : null}

      {screenObj.width === "100%" && screenObj.height === "100%" ? (
        <IframeResizer {...iframeAttributes} id={`iframe-${index}`} />
      ) : (
        <iframe
          {...iframeAttributes}
          id={`iframe-${index}`}
          title={data.title || `iframe-${index}`}
        />
      )}
    </div>
  );
}
