import { HTMLAttributes } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";

type HighlightsProps = {
  articles: SanitizedSectionArticle[];
} & HTMLAttributes<HTMLDivElement>;

export default function MoreStories({
  articles,
  className,
}: HighlightsProps): React.ReactElement {
  if (articles.length === 0) return <></>;

  return (
    <div data-testid="more-stories-component" className={className}>
      <div className="stories grid grid-cols-1 gap-8 lg:grid-cols-4">
        {articles.map(({ title, id, media, kicker, urlPath, sections }) => {
          return (
            <div key={id} className="story">
              <BasicCard
                title={title}
                id={id}
                media={media.at(0)}
                kicker={kicker?.fields[0].value}
                slug={urlPath}
                variations={{
                  image: {
                    position: "",
                    width: "w-full",
                  },
                  content: {
                    width: "w-full",
                    extraClass: "pt-2",
                  },
                  kicker: {
                    color: "text-gray-875",
                    extraClass: "tracking-[.0625rem] leading-[1rem]",
                  },
                  title: {
                    color: "text-gray-875",
                    size: "text-lg",
                  },
                }}
                defaultImage={{
                  directoryName: sections?.[0]?.uniqueName || "",
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
