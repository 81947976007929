import BgBlackImage from "@assets/newsletter/black_bg_darkmode.jpeg";
import BTFooterLogo from "@assets/newsletter/bt-logo-footer.png";
import AppleLogo from "@assets/newsletter/logo-app-store.png";
import GoogleLogo from "@assets/newsletter/logo-google-play.png";
import {
  ANDROID_APP_LINK,
  IOS_APP_LINK,
} from "@components/Footer/utils/constant";
import { NEWSLETTER_FOOTER_SOCIAL_LINKS } from "@components/Newsletter/constants";

export type SocialMediaLink = {
  icon: string;
  url: string;
};

export type FooterLinkProps = {
  url: string;
  children: React.ReactNode;
};

export type SocialIconProps = {
  icon: string;
  url: string;
};

const FooterLink = ({ url, children }: FooterLinkProps) => (
  <div className="p-1">
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  </div>
);

const SocialIcon = ({ icon, url }: SocialIconProps) => (
  <FooterLink url={url}>
    <img src={icon} height="38" width="38" alt="Social Media Icon" />
  </FooterLink>
);

export type NewsletterSampleFooterProps = {
  editionId?: string;
};

export default function NewsletterSampleFooter({
  editionId,
}: NewsletterSampleFooterProps): React.ReactElement {
  return (
    <>
      <div
        className="mx-auto w-full max-w-[600px]"
        style={{ backgroundImage: `url(${BgBlackImage})` }}
      >
        <div className="flex flex-col items-center pt-12">
          <div className="p-2.5">
            <img
              src={BTFooterLogo}
              width="190"
              height="18"
              alt="Business Times Logo"
            />
          </div>
        </div>

        <div className="flex-no-wrap flex justify-center py-6">
          {NEWSLETTER_FOOTER_SOCIAL_LINKS.map((link, index) => (
            <SocialIcon key={index} icon={link.icon} url={link.url} />
          ))}
        </div>

        <div className="flex flex-wrap justify-center pb-12 pt-3">
          <FooterLink url={ANDROID_APP_LINK}>
            <img
              src={GoogleLogo}
              width="125"
              height="37"
              alt="Google Play Store"
            />
          </FooterLink>

          <FooterLink url={IOS_APP_LINK}>
            <img src={AppleLogo} width="125" height="37" alt="App Store" />
          </FooterLink>
        </div>
      </div>
      <div className="flex justify-center pb-16 pt-2 font-arial text-8xs font-normal leading-5 text-gray-700">
        If you want to unsubscribe from this mailing list,&nbsp;
        <a
          href={`https://www.businesstimes.com.sg/bt_newsletter/unsubscribe?edition=${editionId}`}
          target="_blank"
          className="text-gray-700 underline"
          rel="noreferrer"
        >
          click here.
        </a>
      </div>
    </>
  );
}
