import { useState } from "react";
import { Tag } from "@app/types/Cue";
import Container from "@components/Container/Container";
import { useWindowInnerWidth } from "@hooks/useWindowInnerWidth";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { cn } from "@util/helpers";

import Ad from "./Ad";

export type DynamicImuProps = {
  totalParagraphs: number;
  index: number;
  adsWrapperClass?: string;
  fullScreenAds?: boolean;
  articleId?: string;
  keywords?: Tag[];
};

export default function DynamicImu({
  totalParagraphs,
  index,
  adsWrapperClass,
  fullScreenAds = false,
  keywords = [],
  articleId,
}: DynamicImuProps): React.ReactElement {
  const windowInnerSize = useWindowInnerWidth();
  const [adsHeight, setAdsHeight] = useState(0);

  const noAdsDisplayed = adsHeight <= 5;

  const adsExtraCss = {
    maxWidth: `${windowInnerSize.width}px`,
    marginLeft: fullScreenAds
      ? `calc(-1*(${windowInnerSize.width}px - 100%) / 2 )`
      : 0,
    height: fullScreenAds
      ? noAdsDisplayed
        ? 0
        : `${adsHeight + 125}px`
      : "auto",
  };

  const isHideAds = fullScreenAds && noAdsDisplayed;
  const btTags =
    keywords?.map(({ name }) => getFormattedTextForAds(name)).join(",") || "";

  if (
    (totalParagraphs >= 1 && totalParagraphs < 3) ||
    totalParagraphs === 3 ||
    (totalParagraphs >= 3 && totalParagraphs <= 5)
  ) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
        data-testid="dynamic-imu-component"
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Ad
              adType="imu1"
              className="flex flex-col items-center justify-center"
              setAdsHeight={setAdsHeight}
              counter={index}
              articleId={articleId}
              articleTags={btTags}
            />
          </div>
        </Container>
      </div>
    );
  }

  if (
    ((totalParagraphs > 3 && totalParagraphs < 6) || totalParagraphs === 6) &&
    fullScreenAds
  ) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
        data-testid="dynamic-imu-component"
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Ad
              adType="imu2"
              className="flex flex-col items-center justify-center"
              setAdsHeight={setAdsHeight}
              counter={index}
              articleId={articleId}
              articleTags={btTags}
            />
          </div>
        </Container>
      </div>
    );
  }

  if ((totalParagraphs > 3 && totalParagraphs < 6) || totalParagraphs === 6) {
    return (
      <div
        className={cn(adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
        data-testid="dynamic-imu-component"
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Ad
              adType="midarticlespecial"
              className="flex flex-col items-center justify-center"
              setAdsHeight={setAdsHeight}
              counter={index}
              articleId={articleId}
              articleTags={btTags}
            />
          </div>
        </Container>
      </div>
    );
  }

  if ((totalParagraphs > 6 && totalParagraphs < 9) || totalParagraphs === 9) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
        data-testid="dynamic-imu-component"
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Ad
              adType="imu2"
              className="flex flex-col items-center justify-center"
              setAdsHeight={setAdsHeight}
              counter={index}
              articleId={articleId}
              articleTags={btTags}
            />
          </div>
        </Container>
      </div>
    );
  }

  if (totalParagraphs > 9) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
        data-testid="dynamic-imu-component"
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Ad
              adType="imu3"
              className="flex flex-col items-center justify-center"
              setAdsHeight={setAdsHeight}
              counter={index}
              articleId={articleId}
              articleTags={btTags}
            />
          </div>
        </Container>
      </div>
    );
  }

  return <></>;
}
