import { useCallback, useEffect } from "react";
import useOKTAUserStore, { OKTAUserType } from "@store/useOKTAUserStore";
import { DRUPAL_API_ENDPOINT } from "@util/constant";
import { getErrorMessage } from "@util/helpers";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { useFeatureFlagEnabled } from "posthog-js/react";

/**
 * Note: This is the hook to get the user information from current BT mySPH session
 * @returns This current BT version of user information
 */
export default function useOKTAUserInfo() {
  const setUserInfo = useOKTAUserStore((state) => state.setUserInfo);
  const user = useOKTAUserStore((state) => state.userInfo);
  const setUserError = useOKTAUserStore((state) => state.setUserError);
  const isUserLoginEnabled = useFeatureFlagEnabled("feature_enable_user_login");

  const handleFetchLoginDetails = useCallback(() => {
    // Dont fetch login details if feature_enable_user_login disabled
    if (!isUserLoginEnabled) return;

    // load user information
    fetchLoginDetails(
      (res) => {
        if (res.status === 200) {
          const newUserData: OKTAUserType = res.data;

          setUserInfo(newUserData);

          if (newUserData?.loginid) {
            Cookies.set("loginid", newUserData.loginid, { expires: 60 });
          }

          windowDataLayer();

          if (!newUserData?.loginid) trySingleSignOnLogin();

          if (typeof window._data !== "undefined") {
            window._data.visitorcat = newUserData?.service_type_value;
            if (typeof newUserData?.mysphw !== "undefined") {
              window._data.at = newUserData.mysphw;
            }
            if (typeof newUserData?.svc !== "undefined") {
              window._data.svc = newUserData.svc;
            }
          }

          // Dispatch the event
          const event = document.createEvent("Event");
          event.initEvent("ldapLoginDetailsReceived", true, true);
          document.dispatchEvent(event);
        }
      },
      (error) => {
        const errorThrown = getErrorMessage(error);
        trySingleSignOnLogin();
        windowDataLayer();
        setUserError(errorThrown);
      }
    );
  }, [setUserInfo, setUserError, isUserLoginEnabled]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleFetchLoginDetails();
      window.loadSubscriberContent = function () {
        handleFetchLoginDetails();
      };
    }
  }, [handleFetchLoginDetails]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      postMySPHSession(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.loginid]);
}

function fetchLoginDetails(
  successCallback: (res: AxiosResponse) => void,
  errorCallback: (error: unknown) => void
) {
  axios
    .post(`${DRUPAL_API_ENDPOINT}/login_details.php?${new Date().valueOf()}`)
    .then((res) => {
      if (res.status === 200) {
        successCallback(res);
      }
    })
    .catch((error: unknown) => {
      errorCallback(error);
    });
}

function windowDataLayer() {
  if (typeof window === "undefined") {
    return;
  }

  const userType = Cookies.get("mySPHUserType");
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  // Set Targeting based on userType
  window.googletag.cmd.push(function () {
    let user_status = "N";
    if (userType != undefined) {
      if (userType == "y-sub") {
        user_status = "Y";
      } else if (userType == "y-reg") {
        user_status = "R";
      }
      googletag.pubads().setTargeting("subscriber", user_status);
    } else {
      //adding visitor information to tm datalayer
      googletag.pubads().setTargeting("subscriber", user_status);
    }
  });

  const visitorcat = Cookies.get("visitorcat");
  const mysphw = Cookies.get("mysphw");
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window.dataLayer as any).push({
    visitorcat: visitorcat,
    at: mysphw,
  });
}

//MYSPH singleSignon Integration
function trySingleSignOnLogin() {
  const f = document.createElement("iframe");
  f.src = "/ldap/login.php";
  f.style.width = "0px";
  f.style.height = "0px";
  f.style.border = "none";
  f.style.display = "none";
  document.body.append(f);
}

//Post MySPHSession
function postMySPHSession(data: OKTAUserType) {
  const inter = window.setInterval(function () {
    if (null != window._mySPHObj) {
      window.clearInterval(inter);
      window._mySPHObj.postSessionInformation(data?.mySPHSessionId as string);
    }
  }, 300);
}

// TODO logic to be added when develop article page
// window.loadSubscriberContent = function () {
//   console.log("SubscriberContent SSO");
//   if (window._data.articleid !== "") {
//     $.ajax({
//       type: "POST",
//       url: "/mysph/details?article_id=" + window._data.articleid,
//       dataType: "json",
//       cache: true,
//       success: function (data: OKTAUserType) {
//         if (data?.loginid) {
//           Cookies.set("loginid", data.loginid, { expires: 60 });
//           postMySPHSession(data);
//           ssoDispatchEvent(data);
//           const fullBodyContainer = document.querySelector(
//             "body .article-content-rawhtml"
//           );
//           if (data.full_body && fullBodyContainer) {
//             fullBodyContainer.innerHTML = data.full_body;
//           }
//         }
//       },
//     });
//   } else {
//     fetchLoginDetails();
//   }
// };
