import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { SectionSupplementsProps } from "@app/caas/types";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import { ActiveType } from "@components/Breadcrumb/types";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { useLoadMoreSupplementStories } from "@hooks/useLoadMoreSupplementStories";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import { sectionNavigationItems } from "@util/constant";
import { cn } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import WealthSupplementsGroup from "./components/WealthSupplementsGroup";

export default function WealthSupplementsPageContent({
  data: { title, group, seoFooterData },
}: SectionSupplementsProps): ReactElement {
  const breakingNews = useTrimmedBreakingNews();
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();

  const { moreGroup, ref } = useLoadMoreSupplementStories({
    group,
    size: 10,
    section: "wealth",
  });

  return (
    <div className="w-full" data-testid="wealth-magazine-layout-component">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.wealthSupplements}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        slug={RouteFactory.wealth}
      />

      <GAData
        level2={"wealth"}
        title="Supplements"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={sectionNavigationItems["wealth"] && isShowBreadCrumb}
        parentCategory={"wealth"}
        categoryDataMapping={sectionNavigationItems}
      />

      {sectionNavigationItems["wealth"] ? (
        <Container>
          <div>
            <h1 className="pb-3 pt-6 font-playfair text-8xl font-semibold uppercase text-gray-850">
              {"Wealth"}
            </h1>

            <div ref={breadcrumbsRef}>
              <Breadcrumb
                className="m-auto"
                parentCategory={"wealth"}
                childCategory={"supplements"}
                activeFilter={RouteFactory.wealthSupplements.replace("/", "")}
                categoryDataMapping={sectionNavigationItems}
                activeType={ActiveType.QUERY}
                extraBreadcrumbItems={
                  <div
                    className={cn(
                      "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm tracking-tightest text-gray-850 hover:opacity-80"
                    )}
                  >
                    <Link
                      to={`${RouteFactory.wealth}?ref=listing-menubar`}
                      reloadDocument
                    >
                      Top stories
                    </Link>
                  </div>
                }
              />
            </div>
          </div>
        </Container>
      ) : null}

      <Container rootClassName="py-12">
        <Row>
          <Column rootClassName="w-full mb-8 lg:w-8/12">
            <div className="mb-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
              {moreGroup.map(({ entity, articles }, index) => {
                if (entity === null) return;

                return (
                  <WealthSupplementsGroup
                    key={entity.urlPath}
                    tag={entity}
                    articles={index === 0 ? articles : articles.slice(0, 1)}
                    index={index}
                    className={cn("flex flex-col", {
                      "col-span-1 lg:col-span-2": index === 0,
                    })}
                  />
                );
              })}
            </div>

            <div ref={ref} />
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <Ad
              adType="imu2"
              className="mb-6 flex flex-col items-center justify-center"
            />

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />
          </Column>
        </Row>
      </Container>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
