import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import Section1 from "@pages/MyBT/components/Intro/Section1";
import Section2 from "@pages/MyBT/components/Intro/Section2";
import Section3 from "@pages/MyBT/components/Intro/Section3";
import Section4 from "@pages/MyBT/components/Intro/Section4";
import Section5 from "@pages/MyBT/components/Intro/Section5";
import { useDetectAdBlock } from "adblock-detect-react";

export default function MyBTIntroPage(): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="w-full" data-testid="my-bt-intro-page-component">
      <AdSettings
        adNames={["lb1", "catfish", "abm"]}
        path={RouteFactory.myBTIntro}
      />

      <MetaTags
        title={renderPageTitleV2({
          kind: "static",
          title: "myBT Intro",
        })}
        description="Read more at The Business Times."
        slug={RouteFactory.myBTIntro}
      />

      <GAData
        chapter1="mybt intro"
        level2="mybt"
        title="myBT Intro - THE BUSINESS TIMES"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <div className="w-full">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </div>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
