import { useEffect, useRef, useState } from "react";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { SearchInputProps } from "@pages/Search/utils/types";
import { cn } from "@util/helpers";

interface FormElements extends HTMLFormControlsCollection {
  queryInput: HTMLInputElement;
}

interface KeywordsFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function SearchInput({
  className,
  value,
  searchNewList,
  handleClearSearchData,
}: SearchInputProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const [withValue, setWithValue] = useState(false);

  const focusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const blurOnInput = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleClearText = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
      setWithValue(false);
      handleClearSearchData();
    }
    focusOnInput();
  };

  const handleClickSearch = (element: React.FormEvent<KeywordsFormElement>) => {
    element.preventDefault();
    searchNewList(element.currentTarget.elements.queryInput.value);
    blurOnInput();
  };

  const handleOnChange = (element: React.ChangeEvent<HTMLInputElement>) => {
    if (element.target.value !== "" && !withValue) {
      setWithValue(true);
    }
  };

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.value !== value) {
      inputRef.current.value = value || "";
      setWithValue(true);
    }
  }, [value]);

  useEffect(() => {
    focusOnInput();
  }, []);

  return (
    <div
      className={cn(
        "group flex w-full rounded-[4px] border border-gray-175 px-4 py-3 text-gray-515 focus-within:border-gray-850 focus-within:text-gray-850",
        className
      )}
      data-testid="search-input-component"
    >
      <form
        onSubmit={handleClickSearch}
        autoComplete="off"
        className="flex w-full justify-between"
      >
        <button type="submit" className={cn("pr-2", REMOVE_FOCUS_CSS)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect
              x="15.4141"
              y="14"
              width="5"
              height="1.8"
              transform="rotate(45 15.4141 14)"
              style={{ fill: "currentcolor" }}
            />

            <circle
              cx="11"
              cy="11"
              r="5.1"
              style={{ stroke: "currentcolor" }}
              strokeWidth="1.8"
            />
          </svg>
        </button>

        <input
          className={cn(
            "w-full flex-1 outline-0 placeholder:font-light placeholder:text-gray-550",
            REMOVE_FOCUS_CSS
          )}
          type="text"
          placeholder="Search The Business Times"
          ref={inputRef}
          id="queryInput"
          onChange={(e) => {
            handleOnChange(e);
          }}
          required
        />

        {withValue ? (
          <button
            type="reset"
            onClick={handleClearText}
            className={cn(REMOVE_FOCUS_CSS)}
          >
            <Icon type={IconTypeEnum.close} fill="#2b2b2b" size={24} />
          </button>
        ) : null}
      </form>
    </div>
  );
}
