import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetRecentArticleListProps } from "@pages/MyAccount/utils/types";
import { SanitizedSectionArticle } from "@typings/Cue";
import axios from "axios";

export const useGetRecentArticleList = ({
  articleIdList,
  size,
}: useGetRecentArticleListProps) => {
  const [readArticleList, setReadArticleList] = useState<
    SanitizedSectionArticle[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const idSize = page * 10;
      const idSizeNext = (page + 1) * 10;

      const idList = size
        ? articleIdList.slice(0, size)
        : articleIdList.slice(idSize, idSizeNext);

      const response = await axios.post<SanitizedSectionArticle[]>(
        "/_plat/api/v1/recent-read-articles",
        {
          articleIds: idList.map(({ id }) => id),
          timeout: 100000,
        }
      );

      const newData = response.data || [];
      const newReadArticleList = [...readArticleList, ...newData];
      setReadArticleList(newReadArticleList);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error recent read list", error);
      return [];
    } finally {
      setIsLoading(false);
      setPage(page + 1);
    }
  }, [articleIdList, page, readArticleList, size]);

  useEffect(() => {
    if (articleIdList.length === 0) return;

    if (articleIdList.length === readArticleList.length) return;

    if (
      size &&
      articleIdList.length <= size &&
      readArticleList.length === articleIdList.length
    )
      return;

    if (size && articleIdList.length > size && readArticleList.length === size)
      return;

    fetchData();
  }, [articleIdList, fetchData, readArticleList, size]);

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (articleIdList.length === readArticleList.length) return;
      if (!inView) return;

      fetchData();
    },
  });

  const handleDeleteArticle = (articleId: string) => {
    const newReadArticleList = readArticleList.filter(
      (article) => article.id !== articleId
    );
    setReadArticleList(newReadArticleList);
  };

  return { ref, readArticleList, isLoading, handleDeleteArticle };
};
