import { JudgingPanelMember } from "@events-awards/utils/types";

export const judgingPanel: JudgingPanelMember[] = [
  {
    name: "Mr Stephen Lee",
    title: "Former Chairman, Singapore Airlines",
    subtitle: "Chairman of the Judging Panel",
  },
  {
    name: "Ms Chen Huifen",
    title: "Executive Editor",
    subtitle: "The Business Times",
  },
  {
    name: "Mr Christopher Ong",
    title: "Senior Vice-President and Managing Director",
    subtitle: "DHL Express (Singapore) Pte Ltd",
  },
  {
    name: "Ms Jacqueline Poh",
    title: "Managing Director",
    subtitle: "Economic Development Board",
  },
  {
    name: "Ms Jeannie Lim",
    title: "Assistant Chief Executive Officer, Lifestyle & Consumer",
    subtitle: "Enterprise Singapore",
  },
  {
    name: "Ms Jennie Chua",
    title: "Chairman",
    subtitle: "Vanguard Healthcare",
  },
  {
    name: "Mr Lim Ming Yan",
    title: "Chairman",
    subtitle: "Singapore Business Federation",
  },
  {
    name: "Mr Loh Boon Chye",
    title: "Chief Executive Officer",
    subtitle: "SGX Group",
  },
  {
    name: "Professor Wee Chow Hou",
    title: "Emeritus Professor, Nanyang Technological University",
    subtitle: "Honorary Fellow, Singapore University of Social Sciences",
  },
  {
    name: "Mr Wong Wei Kong",
    title: "Editor-In-Chief, EMTM Group",
    subtitle: "SPH Media Trust",
  },
  {
    name: "Mr Yung Chiun Ooi",
    title: "Senior Vice President, Commercial",
    subtitle: "DHL Express Asia Pacific",
  },
];
