import { cn } from "@util/helpers";

import ThrivePastEventsButton from "./ThrivePastEventsButton";

export type ThrivePastEventsItemProps = {
  /** The index of the item. */
  index: number;
  /** The title of the event. */
  title: string;
  /** The date the event is published. */
  date: string;
  /** The link to the event. */
  link?: string;
  /** The link text to display in the event link. */
  linkText?: string;
};

/** The item to display each event. */
export default function ThrivePastEventsItem({
  index,
  title,
  date,
  link,
  linkText,
}: ThrivePastEventsItemProps): React.ReactElement {
  return (
    <div
      className={cn(
        "grid grid-cols-1 items-center gap-4 border border-gray-850 border-l-transparent border-r-transparent border-t-transparent px-7 py-12 shadow-gray-850 transition-colors",
        "lg:grid-cols-12",
        "hover:border hover:border-l-gray-850 hover:border-r-gray-850 hover:bg-white hover:shadow-thrive",
        { "border-t-gray-850": index === 0 }
      )}
    >
      <h2 className="thrive-past-event-title font-interBold text-9xl font-bold leading-[2.8rem] text-white text-shadow-thrive-2 lg:col-span-12 xl:col-span-5">
        {title}
      </h2>

      <p className="mb-0 w-40 text-gray-850 lg:col-span-6 xl:col-span-3">
        {date}
      </p>

      <div className="whitespace-normal text-center font-inter text-base font-semibold uppercase lg:col-span-6 lg:whitespace-nowrap xl:col-span-4">
        <ThrivePastEventsButton link={link} linkText={linkText} />
      </div>
    </div>
  );
}
