import { TestIdClassNameProps } from "@app/types/Common";
import BrightcovePlayerLoader from "@brightcove/react-player-loader";
import useAddBrightcoveCustomScript from "@components/Player/hooks/useAddCustomScript";
import { gtmId } from "@util/constant";
import cx from "classnames";

import styles from "./BrightcoveVideo.module.css";

export type BrightcoveVideoPlayerProps = TestIdClassNameProps & {
  videoId: string;
  accountId: string;
  playerId?: string;
};

/**
 * Configures the Brightcove player to track various events and parameters using Google Analytics 4 (GA4).
 *
 * @param {any} myPlayer - The Brightcove player instance {any} as Brightcover types are not available.
 *
 * Documentation: https://player.support.brightcove.com/plugins/google-analytics-plugin.html#Implement_using_code
 * @trackerGA4 {string} - The GA4 tracking ID.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const gaFallbackSolution = (myPlayer: any) => {
  myPlayer.bcGa({
    paramsToTrackGA4: [
      "account",
      "platform_version",
      "player",
      "range",
      "seconds_viewed",
      "session",
      "tags",
      "video_duration",
    ],
    eventsToTrack: {
      ad_end: "Ad Ended",
      ad_start: "Ad Started",
      error: "Player Errors",
      play_request: "Play Request",
      player_load: "Player Load",
      video_engagement: "Video Engagement",
      video_impression: "Video Impression",
      video_view: "Video View",
    },
    trackerGA4: gtmId,
  });
};

export default function BrightcoveVideoPlayer({
  testId,
  rootClassName,
  videoId,
  accountId,
  playerId = "default",
}: BrightcoveVideoPlayerProps): React.ReactElement {
  useAddBrightcoveCustomScript();

  return (
    <>
      {accountId && videoId ? (
        <div className={cx(rootClassName, "aspect-video")} data-testid={testId}>
          <BrightcovePlayerLoader
            accountId={accountId}
            videoId={videoId}
            embedType="iframe"
            playerId={playerId}
            attrs={{ className: cx("h-full", styles.brightcove) }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onReady={(myPlayer: any) => {
              gaFallbackSolution(myPlayer);
            }}
          />
        </div>
      ) : null}
    </>
  );
}
