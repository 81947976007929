import { Link } from "react-router-dom";
import logoAstar from "@assets/events-awards/ee/logo-astar.png";
import logoMasterCard from "@assets/events-awards/ee/logo-mastercard.png";
import logoPaiaFromcbre from "@assets/events-awards/ee/logo-paia-fromcbre.png";
import logoRajahTananAsia from "@assets/events-awards/ee/logo-r-tasia-tagline-rgb.png";
import logoRsm from "@assets/events-awards/ee/logo-rsm.png";
import iconInfo from "@assets/events-awards/icon-info.svg";
import logoEE from "@assets/events-awards/logo-ee.png";
import logoEnterpriseSingapore from "@assets/events-awards/logo-enterprise-singapore.png";
import logoOCBC from "@assets/events-awards/logo-ocbc.png";
import logoBT from "@assets/logo-masthead.svg";
import { Block } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import BasicCard from "@components/ArticleCard/BasicCard";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { eENavigationMenu, eEQuickLinksMenu } from "@ee/utils/constants";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import {
  EVENTS_AWARDS_EE_LABEL,
  EVENTS_AWARDS_EE_PATH,
  EVENTS_AWARDS_SLOT,
} from "@events-awards/utils/constants";
import { EventsAwardsLandingPageContextData } from "@events-awards/utils/types";
import YouTubeVideo from "@pages/Article/components/StoryElements/YouTubeVideo";
import { sectionNavigationItems } from "@util/constant";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsEEPageContent({
  title,
  article,
}: EventsAwardsLandingPageContextData): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();

  const videoFields = [
    {
      name: "title",
      annotations: [],
      value: "Emerging Enterprise",
    },
    {
      name: "description",
      annotations: [],
      value: "Stars of Emerging Enterprise 2022",
    },
    {
      name: "youtubeId",
      annotations: [],
      value: "Wt94MY9DebY",
    },
    {
      name: "display",
      annotations: [],
      value: "card",
    },
  ];
  return (
    <div
      className="w-full"
      data-testid="events-awards-ee-page-content-component"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={EVENTS_AWARDS_EE_PATH}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title="Emerging_Enterprise_Index"
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`The Business Times ${EVENTS_AWARDS_EE_LABEL} - Find ${EVENTS_AWARDS_EE_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_EE_PATH}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <Link to={EVENTS_AWARDS_EE_PATH}>
              <img
                src={logoEE}
                alt="Emerging Enterprise logo"
                width={190}
                height={85}
                className="mx-auto"
              />
            </Link>

            <EventsAwardsNavigationMenu
              menuData={eENavigationMenu}
              linkClassName="after:bg-eventAwards-e50-accent"
            />

            <Block rootClassName="p-6 text-center border border-eventAwards-ee-accent">
              <img
                src={iconInfo}
                alt="Info icon"
                width={16}
                height={16}
                className="mr-4 inline-block"
              />

              <span className="align-middle font-public-sans text-base">
                Applications for Emerging Enterprise Award and Emerging
                Enterprise Sustainability Award are now closed
              </span>
            </Block>

            <>
              {article ? (
                <BasicCard
                  title={article.title}
                  id={article.id}
                  slug={article.urlPath}
                  media={article.media}
                  defaultImage={{ directoryName: "events-awards" }}
                  rootClassName="mt-6 md:-mx-3 mx-0 justify-center"
                  variations={{
                    title: {
                      color: "text-gray-850",
                      size: "text-4xl",
                      extraClass: "font-public-sans font-semibold",
                    },
                    content: {
                      width: "w-full",
                      extraClass: "px-9 md:px-6 lg:px-14 text-left",
                    },
                  }}
                />
              ) : null}
            </>

            <Row rootClassName="flex py-9">
              <Column rootClassName="w-full lg:12 p-1 m-1">
                <h2 className="mb-2 text-left font-public-sans text-8xl font-bold leading-10">
                  {"Gain recognition for your business "}
                </h2>
                <p className="text-left">
                  {"Join Asia's leading business awards for SMEs and start-ups"}
                </p>
              </Column>

              <Column rootClassName="w-full lg:w-6/12 pr-7 lg:h-full">
                <YouTubeVideo
                  key={"test"}
                  fields={videoFields}
                  className="mb-6 flex h-full items-center justify-center  bg-gray-250"
                />
              </Column>

              <Column rootClassName="w-full lg:w-6/12 text-left lg:pl-7">
                <p className="font-bold">
                  {
                    "Fostering and building a sustainable entrepreneurial ecosystem"
                  }
                </p>
                <p>
                  {
                    "We invite your business to shine at the Emerging Enterprise Award and Emerging Enterprise Sustainability Award. Established in 2008, these awards empowers startups and young businesses under 10 years old across Singapore and Asia."
                  }
                </p>
                <p>
                  {
                    "The Emerging Enterprise Award and Emerging Enterprise Sustainability Award, co-presented by OCBC and The Business Times, celebrates dynamic and forward-thinking enterprises poised for growth and innovation. Finalists and winners gain access to invaluable resources, enabling them to unlock their full potential."
                  }
                </p>
                <p>
                  {
                    "Whether through growth or innovation, the Emerging Enterprise Award and the Emerging Enterprise Sustainability Award are your opportunities to stand out."
                  }
                </p>
                <p>
                  {
                    "Don’t miss the chance to highlight your company’s growth and innovation. Join the ranks of exceptional businesses striving for these coveted awards. Apply now to gain the recognition your business deserves!"
                  }
                </p>
              </Column>
            </Row>

            <Block rootClassName="p-6 text-center border border-eventAwards-ee-accent">
              <img
                src={iconInfo}
                alt="Info icon"
                width={16}
                height={16}
                className="mr-4 inline-block"
              />

              <span className="align-middle font-public-sans text-base">
                Applications for Emerging Enterprise Award and Emerging
                Enterprise Sustainability Award are now closed
              </span>
            </Block>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"PRESENTED "}
                  <br className="hidden lg:inline" />
                  {"BY "}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="py-auto mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoOCBC}
                    alt="OCBC logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
                <div className="py-auto flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoBT}
                    alt="The Business Times logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"PREFERRED PAYMENT SOLUTIONS PARTNER "}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="py-auto flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoMasterCard}
                    alt="Mastercard logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"OFFICIAL AUDITOR"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="py-auto flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoRsm}
                    alt="RSM logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"OFFICIAL LEGAL ADVISER"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="py-auto flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoRajahTananAsia}
                    alt="Rajah & Tann logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"PARTNERS"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="py-auto mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoAstar}
                    alt="Astar logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>

                <div className="py-auto mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoEnterpriseSingapore}
                    alt="Enterprise Singapore logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>

                <div className="py-auto mb-9 flex h-[12.5rem] items-center justify-center border border-gray-175 shadow-1">
                  <img
                    src={logoPaiaFromcbre}
                    alt="Paia From CBRE logo"
                    className="m-auto max-h-[5rem] max-w-[20rem]"
                  />
                </div>
              </Column>
            </Row>

            <Block>
              <h2 className="mb-6 border-b border-gray-175 pb-3 text-left font-public-sans text-base font-bold">
                {"EMERGING ENTERPRISE ©"}
              </h2>

              <div className="flex">
                <h3 className="mr-auto text-left font-public-sans text-lg font-normal">
                  Quicklinks
                </h3>

                <EventsAwardsNavigationMenu
                  menuData={eEQuickLinksMenu}
                  className="m-0 border-0 p-0"
                  linkClassName="after:bg-eventAwards-e50-accent text-base font-public-sans font-light"
                />
              </div>
            </Block>
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
