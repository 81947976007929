import { BlockTitle } from "@blocks/Blocks";
import useGe2025Videos from "@hooks/useGe2025Videos";
import { useSendInViewGTM } from "@pages/SingaporeGe/2025/hooks/useSendInViewGTM";
import { GaCommonObject } from "@pages/SingaporeGe/2025/utils/types";
import { CommonClassNameAndTestId } from "@typings/Common";

import VideoCard from "./VideoCard";

export default function Videos({
  className,
  testId,
  gaObject,
}: CommonClassNameAndTestId & GaCommonObject): React.ReactElement {
  const videos = useGe2025Videos();

  const { ref: videosRef } = useSendInViewGTM({
    eventsObject: gaObject?.eventObject,
  });

  const parameters = gaObject?.parameters || [];

  return (
    <>
      {videos.length > 0 ? (
        <div className={className} data-testid={testId} ref={videosRef}>
          <BlockTitle text="LATEST VIDEO" rootClassName="mb-4" />

          <VideoCard {...videos[0]} parameters={parameters} />
        </div>
      ) : null}
    </>
  );
}
