import { ReactElement } from "react";
import BasicCard from "@components/ArticleCard/BasicCard";
import { BlockTitle } from "@src/app/blocks/Blocks";
import { CardVariations } from "@src/app/components/ArticleCard/types";
import { SanitizedSectionArticle } from "@typings/Cue";

export type PodcastOverviewProps = {
  podcastArticle?: SanitizedSectionArticle[];
};

export default function PodcastOverview({
  podcastArticle,
}: PodcastOverviewProps): ReactElement {
  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-3/10",
      loading: "eager",
    },
    content: {
      width: "w-7/10",
      extraClass: "pl-4",
    },
    kicker: {
      color: "text-gray-515",
    },
    title: {
      size: "text-lg",
      color: "text-gray-850",
    },
  };

  if (!podcastArticle) return <></>;

  return (
    <div
      data-testid="podcast-overview-component"
      className="border-top mx-3 border-gray-850 pt-2"
    >
      <BlockTitle text="PODCASTS" rootClassName="mb-4" />

      <div className="flex flex-col lg:grid lg:grid-cols-2">
        {podcastArticle.map((article, index) => {
          const mainSection = article.sections?.[0];
          return (
            <BasicCard
              id={article.id}
              slug={article.slug}
              key={index}
              title={article.displaySetting?.displayHeadline || article.title}
              media={article.media}
              variations={variant}
              hasUpdatedTimestampDisplay={
                article.displaySetting?.hasUpdatedTimeDisplayed
              }
              defaultImage={
                mainSection
                  ? { directoryName: mainSection.uniqueName }
                  : undefined
              }
              rootClassName="mb-4 lg:mr-4"
            />
          );
        })}
      </div>
    </div>
  );
}
