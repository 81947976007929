import MyAccountMyBTPage from "@pages/MyAccount/components/MyAccountMyBTPage/MyAccountMyBTPage";
import MyAccountRecentReadPage from "@pages/MyAccount/components/MyAccountRecentReadPage/MyAccountRecentReadPage";
import {
  MyAccountActiveProps,
  MyAccountTabsEnum,
} from "@pages/MyAccount/utils/types";

export default function MyAccountActivePage({
  activeTab,
}: MyAccountActiveProps): React.ReactElement {
  if (activeTab === MyAccountTabsEnum.myBT) return <MyAccountMyBTPage />;

  if (activeTab === MyAccountTabsEnum.recent)
    return <MyAccountRecentReadPage />;

  return <></>;
}
