import Ad from "@components/Advertisement/Ad";
import ComingUp from "@pages/SingaporeGe/2025/components/ComingUp";
import Highlights from "@pages/SingaporeGe/2025/components/Highlights";
import IframeEmbed from "@pages/SingaporeGe/2025/components/IframeEmbed";
import RelatedStoriesOverview from "@pages/SingaporeGe/2025/components/RelatedStoriesOverview";
import Videos from "@pages/SingaporeGe/2025/components/Videos";
import Whatsapp from "@pages/SingaporeGe/2025/components/Whatsapp";
import { SingaporeGEBasicProps } from "@pages/SingaporeGe/2025/utils/types";
import { ConfigTypeEnums } from "@typings/enums";
import { cn } from "@util/helpers";

export default function SingaporeGeComponentFactory({
  configData,
  highlights,
  relatedStories,
  comingUp,
  index,
  parentIndex,
}: SingaporeGEBasicProps): React.ReactElement {
  if (typeof configData === "string" || !configData) return <></>;

  switch (configData.type) {
    case ConfigTypeEnums.TOPSTORIES:
      if (!highlights) return <></>;

      return (
        <Highlights
          articles={highlights}
          className={cn("lg:mb-0", {
            "border-t border-gray-850 pt-2": parentIndex !== 0 || index !== 0,
          })}
          testId="ge-2025-top-stories-component"
          gaObject={configData.gaObject}
        />
      );

    case ConfigTypeEnums.RELATEDSTORIES:
      if (!relatedStories) return <></>;

      return (
        <RelatedStoriesOverview
          articles={relatedStories}
          className={cn({
            "border-t border-gray-850 pt-2": parentIndex !== 0 || index !== 0,
          })}
          testId="ge-2025-related-stories"
          gaObject={configData.gaObject}
        />
      );

    case ConfigTypeEnums.IFRAME:
      if (!configData) return <></>;

      return (
        <IframeEmbed
          key={`iframe-${index}`}
          configData={configData}
          index={index || 0}
          gaObject={configData.gaObject}
        />
      );

    case ConfigTypeEnums.ADS:
      if (!configData.adType) return <></>;

      return (
        <Ad
          adType={configData.adType}
          className="flex items-center justify-center lg:sticky lg:top-20"
        />
      );

    case ConfigTypeEnums.COMINGUP:
      if (!comingUp) return <></>;

      return (
        <ComingUp
          article={comingUp}
          className={cn("mb-8 border-t border-gray-850 pt-2 lg:border-0", {
            "lg:border-t": parentIndex !== 0 || index !== 0,
          })}
          testId="ge-2025-coming-up-component"
          gaObject={configData.gaObject}
        />
      );

    case ConfigTypeEnums.VIDEOS:
      return (
        <Videos
          testId="ge-2025-videos-component"
          className={cn("border-t border-gray-850 pt-2", {
            "border-none": !comingUp,
          })}
          gaObject={configData.gaObject}
        />
      );

    case ConfigTypeEnums.WHATSAPP:
      return (
        <Whatsapp
          testId="ge-2025-whatsapp-component"
          gaObject={configData.gaObject}
        />
      );

    default:
      return <></>;
  }
}
