import closeIcon from "@assets/icons/icon-close.svg";
import BasicCard from "@components/ArticleCard/BasicCard";
import {
  MyAccountRecentReadBasicCardProps,
  MyAccountRecentReadProps,
} from "@pages/MyAccount/utils/types";
import useRecentReadStore from "@store/useRecentReadStore";

export function MyAccountRecentReadBasicCard({
  article,
  handleDeleteArticle,
}: MyAccountRecentReadBasicCardProps): React.ReactElement {
  const section = article.sections?.[0];
  const deletedArticleId = useRecentReadStore((state) => state.deleteArticleId);

  const handleDelete = () => {
    deletedArticleId([{ id: article.id }]);
    handleDeleteArticle(article.id);
  };

  return (
    <div
      className="flex justify-between border-b border-gray-175 py-4 first:pt-0 last:border-b-0"
      data-testid={`recent-read-basic-card-${article.id}`}
    >
      <BasicCard
        id={article.id}
        title={article.displaySetting?.displayHeadline || article.title}
        section={section}
        slug={`${article.urlPath}?ref=recent_read`}
        updated={article.updated}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        variations={{
          section: {
            extraClass: "text-gray-850",
          },
        }}
      />

      <button
        onClick={handleDelete}
        className="ml-6 flex-none"
        data-testid="recent-read-delete-button"
      >
        <img src={closeIcon} alt="close" width={36} height={36} />
      </button>
    </div>
  );
}

export default function MyAccountRecentReadList({
  articleList,
  loadMoreRef,
  handleDeleteArticle,
}: MyAccountRecentReadProps): React.ReactElement {
  return (
    <div data-testid="my-account-recent-read-list-component">
      {articleList.map((article, index) => {
        if (!article.id) return <></>;

        return (
          <>
            <MyAccountRecentReadBasicCard
              key={article.id}
              article={article}
              handleDeleteArticle={(id) => {
                handleDeleteArticle(id);
              }}
            />

            {(index + 1) % 10 === 0 ? <div ref={loadMoreRef} /> : null}
          </>
        );
      })}
    </div>
  );
}
