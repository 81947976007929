import { LambdaResponsePayload } from "@api/endpoint-handlers/bff-functions/Alacrity/types";
import Button from "@components/AccessibleComponents/Button";
import { SocialMediaType } from "@components/Button/types";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
  ARTICLE_SUBSHARE_BODY_TEXT,
  ARTICLE_SUBSHARE_EMAIL_SUBJ,
  GIFTER_SOCIALS,
} from "@pages/Article/components/ArticleSubshare/constants";
import { cn } from "@util/helpers";

export default function Socials({
  data: { shortURL, giftCount = 0 },
}: LambdaResponsePayload): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();

  const showNavigator = typeof navigator.share === "function" && shortURL;

  const shareOnClick = (shareName: string) => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "click",
      eventLabel: `gift this article::${shareName}::${giftCount}`,
      nonInteraction: false,
    });

    if (!showNavigator) return;

    navigator.share({
      text: ARTICLE_SUBSHARE_BODY_TEXT, // Current page title
      url: shortURL, // Current page URL
    });
  };

  const renderSocialIconDisplay = (icon: string, label: string) => {
    return (
      <>
        <img
          src={icon}
          alt={label}
          width={40}
          height={40}
          className="mx-auto aspect-square"
        />
        <span className="font-poppins text-4xs font-medium">{label}</span>
      </>
    );
  };

  return (
    <>
      {shortURL ? (
        <div
          className="mb-3 flex w-full justify-between border-b border-b-gray-275 pb-3"
          data-testid="gifter-socials-component"
        >
          {GIFTER_SOCIALS.map(({ label, icon, element }) => {
            const ShareButton = element;
            const isWhatsapp = SocialMediaType.whatsapp === label.toLowerCase();

            return (
              <>
                <ShareButton
                  key={label}
                  url={shortURL}
                  title={ARTICLE_SUBSHARE_BODY_TEXT}
                  subject={ARTICLE_SUBSHARE_EMAIL_SUBJ}
                  body={ARTICLE_SUBSHARE_BODY_TEXT}
                  className={cn("block max-w-[66px] flex-1 text-center", {
                    "hidden lg:block": isWhatsapp,
                  })}
                  onClick={() => {
                    shareOnClick(label.toLowerCase());
                  }}
                >
                  {renderSocialIconDisplay(icon, label)}
                </ShareButton>

                <Button
                  className={cn("hidden max-w-[66px] flex-1 text-center", {
                    "block lg:hidden": isWhatsapp,
                  })}
                  onPress={() => {
                    shareOnClick(label.toLowerCase());
                  }}
                >
                  {renderSocialIconDisplay(icon, label)}
                </Button>
              </>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
