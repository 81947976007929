import { Link } from "react-router-dom";
import BasicCard from "@components/ArticleCard/BasicCard";
import {
  MyAccounMyBTPopoverListProps,
  MyAccountMyBTBasicCardProps,
} from "@pages/MyAccount/utils/types";

export default function MyAccounMyBTPopoverList({
  tagWithArticleList,
}: MyAccounMyBTPopoverListProps): React.ReactElement {
  return (
    <div data-testid="my-account-recent-read-popover-list-component">
      <div>
        {tagWithArticleList.map((tagList) => {
          const { tag, articles } = tagList;

          return (
            <div className="mb-6 last:mb-0" key={`my-bt-popover-${tag.id}`}>
              <Link
                to={tag.urlPath}
                reloadDocument
                className="mb-3 inline-block w-max rounded-sm bg-gray-250 p-2 font-poppins text-4xs text-gray-850"
              >
                {tag.name}
              </Link>

              <div>
                {articles.map((article) => {
                  return (
                    <MyAccounMyBTPopoverBasicCard
                      key={article.id}
                      article={article}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function MyAccounMyBTPopoverBasicCard({
  article,
}: MyAccountMyBTBasicCardProps): React.ReactElement {
  return (
    <div
      className="flex justify-between border-b border-gray-175 py-2 first:pt-0 last:border-b-0"
      data-testid={`recent-read-popover-basic-card-${article.id}`}
    >
      <BasicCard
        id={article.id}
        title={article.displaySetting?.displayHeadline || article.title}
        slug={`${article.urlPath}?ref=mybt`}
        updated={article.updated}
        variations={{
          section: {
            extraClass: "text-gray-850",
          },
          title: {
            size: "text-base",
            color: "text-gray-850",
            extraClass: "font-semibold",
          },
        }}
      />
    </div>
  );
}
