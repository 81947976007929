export enum FieldKey {
  Description = "description",
  MetaTitle = "metaTitle",
  MetaDescription = "metaDescription",
  Displayname = "displayName",
  ImageDescriptionLogo = "image:descriptionLogo",
  Template = "template",
  StoryQueues = "storyQueue",
  Id = "id",
  Source = "source",
  Sourceid = "sourceid",
  ModifiedAt = "modifiedAt",
  CreatedAt = "createdAt",
}
