import { RouteFactory } from "@app/routePaths";
import { PodcastPageContext } from "@app/types/Page";
import { BlockTitle } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useCategoriesFromPath } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import PodcastsTopFeaturedCarousel from "@pages/Section/layouts/PodcastsKeywordPage/components/PodcastsTopFeaturedCarousel";
import { sectionNavigationItems } from "@util/constant";
import { cn, getStaticSectionJSONLD } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import MorePodcasts from "./components/MorePodcasts/MorePodcasts";
import PodcastsKeywords from "./components/PodcastsKeywords";
import { PODCAST_KEYWORDS_DATA } from "./util/constants";

export type PodcastPageContentProps = Pick<PodcastPageContext, "data">;

export default function PodcastPageContent({
  data: {
    title,
    podcastsFeaturedArticles,
    marketFocusArticles,
    moneyHacksArticles,
    lensOnArticles,
    btCorrespondentsArticles,
    brandedContentArticles,
    morePodcastsArticles,
    seoFooterData,
  },
}: PodcastPageContentProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const { parentCategory } = useCategoriesFromPath();

  const termName = "Podcasts";
  const articleMapping = [
    { name: "lens_on", articles: lensOnArticles },
    { name: "money_hacks", articles: moneyHacksArticles },
    { name: "market_focus", articles: marketFocusArticles },
    { name: "bt_correspondents", articles: btCorrespondentsArticles },
    { name: "branded_podcasts", articles: brandedContentArticles },
  ];

  return (
    <div className="w-full" data-testid="podcast-page-content-component">
      <AdSettings
        adNames={["lb1", "imu1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.podcasts}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={RouteFactory.podcasts}
        jsonLd={getStaticSectionJSONLD("Podcasts", RouteFactory.podcasts)}
      />

      <GAData
        title="Podcasts_Index"
        level2="podcasts"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header />

      <PulseEntry />

      {parentCategory && sectionNavigationItems?.[parentCategory] ? (
        <div className="w-full flex-none">
          <Container>
            <h1
              className={cn(
                "pb-3 pt-6 font-playfair text-8xl uppercase",
                "border-b border-gray-850"
              )}
            >
              PODCASTS
            </h1>
          </Container>
        </div>
      ) : null}

      <Container>
        <Row>
          <Column rootClassName={cn("w-full mt-9 md:pr-6", "lg:w-8/12")}>
            <PodcastsTopFeaturedCarousel data={podcastsFeaturedArticles} />
          </Column>

          <Column rootClassName={cn("w-full mt-9 px-0 lg:px-3 lg:w-4/12")}>
            <Ad
              adType="imu1"
              className="flex flex-col items-center justify-center lg:sticky lg:top-[70px]"
            />
          </Column>
        </Row>

        <Row>
          {PODCAST_KEYWORDS_DATA.map(
            ({
              apple_link,
              description,
              id,
              spotify_link,
              tag,
              youtube_link,
              thumbnailSrc,
            }) => {
              // Gets the appropriate article list based on mapping.
              const articles = articleMapping
                .filter(({ name: _name }) => id === _name)
                .flatMap(({ articles }) => articles);
              const blockTitle = tag.name.toUpperCase();
              return (
                <Column
                  rootClassName={cn("w-full py-8", {
                    "py-0": articles.length <= 0,
                  })}
                  key={id}
                >
                  <PodcastsKeywords
                    {...{
                      blockTitle,
                      articles,
                      description,
                      spotify_link,
                      apple_link,
                      youtube_link,
                      tag,
                      cardName: id,
                      thumbnailSrc,
                    }}
                  />
                </Column>
              );
            }
          )}
        </Row>

        <Row>
          <Column rootClassName="w-full lg:w-8/12 py-8">
            <BlockTitle
              text="MORE PODCASTS"
              rootClassName="border-t border-black pt-3"
            />

            <MorePodcasts articles={morePodcastsArticles} />
          </Column>

          <Column rootClassName="w-full py-8 px-0 lg:px-3 lg:w-4/12">
            <Ad
              adType="imu2"
              className="flex flex-col items-center justify-center lg:sticky lg:top-[70px]"
            />
          </Column>
        </Row>
      </Container>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
