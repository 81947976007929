import { useState } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import { Tag } from "@app/types/Cue";
import Button from "@components/AccessibleComponents/Button";
import KeywordFilter from "@components/KeywordFilter";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { myBTTagsDefinedList } from "@pages/MyBT/constants";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import cx from "classnames";

import "@greglaisph/react-splide/css";

export default function Section3({
  testId,
}: TestIdClassNameProps): React.ReactElement {
  const [activeTab, setActiveTab] = useState("recommended");
  const defaultTabClass =
    "font-medium text-base lg:text-3xl py-2 px-2 lg:px-4 font-poppins outline-none border-b-[3px] transition-colors capitalize";

  return (
    <div className="px-3 py-36" data-testid={testId}>
      <h2 className="mb-14 text-center font-poppins text-10xl font-semibold lg:text-14xl">
        {"We've got"}
        <br />
        keywords for
        <br />
        topics that matter.
      </h2>

      <div className="text-center">
        <div className="inline-block border-b border-gray-465">
          {myBTTagsDefinedList.map(({ name, label }) => {
            return (
              <Button
                key={`${name}-tab-button`}
                className={cx(
                  defaultTabClass,
                  activeTab === name
                    ? "border-gray-850 text-gray-850"
                    : "border-white text-gray-465"
                )}
                onPress={() => {
                  setActiveTab(name);
                }}
                data-testid={
                  testId ? `${testId}-tab-button-${name}` : undefined
                }
              >
                {label}
              </Button>
            );
          })}
        </div>

        {myBTTagsDefinedList.map(({ name, tags }) => {
          return (
            <TabContent
              key={`${name}-tab-content`}
              tags={tags}
              testId={testId ? `${testId}-tab-content-${name}` : undefined}
              isActive={activeTab === name}
            />
          );
        })}
      </div>
    </div>
  );
}

function TabContent({
  testId,
  tags,
  isActive,
}: TestIdClassNameProps & {
  tags: Tag[];
  isActive: boolean;
}): React.ReactElement {
  const options: Options = {
    type: "loop",
    drag: "free",
    focus: "center",
    perPage: 3,
    autoScroll: {
      speed: 0.5,
    },
    gap: "1.5rem",
    pagination: false,
    arrows: false,
    padding: "1.5rem",
    mediaQuery: "min",
    fixedWidth: "65%",
    breakpoints: {
      576: {
        fixedWidth: "41%",
      },
      992: {
        fixedWidth: "30%",
      },
      1200: {
        fixedWidth: "17%",
      },
    },
  };
  return (
    <>
      {isActive ? (
        <div data-testid={testId}>
          {tags.length > 0 ? (
            <Splide
              options={options}
              extensions={{ AutoScroll }}
              hasTrack={false}
            >
              <SplideTrack className="py-8">
                {tags.map((tag) => {
                  const { uri, thumbnail } = tag;

                  return (
                    <SplideSlide
                      key={`${uri}-splide-slide`}
                      className="inline-block p-4 shadow-lg"
                    >
                      {thumbnail ? (
                        <img
                          src={thumbnail}
                          height={500}
                          width={500}
                          className="aspect-square"
                        />
                      ) : null}

                      <KeywordFilter
                        tag={tag}
                        rootClassName="mt-4"
                        tagClassName="!font-normal"
                        iconClassName="bg-gray-850 text-white rounded-full p-1 aspect-square text-3xs"
                        disableBorder
                        disableTooltip
                      />
                    </SplideSlide>
                  );
                })}
              </SplideTrack>
            </Splide>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
