import profileAnoy from "@assets/icon-profile-anonymous.svg";
import profileSubs from "@assets/icon-profile-subscriber.svg";
import Container from "@components/Container/Container";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GetStartedButton from "./GetStartedButton";

export default function Section4(): React.ReactElement {
  return (
    <div className="mb-36 bg-gray-225 py-36">
      <Container rootClassName="flex flex-col items-center justify-center gap-8 lg:flex-row px-8 lg:px-36">
        <div className="w-full rounded-sm bg-white p-6 text-center shadow-sm lg:w-1/2">
          <img
            src={profileSubs}
            width={75}
            height={75}
            className="mx-auto mb-6 aspect-square"
          />

          <h2 className="mb-14 text-8xl font-semibold">
            I am a BT
            <br />
            subscriber
          </h2>

          <p className="mb-14 text-lg">
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Follow up to 30 keywords
          </p>

          <GetStartedButton rootClassName="block w-full border-gray-850 bg-gray-850 hover:bg-white hover:text-gray-850 text-white" />
        </div>

        <div className="w-full rounded-sm bg-white p-6 text-center lg:w-1/2">
          <img
            src={profileAnoy}
            width={75}
            height={75}
            className="mx-auto mb-6 aspect-square"
          />

          <h2 className="mb-14 text-8xl font-semibold">
            I am not a BT
            <br />
            subscriber
          </h2>

          <p className="mb-14 text-lg">
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            Follow up to 3 keywords
          </p>

          <GetStartedButton rootClassName="block w-full border-gray-850 bg-gray-850 hover:bg-white hover:text-gray-850 text-white" />
        </div>
      </Container>
    </div>
  );
}
