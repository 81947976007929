import { OS_INDEX_ALIAS } from "@app/util/constant";
import { sortByStickyAndRecentDate } from "@caas/queries/constant";
import { CaasQuery } from "@caas/types";

export const queryByKeywords = (
  keywordUrlPath: string | string[],
  size: number,
  page: number,
  source?: string[],
  sort?: object[]
): CaasQuery => {
  const from = size * (page - 1);

  const keywords =
    typeof keywordUrlPath === "string" ? [keywordUrlPath] : keywordUrlPath;

  const keywordsQuery = [
    {
      nested: {
        path: "data.context.tags",
        query: [{ terms: { "data.context.tags.urlPath.keyword": keywords } }],
      },
    },
    {
      nested: {
        path: "data.context.tags",
        query: [
          { terms: { "data.context.tags.urlPathHistory.keyword": keywords } },
        ],
      },
    },
  ];

  return {
    index: OS_INDEX_ALIAS,
    search: {
      query: {
        bool: {
          should: keywordsQuery,
          minimum_should_match: 1,
          must: [
            {
              term: {
                "data.context.displaySetting.hideFromSectionListing": false,
              },
            },
          ],
        },
      },
      size: size,
      from: from > 0 ? from : 0,
      sort: sort || sortByStickyAndRecentDate,
      ...(source ? { _source: source } : undefined),
    },
  };
};
