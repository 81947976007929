import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { reactRouterRoutes } from "./app/routes";

const init = () => {
  const root = document.getElementById("app");

  if (!root) {
    throw new Error("Missing #root element for hydration");
  }

  const container = root;
  const router = createBrowserRouter(reactRouterRoutes);

  if (
    process.env.NODE_ENV === "production" &&
    !window.__staticRouterHydrationData
  ) {
    // eslint-disable-next-line no-console
    console.error(
      "Missing hydration context window.__staticRouterHydrationData for Production build."
    );
  }

  ReactDOM.hydrateRoot(
    container,
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
} else {
  init();
}
