import { type Payload } from "@api/endpoint-handlers/bff-functions";
import { MAX_GIFTS_PER_MONTH } from "@pages/Article/components/ArticleSubshare/constants";

import makeAlacrityApiCall from "./AWSSignatureService";
import {
  LAMBDA_REQUEST_ACTION_TYPES,
  LAMBDA_RESPONSE_MESSAGES,
  LAMBDA_RESPONSE_STATUS,
  LAMBDA_RESPONSE_TYPES,
  LambdaRequestPayload,
  LambdaResponsePayload,
} from "./types";

const SHORTEN_API_ENDPOINT = "/shorten";
const GIFT_COUNT_API_ENDPOINT = "/gift/count";
const GIT_URL_API_ENDPOINT = "/gift";
const GIT_VERIFY_TOKEN_API_ENDPOINT = "/gift/view";

const PUBLICATION = "bt";
const REQUEST_TYPE = "Web";
const USER_TYPE = "MYSPH";
const GIFT_TOKEN_VALIDITY_DAYS = 14;

async function generateShortnerURL({
  data: { articleURL },
}: LambdaRequestPayload) {
  try {
    const alacrityRequestPayload = {
      pub: PUBLICATION,
      longUrl: articleURL,
    };
    const alacrityRequestHeaders = {};

    const alacrityResponse = await makeAlacrityApiCall(
      SHORTEN_API_ENDPOINT,
      alacrityRequestHeaders,
      alacrityRequestPayload
    );

    if (alacrityResponse.shorturl) {
      const lambdaResponse: LambdaResponsePayload = {
        status: LAMBDA_RESPONSE_STATUS.SUCCESS,
        message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_SHORTNER,
        data: {
          responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_SHORTNER,
          shortURL: alacrityResponse.shorturl,
        },
      };
      return lambdaResponse;
    }
  } catch (e) {
    const sanitizedArticleURL = articleURL?.replace(/[\n\r]/g, "");
    // eslint-disable-next-line no-console
    console.error(
      `Error occurred while generating shortener URL for article: ${sanitizedArticleURL}. Details: ${(e as Error).message}`
    ); // eslint-disable-line no-console
  }
}

async function generateGiftURL({
  data: { articleURL, aovisitorID },
}: LambdaRequestPayload) {
  const alacrityRequestHeaders = {
    pub: PUBLICATION,
    articleUrl: articleURL,
    requestType: REQUEST_TYPE,
    UserType: USER_TYPE,
    validityDays: GIFT_TOKEN_VALIDITY_DAYS,
    aovisitorID: aovisitorID,
  };

  const alacrityRequestPayload = {};

  const countResponse = await makeAlacrityApiCall(
    GIFT_COUNT_API_ENDPOINT,
    alacrityRequestHeaders,
    alacrityRequestPayload
  );

  const statusCode = countResponse.code;

  if (statusCode === 200) {
    const giftCount = countResponse.data?.giftCount;
    const giftURL = countResponse.data?.giftUrl;

    if (giftURL) {
      return <LambdaResponsePayload>{
        status: LAMBDA_RESPONSE_STATUS.SUCCESS,
        message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_ALREADY_GIFTED,
        data: {
          responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_ALREADY_GIFTED,
          shortURL: giftURL,
          giftCount: giftCount,
        },
      };
    }

    if (giftCount < MAX_GIFTS_PER_MONTH) {
      const alacrityResponse = await makeAlacrityApiCall(
        GIT_URL_API_ENDPOINT,
        alacrityRequestHeaders,
        alacrityRequestPayload
      );
      const statusCode = alacrityResponse.code;

      if (statusCode === 200) {
        return <LambdaResponsePayload>{
          status: LAMBDA_RESPONSE_STATUS.SUCCESS,
          message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_GIFT,
          data: {
            responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_GIFT,
            shortURL: alacrityResponse.data,
            giftCount: giftCount,
          },
        };
      } else {
        return <LambdaResponsePayload>{
          status: LAMBDA_RESPONSE_STATUS.ERROR,
          message: LAMBDA_RESPONSE_MESSAGES.ERROR_GIFT,
          data: {
            responseType: LAMBDA_RESPONSE_TYPES.ERROR_GIFT,
          },
        };
      }
    } else {
      // Monthly quota exceeded.
      return <LambdaResponsePayload>{
        status: LAMBDA_RESPONSE_STATUS.ERROR,
        message:
          LAMBDA_RESPONSE_MESSAGES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED,
        data: {
          responseType:
            LAMBDA_RESPONSE_TYPES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED,
          giftCount: giftCount,
        },
      };
    }
  }
}

async function verifyGiftToken({
  data: { articleURL, aovisitorID, giftToken },
}: LambdaRequestPayload) {
  const alacrityRequestHeaders = {
    pub: PUBLICATION,
    articleUrl: articleURL,
    requestType: REQUEST_TYPE,
    UserType: USER_TYPE,
    validityDays: GIFT_TOKEN_VALIDITY_DAYS,
    aovisitorID: aovisitorID,
    giftToken: giftToken,
  };

  const alacrityRequestPayload = {};

  const alacrityResponse = await makeAlacrityApiCall(
    GIT_VERIFY_TOKEN_API_ENDPOINT,
    alacrityRequestHeaders,
    alacrityRequestPayload
  );

  const statusCode = alacrityResponse.code;

  if (statusCode === 200) {
    const giftCreatedDate = alacrityResponse.data;
    const viewGiftStatus = alacrityResponse.viewGiftStatus;

    // giftCreatedDate is in the format of  "2023-11-02 05:07:35";
    // Parse the giftCreatedDate string into a Date object
    const giftDate = new Date(giftCreatedDate);

    // Calculate the current date
    const currentDate = new Date();

    // Calculate the difference in days
    const timeDifference = currentDate.getTime() - giftDate.getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    // Check if the gift is still valid within GIFT_TOKEN_VALIDITY_DAYS
    if (daysDifference <= GIFT_TOKEN_VALIDITY_DAYS) {
      return <LambdaResponsePayload>{
        status: LAMBDA_RESPONSE_STATUS.SUCCESS,
        message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_VALID_GIFT_TOKEN,
        data: {
          responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN,
          viewGiftStatus: viewGiftStatus,
        },
      };
    }
  }

  return <LambdaResponsePayload>{
    status: LAMBDA_RESPONSE_STATUS.ERROR,
    message: LAMBDA_RESPONSE_MESSAGES.ERROR_INVALID_GIFT_TOKEN,
    data: {
      responseType: LAMBDA_RESPONSE_TYPES.ERROR_INVALID_GIFT_TOKEN,
    },
  };
}

export const handleAlacrity = async (payload: Payload) => {
  // Default error response.
  const lambdaDefaultResponse: LambdaResponsePayload = {
    status: LAMBDA_RESPONSE_STATUS.ERROR,
    message: LAMBDA_RESPONSE_MESSAGES.ERROR_GENERIC,
    data: {
      responseType: LAMBDA_RESPONSE_TYPES.ERROR_GENERIC,
    },
  };

  let lambdaResponse: LambdaResponsePayload | undefined;

  const lambdaRequestPayload = payload as LambdaRequestPayload;
  const action = lambdaRequestPayload.action;

  switch (action) {
    case LAMBDA_REQUEST_ACTION_TYPES.GENERATE_SHORTEN_URL:
      lambdaResponse = await generateShortnerURL(lambdaRequestPayload);
      break;
    case LAMBDA_REQUEST_ACTION_TYPES.GENERATE_GIFT_URL:
      lambdaResponse = await generateGiftURL(lambdaRequestPayload);
      break;
    case LAMBDA_REQUEST_ACTION_TYPES.VERIFY_GIFT_TOKEN:
      lambdaResponse = await verifyGiftToken(lambdaRequestPayload);
      break;
    default:
      lambdaResponse = {
        status: LAMBDA_RESPONSE_STATUS.ERROR,
        message: LAMBDA_RESPONSE_MESSAGES.ERROR_UNSUPPORTED_ACTION,
        data: {
          responseType: LAMBDA_RESPONSE_TYPES.ERROR_UNSUPPORTED_ACTION,
        },
      };
      break;
  }

  // If lambdaResponse is defined, return it; otherwise, return the default response.
  return lambdaResponse || lambdaDefaultResponse;
};
