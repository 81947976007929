import { HTMLAttributes } from "react";
import { cn } from "@util/helpers";

export type ToolTipProps = Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function TooltipTriangle({
  className,
}: ToolTipProps): React.ReactElement {
  return (
    <div
      data-testid="tooltip-triange-component"
      className={cn(className)}
    ></div>
  );
}
