import { RouteFactory } from "@app/routePaths";
import pulseOgImage from "@assets/pulse-og-image.jpg";
import { URL_ORIGIN } from "@components/MetaTags/constants";
import {
  getUpdatedOgImageUrl,
  renderPageTitleV2,
} from "@components/MetaTags/helpers";
import { fetchShortUrl } from "@pages/Article/Article.server";
import { dayjsSingaporeTimezone, getEnvironment } from "@util/constant";
import {
  type GaCustomEventTrackerObject,
  getEnvironmentDomain,
} from "@util/helpers";
import dayjs from "dayjs";

import type { PulseArticles } from "./types";
import { SanitizedPulseData } from "./types";

export const getActiveArticleId = (pathname: string): string => {
  const activeArticleId = pathname.split("/");
  const lastItem = activeArticleId[activeArticleId.length - 1];
  return /^[0-9]+$/.test(lastItem) ? lastItem : "";
};

export const filteredPulseArticles = (
  articleId: string,
  pulseArticles: SanitizedPulseData[]
) => {
  const filterActiveArticleId = pulseArticles.filter(
    (p: SanitizedPulseData) => p.id === articleId
  );

  if (filterActiveArticleId.length <= 0) return pulseArticles;

  // remove the item from the array
  const filteredPulseArticles = pulseArticles.filter(
    (p: SanitizedPulseData) => p.id !== articleId
  );

  const firstFilteredArticle = filterActiveArticleId?.[0];

  // add it at the beginning
  filteredPulseArticles.unshift({ ...firstFilteredArticle });
  return filteredPulseArticles;
};

export const getGaDataObject = (
  title: string = "pulse intro",
  virtualUrl: string = "pulse/intro",
  id: string = "",
  articles?: SanitizedPulseData[]
) => {
  const gaObject: GaCustomEventTrackerObject = {
    event: "virtual_pv",
    level2: "pulse",
    contentcat: "free",
    contenttype: "listing",
    title,
    virtual_url: virtualUrl,
  };

  if (id !== "" && articles) {
    const currentSlideData = articles.find((article) => article.id === id);
    gaObject["articleid"] = id;
    gaObject["cue_articleid"] = id;
    gaObject["author"] = currentSlideData?.authors || "(not set)";
    gaObject["pubdate"] =
      dayjs(currentSlideData?.published)
        .tz(dayjsSingaporeTimezone)
        .format("YYYY-MM-DD HH:mm:ss") || "(not set)";
    gaObject["title"] = currentSlideData?.title || "(not set)";
    gaObject["contenttype"] = "article";
    gaObject["virtual_url"] =
      currentSlideData?.slug.substring(1) || "(not set)";
  }

  return gaObject;
};

export const getPulseData = (data: PulseArticles[]) => {
  const santitizedData: Promise<SanitizedPulseData>[] = data
    .filter((x) => x.id)
    .map(async (article): Promise<SanitizedPulseData> => {
      const environmentDomain = getEnvironmentDomain(getEnvironment());
      const slug = RouteFactory.pulseSlide(article.id);
      const longURL = `${environmentDomain}${slug}`;
      const shortUrl = await fetchShortUrl(longURL);

      return {
        ...article,
        summaries: article.summary.split("|").map((text) => text.trim()),
        shareUrl: shortUrl || longURL,
        slug: slug,
      };
    });

  return Promise.all(santitizedData);
};

export const getPulseGaTitle = (
  displayStartScreen: boolean,
  currentSlide: number,
  sortedPulseData: SanitizedPulseData[],
  metaTitle: string
) => {
  if (displayStartScreen) return "pulse intro";

  if (currentSlide === sortedPulseData.length) return "pulse outro";

  return metaTitle;
};

/**
 * Generates SEO data for the Pulse page, including title, description, slug, and image URL.
 *
 * @param pulseData - Optional sanitized Pulse data object containing metadata for the page.
 *
 * @returns An object containing the following properties:
 * - `title`: The title of the page, derived from `pulseData.title` or a default static title.
 * - `description`: The description of the page, derived from `pulseData.standfirst`,
 *   `pulseData.summaries[0]`, or a default static description.
 * - `slug`: The URL slug for the page, derived from `pulseData.slug` or a default static slug.
 * - `imageUrl`: The URL of the image, derived from `pulseData.media` or a default static image URL.
 */
export const getPulseSeoData = (pulseData?: SanitizedPulseData) => {
  const title = renderPageTitleV2({
    kind: "static",
    title: "Pulse",
  });
  const description = "Your 5 minutes daily news summary.";
  const slug = RouteFactory.pulse;
  const imageUrl = `${URL_ORIGIN}${pulseOgImage}`;

  if (!pulseData) {
    return {
      title,
      description,
      slug,
      imageUrl,
    };
  }

  const ogImage = getUpdatedOgImageUrl(pulseData?.media);

  return {
    title: pulseData?.title || title,
    description:
      pulseData?.standfirst || pulseData?.summaries?.[0] || description,
    slug: pulseData?.slug || slug,
    imageUrl: ogImage || imageUrl,
  };
};
