import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "@hooks/useWindowSize";
import { CommonClassNameAndTestId } from "@typings/Common";
import { CommonTextLinkObject } from "@typings/Cue";
import { cn } from "@util/helpers";

export type SeoFooterListProps = {
  /** List of text and links for each items */
  seoList: CommonTextLinkObject[];
  /** Name of the section */
  section: string;
  /** Currently active tab */
  activeTab: string;
} & CommonClassNameAndTestId;

/**
 * SeoFooterList component to display list of SEO footer links.
 * @param {SeoFooterListProps} props - The component props.
 * @param {Array} props.seoList - The list of SEO links.
 * @param {string} props.section - The section name.
 * @param {string} props.activeTab - The currently active tab.
 * @returns {React.ReactElement} - The rendered component.
 */
export default function SeoFooterList({
  seoList,
  testId = "seo-footer-list-components",
  activeTab,
  section,
}: SeoFooterListProps): React.ReactElement {
  const [childrenHeight, setChildrenHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const childrenRef = useRef(null);
  const { isScreenLG } = useWindowSize();

  useEffect(() => {
    if (childrenRef.current) {
      setChildrenHeight((childrenRef.current as HTMLDivElement).scrollHeight);
    }

    setIsExpanded(isScreenLG);
  }, [isScreenLG, activeTab]);

  const toggleMoreLess = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={cn("my-4 hidden", {
        block: activeTab === section,
      })}
      data-testid={testId}
    >
      <div className="-ml-4 inline-flex flex-wrap gap-y-4 divide-x divide-x-reverse divide-gray-175">
        {seoList.slice(0, 3).map((item) => {
          if (!item.link) return null;

          return (
            <Link
              to={item.link}
              key={item.text}
              className="px-4 font-poppins text-xs -tracking-2% text-gray-850 underline-offset-2 first:!border-r hover:underline"
              target="_blank"
            >
              {item.text}
            </Link>
          );
        })}
      </div>

      {seoList.length > 3 ? (
        <>
          <div
            ref={childrenRef}
            className={cn(
              "-ml-4 mt-4 inline-flex flex-wrap gap-y-4 divide-x divide-x-reverse divide-gray-175 overflow-hidden transition-all duration-300 lg:!h-full lg:transition-none",
              { "mt-0": !isExpanded }
            )}
            style={{
              height: isExpanded ? childrenHeight : 0,
            }}
          >
            {seoList.slice(3).map((item) => {
              if (!item.link) return null;

              return (
                <Link
                  to={item.link}
                  key={item.text}
                  className="px-4 font-poppins text-xs -tracking-2% text-gray-850 underline-offset-2 first:!border-r last:!border-r-0 hover:underline"
                  target="_blank"
                >
                  {item.text}
                </Link>
              );
            })}
          </div>

          {!isScreenLG ? (
            <div
              className={cn(
                "cursor-pointer font-poppins text-xs font-semibold capitalize tracking-normal !text-gray-850 [@media(hover:hover)]:hover:underline",
                { "pt-5": isExpanded }
              )}
              onClick={toggleMoreLess}
            >
              {isExpanded ? "View Less" : "View More"}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
