import { ArticleDataObject } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";

export type WealthWatchesProps = {
  articles: ArticleDataObject[];
};

export default function WealthWatches({
  articles,
}: WealthWatchesProps): React.ReactElement {
  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-1/2",
    },
    content: {
      width: "w-1/2",
      extraClass: "bg-gray-150 p-3",
      updatedTimeExtraClass: "lg:mt-12",
    },
    kicker: {
      color: "text-gray-515",
    },
  };

  return (
    <Row data-testid="wealth-watches-component">
      <Column rootClassName="w-full">
        <div className="border-t border-black pt-3"></div>
      </Column>

      <Column rootClassName="pb-6 w-full lg:w-8/12">
        <BlockTitle
          text="WATCHES"
          link="/keywords/watches"
          rootClassName="mb-3"
        />
        <>
          {articles?.map((article) => {
            const { id, media, title, paywall, created, blurb, kicker } =
              article;

            return (
              <BasicCard
                key={id}
                id={id}
                slug={article.slug}
                media={media}
                kicker={kicker?.fields?.[0]?.value}
                title={title}
                blurb={blurb}
                paywall={paywall?.contentAccess === "1"}
                variations={variant}
                updated={created}
                rootClassName="items-stretch"
              />
            );
          })}
        </>
      </Column>

      <Column rootClassName="pb-6 w-full lg:w-4/12">
        <Ad
          adType="imu3"
          className="flex flex-col items-center justify-center lg:sticky lg:top-[70px]"
        />
      </Column>
    </Row>
  );
}
