import { Section, Tag } from "@app/types/Cue";
import thriveSectionOgImage from "@assets/thrive-home-page-og.jpg";

import {
  customSectionDescriptions,
  customSectionTitles,
  URL_ORIGIN,
} from "./constants";
import { PageProps } from "./types";

/**
 * Helper function to derive the title of the page.
 * @param props The page properties, may include kind, term name, and article title.
 * @returns {string}
 */
export const renderPageTitleV2 = (props: PageProps): string => {
  const { kind } = props;

  switch (kind) {
    case "art":
    case "newsletter":
    case "static": {
      const { sectionName, title } = props;

      if (sectionName) {
        return `${title}, ${sectionName} - The Business Times`;
      }

      return `${title} - The Business Times`;
    }

    case "custom": {
      const { title } = props;
      return title;
    }
    case "keyword":
    case "storythread": {
      const { entity } = props;

      // Extract meta title using helper function
      const metaTitle = extractMetaField(entity, "metaTitle");
      return (
        metaTitle ||
        `${entity.name} Latest News & Headlines - The Business Times`
      );
    }
    case "section": {
      const { sectionName, sectionParentName } = props;

      if (customSectionTitles[sectionName]) {
        return customSectionTitles[sectionName];
      }

      // Include sectionParentName only if it's not empty and not "Home"
      const formattedParentName =
        sectionParentName && sectionParentName !== "Home"
          ? ` - ${sectionParentName}`
          : "";

      return `${sectionName}${formattedParentName} - The Business Times`;
    }
    case "author": {
      const { authorName, authorDesignation } = props;

      // Include authorDesignation only if it exists
      const designationPart = authorDesignation
        ? ` - ${authorDesignation}`
        : "";

      return `${authorName}${designationPart} - The Business Times`;
    }
    case "home":
    default:
      return "The Business Times - Get the Latest Business & Financial News";
  }
};

/**
 * Extracts the value of a specific meta field from a tag entity.
 * @param entity The tag entity containing metadata fields.
 * @param fieldName The name of the field to extract.
 * @returns {string | undefined}
 */
export const extractMetaField = (
  entity: Tag | Section,
  fieldName: string
): string | undefined => {
  // Check if the entity has fields (indicating it is a Tag)
  if ("fields" in entity && entity.fields) {
    const field = entity.fields.find((field) => field.key === fieldName);
    return field?.value;
  }

  // If the entity is a Section, return undefined and ignore metaDescription
  // Meta fields are not applicable for Section entities
  return undefined;
};

/**
 * Helper function to derive the meta description of the page from CUE TagManager.
 * @param props The page properties, may include kind, tag Entity.
 * @returns {string}
 */
export const renderMetaDescription = (props: PageProps): string => {
  const { kind } = props;

  switch (kind) {
    case "keyword":
    case "storythread": {
      const { entity } = props;
      const termName = entity.name;

      // Return metaDescription if available; otherwise, return the default format
      return (
        extractMetaField(entity, "metaDescription") ||
        `THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`
      );
    }
    case "section": {
      const { sectionName } = props;

      // Return custom description if available for specific sections, otherwise default
      return (
        customSectionDescriptions[sectionName] ||
        `THE BUSINESS TIMES ${sectionName} - Find ${sectionName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`
      );
    }
    default:
      return "The Business Times - Get the Latest Business & Financial News.";
  }
};

/**
 * Determines the Open Graph image URL and Alt text based on the page properties.
 *
 * This function checks the `kind` property, and if it is "section",
 * it further checks `sectionName` to return the appropriate Open Graph image URL and Alt text.
 * If no match is found, it returns `null` for both values.
 *
 * @param {PageProps} props - The page properties, including `kind` and optionally `sectionName`.
 * @returns {object | null} - An object containing the OG image URL and Alt text, or `null` if no match is found.
 */
export const renderCustomOgImageObject = (
  props: PageProps
): { ogImageUrl: string | undefined; ogImageAlt: string | undefined } => {
  const emptyOgObj = { ogImageUrl: undefined, ogImageAlt: undefined };
  const { kind } = props;

  if (kind === "section") {
    const { sectionName } = props;

    switch (sectionName) {
      case "Thrive":
      case "Thrive Career Guide":
      case "Thrive Personal Growth":
      case "Thrive Money Talk":
        return {
          ogImageUrl: `${URL_ORIGIN}${thriveSectionOgImage}`,
          ogImageAlt:
            "thrive by The Business Times is all about walking with Gen Zs on their adulting journey in Singapore. From internships to investments and more, find out more about what we cover.",
        };
    }
  }

  return emptyOgObj;
};

/**
 * Updates the given URL by adding or modifying query parameters.
 *
 * This function ensures that the URL includes `w=1200` and `f=webp` query parameters.
 * If the parameters already exist, they will be updated. If the input URL is invalid,
 * the function logs an error and returns the original string.
 *
 * @param urlString - The URL to be updated.
 * @returns The updated URL with the specified query parameters, or the original URL if invalid.
 */
export function getUpdatedOgImageUrl(
  urlString: string | undefined
): string | undefined {
  if (!urlString) return undefined;

  try {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);

    params.set("w", "1200");
    params.set("f", "webp");

    url.search = params.toString();
    return url.toString();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Invalid URL:", error);
    return urlString;
  }
}
