import { OSResponse, PartialCueArticle } from "@app/types/Opensearch";
import { CaasQuery } from "@caas/types";
import { WebCoreSecretsManager } from "@sphtech/web2-secrets";

export const queryCaasOpenSearch = async <T = PartialCueArticle>(
  caasQuery: CaasQuery,
  purpose: "render" | "search" | "sitemap" | "feed" = "render"
): Promise<OSResponse<T>> => {
  const caasApiUrl = (
    await WebCoreSecretsManager.getWeb2Secret("VITE_CAAS_API_URL")
  ).value;

  if (!caasApiUrl) {
    throw new Error("Missing VITE_CAAS_API_URL env variable.");
  }

  let caasApiKey;

  try {
    caasApiKey = (await WebCoreSecretsManager.getWeb2Secret("CAAS_API_KEY"))
      .value;
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error("Error retrieving CAAS_API_KEY secret", error);
  }

  if (!caasApiKey) {
    throw new Error("Missing or invalid CAAS_API_KEY secret.");
  }

  const response = await fetch(caasApiUrl, {
    method: "POST",
    headers: { "x-api-key": caasApiKey, "Request-Purpose": purpose },
    body: JSON.stringify(caasQuery),
  });

  return response.json();
};
