import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { SanitizedSectionArticle } from "@app/types/Cue";
import axios from "axios";

export const useLoadMoreStoriesBySectionKeyword = (
  section: string,
  keyword: string,
  initialList: SanitizedSectionArticle[],
  pageSize: number
) => {
  const [articleList, setArticleList] =
    useState<SanitizedSectionArticle[]>(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length >= pageSize
  );
  const [clickableHandleLoadMore, setClickableHandleLoadMore] =
    useState<boolean>(true);

  const handleLoadMore = async () => {
    setClickableHandleLoadMore(false);

    const lastArticle = articleList?.[articleList.length - 1];
    const sort = lastArticle?.sort;

    if (articleList.length === 0) return;
    if (typeof lastArticle === "undefined") return;
    if (typeof sort === "undefined") return;

    const newMoreArticles = await fetchStories(section, keyword, sort);

    if (newMoreArticles.length < pageSize) setHasMoreArticles(false);

    setArticleList((prev) => [...prev, ...newMoreArticles]);
    setClickableHandleLoadMore(true);
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (!inView) return;
      if (!hasMoreArticles) return;

      handleLoadMore();
    },
  });

  return {
    ref,
    articleList,
    handleLoadMore,
    hasMoreArticles,
    clickableHandleLoadMore,
  };
};

const fetchStories = async (
  section: string,
  keyword: string,
  sort: (string | number)[]
) => {
  try {
    const response = await axios.post(
      "/_plat/api/v1/more-stories-by-section-keywords",
      { section, keyword, sort, size: 5 }
    );

    return response?.data || [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Error occurred while fetching stories from v1/more-stories-by-section-keywords for section: ${section}, keyword: ${keyword}, sort: ${JSON.stringify(sort)}. Details: ${(error as Error).message}`
    ); // eslint-disable-line no-console

    return [];
  }
};
