import { useEffect, useState } from "react";
import { AddToYourPageProps } from "@app/types/Common";
import { Tag } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import Button from "@components/AccessibleComponents/Button";
import KeywordFilter from "@components/KeywordFilter";
import { Transition } from "@headlessui/react";
import {
  myBTRecommendedKeywords,
  myBTRegionsKeywords,
  myBTStocksKeywords,
} from "@pages/MyBT/constants";
import Ad from "@src/app/components/Advertisement/Ad";
import useMyBtStore from "@store/useMyBtStore";
import { gaEventTracker } from "@util/helpers";
import cx from "classnames";

export default function AddToYourPage({
  testId,
  rootClassName,
  limit,
  index,
}: AddToYourPageProps): React.ReactElement {
  const [isShowing, setIsShowing] = useState(false);
  const followedTags = useMyBtStore((store) => store.tags);
  // New state to track if the initial content has been set
  const [initialContentSet, setInitialContentSet] = useState(false);
  const [initialDisplayedTags, setInitialDisplayedTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (!initialContentSet) {
      const tags = [
        ...myBTRecommendedKeywords,
        ...myBTStocksKeywords,
        ...myBTRegionsKeywords,
      ].filter(
        ({ urlPath }) => !followedTags.some((_tag) => _tag.urlPath === urlPath)
      );
      const initialTags = tags.slice(limit * 5, (limit + 1) * 5);
      setInitialDisplayedTags(initialTags);
      setInitialContentSet(true);
    }
  }, [followedTags, limit, initialContentSet]);
  const handleShowMore = () => {
    setIsShowing(!isShowing);
    const label = isShowing ? "Show Less" : "Show More";
    gaEventTracker("mybt", "click", label);
  };

  return (
    <>
      {initialDisplayedTags.length > 0 ? (
        <div
          className={cx(rootClassName, "bg-gray-150 px-8 py-6")}
          data-testid={testId}
        >
          <BlockTitle text="ADD TO YOUR PAGE?" rootClassName="mb-4" />

          {initialDisplayedTags.slice(0, 3).map((tag) => (
            <KeywordFilter
              key={tag.urlPath || tag.uri}
              tag={tag}
              rootClassName="font-poppins font-semibold capitalize text-base py-3 border-b border-gray-175"
              iconClassName="bg-gray-850 text-white p-2 rounded-full aspect-square"
              disableBorder
              disableTooltip
            />
          ))}

          <Transition
            show={isShowing}
            enter="transition-all ease-in-out duration-300"
            enterFrom="h-0 opacity-0"
            enterTo="h-[113px] opacity-100"
            leave="transition-all ease-in-out duration-300"
            leaveFrom="h-[113px] opacity-100"
            leaveTo="h-0 opacity-0"
            as="div"
          >
            {initialDisplayedTags.slice(3, 5).map((tag) => (
              <KeywordFilter
                key={tag.urlPath || tag.uri}
                tag={tag}
                rootClassName="font-poppins font-semibold capitalize text-base py-3 border-b border-gray-175"
                iconClassName="bg-gray-850 text-white p-2 rounded-full aspect-square"
                disableBorder
                disableTooltip
              />
            ))}
          </Transition>

          <Button
            className="mt-6 font-poppins font-medium text-gray-650 outline-none hover:underline"
            onPress={handleShowMore}
          >
            {isShowing ? "Show Less" : "Show More"}
          </Button>
        </div>
      ) : (
        <Ad adType="imu1" className="bg-stripes py-4" counter={index} />
      )}
    </>
  );
}
