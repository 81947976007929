import { BlockTitle } from "@blocks/Blocks";
import { useHandleMoreStories } from "@hooks/useHandleMoreStories";
import { useSendInViewGTM } from "@pages/SingaporeGe/2025/hooks/useSendInViewGTM";
import {
  GE2025_KEYWORD,
  GE2025_PAGE_SIZE,
} from "@pages/SingaporeGe/2025/utils/constants";
import { RelatedStoriesOverviewProps } from "@pages/SingaporeGe/2025/utils/types";

import RelatedStories from "./RelatedStories";

export default function RelatedStoriesOverview({
  testId,
  className,
  articles,
  gaObject,
}: RelatedStoriesOverviewProps): React.ReactElement {
  const { ref, loadMoreData } = useHandleMoreStories({
    data: articles,
    uniqueCategoryQuery: GE2025_KEYWORD,
    parentCategory: "keywords",
    pageSize: GE2025_PAGE_SIZE,
  });

  const { ref: relatedStoriesRef } = useSendInViewGTM({
    eventsObject: gaObject?.eventObject,
  });

  const parameters = gaObject?.parameters || [];

  return (
    <div data-testid={testId}>
      {[...articles, ...loadMoreData].length > 0 ? (
        <div className={className} data-testid={testId} ref={relatedStoriesRef}>
          <BlockTitle text="LATEST GE2025 NEWS" />

          <div className="divide-y border-gray-175">
            {[...articles, ...loadMoreData].map((article, index) => {
              return (
                <RelatedStories
                  article={article}
                  index={index}
                  key={article.id}
                  parameters={parameters}
                />
              );
            })}
          </div>

          <div ref={ref} />
        </div>
      ) : null}
    </div>
  );
}
