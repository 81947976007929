import { Link } from "react-router-dom";
import geLogo from "@assets/general-election/ge-logo-v1.svg";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getFormattedUrlPath } from "@util/helpers";

import { NEWS_BAR_SECTION_URL } from "./utils/constants";
import type { NewsBarPropsMobile } from "./utils/types";

export default function NewsBarMobile({
  className,
  parameters = [],
}: NewsBarPropsMobile): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();

  const handleOnClickArticlesAndKeywords = () => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "ge 2025",
      eventAction: "click",
      eventLabel: "news bar",
    });
  };

  const urlPath = getFormattedUrlPath(NEWS_BAR_SECTION_URL, parameters);

  return (
    <div className={className} data-testid="news-bar-mobile-component">
      <div className="flex w-full items-center py-3">
        <Link
          to={urlPath}
          onClick={handleOnClickArticlesAndKeywords}
          className="group relative flex w-full flex-none items-center"
        >
          <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
            <img
              src={geLogo}
              width={150}
              height={60}
              alt="Singapore GE 2025"
              className="m-auto w-[96px] lg:w-[150px]"
            />
          </div>

          <div className="ml-auto">
            <Icon
              type={IconTypeEnum.chrevonRightArrow}
              stroke="#A9A9A3"
              fill="none"
              size={24}
              viewBox="24"
            />
          </div>
        </Link>
      </div>
    </div>
  );
}
