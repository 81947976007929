import { Link } from "react-router-dom";
import { Element } from "@app/types/Cue";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import LoadMore from "@components/LoadMore/LoadMore";
import MetaTags from "@components/MetaTags/MetaTags";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import EventsAwardsQuickLinks from "@events-awards/components/EventsAwardsQuickLinks";
import { AWARDS_EVENTS_PAGE_ROW_DATA } from "@events-awards/utils/constants";
import { EventsAwardsGalleryContextData } from "@events-awards/utils/types";
import { useLoadMoreEventsGallery } from "@hooks/useLoadMoreEventsGallery";
import { useDetectAdBlock } from "adblock-detect-react";

export type GalleryItemProps = {
  title: string;
  elements?: Element[];
  link: string;
};

const GalleryItem = ({ title, elements = [], link }: GalleryItemProps) => {
  // Get image from elements - using your existing image structure
  const firstImage = elements.find((el) => el.type === "image")?.relation
    ?.fields?.["landscape-caas"]?.url;

  return (
    <Link to={link}>
      <div className="group relative aspect-video cursor-pointer overflow-hidden bg-white shadow-sm">
        <img
          src={firstImage}
          alt={`${title} Awards Gallery`}
          className="h-full w-full transform object-cover transition-all duration-300 ease-in-out group-hover:scale-105"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h2 className="text-5xl font-bold text-white transition-transform duration-300 group-hover:scale-110">
            {title}
          </h2>
        </div>
      </div>
    </Link>
  );
};

export default function EventsAwardsGalleryPageContent({
  title,
  articles,
  awardType,
  year,
}: EventsAwardsGalleryContextData): React.ReactElement {
  const { articleList, handleLoadMore, hasMoreArticles } =
    useLoadMoreEventsGallery(articles, 10, awardType, year);

  const awardData = AWARDS_EVENTS_PAGE_ROW_DATA.find(
    (data) => data.awardType === awardType
  );
  const adBlockDetected = useDetectAdBlock();

  const awardPath = awardData?.blockTitleProps?.link || "";

  return (
    <div
      className="min-h-screen w-full bg-gray-50"
      data-testid="events-awards-page"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={`${awardPath}/gallery`}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title={`${awardData?.blockTitleProps?.text}_Gallery`}
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`The Business Times ${awardData?.blockTitleProps?.text} - ${awardData?.description}`}
        ogType="website"
        slug={awardPath}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <EventsAwardsLogoMenu awardType={awardType} />

            <div className="mx-auto max-w-4xl py-8">
              {/* Gallery Grid */}
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                {articleList?.map((article) => (
                  <GalleryItem
                    key={article.title}
                    link={article.urlPath}
                    title={article.title}
                    elements={article.elements}
                  />
                ))}
              </div>

              {/* Load More Button */}
              {hasMoreArticles ? (
                <div className="mt-8">
                  <LoadMore
                    onLoadMore={handleLoadMore}
                    hasMore={hasMoreArticles}
                    loadText="Show More"
                  />
                </div>
              ) : null}
            </div>

            <EventsAwardsQuickLinks awardType={awardType} />
          </Column>
        </Row>
      </Container>
      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
