import { ActionsFactory } from "@pages/Article/components/ArticleSubshare/helpers";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";

export type ActionsProps = LambdaResponsePayload & {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Actions({
  data: { responseType, shortURL, giftCount },
  setIsModalOpen,
}: ActionsProps): React.ReactElement {
  const Actions = ActionsFactory(responseType);

  return (
    <>
      {Actions ? (
        <Actions
          setIsModalOpen={setIsModalOpen}
          link={shortURL}
          giftCount={giftCount}
        />
      ) : null}
    </>
  );
}
