import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import { KeyValuePair, Media } from "@typings/Cue";
import { getFormattedUrlPath, textToSlug } from "@util/helpers";

export type VideoCardProps = BrightcoveVideo & {
  parameters: KeyValuePair[];
};

export default function VideoCard({
  id,
  name,
  images,
  parameters,
}: VideoCardProps): React.ReactElement {
  const media: Media = {
    content: {
      type: "picture",
      fields: {
        landscape: {
          url: images.poster.src,
        },
      },
    },
  };

  const variant: CardVariations = {
    image: {
      position: "",
      width: "w-full",
      isVideo: true,
      playIconPosition: "lower-left",
      aspectRatio: "aspect-video",
      loading: "eager",
    },
    content: {
      width: "w-full",
      extraClass: "pt-2",
    },
    title: {
      color: "!text-gray-850",
      size: "text-base lg:text-lg",
    },
  };

  const urlPath = `/videos/${textToSlug(name)}/${id}`;

  return (
    <div data-testid="singapore-ge-2025-video-card">
      <BasicCard
        id={id}
        slug={getFormattedUrlPath(urlPath, parameters)}
        title={name}
        variations={variant}
        media={media}
        rootClassName="flex-col"
      />
    </div>
  );
}
