import { useState } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import axios from "axios";

export const useLoadMoreEventsWinners = (
  initialList: SanitizedSectionArticle[],
  pageSize: number,
  awardType: string
) => {
  const [articleList, setMoreArticlesList] = useState(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length >= pageSize
  );

  const handleLoadMore = async () => {
    const page = articleList.length / pageSize + 1;

    if (articleList.length === 0) return;

    const newMoreArticles = await fetchEventsAwardsWinnersMoreData(
      page,
      awardType
    );

    if (newMoreArticles.length < pageSize) setHasMoreArticles(false);

    setMoreArticlesList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};

const fetchEventsAwardsWinnersMoreData = async function (
  page: number,
  awardType: string
): Promise<SanitizedSectionArticle[]> {
  try {
    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/event-awards-winners-more-stories",
      { page, awardType }
    );
    return response.data || [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "fetchEventsAwardsWinnersMoreData: - Error in fetching pulse data",
      error
    );
    return [];
  }
};
