import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ARTICLE_ERROR_GENERIC } from "@pages/Article/components/ArticleSubshare/constants";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";
import { ENVIRONMENT } from "@util/constant";
import { getEnvironmentDomain } from "@util/helpers";

import useAlacrity from "./useAlacrity";
import useOKTAUserStore, { OKTAUserTypeEnum } from "../store/useOKTAUserStore";

export default function useGenerateGiftURL(isPremium: boolean) {
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const [alacrityRes, setAlacrityRes] = useState<LambdaResponsePayload>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { pathname } = useLocation();
  const { generateGiftURL } = useAlacrity();

  const isSubscriber = OktaUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  const aovisitorID = OktaUserInfo?.mysphw;

  const handleGenerateGiftURLModal = () => {
    if (!isPremium) return;
    if (!isSubscriber) return;

    const fetchCall = async () => {
      try {
        if (!aovisitorID) throw new Error("aovisitorID is not present");

        const response = await generateGiftURL(
          `${getEnvironmentDomain(ENVIRONMENT)}${pathname}`,
          aovisitorID
        );

        setAlacrityRes(response);
      } catch {
        setAlacrityRes(ARTICLE_ERROR_GENERIC);
        console.error("aovisitorID is not present"); // eslint-disable-line no-console
      } finally {
        setIsModalOpen(true);
      }
    };

    fetchCall();
  };

  return {
    alacrityRes,
    setAlacrityRes,
    isModalOpen,
    setIsModalOpen,
    handleGenerateGiftURLModal,
  };
}
