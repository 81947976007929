import { HTMLAttributes } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import { useHandleMoreStories } from "@hooks/useHandleMoreStories";
import { BUDGET2025_PAGE_SIZE } from "@pages/SingaporeBudget/2025/utils/constants";

import RelatedStories from "./RelatedStories";

export type RelatedStoriesProps = {
  articles: SanitizedSectionArticle[];
  testId?: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function RelatedStoriesOverview({
  testId,
  className,
  articles,
}: RelatedStoriesProps): React.ReactElement {
  const { ref, loadMoreData } = useHandleMoreStories({
    data: articles,
    uniqueCategoryQuery: "singapore-budget-2025",
    parentCategory: "keywords",
    pageSize: BUDGET2025_PAGE_SIZE,
  });

  return (
    <>
      {[...articles, ...loadMoreData].length > 0 ? (
        <div className={className} data-testid={testId}>
          <BlockTitle text="RELATED STORIES" />

          <div className="divide-y border-gray-175">
            {[...articles, ...loadMoreData].map((article, index) => {
              return (
                <RelatedStories
                  article={article}
                  index={index}
                  key={article.id}
                />
              );
            })}
          </div>

          <div ref={ref} />
        </div>
      ) : null}
    </>
  );
}
