/**
 * Company: SPHMedia
 * Description: Unverified banner that has a fixed position at the top of the screen
 */

import { ReactElement, useEffect, useState } from "react";
import Button from "@components/AccessibleComponents/Button";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import useOKTAUserStore, {
  OKTARegistrationStatus,
} from "@store/useOKTAUserStore";
import { mySPHOpenResendVerificationMail } from "@util/helpers";

/**
 * UnverifiedBanner
 * Description: Unverified banner that is displayed when a user is not verified
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function UnverifiedBanner(): ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const [visible, setVisible] = useState(false);

  const email = OKTAUserInfo?.loginid;

  useEffect(() => {
    if (OKTAUserInfo === null) return;
    if (typeof OKTAUserInfo === "undefined") return;

    const email = OKTAUserInfo?.loginid;
    const useStatus = OKTAUserInfo?.reguserstatus;

    if (typeof email === "undefined") return;
    if (useStatus !== OKTARegistrationStatus.UNVERIFIED) return;

    setVisible(true);
  }, [visible, OKTAUserInfo]);

  return (
    <>
      {visible ? (
        <div className="mb-4 bg-gray-900 py-4 text-center text-white">
          <div className="grid grid-cols-12 place-content-center gap-3">
            <div className="col-span-11 flex flex-wrap gap-3 justify-self-center">
              <div className="flex flex-auto items-center">
                <p className="mb-0 px-2 font-poppins text-3xs lg:px-0">
                  {"Hello, your email is unverified. Please confirm "}
                  <strong>{email}</strong> for acess to all your SPH accounts.
                </p>
              </div>
              <div className="flex flex-auto items-center justify-center">
                <Button
                  className="font-poppins text-2xs font-bold underline"
                  onPress={() => {
                    mySPHOpenResendVerificationMail();
                  }}
                >
                  Resend Verification Email
                </Button>
              </div>
            </div>
            <div className="flex h-full items-center justify-center place-self-end">
              <Button
                onPress={() => {
                  setVisible(false);
                }}
              >
                <Icon type={IconTypeEnum.close} size={30} />
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
