import iconSbaInfo from "@assets/events-awards/sba/icon-info.svg";
import logoDHL from "@assets/events-awards/sba/sbaDHL.png";
import logoBT from "@assets/logo-masthead.svg";
import { Block } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import BasicCard from "@components/ArticleCard/BasicCard";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import EventsAwardsSBAQuickLinks from "@events-awards/SBA/components/EventsAwardsSBAQuickLinks";
import {
  EVENTS_AWARDS_SBA_LABEL,
  EVENTS_AWARDS_SBA_PATH,
  EVENTS_AWARDS_SLOT,
} from "@events-awards/utils/constants";
import {
  AwardType,
  EventsAwardsLandingPageContextData,
  JudgingPanelMember,
} from "@events-awards/utils/types";
import { judgingPanel } from "@sba/utils/constants";
import { sectionNavigationItems } from "@util/constant";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsSBAPageContent({
  title,
  article,
}: EventsAwardsLandingPageContextData): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();
  return (
    <div
      className="w-full"
      data-testid="events-awards-sba-page-content-component"
    >
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={EVENTS_AWARDS_SBA_PATH}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title="Singapore_Business_Awards_Index"
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${EVENTS_AWARDS_SBA_LABEL} - Find ${EVENTS_AWARDS_SBA_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_SBA_PATH}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center my-8">
            <EventsAwardsLogoMenu awardType={AwardType.SBA} />

            <Block rootClassName="p-6 text-center border border-eventAwards-sba-accent">
              <img
                src={iconSbaInfo}
                alt="Info icon"
                width={16}
                height={16}
                className="mr-4 inline-block"
              />

              <span className="align-middle font-public-sans text-base uppercase">
                Nominations for SBA 2023/2024 are closed
              </span>
            </Block>

            <>
              {article ? (
                <BasicCard
                  title={article.title}
                  id={article.id}
                  media={article.media}
                  slug={article.urlPath}
                  defaultImage={{ directoryName: "events-awards" }}
                  rootClassName="mt-6 md:-mx-3 mx-0 justify-center"
                  variations={{
                    title: {
                      color: "text-gray-850",
                      size: "text-4xl",
                      extraClass: "font-public-sans font-semibold",
                    },
                    content: {
                      width: "w-full",
                      extraClass: "px-6 lg:px-14 text-left",
                    },
                  }}
                />
              ) : null}
            </>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 lg:pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold uppercase leading-5 lg:mb-0 lg:text-right">
                  {"Singapore "}
                  <br className="hidden lg:inline" />
                  {"Business "}
                  <br className="hidden lg:inline" />
                  {"Awards"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <p className="font-public-sans text-lg leading-7">
                  {
                    "The Singapore Business Awards (SBA) is jointly organised by The Business Times, a business daily published by SPH Media Limited, and DHL, the global market leader of the international express and logistics industry."
                  }
                </p>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"JOINTLY ORGANISED "}
                  <br className="hidden lg:inline" />
                  {"BY"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoBT}
                    width={320}
                    height={32}
                    alt="The Business Times logo"
                    className="mx-auto"
                  />
                </div>

                <div className="mb-9 border border-gray-175 py-20 shadow-1">
                  <img
                    src={logoDHL}
                    width={320}
                    height={79}
                    alt="DHL logo"
                    className="mx-auto"
                  />
                </div>
              </Column>
            </Row>

            <Row rootClassName="flex py-9 px-3 md:px-6 lg:px-0">
              <Column rootClassName="w-full lg:w-3/12 pr-7 pt-4">
                <h2 className="mb-6 text-left font-public-sans text-base font-bold leading-5 lg:mb-0 lg:text-right">
                  {"JUDGING PANEL"}
                </h2>
              </Column>

              <Column rootClassName="w-full lg:w-9/12 text-left lg:pl-7">
                {judgingPanel.map(
                  (member: JudgingPanelMember, index: number) => (
                    <Row
                      key={index}
                      rootClassName="border-b border-gray-200 py-4"
                    >
                      {/* Name Column */}
                      <Column rootClassName="w-full lg:w-4/12 font-semibold text-gray-900">
                        {member.name}
                      </Column>

                      {/* Title & Subtitle Column */}
                      <Column rootClassName="w-full lg:w-8/12 text-gray-700">
                        <p className="text-base">{member.title}</p>
                        {member.subtitle && (
                          <p className="text-sm text-gray-600">
                            {member.subtitle}
                          </p>
                        )}
                      </Column>
                    </Row>
                  )
                )}
              </Column>
            </Row>

            <EventsAwardsSBAQuickLinks />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
