import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import { getIconSrc } from "@components/Icon/helpers";
import { IconTypeEnum } from "@components/Icon/types";
import { cn } from "@util/helpers";

import { WHATSAPP_URL } from "./constants";
import { ButtonSize, SocialMediaType } from "./types";

export type SocialEmbedButtonFactoryProps = {
  whichSocialMedia: SocialMediaType;
  shareUrl: string;
  className?: string;
  commonCss: string;
  title?: string;
  iconSize?: number;
  buttonSize?: ButtonSize;
};

export default function SocialEmbedButtonFactory({
  whichSocialMedia = SocialMediaType.facebook,
  shareUrl = "",
  className = "",
  commonCss,
  title,
  iconSize = 24,
  buttonSize,
}: SocialEmbedButtonFactoryProps) {
  switch (whichSocialMedia) {
    case SocialMediaType.twitter:
      return (
        <TwitterShareButton
          url={shareUrl}
          className={cn("aspect-square", commonCss, className)}
          title={title}
          data-size={buttonSize}
          resetButtonStyle={false}
          data-testid="share-button-twitter"
        >
          <img
            src={getIconSrc(IconTypeEnum.twitter)}
            width={iconSize}
            height={iconSize}
            alt="twitter"
          />
        </TwitterShareButton>
      );

    case SocialMediaType.telegram:
      return (
        <TelegramShareButton
          url={shareUrl}
          className={cn("aspect-square", commonCss, className)}
          title={title}
          data-size={buttonSize}
          resetButtonStyle={false}
          data-testid="share-button-telegram"
        >
          <img
            src={getIconSrc(IconTypeEnum.telegram)}
            width={iconSize}
            height={iconSize}
            alt="telegram"
          />
        </TelegramShareButton>
      );

    case SocialMediaType.whatsapp:
      return (
        <a
          className={cn("aspect-square", commonCss, className)}
          href={`${WHATSAPP_URL}${encodeURIComponent(title || "")} ${encodeURIComponent(shareUrl || "")}`}
          target="_blank"
          rel="noreferrer"
          data-size={buttonSize}
          data-testid="share-button-whatsapp"
        >
          <img
            src={getIconSrc(IconTypeEnum.whatsapp)}
            width={iconSize}
            height={iconSize}
            alt="whatsapp"
          />
        </a>
      );

    case SocialMediaType.email:
      return (
        <EmailShareButton
          url={shareUrl}
          subject="Sharing from The Business Times"
          body={title}
          separator=" - "
          className={cn("aspect-square", commonCss, className)}
          data-size={buttonSize}
          resetButtonStyle={false}
          data-testid="share-button-email"
        >
          <img
            src={getIconSrc(IconTypeEnum.email)}
            width={iconSize}
            height={iconSize}
            alt="email"
          />
        </EmailShareButton>
      );

    case SocialMediaType.linkedin:
      return (
        <LinkedinShareButton
          url={shareUrl}
          className={cn("aspect-square", commonCss, className)}
          title={title}
          data-size={buttonSize}
          resetButtonStyle={false}
          data-testid="share-button-linkedin"
        >
          <img
            src={getIconSrc(IconTypeEnum.linkedin)}
            width={iconSize}
            height={iconSize}
            alt="linkedin"
          />
        </LinkedinShareButton>
      );

    case SocialMediaType.facebook:
    default:
      return (
        <FacebookShareButton
          url={shareUrl}
          className={cn("aspect-square", commonCss, className)}
          title={title}
          data-size={buttonSize}
          resetButtonStyle={false}
          data-testid="share-button-facebook"
        >
          <img
            src={getIconSrc(IconTypeEnum.facebook)}
            width={iconSize}
            height={iconSize}
            alt="facebook"
          />
        </FacebookShareButton>
      );
  }
}
