import { HTMLAttributes, ReactNode, useEffect, useState } from "react";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { Transition, TransitionChild } from "@headlessui/react";
import { useDelayedComponents } from "@hooks/useDelayedComponents";
import { useHideComponents } from "@hooks/useHideComponents";
import { cn } from "@util/helpers";
import Cookies from "js-cookie";

export type ToolTipProps = {
  cookieName: string;
  text: string;
  topTriangle?: ReactNode;
  bottomTriangle?: ReactNode;
  handleExtraFunction?: () => void;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function Tooltip({
  cookieName,
  text,
  topTriangle,
  bottomTriangle,
  className,
  handleExtraFunction,
}: ToolTipProps): React.ReactElement {
  const [isVisited, setIsVisited] = useState(true);
  const { isHide, handleSetIsHide, isTimeout } = useHideComponents(5000);
  const { isShown } = useDelayedComponents(2500);

  const handleLoginTooltip = () => {
    handleSetIsHide(true);

    handleExtraFunction && handleExtraFunction();
  };

  useEffect(() => {
    if (cookieName === "") return;

    const cookie = Cookies.get(cookieName);
    if (typeof cookie !== "undefined") return;

    setIsVisited(false);
  }, [cookieName]);

  useEffect(() => {
    if (cookieName === "") return;
    if (!isTimeout) return;

    Cookies.set(cookieName, "shown");
  }, [isTimeout, cookieName]);

  return (
    <div data-testid="tooltip-component">
      <Transition show={!isVisited && !isHide && isShown}>
        <TransitionChild
          enter="transition duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cn(
              "relative top-2 transition-all duration-300",
              className
            )}
          >
            {topTriangle}

            <div className="flex w-[280px] items-center justify-center rounded-md bg-gray-850 p-3 text-white lg:w-[295px]">
              <p className="mb-0 ml-2 font-poppins text-xs font-medium tracking-2%">
                {text}
              </p>

              <Icon
                className="flex-none cursor-pointer"
                type={IconTypeEnum.close}
                fill="white"
                onClick={handleLoginTooltip}
              />
            </div>

            {bottomTriangle}
          </div>
        </TransitionChild>
      </Transition>
    </div>
  );
}
