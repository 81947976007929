import { Await } from "react-router-dom";
import { NewsletterArchiveContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import GarageArchivePageContent from "@pages/Newsletter/Archive/Garage/GarageArchivePageContent";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

export default function NewsletterGarageArchivePage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<NewsletterArchiveContext, string>>();

  if (context && context.statusCode !== HttpStatusCode.Ok) {
    // StatusCode is not 200
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, error, data }) => {
        if (error || kind !== "newsletter") {
          // Error or kind is not "newsletter"
          return <CustomError statusCode={context.statusCode} />;
        }

        // StatusCode is 200 and kind is "newsletter"
        return <GarageArchivePageContent {...data} />;
      }}
    </Await>
  );
}
