import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import MyAccountActivePage from "@pages/MyAccount/components/MyAccountActivePage";
import MyAccountPageSetting from "@pages/MyAccount/components/MyAccountPageSetting";
import MyAccountPageSettingPopover from "@pages/MyAccount/components/MyAccountPageSettingPopover";
import MyAccountTabs from "@pages/MyAccount/components/MyAccountTabs";
import { MyAccountTabsEnum } from "@pages/MyAccount/utils/types";
import useOKTAUserStore from "@store/useOKTAUserStore";
import { DefaultNavigationData } from "@typings/Page";
import { useDetectAdBlock } from "adblock-detect-react";

export default function MyAccountPage({
  seoFooterData,
}: DefaultNavigationData): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const [activeTab, setActiveTab] = useState<MyAccountTabsEnum>(
    MyAccountTabsEnum.myBT
  );

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    const getRecentRead = sessionStorage.getItem("account-recent-read");
    if (getRecentRead !== "true") return;

    setActiveTab(MyAccountTabsEnum.recent);
  }, []);

  useEffect(() => {
    const loginId = OKTAUserInfo?.loginid;
    if (loginId) return;

    navigate(RouteFactory.home);
  }, [navigate, OKTAUserInfo]);

  return (
    <div className="w-full" data-testid="my-account-page-component">
      <AdSettings
        adNames={["lb1", "imu1", "catfish", "abm"]}
        path={RouteFactory.myAccount}
        adTargets={[{ key: "page", value: "listing" }]}
        pageType="static"
      />

      <MetaTags
        title={renderPageTitleV2({
          kind: "static",
          title: "My Account",
        })}
        description="Read more at The Business Times."
        slug={RouteFactory.myAccount}
      />

      <GAData
        title="My_Account_Index"
        level2="My Account"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <div data-testid="my-account-page-content" id="my-account-page-component">
        <Ad
          adType="lb1"
          className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
        />

        <Header />

        <Container rootClassName="pb-[10rem]">
          <div className="mb-3 flex items-center justify-between border-b border-gray-850 pb-3 pt-6 lg:mb-0 lg:border-none">
            <h1 className="font-playfair text-5xl font-semibold uppercase text-gray-850 md:text-8xl">
              My Account
            </h1>

            <MyAccountPageSettingPopover oktaUserInfo={OKTAUserInfo} />
          </div>

          <MyAccountTabs
            handleTabChange={setActiveTab}
            activeTab={activeTab}
            className="m-0 mb-4 border-0 pt-0 lg:border-b"
          />

          <Row>
            <Column rootClassName="w-full lg:w-7/10 lg:pl-3 lg:pr-8 lg:border-r border-gray-175">
              <MyAccountActivePage activeTab={activeTab} />
            </Column>

            <Column rootClassName="lg:w-3/10 hidden lg:block lg:pl-6 lg:pr-0">
              <MyAccountPageSetting oktaUserInfo={OKTAUserInfo} />
            </Column>
          </Row>
        </Container>
      </div>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />
    </div>
  );
}
