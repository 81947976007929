import { GE2025_KEYWORD } from "@pages/SingaporeGe/2025/utils/constants";
import { Ge2025Data, GeData } from "@pages/SingaporeGe/utils/types";
/**
 * Helper function to get the ge year.
 *
 * @param {string} route
 * @returns {number}
 */

export const getGeYear = (route: string): number | undefined => {
  if (route.includes(GE2025_KEYWORD)) return 2025;
};

/**
 * Helper function to check if the data is GE 2025 Data
 *
 * @param {GeData} data
 * @returns {data is Ge2025Data}
 */
export const isGe2025Data = (data: GeData): data is Ge2025Data => {
  return data.highlights !== undefined && data.year === 2025;
};
