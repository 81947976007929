import { WebFetcher } from "@app/data";
import { RouteFactory } from "@app/routePaths";
import type {
  ArticleDataObject,
  SanitizedSectionArticle,
  Section,
  Tag,
} from "@app/types/Cue";
import type { PodcastKeywordContext, TermContext } from "@app/types/Page";
import { genSectionKeywordQuery } from "@caas/queries/genSectionKeywordQuery";
import { genSectionQuery } from "@caas/queries/genSectionQuery";
import { queryCaasOpenSearch } from "@caas/queryCaasOpenSearch";
import { TrimmedBreakingNewsArticle } from "@caas/types";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import { navigationRequests } from "@pages/DefaultNavigationData.server.ts";
import { MyBtConfigResponse, MyBtUpdateResponse } from "@pages/MyBT/types";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { textToSlug } from "@util/helpers";
import { santizeArticleDataObjects } from "@util/sanitization/sanitizeArticleDataObjects";
import axios, { HttpStatusCode } from "axios";
import { startCase } from "lodash-es";

import { getSectionObject, getTagObject } from "./helpers";

export const sectionRequests = {
  fetchTrimmedBreakingNews: async (): Promise<TrimmedBreakingNewsArticle[]> => {
    try {
      const response = await axios.get("/_plat/api/v1/trimmed-breaking-news");
      return response.data;
    } catch (error) {
      return [];
    }
  },

  fetchArticlesByKeywords: async (tags: Tag[], size = 10) => {
    const urlPaths = tags.map((tag) => tag.urlPath);
    const response = await axios.post("/_plat/api/v1/mybt", { urlPaths, size });
    return response;
  },

  fetchMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum,
    aovisitorid: string
  ) => {
    const response = await axios.get<MyBtConfigResponse>(
      "/_plat/api/v1/mybt/get-config",
      { headers: { usertype: userType, aovisitorid: aovisitorid } }
    );

    return <Tag[] | null>response.data.tags.map(({ name, id }) => {
      return { name: name, urlPath: `/keywords/${textToSlug(name)}`, id: id };
    });
  },

  updateMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum = OKTAUserTypeEnum.ANONYMOUS,
    aovisitorid: string,
    tags: Tag[]
  ) => {
    if (userType == OKTAUserTypeEnum.ANONYMOUS)
      return <MyBtUpdateResponse>{
        code: 403,
        message: "Permission denied. User is anonymous.",
      };
    try {
      const response = await axios.post<MyBtUpdateResponse[]>(
        "/_plat/api/v1/mybt/update",
        { tags: tags },
        { headers: { usertype: userType, aovisitorid: aovisitorid } }
      );

      return response.data[0];
    } catch (error) {
      return <MyBtUpdateResponse>{
        code: 500,
        message: "Internal Server error.",
        errors: error,
      };
    }
  },

  deleteMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum = OKTAUserTypeEnum.ANONYMOUS,
    aovisitorid: string,
    tags: Tag[]
  ) => {
    if (userType == OKTAUserTypeEnum.ANONYMOUS)
      return <MyBtUpdateResponse>{
        code: 403,
        message: "Permission denied. User is anonymous.",
      };
    try {
      const response = await axios.post<MyBtUpdateResponse[]>(
        "/_plat/api/v1/mybt/delete",
        { tags: tags },
        { headers: { usertype: userType, aovisitorid: aovisitorid } }
      );
      return response.data[0];
    } catch (error) {
      return <MyBtUpdateResponse>{
        code: 500,
        message: "Internal Server error.",
        errors: error,
      };
    }
  },
};

export const fetchSectionDataV2: WebFetcher<
  TRouteWithRedirect<TermContext, string>
> = async function ({ urlComponent }) {
  const urlPath =
    urlComponent && urlComponent.path
      ? urlComponent.path.replace(/\/+$/, "") // Remove trailing slash
      : "";

  const sectionUniqueName = urlPath
    .replace(/^\/+/, "") // Remove leading slash
    .replaceAll("/", "_") // Replace slashes with underscores
    .replace("_latest", ""); // Remove _latest

  const query = genSectionQuery(sectionUniqueName, 10);
  const response = await queryCaasOpenSearch(query);

  const overview = response.payload?.hits.hits?.map(
    (article) => article._source.data.context
  );

  const sanitizedOverview = santizeArticleDataObjects(overview);
  const sectionObectFromArticle = getSectionObject(
    overview?.[0]?.sections,
    sectionUniqueName
  );

  const sectionObjectFromPath: Section = {
    parent: {
      uniqueName: "ece_frontpage",
      name: "Home",
      directoryName: "frontpage",
    },
    uniqueName: sectionUniqueName,
    name: startCase(sectionUniqueName.replaceAll("-", " ").toLowerCase()),
    href: `/${sectionUniqueName}/`,
    parameters: [],
    directoryName: sectionUniqueName,
  };

  const seoFooterData = await navigationRequests.fetchSeoFooter();

  return {
    type: ResponseType.SUCCESS,
    statusCode: HttpStatusCode.Ok,
    payload: {
      kind: "section",
      data: {
        entity: sectionObectFromArticle || sectionObjectFromPath,
        urlPath: urlPath,
        title: renderPageTitleV2({
          kind: "section",
          sectionName:
            sectionObectFromArticle?.name || sectionObjectFromPath.name,
          sectionParentName:
            sectionObectFromArticle?.parent.name ||
            sectionObjectFromPath.parent.name,
        }),
        overview: sanitizedOverview,
        seoFooterData,
      },
    },
  };
};

export const fetchMoreStoriesData = async function (
  category: string,
  parentCategory: string,
  page: number
): Promise<SanitizedSectionArticle[]> {
  try {
    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/more-stories",
      {
        category, // "companies-markets_banking-finance" || "food-drink"
        parentCategory, // "companies-markets" || "keywords"
        size: 10,
        page,
      }
    );

    return response.data || [];
  } catch (error) {
    return [];
  }
};

export const fetchMoreBrandedContentData = async function (
  article: ArticleDataObject
): Promise<ArticleDataObject[]> {
  try {
    const response = await axios.post("/_plat/api/v1/more-stories-by-kicker", {
      kicker: "Branded Content",
      sort: article.sort,
      size: 10,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchPodcastKeywordData: WebFetcher<
  TRouteWithRedirect<PodcastKeywordContext, string>
> = async ({ urlComponent }) => {
  const keywordUri =
    urlComponent && urlComponent.path
      ? urlComponent.path.replace("/", "").split("/")[1]
      : "";

  const query = genSectionKeywordQuery(
    RouteFactory.podcasts.replace("/", ""), // "podcasts"
    `/keywords/${keywordUri}`, // ie /keywords/money-hacks
    5 // pageSize,
  );
  const response = await queryCaasOpenSearch(query);

  const overview = response.payload?.hits.hits?.map((article) => {
    const articleContext = article._source.data.context;
    return { ...articleContext, sort: article.sort };
  });

  const sanitizedOverview = santizeArticleDataObjects(overview);
  const tag = getTagObject(overview?.[0]?.tags, `/keywords/${keywordUri}`);

  const seoFooterData = await navigationRequests.fetchSeoFooter();

  return {
    type: ResponseType.SUCCESS,
    statusCode: HttpStatusCode.Ok,
    payload: {
      kind: "keyword",
      data: {
        entity: tag,
        title: renderPageTitleV2({
          kind: "keyword",
          entity: tag,
        }),
        overview: sanitizedOverview,
        seoFooterData,
      },
    },
  };
};
