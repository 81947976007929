import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useSendInViewGTM } from "@pages/SingaporeGe/2025/hooks/useSendInViewGTM";
import { HighlightsProps } from "@pages/SingaporeGe/2025/utils/types";
import { cn, getFormattedUrlPath } from "@util/helpers";

export default function Highlights({
  testId,
  className,
  articles,
  gaObject,
}: HighlightsProps): React.ReactElement {
  const { ref: topStoriesRef } = useSendInViewGTM({
    eventsObject: gaObject?.eventObject,
  });

  const parameters = gaObject?.parameters || [];

  const mainVariation: CardVariations = {
    image: {
      position: "left",
      width: "hidden lg:block lg:w-1/2",
    },
    content: {
      width: "w-full lg:w-1/2",
      extraClass:
        "bg-gray-850 text-center p-5 lg:pt-0 lg:pb-0 lg:pr-0 lg:pl-4 lg:bg-transparent lg:text-left",
    },
    title: {
      color: "!text-white lg:!text-gray-850",
      size: "text-4xl",
    },
    kicker: {
      color: "text-white lg:text-black",
    },
  };

  const otherVariation: CardVariations = {
    title: {
      color: "!text-gray-850",
      size: "text-base lg:text-lg",
    },
  };

  const mainArticle = articles.slice(0, 1)?.[0];
  const mainArticleSectionUniqueName = mainArticle?.sections?.[0]?.uniqueName;

  return (
    <section className={className} data-testid={testId} ref={topStoriesRef}>
      <BlockTitle text="TOP STORIES" rootClassName="mb-4" />

      <div className="stories grid divide-y border-gray-175">
        {mainArticle ? (
          <BasicCard
            id={mainArticle.id}
            slug={getFormattedUrlPath(mainArticle.urlPath, parameters)}
            title={mainArticle.title}
            kicker={mainArticle.kicker?.fields?.[0]?.value}
            media={mainArticle.media}
            variations={mainVariation}
            blurb={mainArticle.blurb}
            rootClassName="pb-4 story"
            updated={mainArticle.updated}
            edited={mainArticle.edited}
            hasUpdatedTimestampDisplay={
              mainArticle.displaySetting?.hasUpdatedTimeDisplayed
            }
            {...(mainArticleSectionUniqueName
              ? {
                  defaultImage: {
                    directoryName: mainArticleSectionUniqueName,
                  },
                }
              : null)}
          />
        ) : null}

        <div className="stories grid divide-y !border-t-0 border-gray-175 lg:grid-cols-2 lg:gap-4 lg:divide-y-0 lg:!border-t lg:py-4">
          {articles.slice(1, 3).map((article) => {
            const {
              id,
              title,
              urlPath,
              kicker,
              updated,
              edited,
              displaySetting,
            } = article;

            return (
              <BasicCard
                key={id}
                id={id}
                slug={getFormattedUrlPath(urlPath, parameters)}
                title={title}
                kicker={kicker?.fields?.[0]?.value}
                variations={otherVariation}
                rootClassName="story lg:last:border-l lg:last:pl-4 lg:last:border-gray-175 py-2 lg:py-0"
                updated={updated}
                edited={edited}
                hasUpdatedTimestampDisplay={
                  displaySetting?.hasUpdatedTimeDisplayed
                }
              />
            );
          })}
        </div>

        {articles.length > 3 ? (
          <>
            <div className="stories grid gap-4 divide-y border-gray-175 py-4 lg:grid-cols-2 lg:divide-y-0">
              {articles.slice(3, 5).map((article) => {
                const {
                  id,
                  title,
                  urlPath,
                  kicker,
                  updated,
                  edited,
                  displaySetting,
                } = article;

                return (
                  <BasicCard
                    key={id}
                    id={id}
                    slug={getFormattedUrlPath(urlPath, parameters)}
                    title={title}
                    kicker={kicker?.fields?.[0]?.value}
                    variations={otherVariation}
                    rootClassName={cn({
                      "lg:last:border-l lg:last:pl-4 lg:last:border-gray-175":
                        articles.length > 4,
                    })}
                    updated={updated}
                    edited={edited}
                    hasUpdatedTimestampDisplay={
                      displaySetting?.hasUpdatedTimeDisplayed
                    }
                  />
                );
              })}
            </div>

            {articles.length > 5 ? (
              <div className="stories grid gap-4 divide-y border-gray-175 py-4 lg:grid-cols-2 lg:divide-y-0">
                {articles.slice(5, 7).map((article) => {
                  const {
                    id,
                    title,
                    urlPath,
                    kicker,
                    updated,
                    edited,
                    displaySetting,
                  } = article;

                  return (
                    <BasicCard
                      key={id}
                      id={id}
                      slug={getFormattedUrlPath(urlPath, parameters)}
                      title={title}
                      kicker={kicker?.fields?.[0]?.value}
                      variations={otherVariation}
                      rootClassName={cn({
                        "lg:last:border-l lg:last:pl-4 lg:last:border-gray-175":
                          articles.length > 6,
                      })}
                      updated={updated}
                      edited={edited}
                      hasUpdatedTimestampDisplay={
                        displaySetting?.hasUpdatedTimeDisplayed
                      }
                    />
                  );
                })}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </section>
  );
}
