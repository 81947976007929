import { useEffect, useState } from "react";

const useHideComponents = (waitBeforeHide: number) => {
  const [isHide, setIsHide] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHide(true);
      setIsTimeout(true);
    }, waitBeforeHide);

    return () => {
      clearTimeout(timer);
    };
  }, [waitBeforeHide]);

  const handleSetIsHide = (value: boolean) => {
    setIsHide(value);
  };

  return {
    isHide,
    handleSetIsHide,
    isTimeout,
  };
};

export { useHideComponents };
