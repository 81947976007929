import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import EventsAwardsPageRow from "@events-awards/components/EventsAwardsPageRow";
import {
  AWARDS_EVENTS_PAGE_ROW_DATA,
  EVENTS_AWARDS_LABEL,
  EVENTS_AWARDS_PATH,
  EVENTS_AWARDS_SLOT,
} from "@events-awards/utils/constants";
import {
  AwardType,
  EventsAwardsPageContextData,
} from "@events-awards/utils/types";
import { sectionNavigationItems } from "@util/constant";
import { cn } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

export default function EventsAwardsPageContent({
  title,
  e50Article,
  eeArticle,
  sbaArticle,
  scaArticle,
  siaArticle,
  dataArticle,
  seoFooterData,
}: EventsAwardsPageContextData): React.ReactElement {
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();

  const articleDataMapping = {
    [AwardType.E50]: e50Article,
    [AwardType.EE]: eeArticle,
    [AwardType.SBA]: sbaArticle,
    [AwardType.SCA]: scaArticle,
    [AwardType.SIA]: siaArticle,
    [AwardType.DATA]: dataArticle,
  };

  return (
    <div className="w-full" data-testid="events-awards-page-content-component">
      <AdSettings
        adNames={["lb1", "imu1", "catfish", "abm"]}
        path={RouteFactory.section("events-awards")}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <GAData
        title="Events_Awards_Index"
        level2="events awards"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${EVENTS_AWARDS_LABEL} - Find ${EVENTS_AWARDS_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_PATH}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <h1
          className={cn(
            "pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850",
            "md:text-8xl"
          )}
        >
          {EVENTS_AWARDS_LABEL}
        </h1>

        <div ref={breadcrumbsRef}>
          <Breadcrumb
            className="m-auto"
            parentCategory={EVENTS_AWARDS_SLOT}
            categoryDataMapping={sectionNavigationItems}
            extraBreadcrumbItems={
              <div
                className={cn(
                  "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm font-bold tracking-tightest text-gray-850 hover:opacity-80"
                )}
              >
                <Link
                  to={`${EVENTS_AWARDS_PATH}?ref=listing-menubar`}
                  reloadDocument
                >
                  Our events
                </Link>
              </div>
            }
          />
        </div>

        <Row rootClassName="my-6">
          <Column rootClassName="w-full mb-5 lg:w-8/12 text-lg text-gray-850 leading-6">
            <p>
              {
                "The Business Times' Awards are among the most established and prestigious business awards in Singapore. The awards, over the decades, have traced the growth and development of Singapore business as Singapore made the transition from a developing economy to an international business and financial hub. Each year, BT gives out several awards that highlight the achievements of companies from emerging enterprises to established businesses, as well as honouring the contributions of entrepreneurs and business leaders. These highly anticipated events have become highlights of the corporate calendar, and are regarded as important efforts to promote business excellence in Singapore."
              }
            </p>
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <Ad
              adType="imu1"
              className="flex flex-col items-center justify-center border border-gray-250 bg-gray-225 bg-opacity-50 px-5 py-4"
            />
          </Column>
        </Row>

        <>
          {AWARDS_EVENTS_PAGE_ROW_DATA.map((data) => {
            const articleData = articleDataMapping[data.awardType];

            return (
              <>
                {articleData ? (
                  <EventsAwardsPageRow
                    {...data}
                    article={articleData}
                    className="border-t border-gray-250 py-6"
                  />
                ) : null}
              </>
            );
          })}
        </>
      </Container>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
