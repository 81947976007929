import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import type { ArticleDataObject, Section, Tag } from "@app/types/Cue";
import { defaultTimestampFormat } from "@src/app/util/constant";
import { getFormattedTime } from "@util/helpers";
import cx from "classnames";

import { getUrlPathWithRefParams } from "./helpers";

export type BreakingNewsProps = {
  data?: ArticleDataObject[];
  sections?: Section[];
  displayMoreArticlesWithKeywords?: Tag;
};

export default function ArticlesFromSameSectionNew({
  data = [],
  sections = [],
  displayMoreArticlesWithKeywords,
}: BreakingNewsProps): React.ReactElement {
  const firstSection = sections.length > 0 ? sections[0] : null;
  const firstSectionName = firstSection ? firstSection.name : "";
  const firstSectionUniqueName = firstSection ? firstSection.uniqueName : "";

  const displayName = displayMoreArticlesWithKeywords
    ? displayMoreArticlesWithKeywords.name
    : firstSectionName;

  const link = displayMoreArticlesWithKeywords
    ? displayMoreArticlesWithKeywords.urlPath
    : RouteFactory.section(":name").replace(
        ":name",
        firstSectionUniqueName.replace("_", "/")
      );

  if (displayMoreArticlesWithKeywords && data.length < 9) return <></>;

  return (
    <>
      {data && data.length > 0 ? (
        <section className="my-5">
          <h2 className="border-t-2 border-gray-850 pt-3 font-poppins text-lg font-semibold uppercase tracking-[0.05625rem]">
            <Link className="!text-gray-850" to={link}>
              more in {displayName}
            </Link>
          </h2>
          <ul
            data-testid="stories-from-same-section"
            className="grid lg:grid-cols-2"
          >
            {data
              .slice(0, 8)
              .map(
                ({ id, title, urlPath, updated }: ArticleDataObject, index) => {
                  return (
                    <li
                      key={id}
                      className="h-full border-b border-gray-175 py-3"
                    >
                      <div
                        className={cx(
                          "mb-2 mt-1 flex h-full flex-grow flex-col border-gray-175 py-3 pb-2",
                          {
                            "lg:border-r lg:pr-3": [0, 2, 4, 6, 8].includes(
                              index
                            ),
                            "lg:pl-3": [1, 3, 5, 7].includes(index),
                          }
                        )}
                      >
                        <h2 className="font-lct text-base font-bold">
                          <Link
                            className="block !text-gray-850"
                            to={
                              urlPath
                                ? getUrlPathWithRefParams(link, urlPath)
                                : RouteFactory.article(id)
                            }
                            reloadDocument
                          >
                            {title}
                          </Link>
                        </h2>
                        <p className="mb-0 mt-auto font-poppins text-4xs uppercase text-gray-550">
                          {getFormattedTime(updated, defaultTimestampFormat)}
                        </p>
                      </div>
                    </li>
                  );
                }
              )}
          </ul>
        </section>
      ) : null}
    </>
  );
}
