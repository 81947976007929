import { Element, KeyValuePair, ValueOf } from "@app/types/Cue";

export type Annotation = {
  index: number;
  length: number;
  name: ValueOf<AnnotationType>;
  value: string;
  reference?: Element;
};

export enum AnnotationType {
  Bold = "bold",
  SubHeadItalic = "subhead_italic",
  Italic = "italic",
  Underline = "underline",
  Strikethrough = "strike",
  Subscript = "subscript",
  Superscript = "superscript",
  Capitals = "capitals",
  InternalLink = "internal_link",
  AnchorLink = "anchor_link",
  ExternalLink = "external_link",
  StockPicker = "stock-picker",
  MarkOut = "mark-out", // Currently has no use for BT
  Note = "note", // Currently has no use for BT
  AmazonDynamicPricing = "amazon_dynamic_pricing", // Currently has no use for BT
}

export type AnnotationComponentProps = {
  className?: string;
  children: React.ReactNode;
  element?: Element;
  parameters?: KeyValuePair[];
};

export enum RelAttributeKeys {
  ISNEWWINDOW = "isNewWindow",
  ISNOWFOLLOW = "isNoFollow",
}
