import useScript from "@hooks/useScript";
import lotameInitScript from "@util/customScripts/lotameInitScript";
import { useFeatureFlagEnabled } from "posthog-js/react";

import useLink from "./useLink";
import { fetchEnvelope, useLiveRamp } from "./useLiveRamp";
import useOKTAUserInfo from "./useOKTAUserInfo";
import useScriptFunction from "./useScriptFunction";
import useVWO from "./useVWO";

/**
 *  Hook that loads all scripts, links, and custom hooks
 */
export default function useAllCustomHooks() {
  useOKTAUserInfo();

  useScript({
    id: "edge-permutive-app",
    async: true,
    src: "https://5f876161-9740-4cc8-9b64-4585990b2690.edge.permutive.app/5f876161-9740-4cc8-9b64-4585990b2690-web.js",
    type: "text/javascript",
  });

  // Lotame Tags
  useLink({
    id: "tags-crwdcntrl-preconnect",
    rel: "preconnect",
    href: "https://tags.crwdcntrl.net",
  });

  useLink({
    id: "bcp.crwdcntrl",
    rel: "preconnect",
    href: "https://bcp.crwdcntrl.net",
  });

  useLink({
    id: "tags-crwdcntrl-prefetch",
    rel: "dns-prefetch",
    href: "https://tags.crwdcntrl.net",
  });

  useScript({
    id: "tags-crwdcntrl-script",
    async: true,
    src: "https://tags.crwdcntrl.net/lt/c/12374/lt.min.js",
    type: "text/javascript",
  });

  useScriptFunction({
    id: "lotame-init",
    value: lotameInitScript,
  });

  // Set Live Ramp Cookie
  useLiveRamp({ fetchEnvelope });

  const isUserLoginEnabled = useFeatureFlagEnabled("feature_enable_user_login");
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX,
    checkScriptIsLoaded: () => {
      // Disable loading mysph-lightbox-lib if feature_enable_user_login is disabled
      return !isUserLoginEnabled;
    },
  });

  useScript({
    id: "google-one-tap-sign-in",
    src: import.meta.env.VITE_GOOGLE_ONE_TAP_SIGN_IN,
    checkScriptIsLoaded: () => {
      // Disable loading google one tap script if feature_enable_user_login is disabled
      return !isUserLoginEnabled;
    },
  });

  useVWO();
}
