import { Link } from "react-router-dom";
import { TestIdClassNameProps } from "@app/types/Common";
import Button from "@components/AccessibleComponents/Button";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { myBTLoginTracker, mySPHOpenLogin } from "@util/helpers";
import cx from "classnames";

export default function GetStartedButton({
  testId,
  rootClassName,
}: TestIdClassNameProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const logged_user =
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER ||
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.REGISTERED;
  const trackMyBtLogin = () => {
    myBTLoginTracker(logged_user, "get started");
    !logged_user ? mySPHOpenLogin() : null;
  };
  const buttonClass = cx(
    rootClassName,
    "mx-auto block rounded-full border px-12 py-3 font-poppins text-base font-semibold transition-colors"
  );

  return (
    <>
      {logged_user ? (
        <Link
          to="/mybt/onboard"
          className={buttonClass}
          reloadDocument
          data-testid={testId}
          data-type="link"
          onClick={trackMyBtLogin}
        >
          GET STARTED
        </Link>
      ) : (
        <Button
          onPress={trackMyBtLogin}
          className={buttonClass}
          data-testid={testId}
          data-type="button"
        >
          GET STARTED
        </Button>
      )}
    </>
  );
}
