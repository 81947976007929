import {
  Budget2023Data,
  Budget2024Data,
  Budget2025Data,
  BudgetData,
} from "@app/types/Page";

/**
 * Helper function to get the budget year.
 * @param {string} route
 * @returns {number}
 */
export const getBudgetYear = (route: string): number | undefined => {
  if (route.includes("singapore-budget-2023")) return 2023;
  if (route.includes("singapore-budget-2024")) return 2024;
  if (route.includes("singapore-budget-2025")) return 2025;
};

// Type guard for Budget2023Data
export const isBudget2023Data = (data: BudgetData): data is Budget2023Data => {
  return (data as Budget2023Data).topStories !== undefined;
};

// Type guard for Budget2024Data
export const isBudget2024Data = (data: BudgetData): data is Budget2024Data => {
  return (
    (data as Budget2024Data).highlights !== undefined && data.year === 2024
  );
};

// Type guard for Budget2025Data
export const isBudget2025Data = (data: BudgetData): data is Budget2025Data => {
  return (
    (data as Budget2025Data).highlights !== undefined && data.year === 2025
  );
};
