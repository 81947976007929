import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { renderPageTitleV2 } from "@app/components/MetaTags/helpers";
import { AccessFlag, Name, Type } from "@app/types/enums";
import {
  COMMON_BUTTON_CSS,
  REMOVE_FOCUS_CSS,
} from "@components/Button/constants";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import {
  getArticleAuthorNames,
  getArticleChapter1,
  getArticleKeywords,
  getArticleLevel2,
  getArticleStoryThread,
} from "@components/GAData/helper";
import MetaTags from "@components/MetaTags/MetaTags";
import ProgressBar from "@components/ProgressBar/ProgressBar";
import useGenerateGiftURL from "@hooks/useGenerateGiftURL";
import { useGetProgressWidth } from "@hooks/useGetProgressWidth";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useHookUpdateUrlArticle } from "@hooks/useHookUpdateUrlArticle";
import useIsAppWebView from "@hooks/useIsAppWebView";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useWindowSize } from "@hooks/useWindowSize";
import ArticleAttachments from "@pages/Article/components/ArticleAttachments";
import ArticleAuthorsList from "@pages/Article/components/ArticleAuthorsList";
import ArticleKeywords, {
  KeywordDisplayOption,
} from "@pages/Article/components/ArticleKeywords";
import ArticleKicker from "@pages/Article/components/ArticleKicker";
import ArticleMainImage from "@pages/Article/components/ArticleMainImage/ArticleMainImage";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import ArticlesFromSameSectionNew from "@pages/Article/components/ArticlesFromSameSection/ArticlesFromSameSectionNew";
import ArticleSubscriberLabel from "@pages/Article/components/ArticleSubscriberLabel";
import ArticleSubshare from "@pages/Article/components/ArticleSubshare/ArticleSubshare";
import ArticleSubshareSent from "@pages/Article/components/ArticleSubshare/ArticleSubshareSent";
import OutBrainWidget from "@pages/Article/components/OutBrainWidget";
import { ArticleProps } from "@pages/Article/types/Article";
import {
  checkDisplayPaywall,
  getKeywordsToDisplayItems,
  getOgImageUrl,
} from "@pages/Article/utils/helpers";
import MostPopularNew from "@pages/Section/components/MostPopular/MostPopularNew";
import useMyBtStore from "@store/useMyBtStore";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import useRecentReadStore from "@store/useRecentReadStore";
import { dayjsSingaporeTimezone } from "@util/constant";
import {
  checkIfFromPaidPressRelease,
  checkIsHubArticle,
  cn,
  gaEventTracker,
  getArticleJSONLD,
  getFieldValue,
  getFirstParagraph,
  getKickerFromArticleDataObject,
  replaceTextSingleQuote,
  toUnderscoreFromSpace,
} from "@util/helpers";
import dayjs from "dayjs";
import { find } from "lodash-es";

export default function ArticleDisplayMainNewsDefault({
  article,
  isGiftReceived,
  seeAlso,
  sectionNews,
  variant,
  index,
  isNavigate,
  setArticleObj,
  isOutbrainEnabled = true,
  isPreview,
  gsChannels,
  articleInView,
}: ArticleProps): React.ReactElement {
  // De-structure of article so it is cleaner to get the fields.
  const {
    id,
    title,
    updated: publishedDate,
    edited: updatedDate,
    media,
    authors,
    sections,
    tags,
    paywall,
    displaySetting,
    embedCodes,
    elements,
    relatedContributorProfile,
    urlPath,
    storyline,
    sanitizedRelatedStories,
    attachments,
  } = article;

  const { gsChannels: gsChannelsAPI } = useGrapeshotData(urlPath, index);

  const mainSection = sections?.[0];

  const isPaidPressRelease = checkIfFromPaidPressRelease(sections);

  const articleCategory =
    mainSection?.parent.name !== "Home"
      ? mainSection?.parent.name
      : mainSection.name;

  const kicker = getKickerFromArticleDataObject(article);
  const contentAccess = paywall?.contentAccess || "0";
  const displayPaywall = checkDisplayPaywall({
    isGiftReceived,
    variant: variant,
    contentAccess: contentAccess,
  });
  const showRelatedLinksAndNewsletterSignupForm =
    displaySetting?.showRelatedLinksAndNewsletterSignupForm || false;

  const standFirstField =
    find(elements, { type: Type.Standfirst })?.fields || [];
  const standFirst = getFieldValue(standFirstField, Name.Standfirst);
  const isPremium = contentAccess === "1";
  const mostPopularData = useMostReadArticleList();

  const {
    alacrityRes,
    isModalOpen,
    setIsModalOpen,
    handleGenerateGiftURLModal,
  } = useGenerateGiftURL(isPremium);

  const firstParagraph = getFirstParagraph(article.elements || []);
  const { ref } = useHookUpdateUrlArticle(urlPath, isNavigate, article);
  const isBrandedContent = checkIsHubArticle(article.elements || []);
  const isAppWebView = useIsAppWebView();

  const { isScreenLG } = useWindowSize();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const { progressBarContainerRef, progressBarRef, handleSetProgressBarWidth } =
    useGetProgressWidth();

  const { ref: articleRef } = useInView({
    threshold: isScreenLG ? 0.1 : 0,
    onChange: (inView) => {
      if (inView) {
        setArticleObj(article);
      }
    },
  });

  const { ref: checkArticleTitleRef } = useInView({
    onChange: (inView) => {
      if (inView && articleInView?.title !== title) {
        setArticleObj(article);
      }
    },
  });

  const { ref: endOfArticle } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        gaEventTracker("end of article", "visible", article.urlPath);

        if (progressBarContainerRef.current) {
          progressBarContainerRef.current.style.opacity = "0";
        }
      }
    },
  });

  const showGiftIcon =
    isPremium && OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  const titleReplaceSingleQuote = replaceTextSingleQuote(article.title);
  const myBTTags = useMyBtStore((store) => store.tags);

  const displayMoreArticlesWithKeywords = getKeywordsToDisplayItems(tags || []);
  const setArticleList = useRecentReadStore((state) => state.setArticleId);

  useEffect(() => {
    if (typeof window === "undefined") return;

    setArticleList({
      id,
      dateRead: dayjs().format("MM/DD/YYYY"),
    });
  }, [id, setArticleList]);

  return (
    <>
      <GAData
        articleid={id}
        author={getArticleAuthorNames(authors, relatedContributorProfile)}
        chapter1={mainSection ? getArticleChapter1(mainSection) : ""}
        contentcat={isPremium ? 2 : 1}
        contenttype="1"
        keyword={getArticleKeywords(tags || [])}
        level2={mainSection ? getArticleLevel2(mainSection) : ""}
        pubdate={dayjs(article.updated)
          .tz(dayjsSingaporeTimezone)
          .format("YYYY-MM-DD HH:mm:ss")}
        title={toUnderscoreFromSpace(titleReplaceSingleQuote)}
        product_flag={myBTTags.length > 0 ? "mybt" : "(not set)"}
        user_keyword={
          myBTTags.length > 0
            ? myBTTags.map((tag) => tag.name).join("|")
            : "(not set)"
        }
        story_threads={getArticleStoryThread(tags || [])?.name.toLowerCase()}
        gsChannels={index === 0 ? gsChannels : gsChannelsAPI}
      />

      <MetaTags
        title={renderPageTitleV2({
          kind: "art",
          title: article?.seoFields?.metaTitle || title,
        })}
        author={getArticleAuthorNames(authors, relatedContributorProfile)}
        articleId={id}
        description={article?.seoFields?.metaDescription || firstParagraph}
        keywords={article?.tags?.map((tag) => tag.name)}
        slug={urlPath}
        canonicalUrl={article?.seoFields?.canonicalUrl}
        imageUrl={article?.media?.[0]?.content?.fields?.["original-caas"]?.url}
        imageAlt={
          article?.media?.[0]?.summary?.find(
            (summaryItem) => summaryItem.key === "alttext"
          )?.value
        }
        jsonLd={getArticleJSONLD(article)}
        robots={"index, follow, max-image-preview:large"}
        gsChannels={index === 0 ? gsChannels : gsChannelsAPI}
        articleCategory={articleCategory}
        accessFlag={isPremium ? AccessFlag.Premium : AccessFlag.Free}
        publishedDate={publishedDate}
        updatedDate={updatedDate}
        ogTitle={article?.ogFields?.ogTitle}
        ogDescription={article?.ogFields?.ogDescription}
        ogImageUrl={getOgImageUrl(article?.ogImages)}
      />

      <ArticleSubshareSent {...{ alacrityRes, isModalOpen, setIsModalOpen }} />

      {!isAppWebView ? (
        <ProgressBar
          progressBarContainerRef={progressBarContainerRef}
          progressBarRef={progressBarRef}
        />
      ) : null}

      <article
        className="display-type--mainNewsDefault w-full py-5 font-public-sans"
        data-index={index}
        data-cueid={id}
        data-url-path={urlPath}
        ref={ref}
      >
        <div ref={articleRef}>
          <div
            ref={(e) => {
              if (e?.offsetHeight) {
                handleSetProgressBarWidth(e);
              }
            }}
          >
            <Container rootClassName="md:pt-4">
              <Container rootClassName="px-0 mx-auto md:px-3 w-full md:w-10/12 lg:w-8/12 mb-4">
                <>
                  {isPremium || kicker ? (
                    <div className="flex flex-wrap items-center pb-2 md:px-6 md:pb-4">
                      <ArticleKicker
                        kicker={kicker}
                        className="font-base font-light uppercase"
                      />

                      {isPremium && kicker ? (
                        <div
                          className="ml-3 mr-2 font-poppins text-2xl font-light leading-normal tracking-[0.075rem] text-gray-650"
                          data-testid="kicker-subscriber-label-separator"
                        >
                          ·
                        </div>
                      ) : null}

                      <ArticleSubscriberLabel
                        isPremium={isPremium}
                        displayDot={false}
                        rootClassName={"font-base font-light"}
                      />
                    </div>
                  ) : null}
                </>

                <h1
                  data-testid="article-title"
                  className="mb-2 font-lct text-4xl font-bold !leading-[1.3] tracking-[-0.045rem] text-gray-850 md:px-6 md:text-10xl"
                >
                  {title}
                </h1>

                <>
                  {typeof standFirst === "string" ? (
                    <p className="mb-6 mt-2 font-lucida text-xl tracking-[-0.075rem] text-gray-850 md:px-6 md:text-4xl md:leading-normal">
                      {standFirst}
                    </p>
                  ) : null}
                </>

                <>
                  {publishedDate && updatedDate ? (
                    <ArticleAuthorsList
                      rootClassName="mb-6 md:px-6 min-h-[50px] text-gray-850"
                      authors={authors}
                      contributors={relatedContributorProfile}
                      updatedDate={updatedDate}
                      publishedDate={publishedDate}
                      imgHeight={50}
                      imgWidth={50}
                      alwaysShowDefaultPhoto
                      hasUpdatedTimeDisplayed={
                        displaySetting?.hasUpdatedTimeDisplayed
                      }
                    />
                  ) : null}
                </>

                <>
                  {tags?.length || showGiftIcon ? (
                    <div
                      className={
                        "mb-3 flex items-center justify-between border-b border-t border-gray-250 py-3 md:mx-6 md:mb-6 md:py-4"
                      }
                    >
                      {tags ? (
                        <ArticleKeywords
                          keywords={tags}
                          keywordDisplayOption={KeywordDisplayOption.First}
                          firstKeyworkDisplayClassname={cn("w-full", {
                            "border-r border-gray-175 pr-3 mr-3": showGiftIcon,
                          })}
                          rootClassName={"w-full bg-transparent !p-0"}
                          tagClassName={"border-r-0 px-0 md:px-3"}
                          followtextButton={true}
                          toolTipClassName={
                            "flex flex-col items-end right-0 left-auto"
                          }
                          toolTipArrowClassName={
                            "md:mr-10 border-b-[8px] border-l-[6px] border-r-[6px] border-x-transparent border-b-black border-t-transparent mr-3"
                          }
                          disableTooltip
                          withGiftIcon={showGiftIcon}
                          disableBorder
                        />
                      ) : null}

                      <ArticleSubshare
                        displayNewGiftIcon
                        isPremium={isPremium}
                        userType={OKTAUserInfo?.usertype}
                        {...{
                          handleGenerateGiftURLModal,
                          setIsModalOpen,
                          alacrityRes,
                        }}
                      />
                    </div>
                  ) : null}
                </>

                <>
                  {media ? (
                    <ArticleMainImage
                      media={media}
                      rootClassName={"aspect-3x2 -mx-3 md:mx-0"}
                      imgRootClassName="mx-auto h-full max-h-[20rem] md:max-h-[29rem] lg:max-h-[33rem] relative overflow-hidden"
                      captionRootClassname="py-2 px-3 font-poppins text-xs text-gray-850 md:px-8"
                      thumbnailRootClassName="object-contain flex items-center justify-center relative overflow-hidden h-full"
                      landscapeImgRootClassName={`w-full mx-auto object-contain overflow-hidden ${media.length > 1 ? "aspect-3x2" : ""}`}
                      thumbnailArrowRootClassNameLeft={cn(
                        COMMON_BUTTON_CSS,
                        REMOVE_FOCUS_CSS,
                        "md:-ml-[2.75rem] min-[1040px]:-left-4 left-0 bg-white aspect-square w-auto"
                      )}
                      thumbnailArrowRootClassNameRight={cn(
                        COMMON_BUTTON_CSS,
                        REMOVE_FOCUS_CSS,
                        "md:-mr-[2.75rem] min-[1040px]:-right-4 right-0 bg-white aspect-square w-auto"
                      )}
                      displayBlurBg
                      forceDisplayArrow={false}
                      withPagination
                      withZoom={false}
                    />
                  ) : null}
                </>

                <ArticleAttachments
                  attachments={attachments}
                  rootClassName="md:mx-8"
                />

                <div ref={checkArticleTitleRef} />

                <>
                  {elements && storyline ? (
                    <ArticleParagraphs
                      className="body-content tracking-tighter mb-6 mt-4 font-lucida text-lg font-medium leading-normal tracking-[-0.05625rem] text-gray-850 antialiased md:px-8 md:text-xl"
                      {...{
                        id,
                        elements,
                        seeAlso,
                        embedCodes,
                        displayPaywall,
                        sections,
                        index,
                        gsChannels: index === 0 ? gsChannels : gsChannelsAPI,
                        isPremium,
                        storyline: storyline,
                        displayRelatedLinksAndNewsletterSignupForm:
                          showRelatedLinksAndNewsletterSignupForm,
                        keywords: tags,
                        isGifted: isGiftReceived,
                        adsWrapperClass:
                          "min-h-[375px] w-screen bg-gray-125 py-6 transition-all duration-300 border-y border-gray-175",
                        isBrandedContent,
                        displayStyleForDefaultLayout: true,
                        readMoreArticle: sanitizedRelatedStories,
                        fullScreenAds: true,
                        elementsClass: "mb-4 md:mb-6",
                        forceHeadingCss: true,
                        displayPurchase: {
                          isDisplayed: paywall?.copyright === "SPH",
                          publishedDate,
                          title,
                        },
                        isPreview,
                        urlPath,
                        publishedDate,
                      }}
                    />
                  ) : null}
                </>

                <div ref={endOfArticle}></div>
              </Container>

              <Container rootClassName="flex md:gap-6 lg:flex-row flex-col-reverse">
                <>
                  {!isBrandedContent && !isPaidPressRelease ? (
                    <ArticlesFromSameSectionNew
                      data={sectionNews}
                      sections={sections}
                      displayMoreArticlesWithKeywords={
                        displayMoreArticlesWithKeywords
                      }
                    />
                  ) : null}
                </>

                <>
                  {mostPopularData && !isPaidPressRelease ? (
                    <div className="mx-auto mb-6 lg:min-w-[300px] lg:max-w-[300px]">
                      <MostPopularNew
                        data={mostPopularData}
                        rootClassName="my-5"
                      />
                    </div>
                  ) : null}
                </>
              </Container>
              <>
                {article.urlPath && isOutbrainEnabled ? (
                  <OutBrainWidget
                    articleUrl={article.urlPath}
                    widgetId={index === 0 ? "AR_4" : "AR_5"}
                  />
                ) : null}
              </>
            </Container>
          </div>
        </div>
      </article>
    </>
  );
}
