import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import { textToSlug } from "@util/helpers";
import cx from "classnames";

export type RelatedVideosProps = {
  className?: string;
  videos: BrightcoveVideo[];
};

export default function RelatedVideos({
  className,
  videos,
}: RelatedVideosProps): React.ReactElement {
  return (
    <div>
      <BlockTitle text="RELATED VIDEOS" rootClassName="mb-3" />

      <div className={cx("flex flex-wrap gap-6 lg:flex-nowrap", className)}>
        {videos.map(({ id, name, images }) => {
          const thumbnail = {
            content: {
              type: "picture",
              url: images.thumbnail.src,
            },
          };
          const variant: CardVariations = {
            image: {
              isVideo: true,
              position: "left",
              width: "w-120px lg:w-full",
              aspectRatio: "aspect-ratio-16/9",
            },
            content: {
              width: "w-full-120px pl-4 lg:w-full lg:pl-0 lg:pt-4",
            },
            title: {
              color: "text-gray-850",
              size: "text-base",
              extraClass: "font-lct",
            },
          };
          return (
            <BasicCard
              key={id}
              id={id}
              slug={`/videos/${textToSlug(name)}/${id}`}
              title={name}
              media={thumbnail}
              variations={variant}
              rootClassName="w-full lg:w-3/12 h-full"
            />
          );
        })}
      </div>
    </div>
  );
}
