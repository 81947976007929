import { Helmet } from "react-helmet-async";

type PreloadLCPImageProps = { imageSource: string };

export default function PreloadLCPImage({
  imageSource,
}: PreloadLCPImageProps): React.ReactElement {
  return (
    <Helmet>
      <link rel="preload" as="image" href={imageSource} />
    </Helmet>
  );
}
