import type { SectionContenxt } from "@app/types/Page";

export type SectionDefaultProps = {
  parentCategory?: string;
  childCategory?: string;
} & Pick<SectionContenxt, "data">;

export enum TagType {
  StoryThread = "storyThread",
  Keyword = "keyword",
}
