import { Field } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import cx from "classnames";

export type TableProps = {
  fields: Field[];
  className?: string;
};

export default function Table({
  className,
  fields,
}: TableProps): React.ReactElement {
  const tableEditor = fields.filter(
    (field) => field.name === Name.TableEditor
  )?.[0]?.value;

  // @TODO Currently there is no use of this for BT.
  // const rowHeader = fields
  //   .filter((field) => field.name === Name.RowHeader)
  //   .at(0)?.booleanValue;
  // const columnHeader = fields
  //   .filter((field) => field.name === Name.ColumnHeader)
  //   .at(0)?.booleanValue;

  return (
    <>
      {tableEditor ? (
        <div
          className="overflow-x-auto"
          data-testid="table-component"
          data-story-element={Type.Table}
        >
          <table className={cx("w-full table-auto", className)}>
            <tbody className="dark:bg-slate-800 bg-white">
              {JSON.parse(tableEditor).map((row: string[], index: number) => (
                <tr key={index}>
                  {row.map((cell, _index) => (
                    <td
                      key={_index}
                      className="border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400 border-b p-4"
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
}
