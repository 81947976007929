import { RouteFactory } from "@app/routePaths";
import { Type } from "@app/types/enums";

export const OUTBRAIN_SCRIPT = "https://widgets.outbrain.com/outbrain.js";
export const ELEMENTS_TYPE_TO_EXCLUDE = [
  Type.Headline.valueOf(),
  Type.Kicker.valueOf(),
  Type.Standfirst.valueOf(),
  Type.Slug.valueOf(),
  Type.ExternalLink.valueOf(),
  Type.InternalLink.valueOf(),
  Type.Map.valueOf(),
  Type.Endnote.valueOf(),
  Type.Relation.valueOf(),
];

export const KEYWORDS_TO_DISPLAY_MORE_SECTION = [
  RouteFactory.keywordsBudget2025,
];
