import { SanitizedSectionArticle } from "@app/types/Cue";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";

type DefaultStaticLayoutProps = {
  content: SanitizedSectionArticle;
};
export default function DefaultStaticLayout({
  content,
}: DefaultStaticLayoutProps): React.ReactElement {
  const { id, elements, storyLine } = content;

  return (
    <Container>
      <Row rootClassName="justify-center">
        <Column rootClassName="w-full my-8">
          <ArticleParagraphs
            elements={elements}
            id={id}
            storyline={storyLine || []}
            isPremium={false}
            className="text-left"
            isBoxWrapped
          />
        </Column>
      </Row>
    </Container>
  );
}
