import { BlockTitle } from "@blocks/Blocks";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import { useMoreVideos } from "@hooks/userMoreVideos";
import MoreVideosCard from "@src/app/pages/Videos/components/MoreVideoCard";

export type MoreVideosProps = {
  className?: string;
  videos: BrightcoveVideo[];
};

export default function MoreVideos({
  className,
  videos,
}: MoreVideosProps): React.ReactElement {
  const { moreVideos, ref } = useMoreVideos(videos);

  return (
    <div className={className}>
      <BlockTitle text="MORE VIDEOS" rootClassName="mb-3" />

      <div className="mb-8 grid gap-6 lg:grid-cols-2">
        {moreVideos.map((video, index) => {
          return <MoreVideosCard key={video.id} video={video} index={index} />;
        })}
      </div>

      <div ref={ref} />
    </div>
  );
}
