import { RouteFactory } from "@app/routePaths";
import { ConfigObj } from "@typings/Cue";
import { ConfigTypeEnums } from "@typings/enums";

export const GE2025_WHATSAPP_SHARE_URL =
  "https://www.whatsapp.com/channel/0029VaI0vLF29753DedKkb24";
export const GE2025_PAGE_QUERY = RouteFactory.keywordsSingaporeGe2025;
export const GE2025_KEYWORD = "ge2025";
export const GE2025_PAGE_SIZE = 10;

export const defaultConfig: ConfigObj[] = [
  {
    type: ConfigTypeEnums.TOPSTORIES,
    gaObject: {
      parameters: [
        {
          key: "ref",
          value: "ge2025-top-stories",
        },
      ],
      eventObject: {
        eventCategory: "GE 2025",
        eventLabel: "top stories",
        eventAction: "visible",
        event: "custom_event",
      },
    },
    children: [
      {
        type: ConfigTypeEnums.COMINGUP,
        gaObject: {
          parameters: [
            {
              key: "ref",
              value: "ge2025-coming-up",
            },
          ],
          eventObject: {
            eventCategory: "GE 2025",
            eventLabel: "coming up",
            eventAction: "visible",
            event: "custom_event",
          },
        },
      },
      {
        type: ConfigTypeEnums.VIDEOS,
        gaObject: {
          parameters: [
            {
              key: "ref",
              value: "ge2025-latest-video",
            },
          ],
          eventObject: {
            eventCategory: "GE 2025",
            eventLabel: "latest video",
            eventAction: "visible",
            event: "custom_event",
          },
        },
      },
    ],
  },
  {
    type: ConfigTypeEnums.IFRAME,
    title: "ELECTORAL BOUNDARY MAP",
    src: "https://elections.data.gov.sg/en/map?isScrollable=true&primaryColor=%236253E8&view=Boundary%20Change&lang=en&year=2020&constituenciesView=all",
    desktop: {
      height: 650,
    },
    mobile: {
      height: 475,
    },
    gaObject: {
      eventObject: {
        eventCategory: "GE 2025",
        eventLabel: "electoral boundary",
        eventAction: "visible",
        event: "custom_event",
      },
    },
  },
  {
    type: ConfigTypeEnums.WHATSAPP,
    onlyMobile: true,
    gaObject: {
      eventObject: {
        eventCategory: "GE 2025",
        eventLabel: "whatsapp",
        eventAction: "visible",
        event: "custom_event",
      },
    },
  },
  {
    type: ConfigTypeEnums.RELATEDSTORIES,
    gaObject: {
      parameters: [
        {
          key: "ref",
          value: "ge2025-latest-news",
        },
      ],
      eventObject: {
        eventCategory: "GE 2025",
        eventLabel: "latest news",
        eventAction: "visible",
        event: "custom_event",
      },
    },
    children: [
      {
        type: ConfigTypeEnums.WHATSAPP,
        onlyDesktop: true,
        gaObject: {
          eventObject: {
            eventCategory: "GE 2025",
            eventLabel: "whatsapp",
            eventAction: "visible",
            event: "custom_event",
          },
        },
      },
      {
        type: ConfigTypeEnums.ADS,
        adType: "imu2",
      },
    ],
  },
];
