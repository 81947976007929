import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  type BuilderContent,
  Content,
  fetchOneEntry,
  getBuilderSearchParams,
  isPreviewing,
} from "@builder.io/sdk-react";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import NoAdSettings from "@components/Advertisement/NoAdSettings";
import Container from "@components/Container/Container";
import CustomError from "@components/Error/CustomError";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { customComponents } from "@pages/Builder/components/CustomComponents";
import { DefaultNavigationData } from "@typings/Page";
import { replaceTextSingleQuote, toUnderscoreFromSpace } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import { HttpStatusCode } from "axios";

import { BUILDER_API_KEY } from "./utils/constants";

const MODEL_NAME = "page";

export default function CatchAllBuilderRoutes({
  seoFooterData,
}: DefaultNavigationData): React.ReactElement {
  const { pathname } = useLocation();
  const adBlockDetected = useDetectAdBlock();

  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState<BuilderContent | null>(null);

  useEffect(() => {
    fetchOneEntry({
      model: MODEL_NAME,
      apiKey: BUILDER_API_KEY,
      userAttributes: {
        urlPath: pathname,
      },
      options: getBuilderSearchParams(new URL(location.href).searchParams),
    })
      .then((content) => {
        if (content) {
          setContent(content);
        }
        setNotFound(!content);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log("CatchAllBuilderRoutes: ", err);
      });
  }, [pathname]);

  console.log("[builder] pathname", pathname); // eslint-disable-line no-console
  console.log("[builder] notFound", notFound); // eslint-disable-line no-console
  console.log("[builder] content", content); // eslint-disable-line no-console
  console.log("[builder] isPreviewing", isPreviewing()); // eslint-disable-line no-console

  // If no page is found, return
  // a 404 page from your code.
  if (notFound && !isPreviewing()) {
    return <CustomError statusCode={HttpStatusCode.NotFound} />;
  }

  // return the page when found
  return (
    <div className="w-full">
      <AdSettings adNames={["lb1", "catfish", "abm"]} path={pathname} />

      {isPreviewing() ? <NoAdSettings /> : null}

      <MetaTags
        title={content?.data?.title || ""}
        description={content?.data?.description || ""}
        slug={pathname}
      />

      <GAData
        title={toUnderscoreFromSpace(
          replaceTextSingleQuote(content?.data?.title || "")
        )}
        level2="miscellaneous"
        contentcat={1}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <Container rootClassName="py-8">
        <Content
          content={content}
          model={MODEL_NAME}
          apiKey={BUILDER_API_KEY}
          customComponents={customComponents}
        />
      </Container>

      <SeoFooter data={seoFooterData} />
      <Ad adType="catfish" />
      <Ad adType="abm" />
    </div>
  );
}
