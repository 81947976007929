import { Helmet } from "react-helmet-async";

export default function ThriveFavicon(): React.ReactElement {
  return (
    <Helmet>
      <link rel="icon" href="/favicon-thrive.png" type="image/png" />
      <link rel="mask-icon" href="/favicon-thrive.png" color="#000000" />
    </Helmet>
  );
}
