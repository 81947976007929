import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";

export type ImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  image: string | null;
};

export const ImageModal = ({ isOpen, onClose, image }: ImageModalProps) => {
  if (!isOpen || !image) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-850/90 p-4"
      onClick={onClose}
    >
      <div
        className="absolute inset-0 box-border h-full w-full p-2 text-center"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="relative inline-block">
          <div className="leading-none">
            <button
              title="Close (Esc)"
              type="button"
              className="absolute right-0 cursor-pointer px-1.5 text-right"
              onClick={onClose}
            >
              <Icon type={IconTypeEnum.close} size={24} />
            </button>
            <figure>
              <img
                src={image}
                alt=""
                className="mx-auto block h-auto max-h-[90vh] w-auto max-w-full object-contain py-10 leading-none"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};
