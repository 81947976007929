import { RouteFactory } from "@app/routePaths";
import uobLogo from "@assets/logo-uob-blue.png";
import aseanBusinessLogo from "@assets/verticals/asean-logo-black.svg";
import SectionMasthead from "@components/SectionMasthead/SectionMasthead";

export default function AseanBanner(): React.ReactElement {
  return (
    <div className="my-6 flex w-full flex-wrap items-center justify-center gap-2 border-b border-gray-850 pb-2 md:justify-between">
      <SectionMasthead
        link={RouteFactory.aseanBusiness}
        masthead={{
          alt: "Asean Business logo",
          src: aseanBusinessLogo,
          width: 240,
          height: 57,
        }}
      />
      <div className="block h-[1px] w-full bg-gray-850 md:hidden "></div>
      <div className="flex items-center justify-end gap-x-4">
        <span className="font-poppins text-4xs font-light">SPONSORED BY</span>
        <img src={uobLogo} alt="UOB logo" className="w-[5rem]" width="90" />
      </div>
    </div>
  );
}
