import { Link } from "react-router-dom";
import logoSBA from "@assets/events-awards/logo-sba.svg";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";
import {
  EVENTS_AWARDS_SBA_PATH,
  sbaNavigationMenu,
} from "@events-awards/utils/constants";

export default function EventsAwardsSBALogoMenu(): React.ReactElement {
  return (
    <>
      <Link to={EVENTS_AWARDS_SBA_PATH}>
        <img
          src={logoSBA}
          alt="Singapore Business Awards logo"
          width={500}
          height={71}
          className="mx-auto"
        />
      </Link>

      <EventsAwardsNavigationMenu
        menuData={sbaNavigationMenu}
        linkClassName="after:bg-eventAwards-sba-accent"
      />
    </>
  );
}
