import { useEffect } from "react";
import { useFirstMountState } from "react-use";
import { Loading } from "@components/LoadMore/LoadMore";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useGetRecentArticleList } from "@pages/MyAccount/hooks/useGetRecentArticleList";
import { GaObject } from "@pages/MyAccount/utils/types";
import useRecentReadStore from "@store/useRecentReadStore";

import MyAccountRecentReadEmptyState from "./MyAccountRecentReadEmptyState";
import MyAccountRecentReadList from "./MyAccountRecentReadList";

export default function MyAccountRecentReadPage(): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const isFirstMount = useFirstMountState();

  const articleIdList =
    useRecentReadStore((state) => state.articleIdList) || [];
  const { readArticleList, isLoading, ref, handleDeleteArticle } =
    useGetRecentArticleList({
      articleIdList,
    });

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!isFirstMount) return;

    const gaObject: GaObject = {
      event: "custom_event",
      eventCategory: "recent read",
      eventAction: "visible",
      eventLabel: window.location.href,
    };

    sendDataToGTM(gaObject);
  }, [sendDataToGTM, isFirstMount]);

  return (
    <div data-testid="my-account-Recent-read-page">
      {readArticleList.length > 0 ? (
        <MyAccountRecentReadList
          articleList={readArticleList}
          loadMoreRef={ref}
          handleDeleteArticle={handleDeleteArticle}
        />
      ) : (
        <MyAccountRecentReadEmptyState />
      )}

      <Loading isLoading={isLoading} />
    </div>
  );
}
