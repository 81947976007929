import { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  SectionSupplementsContextData,
  SectionSupplementsContextGroup,
} from "@app/types/Page";
import axios from "axios";

type UseLoadMoreSupplementStoriesProps = {
  size: number;
  section: string;
} & Pick<SectionSupplementsContextData, "group">;

export const useLoadMoreSupplementStories = ({
  group,
  size,
  section,
}: UseLoadMoreSupplementStoriesProps) => {
  const [moreGroup, setMoreGroup] = useState(group);
  const [hasMore, setHasMore] = useState(group.length >= size);

  const handleLoadMore = async () => {
    const page = moreGroup.length / size + 1;

    if (moreGroup.length === 0) return;

    const newMoreGroup = await fetchSectionSupplementsMoreData(
      page,
      size,
      section
    );

    if (newMoreGroup.length < size) setHasMore(false);

    setMoreGroup((prev) => [...prev, ...newMoreGroup]);
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (!inView) return;
      if (!hasMore) return;

      handleLoadMore();
    },
  });

  return { moreGroup, handleLoadMore, hasMore, ref };
};

const fetchSectionSupplementsMoreData = async function (
  page: number,
  size: number,
  section: string
): Promise<SectionSupplementsContextGroup[]> {
  try {
    const response = await axios.post<SectionSupplementsContextGroup[]>(
      "/_plat/api/v1/section-supplements-more-stories",
      { page, size, section }
    );
    return response.data || [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }
};
