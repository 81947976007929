import { useBrightcoveFeaturedVideos } from "@hooks/useBrightcoveFeaturedVideos";

import BrightcoveVideoPlaylistFeatured from "./BrightcoveVideoPlaylistFeatured";
import BrightcoveVideoPlaylistPagination from "./BrightcoveVideoPlaylistPagination";

export type BrightcoveVideoPlaylistProps = {
  className?: string;
};

export default function BrightcoveVideoPlaylist({
  className,
}: BrightcoveVideoPlaylistProps): React.ReactElement {
  const { isLoading, activeVideo, videos, setActiveVideo } =
    useBrightcoveFeaturedVideos();

  return (
    <div className={className}>
      {isLoading ? (
        <LoaderShimmer />
      ) : (
        <>
          {activeVideo ? (
            <BrightcoveVideoPlaylistFeatured {...activeVideo} />
          ) : null}

          <BrightcoveVideoPlaylistPagination
            videos={videos}
            setActiveVideo={setActiveVideo}
            activeVideo={activeVideo}
          />
        </>
      )}
    </div>
  );
}

const LoaderShimmer = (): React.ReactElement => {
  return (
    <div className="h-full overflow-hidden">
      <div className="aspect-video w-full bg-gray-400 object-cover object-center"></div>
      <div className="py-6">
        <span className="mb-4 h-6 w-1/2 animate-pulse bg-gray-500"></span>
        <p className="mb-3 h-3 w-full animate-pulse bg-gray-400 leading-relaxed"></p>
        <p className="mb-3 h-3 w-2/3 animate-pulse bg-gray-400 leading-relaxed"></p>
        <p className="mb-3 h-3 w-1/2 animate-pulse bg-gray-400 leading-relaxed"></p>
        <div className="flex flex-wrap items-center ">
          <span className="bg-indigo-300 mt-2 inline-flex h-4 w-32 animate-pulse items-center md:mb-2 lg:mb-0"></span>
          <span className="bg-indigo-300 ml-auto mr-3 mt-2 inline-flex h-4 w-16 animate-pulse items-center px-2 py-1 pr-5 text-sm leading-none"></span>
        </div>
      </div>
    </div>
  );
};
