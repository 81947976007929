import { Block } from "@blocks/Blocks";
import { e50QuickLinksMenu } from "@e50/utils/constants";
import EventsAwardsNavigationMenu from "@events-awards/components/EventsAwardsNavigationMenu";

export default function EventsAwardsE50QuickLinks(): React.ReactElement {
  return (
    <Block rootClassName="py-18">
      <h2 className="mb-6 border-b border-gray-175 pb-3 text-left font-public-sans text-base font-bold leading-5">
        {"ENTERPRISE 50 ©"}
      </h2>

      <div className="flex">
        <h3 className="mr-auto text-left font-public-sans text-lg font-normal">
          Quicklinks
        </h3>

        <EventsAwardsNavigationMenu
          menuData={e50QuickLinksMenu}
          className="m-0 border-0 p-0"
          linkClassName="after:bg-eventAwards-e50-accent text-base font-public-sans font-light after:right-0"
        />
      </div>
    </Block>
  );
}
