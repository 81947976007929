import { KeywordContext } from "@app/types/Page";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import SeoFooter from "@components/Footer/SeoFooter/SeoFooter";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import KeywordFilter from "@components/KeywordFilter";
import { KeywordType } from "@components/KeywordFilter/types";
import MetaTags from "@components/MetaTags/MetaTags";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import Overview from "@pages/Section/components/Overview/Overview";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { renderMetaDescription } from "@src/app/components/MetaTags/helpers";
import { sectionNavigationItems } from "@util/constant";

type KeywordPageContentProps = Pick<KeywordContext, "data">;

export default function KeywordPageContent({
  data: { title, entity, overview, seoFooterData },
}: KeywordPageContentProps): React.ReactElement {
  const termName = entity.name;
  const termPath = entity.urlPath;

  const breakingNews = useTrimmedBreakingNews();
  const mostPopular = useMostReadArticleList();

  return (
    <div className="w-full" data-testid="keyword-page-content-component">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={termPath}
        adTargets={[
          { key: "page", value: "listing" },
          { key: "bttags", value: getFormattedTextForAds(termName) },
        ]}
      />

      <MetaTags
        title={title}
        description={renderMetaDescription({ kind: "keyword", entity: entity })}
        ogType="article"
        slug={termPath}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[calc(50px+0.50rem)] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[calc(250px+1.5rem)]"
      />

      <Header
        parentCategory="keywords"
        childCategory={termPath.replace("/keywords/", "")}
        categoryDataMapping={sectionNavigationItems}
      />

      <PulseEntry />

      <Container rootClassName="pt-6">
        <KeywordFilter
          followtextButton
          tag={entity}
          rootClassName="mb-7 py-3 pt-6 inline-block w-full border-b border-gray-850 "
          tagClassName="border-none !font-playfair !text-6xl md:!text-8xl !p-0 text-gray-850 !uppercase !text-left !text-normal"
          toolTipClassName={
            "!right-0 top-full min-[425px]:!left-auto min-[425px]:top-1/2 min-[425px]:flex min-[425px]:-translate-y-1/2 min-[425px]:-translate-x-0 min-[425px]:items-center xl:left-auto xl:top-full xl:block xl:-translate-x-0 xl:translate-y-0 top-full min-[425px]:top-full min-[425px]:!block left-auto w-[200px] xs:w-[250px] xl:translate-x-0"
          }
          toolTipArrowClassName={
            "xl:ml-0 xl:mr-10 md:mr-10 border-b-[8px] border-l-[6px] border-r-[6px] border-x-transparent border-b-black mr-3 min-[425px]:border-r-transparent min-[425px]:!border-b-black min-[425px]:border-t-transparent relative left-[90%] md:left-[80%] mr-0 border-t-transparent ml-0"
          }
          disableTooltip
          keywordType={KeywordType.listing}
        />

        <Row>
          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <Overview data={overview} testId={"section-listing-component"} />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-6">
            <div className="mb-6 lg:h-[800px]">
              <Ad
                adType="imu2"
                className="flex flex-col items-center justify-center lg:!sticky lg:top-[60px]"
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <SeoFooter data={seoFooterData} />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
