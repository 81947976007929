import React from "react";
import { Link, useLocation } from "react-router-dom";
import useScrollToCenter from "@hooks/useScrollToCenter";
import { AwardsMenu } from "@pages/Section/layouts/EventsAwardsPage/utils/types";
import { cn } from "@util/helpers";

type EventsAwardsNavigationMenuProps = {
  className?: string;
  menuData: AwardsMenu[];
  linkClassName?: string;
  listClassName?: string;
  listItemClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;

// Helper function to check if the current path matches the menu link
export const isActiveLink = (menuLink: string, pathname: string) => {
  // Remove trailing slash for consistent comparison
  const currentPath = pathname.replace(/\/$/, "");
  const baseMenuLink = menuLink.replace(/\/$/, "");

  // Check if current path exactly matches or starts with menu link and has /gallery/ pattern
  return (
    currentPath === baseMenuLink ||
    (currentPath.startsWith(baseMenuLink) && currentPath.includes("/gallery/"))
  );
};

export default function EventsAwardsNavigationMenu({
  className,
  menuData,
  linkClassName = "",
  listClassName = "",
  listItemClassName = "",
}: EventsAwardsNavigationMenuProps): React.ReactElement {
  const { pathname } = useLocation();

  // Use the updated hook
  const { containerRef, targetRef } = useScrollToCenter<
    HTMLUListElement,
    HTMLLIElement
  >(100);

  return (
    <div className={cn("mt-6 border-t border-gray-175 py-6", className)}>
      <ul
        ref={containerRef}
        className={cn(
          "no-scrollbar flex justify-between overflow-auto pb-4",
          listClassName
        )}
      >
        {menuData.map(({ link, text, enabled }) => {
          const isActive = isActiveLink(link, pathname);

          return (
            <React.Fragment key={link}>
              {enabled ? (
                <li
                  // Attach the targetRef only to the active item
                  ref={isActive ? targetRef : null}
                  className={cn(
                    "flex-shrink-0 px-4 text-center",
                    listItemClassName
                  )}
                >
                  <Link
                    to={link}
                    reloadDocument
                    className={cn(
                      "relative border-b-[3px] border-transparent pb-2 font-public-sans text-base font-light",
                      "after:absolute after:-bottom-0.5 after:block after:h-[2px] after:w-full",
                      "after:origin-center after:scale-x-0 after:transition after:duration-300 after:hover:scale-x-100",
                      linkClassName,
                      { "after:scale-x-100": isActive }
                    )}
                  >
                    {text}
                  </Link>
                </li>
              ) : null}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
}
