import { VWO_SCRIPT_CONTENT } from "@util/customScripts/vwo";

import useLink from "./useLink";
import useScriptFunction from "./useScriptFunction";

// Custom hook for VWO integration
export default function useVWO() {
  useLink({
    id: "vwoCode-preconnect",
    rel: "preconnect",
    href: "https://dev.visualwebsiteoptimizer.com",
  });

  useScriptFunction({
    id: "vwoCode",
    value: VWO_SCRIPT_CONTENT,
  });
}
