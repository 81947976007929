import { HTMLAttributes } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { cn, getFormattedUrlPath } from "@util/helpers";

export type ViewsProps = {
  articles: SanitizedSectionArticle[];
  testId?: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function Views({
  testId,
  articles,
  className,
}: ViewsProps): React.ReactElement {
  const firstArticle = articles.slice(0, 1);
  const restArticle = articles.slice(1, 3);

  const parameters = [
    {
      key: "ref",
      value: "budget2025-views",
    },
  ];

  return (
    <div className={className} data-testid={testId}>
      <BlockTitle text="VIEWS" rootClassName="lg:text-center" />

      <div className="border-gray-175">
        <div className="border-b border-gray-175">
          {firstArticle.map((article) => {
            const {
              id,
              blurb,
              title,
              authors,
              urlPath,
              relatedContributorProfile,
            } = article;

            return (
              <BasicCard
                key={id}
                id={id}
                slug={getFormattedUrlPath(urlPath, parameters)}
                title={title}
                blurb={blurb}
                rootClassName="py-4 lg:py-5"
                author={authors}
                contributorProfile={relatedContributorProfile}
                variations={{
                  content: {
                    width: "w-full",
                    extraClass: "text-start lg:text-center",
                    blurb: {
                      extraClass: "lg:!text-lg !block",
                    },
                  },
                  title: {
                    color: "text-gray-850",
                    size: "text-lg lg:text-4xl",
                  },
                  byline: {
                    size: "text-xs",
                    imageWidth: 32,
                    imageHeight: 32,
                    extraClass: "flex lg:justify-center",
                  },
                }}
              />
            );
          })}
        </div>

        <div className="grid divide-y lg:mt-4 lg:grid-cols-2 lg:gap-4 lg:divide-x lg:divide-y-0">
          {restArticle.map((article, index: number) => {
            const {
              id,
              urlPath,
              title,
              authors,
              relatedContributorProfile,
              blurb,
            } = article;

            return (
              <div key={id} className="lg:grid-cols-1">
                <BasicCard
                  rootClassName={cn("py-4", { "lg:pl-3": index !== 0 })}
                  id={id}
                  slug={getFormattedUrlPath(urlPath, parameters)}
                  title={title}
                  blurb={blurb}
                  author={authors}
                  contributorProfile={relatedContributorProfile}
                  variations={{
                    title: {
                      color: "text-gray-850",
                      size: "text-lg",
                    },
                    content: {
                      width: "w-full",
                      blurb: {
                        extraClass: "lg:!text-base !block",
                      },
                    },
                    byline: {
                      size: "text-xs",
                      imageWidth: 32,
                      imageHeight: 32,
                    },
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
