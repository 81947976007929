import { SanitizedSectionArticle } from "@app/types/Cue";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import EventsAwardsE50QuickLinks from "@e50/components/EventsAwardsE50QuickLinks";
import EventsAwardsLogoMenu from "@events-awards/components/EventsAwardsLogoMenu";
import { AwardType } from "@events-awards/utils/types";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";

type EventsAwardsE50StaticPagesProps = {
  content: SanitizedSectionArticle;
};
export default function EventsAwardsE50StaticPages({
  content,
}: EventsAwardsE50StaticPagesProps): React.ReactElement {
  const { id, elements, storyLine } = content;

  return (
    <Container data-testid="events-awards-e50-static-pages-component">
      <Row rootClassName="justify-center">
        <Column rootClassName="w-full lg:w-8/12 text-center my-8">
          <EventsAwardsLogoMenu awardType={AwardType.E50} />

          <ArticleParagraphs
            elements={elements}
            id={id}
            storyline={storyLine || []}
            isPremium={false}
            className="mb-16 pb-15 text-left"
            isBoxWrapped
            accordionHeaderClass="border-none"
          />

          <EventsAwardsE50QuickLinks />
        </Column>
      </Row>
    </Container>
  );
}
